import React, { useState ,useContext} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Info from "../assets/images/info.svg";
import { TokenVerificationLoggedin, ScreenView } from "../utils/tokenVerify";
import axios from "axios";
import { LoadingContext } from "react-router-loading";
import configData from "../config/config.json";
import { ToastContainer, toast } from "react-toastify";
import TableDomain from "./TableDomain";
import { saveAs } from "file-saver";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "antd";
import Select, { components } from "react-select";


const ListDomain = () => {
  const loadingContext = useContext(LoadingContext);
  const [active, setActive] = useState(true);

  React.useEffect(() => {
    TokenVerificationLoggedin();
    ScreenView();

    getData();
    loadingContext.done()
  }, [active]);







  const notifysuccess = (msg) => toast.success(msg, { autoClose: 1000 });

  const notifyfailure = (msg) => toast.error(msg, { autoClose: 1000 });

  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 2000 });




  var arr;

  const [checkRole, setCheckRole] = useState("RM");

  //table header and row creation
  const [settingHeader, setSettingHeader] = useState([]);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  // loader
  const [isLoading, setIsLoading] = useState(true);


React.useState(()=>{

  let status = Cookies.get("token_moment") ? true : false;
  if (status) {
  let token = Cookies.get("token_moment");

  axios
  .get(configData.SERVER_URL + "advertiser/advertiserMinifiedList", {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  })
  .then((response) => {
    if (response.status === 200) {
      let makingData = response.data.result.map((val) => {
        return {
          value: val.advertiserId,
          label: val.advertiserName,
        };
      });

      setAdvertiserList(makingData);
    }
  })
  .catch((error) => {});
}

},[])


  const getData = () => {
    let token = Cookies.get("token_moment");
    arr = [];

    axios
      .get(configData.SERVER_URL + `campaign/domainList`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((response) => {
        const data = response.data.data[0];
        const dataValue = response.data.data;

        setData(dataValue);
        setInitialData(dataValue[0]);

        let status= Cookies.get("token_moment")?true:false;
        if(status){
    
        let token = Cookies.get("token_moment");
        let role = jwt_decode(token).role;
        setCheckRole(role)
  
        

        {role==="RM"?

        arr.push({
          Header: "Actions",
          accessor: "Actions",
          Cell: (cell) => (

            <>
            
              <svg width="16" height="16" className="wallet_hover" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) =>
                handleButtonClick(
                  cell.row.original["List ID"],
                  cell.row.original["List Name"]
                )
              }>
                <path d="M7.99935 10.3833C7.91046 10.3833 7.82713 10.3695 7.74935 10.342C7.67157 10.3144 7.59935 10.2671 7.53268 10.2L5.13268 7.79996C5.01046 7.67774 4.94935 7.52218 4.94935 7.3333C4.94935 7.14441 5.01046 6.98885 5.13268 6.86663C5.25491 6.74441 5.41335 6.68063 5.60802 6.67529C5.80268 6.66996 5.96091 6.72818 6.08268 6.84996L7.33268 8.09996V3.33329C7.33268 3.14441 7.39668 2.98596 7.52468 2.85796C7.65268 2.72996 7.81091 2.66618 7.99935 2.66663C8.18824 2.66663 8.34668 2.73063 8.47468 2.85863C8.60268 2.98663 8.66646 3.14485 8.66602 3.33329V8.09996L9.91602 6.84996C10.0382 6.72774 10.1967 6.6693 10.3913 6.67463C10.586 6.67996 10.7442 6.74396 10.866 6.86663C10.9882 6.98885 11.0494 7.14441 11.0494 7.3333C11.0494 7.52218 10.9882 7.67774 10.866 7.79996L8.46602 10.2C8.39935 10.2666 8.32713 10.314 8.24935 10.342C8.17157 10.37 8.08824 10.3837 7.99935 10.3833ZM3.99935 13.3333C3.63268 13.3333 3.31868 13.2026 3.05735 12.9413C2.79602 12.68 2.66557 12.3662 2.66602 12V10.6666C2.66602 10.4777 2.73002 10.3193 2.85802 10.1913C2.98602 10.0633 3.14424 9.99952 3.33268 9.99996C3.52157 9.99996 3.68002 10.064 3.80802 10.192C3.93602 10.32 3.99979 10.4782 3.99935 10.6666V12H11.9993V10.6666C11.9993 10.4777 12.0633 10.3193 12.1913 10.1913C12.3193 10.0633 12.4776 9.99952 12.666 9.99996C12.8549 9.99996 13.0133 10.064 13.1413 10.192C13.2693 10.32 13.3331 10.4782 13.3327 10.6666V12C13.3327 12.3666 13.202 12.6806 12.9407 12.942C12.6794 13.2033 12.3656 13.3337 11.9993 13.3333H3.99935Z" fill="#BDBDBD"/>
</svg>
            </>

         
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        }):
        arr.push({
          Header: "Actions",
          accessor: "Actions",
          Cell: (cell) => (

            <>

<Tooltip title="Download">
          <svg width="16" height="16" className="wallet_hover" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) =>
                handleButtonClick(
                  cell.row.original["List ID"],
                  cell.row.original["List Name"]
                )
              }>
              <path d="M7.99935 10.3833C7.91046 10.3833 7.82713 10.3695 7.74935 10.342C7.67157 10.3144 7.59935 10.2671 7.53268 10.2L5.13268 7.79996C5.01046 7.67774 4.94935 7.52218 4.94935 7.3333C4.94935 7.14441 5.01046 6.98885 5.13268 6.86663C5.25491 6.74441 5.41335 6.68063 5.60802 6.67529C5.80268 6.66996 5.96091 6.72818 6.08268 6.84996L7.33268 8.09996V3.33329C7.33268 3.14441 7.39668 2.98596 7.52468 2.85796C7.65268 2.72996 7.81091 2.66618 7.99935 2.66663C8.18824 2.66663 8.34668 2.73063 8.47468 2.85863C8.60268 2.98663 8.66646 3.14485 8.66602 3.33329V8.09996L9.91602 6.84996C10.0382 6.72774 10.1967 6.6693 10.3913 6.67463C10.586 6.67996 10.7442 6.74396 10.866 6.86663C10.9882 6.98885 11.0494 7.14441 11.0494 7.3333C11.0494 7.52218 10.9882 7.67774 10.866 7.79996L8.46602 10.2C8.39935 10.2666 8.32713 10.314 8.24935 10.342C8.17157 10.37 8.08824 10.3837 7.99935 10.3833ZM3.99935 13.3333C3.63268 13.3333 3.31868 13.2026 3.05735 12.9413C2.79602 12.68 2.66557 12.3662 2.66602 12V10.6666C2.66602 10.4777 2.73002 10.3193 2.85802 10.1913C2.98602 10.0633 3.14424 9.99952 3.33268 9.99996C3.52157 9.99996 3.68002 10.064 3.80802 10.192C3.93602 10.32 3.99979 10.4782 3.99935 10.6666V12H11.9993V10.6666C11.9993 10.4777 12.0633 10.3193 12.1913 10.1913C12.3193 10.0633 12.4776 9.99952 12.666 9.99996C12.8549 9.99996 13.0133 10.064 13.1413 10.192C13.2693 10.32 13.3331 10.4782 13.3327 10.6666V12C13.3327 12.3666 13.202 12.6806 12.9407 12.942C12.6794 13.2033 12.3656 13.3337 11.9993 13.3333H3.99935Z" fill="#BDBDBD"/>
</svg>
</Tooltip>
<Tooltip title="Edit">
              <svg width="16" height="16" className="edit_hover" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => {
                setShowModal(true);
                setFileDomainName(cell.row.original["List Name"]);
                setEditId(cell.row.original["List ID"]);
                setFileDomainNameBox(cell.row.original["List Name"]);
                setSelectedAdvertiser(cell.row.original["selectedAdvertiser"])
              }}>
                <path d="M3.33333 12.6667H4.26667L10.0167 6.91671L9.08333 5.98337L3.33333 11.7334V12.6667ZM12.8667 5.95004L10.0333 3.15004L10.9667 2.21671C11.2222 1.96115 11.5362 1.83337 11.9087 1.83337C12.2807 1.83337 12.5944 1.96115 12.85 2.21671L13.7833 3.15004C14.0389 3.4056 14.1722 3.71404 14.1833 4.07537C14.1944 4.43626 14.0722 4.74449 13.8167 5.00004L12.8667 5.95004ZM2.66667 14C2.47778 14 2.31956 13.936 2.192 13.808C2.064 13.6805 2 13.5223 2 13.3334V11.45C2 11.3612 2.01667 11.2752 2.05 11.192C2.08333 11.1085 2.13333 11.0334 2.2 10.9667L9.06667 4.10004L11.9 6.93337L5.03333 13.8C4.96667 13.8667 4.89178 13.9167 4.80867 13.95C4.72511 13.9834 4.63889 14 4.55 14H2.66667ZM9.55 6.45004L9.08333 5.98337L10.0167 6.91671L9.55 6.45004Z" fill="#BDBDBD"/>
              </svg>
              </Tooltip>
            
            </>

         
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        })}
      }

        for (const [key] of Object.entries(data)) {
          arr.push({
            Header: key,
            accessor: key,
          });
        }

        setSettingHeader(arr);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleDataReceived = (data) => {
    setShowModal(true);
  };

  const handleButtonClick = (id, name) => {
    handleDownload(id, name);
  };

  const handleDownload = async (id, name) => {
    let token = Cookies.get("token_moment");

    axios
      .get(
        configData.SERVER_URL +
        `campaign/downloadList?list=domainList&fileId=` +
        id,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(blob, name + ".txt");
      })
      .catch((error) => { });
  };



  //////////////////////////////////////////////

  //////////////////////////////////////////////
  // modal related code
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(!showModal);

  //create domainlist
  const [editId, setEditId] = useState(null);
  const [fileDomain, setFileDomain] = useState();
  const [validateFileDomain, setValidateFileDomain] = useState(false);
  const [validateFileDomainValid, setValidateFileDomainValid] = useState(false);
  const [fileDomainName, setFileDomainName] = useState("");
  const [fileDomainNameBox, setFileDomainNameBox] = useState("");
  const [validateFileDomainName, setValidateFileDomainName] = useState(false);
  const [buttonDomainListUpload, setButtonDomainListUpload] = useState(false);

  function handleDomainFile(e) {
    if (e.target.files[0]) {
      setFileDomain(e.target.files[0]);
      setValidateFileDomain(false);

      if (e.target.files[0].type !== "text/plain") {
        setValidateFileDomainValid(true);
      } else {
        setValidateFileDomainValid(false);
      }
    } else {
      setValidateFileDomainValid(false);
      setFileDomain(e.target.files[0]);
    }
  }

  function handleDomainFileName(e) {
    setValidateFileDomainName(false);
    setFileDomainName(e.target.value);
  }

  const [toggleData, setToggleData] = useState(
    Math.floor(Math.random() * 90000) + 10000
  );

  function uploadDomainFile(e) {

if(checkRole==="SA"){


  
  if (fileDomain && fileDomainName !== "" && validateFileDomainValid === false && validateFileDomainName===false && validateFileDomain===false && advertiserIsRequiredError===false && selectedAdvertiser.length!==0) {
    let formData = new FormData();
    formData.append("name", fileDomainName);
    formData.append("advertiser", selectedAdvertiser.value);
    formData.append("file", fileDomain);
    let token = Cookies.get("token_moment");

    if (editId !== null) {
      setButtonDomainListUpload(true);

      axios
        .post(
          configData.SERVER_URL + "campaign/updateDomainList?id=" + editId,
          formData,
          {
            headers: {
              "Content-Type": "multdomainart/form-data",
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            // notifysuccess(response.data.message);

            localStorage.setItem("domainReload", toggleData);
            setToggleData(Math.floor(Math.random() * 90000) + 10000);

            // setTimeout(() => {
            //   setButtonDomainListUpload(false);
            //   setShowModal(false);

            //   window.location.reload();
            // }, 1000);

            setActive(!active)
            notifysuccess(response.data.message);

            setFileDomain(null);
            setValidateFileDomain(false);
            setFileDomainName(null);
            setValidateFileDomainName(false);
            setEditId(null);
            setValidateFileDomainValid(false);
            setFileDomainNameBox(null)
            setSelectedAdvertiser([])
            setAdvertiserIsRequiredError(false)
            setButtonDomainListUpload(false);
            setShowModal(false);
         
          } else {
            notifyfailure(response.data.message);
            setButtonDomainListUpload(false);
          }
        })
        .catch((error) => {
          notifycatch();
          setButtonDomainListUpload(false);
        });
    } else {
      setButtonDomainListUpload(true);

      axios
        .post(configData.SERVER_URL + "campaign/createDomainList", formData, {
          headers: {
            "Content-Type": "multdomainart/form-data",
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.data.status === 201) {
            // notifysuccess(response.data.message);

            localStorage.setItem("domainReload", toggleData);
            setToggleData(Math.floor(Math.random() * 90000) + 10000);

            // setTimeout(() => {
            //   setButtonDomainListUpload(false);
            //   setShowModal(false);

            //   window.location.reload();
            // }, 1000);
            setActive(!active)
            notifysuccess(response.data.message);

            setFileDomain(null);
            setValidateFileDomain(false);
            setFileDomainName(null);
            setValidateFileDomainName(false);
            setEditId(null);
            setValidateFileDomainValid(false);
            setFileDomainNameBox(null)
            setSelectedAdvertiser([])
            setAdvertiserIsRequiredError(false)
            setButtonDomainListUpload(false);
            setShowModal(false);
          } else {
            notifyfailure(response.data.message);
            setButtonDomainListUpload(false);
          }
        })
        .catch((error) => {
          notifycatch();
          setButtonDomainListUpload(false);
        });
    }
  } else {
    if (
      fileDomainName === null ||
      fileDomainName === "" ||
      fileDomainName === undefined
    ) {
      setValidateFileDomainName(true);
    }
    if (!fileDomain) {
      setValidateFileDomain(true);
    }
    if(selectedAdvertiser.length===0){
      setAdvertiserIsRequiredError(true)
    }
  }

}

else{


  
  if (fileDomain && fileDomainName !== "" && validateFileDomainValid === false && validateFileDomainName===false && validateFileDomain===false) {
    let formData = new FormData();
    formData.append("name", fileDomainName);
    formData.append("file", fileDomain);
    let token = Cookies.get("token_moment");

    if (editId !== null) {
      setButtonDomainListUpload(true);

      axios
        .post(
          configData.SERVER_URL + "campaign/updateDomainList?id=" + editId,
          formData,
          {
            headers: {
              "Content-Type": "multdomainart/form-data",
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            // notifysuccess(response.data.message);

            localStorage.setItem("domainReload", toggleData);
            setToggleData(Math.floor(Math.random() * 90000) + 10000);

            // setTimeout(() => {
            //   setButtonDomainListUpload(false);
            //   setShowModal(false);

            //   window.location.reload();
            // }, 1000);

            setActive(!active)
            notifysuccess(response.data.message);

            setFileDomain(null);
            setValidateFileDomain(false);
            setFileDomainName(null);
            setValidateFileDomainName(false);
            setEditId(null);
            setValidateFileDomainValid(false);
            setFileDomainNameBox(null)
            setSelectedAdvertiser([])
            setAdvertiserIsRequiredError(false)
            setButtonDomainListUpload(false);
            setShowModal(false);
          } else {
            notifyfailure(response.data.message);
            setButtonDomainListUpload(false);
          }
        })
        .catch((error) => {
          notifycatch();
          setButtonDomainListUpload(false);
        });
    } else {
      setButtonDomainListUpload(true);

      axios
        .post(configData.SERVER_URL + "campaign/createDomainList", formData, {
          headers: {
            "Content-Type": "multdomainart/form-data",
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.data.status === 201) {
            // notifysuccess(response.data.message);

            localStorage.setItem("domainReload", toggleData);
            setToggleData(Math.floor(Math.random() * 90000) + 10000);

            // setTimeout(() => {
            //   setButtonDomainListUpload(false);
            //   setShowModal(false);

            //   window.location.reload();
            // }, 1000);
            setActive(!active)
            notifysuccess(response.data.message);

            setFileDomain(null);
            setValidateFileDomain(false);
            setFileDomainName(null);
            setValidateFileDomainName(false);
            setEditId(null);
            setValidateFileDomainValid(false);
            setFileDomainNameBox(null)
            setSelectedAdvertiser([])
            setAdvertiserIsRequiredError(false)
            setButtonDomainListUpload(false);
            setShowModal(false);
          } else {
            notifyfailure(response.data.message);
            setButtonDomainListUpload(false);
          }
        })
        .catch((error) => {
          notifycatch();
          setButtonDomainListUpload(false);
        });
    }
  } else {
    if (
      fileDomainName === null ||
      fileDomainName === "" ||
      fileDomainName === undefined
    ) {
      setValidateFileDomainName(true);
    }
    if (!fileDomain) {
      setValidateFileDomain(true);
    }
  }
  
}








  }



  const [advertiserList, setAdvertiserList] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState([]);
  const [advertiserIsRequiredError, setAdvertiserIsRequiredError] =
    useState(false);

  function onChangeAdvertiser(data) {
    setAdvertiserIsRequiredError(false);
    setSelectedAdvertiser(data);


    if (data.value === null || data.value === "") {


      setAdvertiserIsRequiredError(true);
    } 

  }


  return (
    <>
      {/* <ToastContainer/> */}

      <div className="main_contain_area">
        <div className="">
          <div className="table_area_spacing">
            <TableDomain
              columns={settingHeader}
              data={data}
              loading={isLoading}
              initial={initialData}
              onDataReceived={handleDataReceived}
              role={checkRole}
            />
          </div>
        </div>

      </div>

      <Modal className="custom_modal_design" show={showModal} size="md" centered>
        <Modal.Header>
          <Modal.Title>{editId===null?"Add New Domain List":"Edit Domain List"}</Modal.Title>
          <button type="button" data-dismiss="modal" className="btn p-0 " onClick={() => {
            handleShowModal();
            setFileDomain(null);
            setValidateFileDomain(false);
            setFileDomainName(null);
            setValidateFileDomainName(false);
            setEditId(null);
            setValidateFileDomainValid(false);
            setFileDomainNameBox(null)
            setSelectedAdvertiser([])
            setAdvertiserIsRequiredError(false)
          }}
          >
          </button>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="form-row">

{checkRole==="SA"&&
            <Form.Group
                          className="mb-4"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Advertiser * </Form.Label>
                          <Select
                            className="custom_drop_design select-custom p-0"
                            options={advertiserList}
                            placeholder="-Select-"
                            value={selectedAdvertiser}
                            onChange={onChangeAdvertiser}
                            isSearchable={true}
                            isDisabled={editId===null?false:true}
                          ></Select>

                          {advertiserIsRequiredError && (
                            <span className="alert_text">
                              This field is required
                            </span>
                          )}
                        </Form.Group>
}



              <div className="col-md-12 mb-4">
                <label for="validationCustom02">List Name *</label>
                <input type="text" className="form-control" id="domainFileName" name="domainFileName" onChange={handleDomainFileName} value={fileDomainName} ></input>
                {validateFileDomainName && (
                  <span className="alert_text">
                    This field is required
                  </span>
                )}
              </div>

              <Form.Group className="mt-16" controlId="exampleForm.ControlInput1">
                <div className="d-flex justify-content-between">
                  <Form.Label>Domains *
                    <Tooltip title="Upload a txt file with one domain per line">
                          <img src={Info} className="img-fluid" alt="" />
                    </Tooltip>
                  </Form.Label>
                  <button className="blue_text fs-12 cursor-pointer text_hover_colour bg-transparent border-0 text-decoration-none mb-6" onClick={() => {
                        handleDownload("00000", "sample");
                      }}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00065 9.08543C6.92287 9.08543 6.84996 9.07337 6.7819 9.04926C6.71385 9.02515 6.65065 8.98373 6.59232 8.92501L4.49232 6.82501C4.38537 6.71807 4.3319 6.58196 4.3319 6.41668C4.3319 6.2514 4.38537 6.11529 4.49232 6.00835C4.59926 5.9014 4.7379 5.8456 4.90824 5.84093C5.07857 5.83626 5.21701 5.88721 5.32357 5.99376L6.41732 7.08751V2.91668C6.41732 2.7514 6.47332 2.61276 6.58532 2.50076C6.69732 2.38876 6.83576 2.33296 7.00065 2.33335C7.16593 2.33335 7.30457 2.38935 7.41657 2.50135C7.52857 2.61335 7.58437 2.75179 7.58399 2.91668V7.08751L8.67774 5.99376C8.78468 5.88682 8.92332 5.83568 9.09365 5.84035C9.26399 5.84501 9.40243 5.90101 9.50898 6.00835C9.61593 6.11529 9.6694 6.2514 9.6694 6.41668C9.6694 6.58196 9.61593 6.71807 9.50898 6.82501L7.40899 8.92501C7.35065 8.98335 7.28746 9.02476 7.2194 9.04926C7.15135 9.07376 7.07843 9.08582 7.00065 9.08543ZM3.50065 11.6667C3.17982 11.6667 2.90507 11.5523 2.6764 11.3237C2.44774 11.095 2.3336 10.8205 2.33399 10.5V9.33335C2.33399 9.16807 2.38999 9.02943 2.50199 8.91743C2.61399 8.80543 2.75243 8.74962 2.91732 8.75001C3.0826 8.75001 3.22124 8.80601 3.33324 8.91801C3.44524 9.03001 3.50104 9.16846 3.50065 9.33335V10.5H10.5007V9.33335C10.5007 9.16807 10.5567 9.02943 10.6687 8.91743C10.7807 8.80543 10.9191 8.74962 11.084 8.75001C11.2493 8.75001 11.3879 8.80601 11.4999 8.91801C11.6119 9.03001 11.6677 9.16846 11.6673 9.33335V10.5C11.6673 10.8208 11.553 11.0956 11.3243 11.3243C11.0957 11.5529 10.8211 11.6671 10.5007 11.6667H3.50065Z" fill="#7375F2"/>
                  </svg> Sample File
                  </button>
                </div>
                <div className='upload_logo_area txt_file w-100 pe-1'>
                  <div className='d-flex align-items-center'>
                      {fileDomain?
                     ( <p>{fileDomain.name}</p>):
                     editId===null?
                     <p>Upload txt file.</p>:

                      (
                        <>
                    
                      <p className="pe-2">{fileDomainNameBox}.txt</p>{" "}
                             <svg width="16" height="16" class="wallet_hover m-0" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
                        handleButtonClick(
                        editId,fileDomainNameBox
                        )}>
                          <path d="M7.99935 10.3833C7.91046 10.3833 7.82713 10.3695 7.74935 10.342C7.67157 10.3144 7.59935 10.2671 7.53268 10.2L5.13268 7.79996C5.01046 7.67774 4.94935 7.52218 4.94935 7.3333C4.94935 7.14441 5.01046 6.98885 5.13268 6.86663C5.25491 6.74441 5.41335 6.68063 5.60802 6.67529C5.80268 6.66996 5.96091 6.72818 6.08268 6.84996L7.33268 8.09996V3.33329C7.33268 3.14441 7.39668 2.98596 7.52468 2.85796C7.65268 2.72996 7.81091 2.66618 7.99935 2.66663C8.18824 2.66663 8.34668 2.73063 8.47468 2.85863C8.60268 2.98663 8.66646 3.14485 8.66602 3.33329V8.09996L9.91602 6.84996C10.0382 6.72774 10.1967 6.6693 10.3913 6.67463C10.586 6.67996 10.7442 6.74396 10.866 6.86663C10.9882 6.98885 11.0494 7.14441 11.0494 7.3333C11.0494 7.52218 10.9882 7.67774 10.866 7.79996L8.46602 10.2C8.39935 10.2666 8.32713 10.314 8.24935 10.342C8.17157 10.37 8.08824 10.3837 7.99935 10.3833ZM3.99935 13.3333C3.63268 13.3333 3.31868 13.2026 3.05735 12.9413C2.79602 12.68 2.66557 12.3662 2.66602 12V10.6666C2.66602 10.4777 2.73002 10.3193 2.85802 10.1913C2.98602 10.0633 3.14424 9.99952 3.33268 9.99996C3.52157 9.99996 3.68002 10.064 3.80802 10.192C3.93602 10.32 3.99979 10.4782 3.99935 10.6666V12H11.9993V10.6666C11.9993 10.4777 12.0633 10.3193 12.1913 10.1913C12.3193 10.0633 12.4776 9.99952 12.666 9.99996C12.8549 9.99996 13.0133 10.064 13.1413 10.192C13.2693 10.32 13.3331 10.4782 13.3327 10.6666V12C13.3327 12.3666 13.202 12.6806 12.9407 12.942C12.6794 13.2033 12.3656 13.3337 11.9993 13.3333H3.99935Z" fill="#BDBDBD"></path></svg>
                      </>
                      )}
                  </div>
                  <div className='upload_btn_img'>
                    <Form.Control type="file" id="domainFile" name="domainFile" accept="text/plain" onChange={handleDomainFile} />
                    <span>Upload</span>
                  </div>
                </div>
                {validateFileDomain && editId===null && (
                  <span className="alert_text">
                   This field is required.
                  </span>
                )}
                {validateFileDomain && editId!==null  &&(
                  <span className="alert_text">
                  Please upload a new file to edit.
                  </span>
                )}
                {validateFileDomainValid && (
                  <span className="alert_text">
                    File should be a text file
                  </span>
                )}
              </Form.Group>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          { }

          {!buttonDomainListUpload && (
            <button variant="secondary" className="btn default_btn btn-sm" onClick={uploadDomainFile}> Submit </button>
          )}
          {buttonDomainListUpload && (
            <button variant="secondary" className="btn default_btn btn-sm">
              Processing
            </button>
          )}
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default ListDomain;
