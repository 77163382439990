import ListUser from "../components/ListUser";

function ListUserPage() {
  return (
    <>
      <ListUser />
    </>
  );
}

export default ListUserPage;
