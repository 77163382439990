import React from "react";
import { useMemo, useState } from "react";
import noRecord from "../assets/images/no_data_new.svg";
import Select from "react-select";
import ConstantData from "../utils/constantData.json"

import FileSearchIcon from '../assets/images/file_searching_icon.svg'


import ContentLoader from "react-content-loader";

import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import Loader from "../components/Loader.jsx";
import NOComponent from "./EmptyTableMsg";
import { InputGroup, Table } from 'react-bootstrap'

const TableAdvertiser = ({ columns, data, initial, loading }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const [minFoc, setMinFoc] = useState(false);
  const onFocusMin = () => {
    setMinFoc(true);
  };
  const onBlurMin = () => {
    setMinFoc(false);
  };
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // allColumns,
    // getToggleHideAllColumnsProps,

    setGlobalFilter,

    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage, pageSize: 20 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // sets the column which needs to be hidden
  // const [hideColumn, setHideColumn] = useState();
  // hide/unhide the column option
  // const [showColvis, setShowColvis] = useState(false);

  const [filter, setFilter] = useState("");

  const toggleSortByDirection = useMemo(() => {
    return (columnId, desc) => {
      const column = headerGroups
        .flatMap((headerGroup) => headerGroup.headers)
        .find((header) => header.id === columnId);
      column.toggleSortBy(desc, false);
    };
  }, [headerGroups]);

  // const dropdownRef2 = useRef(null);
  // useEffect(() => {
  //   // Function to handle clicks outside the dropdown
  //   function handleClickOutside(event) {
  //     if (
  //       dropdownRef2.current &&
  //       !dropdownRef2.current.contains(event.target)
  //     ) {
  //       setShowColvis(false);
  //     }
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dropdownRef2]);

  function onChangeSizePagination(event) {
    setPageSize(event.value)
  }

  const customStyles = {
    menuPortal: base => ({
      ...base,
      zIndex: 9999, // Set a high z-index to ensure it appears above other content
    }),
    menu: provided => ({
      ...provided,
      position: 'absolute',
      top: 'auto',
      bottom: '100%', // Position the menu above the select input
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Optional: add some shadow
    }),
  };

  const [backupCurrentPage, setBackupCurrentPage] = useState(-1);

  return (
    <>
      {/* ////////////////////////////////// */}

      <div className="">
        {/* <div className="d-flex search-table-main "> */}
        {/* <div className="custom-drop">
            <div
              class="dropdown  check-side m-3 ml-4"
              style={{ position: "relative" }}
              id="colvs"
              ref={dropdownRef2}
            >
              <button
                className="btn btn-primary btn-sm btn-filter"
                onClick={() => {
               
                  const filteredData = allColumns.filter((obj) =>
                    [
                      "Company Name",
                      "Advertiser Name",
                      "Status",
                      "Sales Partner",
                      "CSM",
                      "Status",
                      "Users",
                      "Current Balance",
                      "Spent",
                      "Campaign Count",
                      "IM",
                      "Last Login",

                    ].includes(obj.Header)
                  );
                  setHideColumn(filteredData);
                  // setHideColumn(allColumns);
                  setShowColvis(!showColvis);
                }}
              >
                <i className="fas fa-th-large fs-3"></i>
              </button>

              {showColvis && (
                <div className="card custom-check" style={{ width: "200px" }}>
                  {hideColumn.map((column) => (
                    <div
                      className="cb action div-checkbox-color"
                      key={column.id}
                    >
                      <label className="checkbox checkbox-primary m-0">
                        <input
                          className="toggle-vis"
                          type="checkbox"
                          {...column.getToggleHiddenProps()}
                        />{" "}
                        <span>{column.Header}</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div> */}
        {/* </div> */}

        <div class="d-flex align-items-center justify-content-between position-relative">
          {/* <span class="icon-search position-absolute ms-6 report-search-icon">
            <i class="fas fa-solid fa-magnifying-glass" aria-hidden="true"></i>
          </span> */}
          <InputGroup className={minFoc? 'full_input_border search_width custom_search_bar' : 'search_width custom_search_bar'}>
            <InputGroup.Text id="basic-addon1" className="border-end-0">
              <i class="fas fa-solid fa-magnifying-glass" aria-hidden="true"></i>
            </InputGroup.Text>
            <input
              id="custom-filter"
              type="text"
              onFocus={onFocusMin}
                onBlur={onBlurMin}
              data-kt-user-table-filter="search"
              class="form-control ps-14 report-search border-start-0"
              placeholder="Search Advertiser"
              value={filter || ""}
              onChange={(e) => {
                if(e.target.value.length!==0){
           
                  if(backupCurrentPage===-1){
                    setBackupCurrentPage(currentPage)
                  }
                
                  setCurrentPage(0)

                }
                else{
                
                  setCurrentPage(backupCurrentPage)
                  setBackupCurrentPage(-1)

                }
                setFilter(e.target.value);
                setGlobalFilter(e.target.value);
              }}
            />
          </InputGroup>

          <div className="d-flex search-table-main ">
            <div className="filter-button">
              <a className="btn default_btn btn-sm h-40" href="/createAdvertiserAdmin" target="_blank">
                <i className="fas fa-plus me-1"></i> Add New </a>
            </div>
          </div>
        </div>
      </div>

      {/* //////////////////////////////////////// */}

      {/* Table Start */}

      {loading ? (
        <ContentLoader className="" viewBox="16 65 1150 450" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
          <rect x="26" y="80" rx="4" ry="4" width="20" height="6"/>
          <rect x="67" y="80" rx="4" ry="4" width="85" height="6"/>
          <rect x="188" y="80" rx="4" ry="4" width="169" height="6"/>
          <rect x="402" y="80" rx="4" ry="4" width="85" height="6"/>
          <rect x="523" y="80" rx="4" ry="4" width="169" height="6"/>
          <rect x="731" y="80" rx="4" ry="4" width="85" height="6"/>
          <rect x="852" y="80" rx="4" ry="4" width="85" height="6"/>

          <rect x="26" y="110" rx="4" ry="4" width="20" height="6"/>
          <rect x="66" y="110" rx="4" ry="4" width="85" height="6"/>
          <rect x="187" y="110" rx="4" ry="4" width="169" height="6"/>
          <rect x="401" y="110" rx="4" ry="4" width="85" height="6"/>
          <rect x="522" y="110" rx="4" ry="4" width="169" height="6"/>
          <rect x="730" y="110" rx="4" ry="4" width="85" height="6"/>
          <rect x="851" y="110" rx="4" ry="4" width="85" height="6"/>

          <rect x="26" y="140" rx="4" ry="4" width="20" height="6"/>
          <rect x="66" y="140" rx="4" ry="4" width="85" height="6"/>
          <rect x="187" y="140" rx="4" ry="4" width="169" height="6"/>
          <rect x="401" y="140" rx="4" ry="4" width="85" height="6"/>
          <rect x="522" y="140" rx="4" ry="4" width="169" height="6"/>
          <rect x="730" y="140" rx="4" ry="4" width="85" height="6"/>
          <rect x="851" y="140" rx="4" ry="4" width="85" height="6"/>

          <rect x="26" y="170" rx="4" ry="4" width="20" height="6"/>
          <rect x="66" y="170" rx="4" ry="4" width="85" height="6"/>
          <rect x="187" y="170" rx="4" ry="4" width="169" height="6"/>
          <rect x="401" y="170" rx="4" ry="4" width="85" height="6"/>
          <rect x="522" y="170" rx="4" ry="4" width="169" height="6"/>
          <rect x="730" y="170" rx="4" ry="4" width="85" height="6"/>
          <rect x="851" y="170" rx="4" ry="4" width="85" height="6"/>

          <rect x="26" y="200" rx="4" ry="4" width="20" height="6"/>
          <rect x="66" y="200" rx="4" ry="4" width="85" height="6"/>
          <rect x="187" y="200" rx="4" ry="4" width="169" height="6"/>
          <rect x="401" y="200" rx="4" ry="4" width="85" height="6"/>
          <rect x="522" y="200" rx="4" ry="4" width="169" height="6"/>
          <rect x="730" y="200" rx="4" ry="4" width="85" height="6"/>
          <rect x="851" y="200" rx="4" ry="4" width="85" height="6"/>

          <rect x="26" y="230" rx="4" ry="4" width="20" height="6"/>
          <rect x="66" y="230" rx="4" ry="4" width="85" height="6"/>
          <rect x="187" y="230" rx="4" ry="4" width="169" height="6"/>
          <rect x="401" y="230" rx="4" ry="4" width="85" height="6"/>
          <rect x="522" y="230" rx="4" ry="4" width="169" height="6"/>
          <rect x="730" y="230" rx="4" ry="4" width="85" height="6"/>
          <rect x="851" y="230" rx="4" ry="4" width="85" height="6"/>

          <rect x="26" y="260" rx="4" ry="4" width="20" height="6"/>
          <rect x="66" y="260" rx="4" ry="4" width="85" height="6"/>
          <rect x="187" y="260" rx="4" ry="4" width="169" height="6"/>
          <rect x="401" y="260" rx="4" ry="4" width="85" height="6"/>
          <rect x="522" y="260" rx="4" ry="4" width="169" height="6"/>
          <rect x="730" y="260" rx="4" ry="4" width="85" height="6"/>
          <rect x="851" y="260" rx="4" ry="4" width="85" height="6"/>

          <rect x="26" y="290" rx="4" ry="4" width="20" height="6"/>
          <rect x="66" y="290" rx="4" ry="4" width="85" height="6"/>
          <rect x="187" y="290" rx="4" ry="4" width="169" height="6"/>
          <rect x="401" y="290" rx="4" ry="4" width="85" height="6"/>
          <rect x="522" y="290" rx="4" ry="4" width="169" height="6"/>
          <rect x="730" y="290" rx="4" ry="4" width="85" height="6"/>
          <rect x="851" y="290" rx="4" ry="4" width="85" height="6"/>

          <rect x="978" y="80" rx="4" ry="4" width="169" height="6"/>
          <rect x="977" y="110" rx="4" ry="4" width="169" height="6"/>
          <rect x="977" y="140" rx="4" ry="4" width="169" height="6"/>
          <rect x="977" y="170" rx="4" ry="4" width="169" height="6"/>
          <rect x="977" y="200" rx="4" ry="4" width="169" height="6"/>
          <rect x="977" y="230" rx="4" ry="4" width="169" height="6"/>
          <rect x="977" y="260" rx="4" ry="4" width="169" height="6"/>
          <rect x="977" y="290" rx="4" ry="4" width="169" height="6"/>
        </ContentLoader>  
      ) : (
        <div>
          {data.length === 0 ? (
            <div className="no_table_data">
              <div className="d-flex justify-content-center min-height-255 pt-4 pb-4 align-items-center">
                <div className="text-center">
                  <img src={noRecord} className="img-fluid" alt="" />
                  <h2>Nothing to see here</h2>
                  <p className="text-center">There are no Advetisers record to show you <br /> right now. </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className={rows.length<=0?"table-responsive table_border_radius tableFixHead no_found_table_screen":"table-responsive table_border_radius tableFixHead"}>
                <Table {...getTableProps()} className="display table nowrap table-advertiser-hidden custom_data_table table_first_th">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          if (
                            column.id !== "logo" &&
                            column.id !== "AID" &&
                            column.id !== "Company Name" &&
                            column.id !== "Actions" &&
                            column.id !== "Currency"  &&
                            column.id !== "Step"  &&
                            column.id !== "Status"
                          ) {
                            return (
                              <th
                                {...column.getHeaderProps({
                                  onClick: () =>
                                    toggleSortByDirection(
                                      column.id,
                                      !column.isSortedDesc
                                    ),
                                })}
                                className={
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? "sort-desc"
                                      : "sort-asc"
                                    : "unsort"
                                }
                              >
                                {column.render("Header")}
                              </th>
                            );
                          } else if (
                            column.id === "Company Name" ||
                            column.id === "Actions" ||
                            column.id === "Status"
                          ) {
                            return (
                              <th
                                {...column.getHeaderProps({
                                  onClick: () =>
                                    toggleSortByDirection(
                                      column.id,
                                      !column.isSortedDesc
                                    ),
                                })}
                                className={
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? "sort-desc fixed-sticky"
                                      : "sort-asc fixed-sticky"
                                    : "unsort fixed-sticky"
                                }
                              >
                                {column.render("Header")}
                              </th>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);

                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            if (
                              cell.column.id !== "logo" &&
                              cell.column.id !== "AID" &&
                              cell.column.id !== "Company Name" &&
                              cell.column.id !== "Actions" &&
                              cell.column.id !== "Currency" &&
                              cell.column.id !== "Step" &&
                              cell.column.id !== "Status"

                            ) {
                              return (
                                <td {...cell.getCellProps()} >
                                  {cell.render("Cell")}
                                </td>
                              );
                            } else if (
                              cell.column.id === "Company Name" ||
                              cell.column.id === "Actions" ||
                              cell.column.id === "Status"
                            ) {
                              return (
                                <td
                                  className="fixed-sticky"
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              {rows.length <= 0 && (
                  <div className="no_search_record d-flex justify-content-center align-items-center">
                    <div className="text-center">
                      <img src={FileSearchIcon} className="img-fluid" alt="" />
                      <h4>Sorry! No result found :(</h4>
                      <span>Try adjusting your search or filter to find <br /> what you are looking for.</span>
                    </div>
                  </div>
              )}

            {rows.length >0 && 

              <div className="card-footer d-flex align-items-center justify-content-between ">
                <div className=" ">
                  <div className="child-pagination-row">
                    <Select
                      className="custom_drop_design select-custom p-0 pagination_select"
                      value={{ label: pageSize, value: pageSize }}
                      options={ConstantData.paginationSize}
                      placeholder="-Select-"
                      onChange={onChangeSizePagination}
                      styles={customStyles}
                      menuPortalTarget={document.body}>
                    </Select>
                  </div>
                </div>
                <div className="pagination-text-end">
                  {rows.length > 0 && (
                    <span>
                      Showing{" "}
                      <strong>
                        {pageIndex * pageSize + 1} -{" "}
                        {pageIndex * pageSize + pageSize > rows.length
                          ? rows.length
                          : pageIndex * pageSize + pageSize}
                      </strong>{" "}
                      of {rows.length} records
                    </span>
                  )}
                  <button
                    onClick={() => { gotoPage(0); setCurrentPage(0) }}
                    disabled={!canPreviousPage}
                  >
                    <i className="fa-solid fa-angles-left"></i>
                  </button>{" "}
                  <button
                    onClick={() => { previousPage(); setCurrentPage(currentPage - 1) }}
                    disabled={!canPreviousPage}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>{" "}
                  <button onClick={() => { nextPage(); setCurrentPage(currentPage + 1) }} disabled={!canNextPage}>
                    <i className="fa-solid fa-angle-right"></i>
                  </button>{" "}
                  <button
                    onClick={() => { gotoPage(pageCount - 1); setCurrentPage(pageCount - 1) }}
                    disabled={!canNextPage}
                  >
                    <i className="fa-solid fa-angles-right"></i>
                  </button>{" "}
                </div>
              </div>
}
            </div>
          )}
        </div>
      )}

      {/* Table End */}
    </>
  );
};

export default TableAdvertiser;
