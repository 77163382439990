import ListDomain from "../components/ListDomain";

function ListDomainPage() {
  return (
    <>
      <ListDomain />
    </>
  );
}

export default ListDomainPage;
