import {
    Table,
  } from "react-bootstrap";
function MacroTableHideUnhide() {
  return (
    <>
     <div className="url_list_design mt-1">
                              <Table
                                responsive
                                className="table mb-0 table-borderless"
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>

                                      <span>
                                        &#123;creative_id&#125; -{" "}
                                        <em>creative id</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>

                                      <span>
                                        &#123;campaign_id&#125; -{" "}
                                        <em>campaign id</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>

                                      <span>
                                        &#123;publisher_id&#125; -{" "}
                                        <em>publisher id</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>

                                      <span>
                                        &#123;store_url&#125; -{" "}
                                        <em>store URL</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>

                                      <span>
                                        &#123;timestamp&#125; -{" "}
                                        <em>timestamp</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>

                                      <span>
                                        &#123;keyword&#125; -{" "}
                                        <em>query, keyword</em>{" "}
                                      </span>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;exchange&#125; - <em>exchange</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;page_url&#125; - <em>page URL</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;domain&#125; -{" "}
                                        <em>domain referrer</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;bundle&#125; -{" "}
                                        <em>application id</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;app_name&#125; -{" "}
                                        <em>application name</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;conversion&#125; -{" "}
                                        <em>conversion</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;site_id&#125; - <em>site id</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;app_id&#125; - <em>app id</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;country&#125; - <em>country</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;region&#125; - <em>region</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;city&#125; - <em>city</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;postal_code&#125; -{" "}
                                        <em>postal code</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;os&#125; - <em>os</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;os_version&#125; -{" "}
                                        <em>os version</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>

                                      <span>
                                        &#123;make&#125; - <em>make</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;model&#125; - <em>model</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;carrier&#125; - <em>carrier</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;connection_type&#125; -{" "}
                                        <em>connection type</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;device_type&#125; -{" "}
                                        <em>device type</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;user_id&#125; - <em>user id</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;ipv4_address&#125; -{" "}
                                        <em>ipv4 address</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;ipv6_address&#125; -{" "}
                                        <em>ipv6 address</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;cachebuster&#125; -{" "}
                                        <em>random number</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;dnt&#125; -{" "}
                                        <em>
                                          do not track flag. 0 = tracking is
                                          unrestricted, 1 = do not track
                                        </em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;tagid&#125; -{" "}
                                        <em>
                                          imp.tagid parameter of bid request
                                        </em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;GDPR_CONSENT_XXX&#125; -{" "}
                                        <em>
                                          base64-encoded GDPR consent string
                                        </em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>

                                      <span>
                                        &#123;GDPR&#125; -{" "}
                                        <em>
                                          GDPR flag. 0 - GDPR does not apply, 1
                                          - GDPR applies
                                        </em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;ADDTL_CONSENT&#125; -{" "}
                                        <em>Google"s additional consent</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <i className="fa fa-circle"></i>

                                      <span>
                                        &#123;GDPR_PD&#125; -{" "}
                                        <em>GDPR personal data status</em>{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <i className="fa fa-circle"></i>
                                      <span>
                                        &#123;ssp&#125; -{" "}
                                        <em>exchange SSP id</em>{" "}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
    </>
  );
}

export default MacroTableHideUnhide;
