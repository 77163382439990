import BlsFormViewEdit from "../components/BlsFormViewEdit";
import { useLocation } from "react-router-dom";

function BlsFormViewEditPage() {
    const search = useLocation().search;
    const blsId = new URLSearchParams(search).get("blsId");
    return (
        <>
            <BlsFormViewEdit blsId={blsId} />
        </>
    );
}

export default BlsFormViewEditPage;