import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/images/xaprio_logo_new.svg";
import SlideOne from "../assets/images/slide_one.svg";
import SlideTwo from "../assets/images/slide_two.svg";
import SlideThree from "../assets/images/slide_three.svg";
import SlideFour from "../assets/images/slide_four.svg";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Lock from "../assets/images/Password.svg";
import { Carousel } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import configData from "../config/config.json";
import { TokenVerificationLoggedOut } from "../utils/tokenVerify";
// import { LoadingContext } from "react-router-loading";

const contentStyle = {
  margin: 0,
  height: "auto",
  textAlign: "center",
};

const ResetPassword = () => {
  // const loadingContext = useContext(LoadingContext);

  React.useEffect(() => {
    TokenVerificationLoggedOut();
    // loadingContext.done();
  });

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("token");

  const notifysuccess = (msg) => toast.success(msg, { autoClose: 1500 });
  const notifyfailure = (msg) => toast.error(msg, { autoClose: 1500 });
  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 1500 });

  const [password, setPassword] = useState(null);
  const [passwordIsRequiredError, setPasswordIsRequiredError] = useState(false);
  const [passwordStrengthError, setPasswordStrengthError] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState(null);
  const [confirmPasswordIsRequiredError, setConfirmPasswordIsRequiredError] =
    useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const [buttonValue, setButtonValue] = useState(false);

  function onChangePassword(event) {
    setPasswordIsRequiredError(false);
    setPassword(event.target.value);
    // const regex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

    // if(regex.test(event.target.value)){
    if (event.target.value.length >= 8) {
      setPasswordStrengthError(false);

      if (confirmPassword !== null) {
        if (event.target.value !== confirmPassword) {
          setPasswordMatchError(true);
        } else {
          setPasswordMatchError(false);
        }
      }
    } else {
      setPasswordStrengthError(true);
    }
  }

  function onBlurPassword(event) {
    if (event.target.value.length === 0) {
      setPasswordStrengthError(false);

      setPasswordIsRequiredError(true);
    }
  }

  function onChangeConfirmPassword(event) {
    setConfirmPasswordIsRequiredError(false);
    setConfirmPassword(event.target.value);

    if (event.target.value !== password) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  }

  function onBlurConfirmpassword(event) {
    if (event.target.value.length === 0) {
      setPasswordMatchError(false);
      setConfirmPasswordIsRequiredError(true);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== null && confirmPassword !== null) {
      if (
        passwordIsRequiredError === false &&
        confirmPasswordIsRequiredError === false &&
        passwordStrengthError === false &&
        passwordMatchError === false
      ) {
        let payload = {
          password: password,
        };

        setButtonValue(true);
        axios
          .post(configData.SERVER_URL + "user/resetpassword", payload, {
            params: {
              token: id,
            },
          })
          .then((response) => {
            if (response.data.status === 200) {
              notifysuccess(response.data.message);
              setTimeout(() => {
                setButtonValue(false);
                window.location.replace("/login");
              }, 1500);
            } else {
              notifyfailure(response.data.message);
              setButtonValue(false);
            }
          })
          .catch((error) => {
            notifycatch();

            setButtonValue(false);
          });
      }
    } else {
      if (password === null) {
        setPasswordIsRequiredError(true);
      }
      if (confirmPassword === null) {
        setConfirmPasswordIsRequiredError(true);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="login_page">
        <div className="custom_container">
          <Row className="h-100">
            <Col md={6} className="col-md-6 h-100 d-flex align-items-center justify-content-center position-relative">
          <img src={Logo} className="img-fluid fixed_logo" alt="" />
              <div className="form_side_area">
                <div className="for_center_div">
                  <div className="form_fields">
                    <h1>Create Password</h1>
                    <p>Type and confirm a secure new password.</p>
                    <Form>
                      <Form.Group className="">
                        <Form.Label>Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type="password"
                            className=""
                            aria-label="email"
                            aria-describedby="email"
                            value={password}
                            onBlur={onBlurPassword}
                            onChange={onChangePassword}
                            placeholder="Password"
                          />
                          <img
                            src={Lock}
                            className="img-fluid abso_element_left"
                            alt=""
                          />
                        </div>
                        {passwordIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}
                        {passwordStrengthError && (
                          <span className="alert_text">
                            Password should be of minimum 8 characters in length
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="mt-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type="password"
                            className=""
                            aria-label="email"
                            aria-describedby="email"
                            value={confirmPassword}
                            onBlur={onBlurConfirmpassword}
                            onChange={onChangeConfirmPassword}
                            placeholder="Confirm Password"
                          />
                          <img
                            src={Lock}
                            className="img-fluid abso_element_left"
                            alt=""
                          />
                        </div>
                        {confirmPasswordIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}
                        {passwordMatchError && (
                          <span className="alert_text">
                            Password does not match.
                          </span>
                        )}
                      </Form.Group>

                      {!buttonValue && (
                        <Button
                          className="btn btn_button"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      )}
                      {buttonValue && (
                        <Button className="btn btn_button" type="button">
                          Processing
                        </Button>
                      )}

                      <span className="sign_up_text">
                        Don't have an account?{" "}
                        <Link to="/register" className="text-decoration-underline hyper_link_clr">Sign Up</Link>
                      </span>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="">
              <div className="d-flex align-items-center justify-content-center h-100">
                <div className="login_img_area">
                  <Carousel autoplay dots={false} effect="fade">
                    <div>
                      <div style={contentStyle}>
                        <img src={SlideOne} className="img-fluid" alt="" />
                        <p>
                          Take your campaign performance to the next level with
                          our{" "}
                          <span className="text-decoration-none hyper_text_link_clr"> real-time triggers. </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div style={contentStyle}>
                        <img src={SlideTwo} className="img-fluid" alt="" />
                        <p>
                          Take your campaign performance to the next level with
                          our{" "}
                          <a
                            href="/"
                            className="text-decoration-none hyper_text_link_clr"
                          >
                            real-time triggers.
                          </a>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div style={contentStyle}>
                        <img src={SlideThree} className="img-fluid" alt="" />
                        <p>
                          Take your campaign performance to the next level with
                          our{" "}
                          <a
                            href="/"
                            className="text-decoration-none hyper_text_link_clr"
                          >
                            real-time triggers.
                          </a>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div style={contentStyle}>
                        <img src={SlideFour} className="img-fluid" alt="" />
                        <p>
                          Take your campaign performance to the next level with
                          our{" "}
                          <a
                            href="/"
                            className="text-decoration-none hyper_text_link_clr"
                          >
                            real-time triggers.
                          </a>
                        </p>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
