import ForgotPassword from "../components/ForgotPassword";

function ForgotPasswordPage() {
  return (
    <>
      <ForgotPassword />
    </>
  );
}

export default ForgotPasswordPage;
