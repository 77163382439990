import React, { useState ,useEffect,useRef} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/images/xaprio_logo_new.svg";
import SlideOne from "../assets/images/slide_one.svg";
import SlideTwo from "../assets/images/slide_two.svg";
import SlideThree from "../assets/images/slide_three.svg";
import SlideFour from "../assets/images/slide_four.svg";
import Cookies from 'js-cookie';
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import PassEye from "../assets/images/password_eye.svg";
import PassEyeOff from "../assets/images/password_eye_off.svg";
import Lock from "../assets/images/Password.svg";
import MailBlue from "../assets/images/mailblue.svg";
import { LoadingOutlined } from '@ant-design/icons'
import LockBlue from "../assets/images/passwordblue.svg";
import Mail from "../assets/images/mail.svg";
import { Carousel } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import configData from "../config/config.json";
import { TokenVerificationLoggedOut } from "../utils/tokenVerify";
// import { LoadingContext } from "react-router-loading";

const contentStyle = {
  margin: 0,
  height: "auto",
  textAlign: "center",
};

const Login = () => {
  // const loadingContext = useContext(LoadingContext);
  const buttonRef = useRef(null);

  React.useEffect(() => {
    TokenVerificationLoggedOut();
    let status= Cookies.get("token_moment")?true:false;     
    if(status){
    setTimeout(() => {
      // setLoading(false);
   }, 1500);

  }

  else{
    setLoading(false);
  }

  // setLoading(false);
    // loadingContext.done();
  });

  useEffect(() => {
    const handleEnterKeyPress = (event) => {
      if (event.key === 'Enter') {
        buttonRef.current.click();
      }
    };
    document.addEventListener('keydown', handleEnterKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, []); 

  const notifysuccess = (msg) => toast.success(msg, { autoClose: 1500 });
  const notifyfailure = (msg) => toast.error(msg, { autoClose: 1500 });
  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 1500 });

  const [loading, setLoading] = useState(true);  
  const [email, setEmail] = useState(null);
  const [ip, setIp] = useState(null);
  const [emailIsRequiredError, setEmailIsRequiredError] = useState(false);
  const [emailUrlError, setEmailUrlError] = useState(false);

  const [password, setPassword] = useState(null);
  const [passwordIsRequiredError, setPasswordIsRequiredError] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [buttonValue, setButtonValue] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  function onChangeEmail(event) {
    setEmailIsRequiredError(false);
    setEmail(event.target.value);

    if (validateEmail(event.target.value) === null) {
      setEmailUrlError(true);
    } else {
      setEmailUrlError(false);
    }
  }

  function onBlurEmail(event) {
    if (event.target.value.length === 0) {
      setEmailUrlError(false);
      setEmailIsRequiredError(true);
    }
  }

  function onChangePassword(event) {
    setPasswordIsRequiredError(false);
    setPassword(event.target.value);
  }

  function onBlurPassword(event) {
    if (event.target.value.length === 0) {
      setPasswordIsRequiredError(true);
    }
  }

  React.useEffect(()=>{
    getIP()
      },[])
      
      const getIP = async () => {
        try {
          const res = await  axios.get("https://geolocation-db.com/json/");
          Cookies.set("IP", res.data.IPv4,{ expires: 1 });
          setIp(res.data.IPv4)
        } catch (error) { }
       
      };
    

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email !== null && password !== null) {
      if (
        emailIsRequiredError === false &&
        emailUrlError === false &&
        passwordIsRequiredError === false
      ) {
        let payload = {
          email: email,
          password: password,
          ip:ip
        };
        setButtonValue(true);
        axios
          .post(configData.SERVER_URL + "user/login", payload)
          .then((response) => {
            if (response.data.status === 200) {
              // getIP();
              notifysuccess(response.data.message);
              setTimeout(() => {
                setButtonValue(false);

                Cookies.set(
                  "token_moment",
                  response.data.data.token,
                   { expires: 1 }
                );
              }, 1500);
            } else {
              notifyfailure(response.data.message);

              setButtonValue(false);
            }
          })
          .catch((error) => {
            notifycatch();
            setButtonValue(false);
          });
      }
    } else {
      if (email === null) {
        setEmailIsRequiredError(true);
      }
      if (password === null) {
        setPasswordIsRequiredError(true);
      }
    }
  };

  // const onChange = (currentSlide) => {

  // };


  if(loading){
    return <>
     <div className="fullscreen" >
          </div>
    </>
  }

  else{
  return (
    <>
      <ToastContainer />
      <div className="login_page">
        <div className="custom_container">
          <Row className="h-100">
            <Col md={6} className="col-md-6 h-100 d-flex align-items-center justify-content-center position-relative">
          <img src={Logo} className="img-fluid fixed_logo" alt="" />
              <div className="form_side_area">
                <div className="for_center_div">
                  <div className="form_fields">
                    <h1>Log In</h1>
                    <p>Welcome back! Please enter your details.</p>
                    {/* <Form> */}
                      <Form.Group className="login_between_space">
                        <Form.Label>Email</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            className=""
                            aria-label="email"
                            aria-describedby="email"
                            value={email}
                            onBlur={onBlurEmail}
                            onChange={onChangeEmail}
                            placeholder="Enter your email"
                          />

                          {/* <img src={Mail} className="img-fluid abso_element_left" alt="" /> */}
                          {email?.length > 0 ? <img src={MailBlue} className="img-fluid abso_element_left" /> : <img src={Mail} className="img-fluid abso_element_left" />
                          }


                          {emailIsRequiredError && (
                            <span className="alert_text">
                              This field is required
                            </span>
                          )}
                          {emailUrlError && (
                            <span className="alert_text">
                              Invalid Email Address
                            </span>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group className="login_between_space position-relative">
                        <Form.Label>Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type={passwordVisibility ? "text" : "password"}
                            aria-label="password"
                            className=""
                            aria-describedby="password"
                            value={password}
                            placeholder="Enter password"
                            onBlur={onBlurPassword}
                            onChange={onChangePassword}
                          />
                        {passwordVisibility &&  <img src={PassEye} className="img-fluid eye_abso_element_left abso_element_left" alt=""  onClick={() => {
                          setPasswordVisibility(!passwordVisibility);
                        }}/>}
                        {!passwordVisibility &&  <img src={PassEyeOff} className="img-fluid eye_abso_element_left abso_element_left" alt=""  onClick={() => {
                          setPasswordVisibility(!passwordVisibility);
                        }}/>}

                          {password?.length > 0 ? <img src={LockBlue} className="img-fluid abso_element_left" alt="" /> : <img src={Lock} className="img-fluid abso_element_left" alt="" />}

                          {passwordIsRequiredError && (
                            <span className="alert_text">
                              This field is required
                            </span>
                          )}
                          <Link
                            to="/forgotpassword"
                            className="hyper_link_clr d-block text-end"
                          >
                            Forgot password?
                          </Link>
                        </div>
                      </Form.Group>

                      <div className="">
                        {!buttonValue && (
                          <Button
                            type="button"
                            className="btn btn_button"
                            onClick={handleSubmit}
                            ref={buttonRef}
                          >
                            Log In
                          </Button>
                        )}

                        {buttonValue && (
                          <Button type="button" className="btn btn_button">
                            Logging In... <LoadingOutlined className="ms-2" />
                          </Button>
                        )}
                      </div>
                      <span className="sign_up_text">
                        Don't have an account?{" "}
                        <Link
                          to="/register"
                          className="text-decoration-underline hyper_link_clr"
                        >
                          Sign Up
                        </Link>
                      </span>
                    {/* </Form> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="">
              <div className="login_img_area">
                <Carousel
                  //  afterChange={onChange}
                  autoplay
                  dots={false}
                  effect="fade"
                >
                  <div>
                    <div style={contentStyle}>
                      <img src={SlideOne} srcset={SlideOne} className="img-fluid" alt="" />
                      <p>
                        Take your campaign performance to the next level with
                        our{" "}
                        <span className="text-decoration-none hyper_text_link_clr"> real-time triggers. </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div style={contentStyle}>
                      {/* <img src={SlideTwo} className="img-fluid" alt="" /> */}
                      <img src={SlideTwo} srcset={SlideTwo} className="img-fluid" alt="" />
                      <p>
                        Take your campaign performance to the next level with
                        our{" "}
                        <a
                          href="/"
                          className="text-decoration-none hyper_text_link_clr"
                        >
                          real-time triggers.
                        </a>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div style={contentStyle}>
                      <img src={SlideThree} srcset={SlideThree} className="img-fluid" alt="" />
                      <p>
                        Take your campaign performance to the next level with
                        our{" "}
                        <a
                          href="/"
                          className="text-decoration-none hyper_text_link_clr"
                        >
                          real-time triggers.
                        </a>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div style={contentStyle}>
                      <img src={SlideFour} srcset={SlideFour} className="img-fluid" alt="" />
                      <p>
                        Take your campaign performance to the next level with
                        our{" "}
                        <a
                          href="/"
                          className="text-decoration-none hyper_text_link_clr"
                        >
                          real-time triggers.
                        </a>
                      </p>
                    </div>
                  </div>
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
  }
};

export default Login;
