import React, { useState, useContext } from 'react'
import { Form } from "react-bootstrap";
import Select from "react-select";
import { LoadingContext } from "react-router-loading";
import { TokenVerificationLoggedin, ScreenView } from "../utils/tokenVerify";
import axios from "axios";
import configData from "../config/config.json";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import LoaderImage from "../assets/images/loading-img.gif";
import jwt_decode from "jwt-decode";

const BlsFormViewEdit = (props) => {

    const loadingContext = useContext(LoadingContext);
    const [initialLoader, setInitialLoader] = useState(true)
    const [checkRole, setCheckRole] = useState("SA")

    React.useEffect(() => {
        TokenVerificationLoggedin();
        ScreenView();
        let status = Cookies.get("token_moment") ? true : false;
        if (status) {
            let token = Cookies.get("token_moment");
            setCheckRole(jwt_decode(token)?.role)

            axios
                .get(configData.SERVER_URL + "bls/blsDetail?_id=" + props.blsId, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": token,
                    },
                })
                .then((response) => {
                    if (response.data.status === 200) {
                        setAdvertiser(response.data.data.advertiser)
                        setCampaign(response.data.data.campaign)
                        setLiftStudyName(response.data.data.liftStudyName)
                        setLocations(response.data.data.locations)
                        setOptions(response.data.data.options)
                        setLanguage(languageOptions.find(item => item.value === response.data.data.language))
                        setIntendedAction(intendedActionOptions.find(item => item.value === response.data.data.intendedAction))
                        setSurveyData(response.data.data.surveyForm !== undefined ? response.data.data.surveyForm : [])

                        setInitialLoader(false)
                    }
                })
                .catch((error) => {
                    setInitialLoader(false)
                    notifycatch()
                });
        }
        loadingContext.done()
    }, []);

    const notifysuccess = (msg) => toast.success(msg, { autoClose: 1500 });
    const notifyfailure = (msg) => toast.error(msg, { autoClose: 1500 });
    const notifycatch = () =>
        toast.error("Something went wrong", { autoClose: 1500 });

    const [buttonProcessing, setButtonProcessing] = useState(false);

    const [surveyData, setSurveyData] = useState([])

    const [advertiser, setAdvertiser] = useState("");
    const [advertiserIsRequiredError, setAdvertiserIsRequiredError] = useState(false);
    const [advertiserOptions, setAdvertiserOptions] = useState([]);
    function onChangeAdvertiser(event) {
        setAdvertiserIsRequiredError(false)
        setAdvertiser(event);

        let status = Cookies.get("token_moment") ? true : false;
        if (status) {
            let token = Cookies.get("token_moment");
            axios
                .get(configData.SERVER_URL + "bls/campaignMinifiedList?Id=" + event.value, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        setCampaignOptions(response.data.data);
                        setCampaign("")
                        setCampaignIsRequiredError(false)
                    }
                })
                .catch((error) => { setCampaignOptions([]); setCampaign(""); setCampaignIsRequiredError(false) });
        }
    }


    const [liftStudyName, setLiftStudyName] = useState("");
    const [liftStudyNameIsRequiredError, setLiftStudyNameIsRequiredError] =
        useState(false);

    function onChangeLiftStudyName(event) {
        setLiftStudyNameIsRequiredError(false);
        setLiftStudyName(event.target.value);
        if (event.target.value.length === 0) {
            setLiftStudyNameIsRequiredError(true)
        }
    }

    function onBlurLiftStudyName(event) {
        if (event.target.value.trim().length === 0) {
            setLiftStudyNameIsRequiredError(true);
        }
        setLiftStudyName(event.target.value.trim());
    }


    const [campaign, setCampaign] = useState("");
    const [campaignIsRequiredError, setCampaignIsRequiredError] = useState(false);
    const [campaignOptions, setCampaignOptions] = useState([]);
    function onChangeCampaign(event) {
        setCampaignIsRequiredError(false)
        setCampaign(event);
    }


    const [liftType, setLiftType] = useState("brandLift");
    const [liftTypeIsRequiredError, setLiftTypeIsRequiredError] = useState(false);

    function onChangeLiftType(event) {
        setLiftTypeIsRequiredError(false)
        setLiftType(event.target.value)

    }

    const [locations, setLocations] = useState([]);
    const [locationsIsRequiredError, setLocationsIsRequiredError] = useState(false);
    const [locationsMaximumError, setLocationsMaximumError] = useState(false);

    function onChangeLocations(event) {
        const value = event.target.value;

        setLocations((prevLocations) => {
            let updatedLocations;
            if (prevLocations.includes(value)) {
                updatedLocations = prevLocations.filter((location) => location !== value);
            } else {
                updatedLocations = [...prevLocations, value];
            }

            if (updatedLocations.length === 0) {
                setLocationsIsRequiredError(true)
            }
            else {
                setLocationsIsRequiredError(false)

                if (updatedLocations.length > 3) {
                    setLocationsMaximumError(true)
                }
                else {
                    setLocationsMaximumError(false)
                }
            }

            return updatedLocations
        });


    }


    const [surveyDetailIsRequiredError, setSurveyDetailIsRequiredError] = useState(false);
    const [options, setOptions] = useState(["", "", "", "", "None of the above"]);

    function onChangeOptions(event, index) {
        setSurveyDetailIsRequiredError(false)
        let tempOptions = [...options]
        tempOptions[index] = event.target.value
        setOptions(tempOptions);
    }

    function onBlurOptions(event, index) {
        let tempOptions = [...options]
        tempOptions[index] = event.target.value.trim()
        setOptions(tempOptions);
    }


    const [language, setLanguage] = useState({ label: "English", value: "english" });
    const [languageOptions, setLanguageOptions] = useState([{ label: "English", value: "english" }, { label: "Russian", value: "russian" }, { label: "Indonesian", value: "indonesian" }, { label: "Arabic", value: "arabic" },]);
    function onChangeLanguage(event) {
        setLanguage(event);
    }

    const [objectType, setObjectType] = useState({ label: "Brand", value: "brand" });
    const [objectTypeOptions, setObjectTypeOptions] = useState([{ label: "Brand", value: "brand" }]);
    function onChangeObjectType(event) {
        setObjectType(event);
    }

    const [intendedAction, setIntendedAction] = useState({ label: "Buy", value: "buy" });
    const [intendedActionOptions, setIntendedActionOptions] = useState([{ label: "Buy", value: "buy" }, { label: "Choose", value: "choose" }, { label: "Use", value: "use" }]);
    function onChangeIntendedAction(event) {
        setIntendedAction(event);
    }


    // const [searchLiftRepeater, setSearchLiftRepeater] = useState([{
    //     group: "",
    //     validation_group: false,
    // }]);

    // const handleAddSearchLiftRepeater = () => {
    //     setSearchLiftRepeater([
    //         ...searchLiftRepeater,
    //         {
    //             group: "",
    //             validation_group: false,
    //         },
    //     ]);
    // };

    // const handleRemoveSearchLiftRepeater = (index) => {
    //     let listTrigger = [...searchLiftRepeater];
    //     listTrigger.splice(index, 1);
    //     setSearchLiftRepeater(listTrigger);
    // };

    // const handleChangeSearchLiftRepeaterGroup = (e, index) => {
    //     const listTrigger = [...searchLiftRepeater];
    //     listTrigger[index]["group"] = e.target.value;
    //     listTrigger[index]["validation_group"] = false;
    //     setSearchLiftRepeater(listTrigger);
    // };

    // const handleBlurSearchLiftRepeaterGroup = (e, index) => {
    //     const listTrigger = [...searchLiftRepeater];
    //     listTrigger[index]["group"] = e.target.value.trim();
    //     setSearchLiftRepeater(listTrigger);
    // };

    // const validateSearchLiftRepeater = () => {
    //     setSearchLiftRepeater(prevsearchLiftRepeater =>
    //         prevsearchLiftRepeater.map(item => {
    //             const isGroupEmpty = item.group === "";

    //             return {
    //                 ...item,
    //                 validation_group: isGroupEmpty,
    //             };
    //         })
    //     );
    //     return searchLiftRepeater.every(item => item.group !== "");
    // };




    function handleSubmit() {
        // validateSearchLiftRepeater()
        if
            (
            liftStudyName !== "" &&
            advertiser !== "" &&
            campaign !== "" &&
            liftType !== "" &&
            locations.length > 0 &&
            (options[0].trim() !== "" && options[1].trim() !== "" && options[2].trim() !== "" && options[3].trim() !== "")
            // validateSearchLiftRepeater()

        ) {

            if (
                liftStudyNameIsRequiredError === false &&
                advertiserIsRequiredError === false &&
                campaignIsRequiredError === false &&
                liftStudyNameIsRequiredError === false &&
                locationsIsRequiredError === false &&
                locationsMaximumError === false &&
                surveyDetailIsRequiredError === false
            ) {

                // const surveyFormCreate = locations.map((str) => ({
                //     location: str,
                // question: str === "purchaseIntent" || str === "consideration" ? questionSet[liftType][str][intendedAction.value][language.value] : questionSet[liftType][str][language.value],
                // }));

                let payload = {
                    liftStudyName: liftStudyName,
                    advertiser: advertiser.value,
                    campaign: campaign.value,
                    liftType: liftType,
                    locations: locations,
                    options: options,
                    language: language.value,
                    objectType: objectType.value,
                    intendedAction: intendedAction.value,
                    // questionSet: questionSet,
                    // surveyForm: surveyFormCreate
                    // searchLift: searchLiftRepeater.map(({ validation_group, ...rest }) => rest)
                }

                setButtonProcessing(true)

                axios
                    .post(configData.SERVER_URL + "test123", payload)
                    .then((response) => {
                        if (response.data.status === 200) {
                            notifysuccess(response.data.message);
                            setTimeout(() => {
                                setButtonProcessing(false);

                            }, 1500);
                        } else {
                            notifyfailure(response.data.message);

                            setButtonProcessing(false);
                        }
                    })
                    .catch((error) => {
                        notifycatch();
                        setButtonProcessing(false);
                    });

            }

        }
        else {
            if (liftStudyName === "") {
                setLiftStudyNameIsRequiredError(true)
            }
            if (advertiser === "") {
                setAdvertiserIsRequiredError(true)
            }
            if (campaign === "") {
                setCampaignIsRequiredError(true)
            }
            if (liftType === "") {
                setLiftTypeIsRequiredError(true)
            }
            if (locations.length === 0) {
                setLocationsIsRequiredError(true)
            }
            if (options[0].trim() === "" || options[1].trim() === "" || options[2].trim() === "" || options[3].trim() === "") {
                setSurveyDetailIsRequiredError(true)
            }

        }

    }


    return (
        <div className='main_contain_area'>
            <div className='step_area lift_study_text'>
                <h3>Edit Lift Study</h3>
            </div>
            <div className='scroll_area'>
                <div className='step_form_area'>
                    <div className='custom_container_form edit_brand_lift_cls'>
                        {checkRole === "SA" &&
                            <>
                                <div className="row_devider d-flex diff_campaign_box">
                                    <div className="custom_col_space_label">
                                        <div className="step_form_label">
                                            <h4>Advertiser</h4>
                                        </div>
                                    </div>
                                    <div className="step_input_fields second_step_area">
                                        <div className="d-flex">
                                            <div className="w-896">
                                                <Form.Group className="position-relative" controlId="">
                                                    <Form.Label>Advertiser *</Form.Label>
                                                    <Select
                                                        className="custom_drop_design select-custom p-0"
                                                        placeholder="-Select-"
                                                        isSearchable={true}
                                                        value={advertiser}
                                                        options={advertiserOptions}
                                                        onChange={onChangeAdvertiser}
                                                        isDisabled
                                                    >
                                                    </Select>
                                                    {advertiserIsRequiredError &&
                                                        <span className="alert_text"> This field is required </span>
                                                    }
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider_line"></div>
                            </>
                        }
                        <div className="row_devider d-flex diff_campaign_box">
                            <div className="custom_col_space_label">
                                <div className="step_form_label">
                                    <h4>Lift Study</h4>
                                </div>
                            </div>
                            <div className="step_input_fields second_step_area">
                                <div className="d-flex">
                                    <div className="w-896">
                                        <Form.Group className="position-relative" controlId="">
                                            <Form.Label>Name *</Form.Label>
                                            <Form.Control type="text" placeholder=""
                                                value={liftStudyName}
                                                onChange={onChangeLiftStudyName}
                                                onBlur={onBlurLiftStudyName}
                                                autocomplete="off"
                                                disabled
                                            />
                                            {liftStudyNameIsRequiredError &&
                                                <span className="alert_text"> This field is required </span>
                                            }
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="divider_line"></div>
                        <div className="row_devider d-flex diff_campaign_box">
                            <div className="custom_col_space_label">
                                <div className="step_form_label">
                                    <h4>Campaign</h4>
                                </div>
                            </div>
                            <div className="step_input_fields second_step_area">
                                <div className="d-flex">
                                    <div className="w-896">
                                        <Form.Group className="position-relative" controlId="">
                                            <Form.Label>Select Campaign *</Form.Label>
                                            <Select
                                                className="custom_drop_design select-custom p-0"
                                                placeholder="-Select-"
                                                isSearchable={true}
                                                value={campaign}
                                                options={campaignOptions}
                                                onChange={onChangeCampaign}
                                                isDisabled
                                            >
                                            </Select>
                                            {campaignIsRequiredError &&
                                                <span className="alert_text"> This field is required </span>
                                            }
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="divider_line"></div>
                        <div className="row_devider d-flex diff_campaign_box">
                            <div className="custom_col_space_label">
                                <div className="step_form_label">
                                    <h4>Lift Types</h4>
                                </div>
                            </div>
                            <div className="step_input_fields second_step_area">
                                <div className="d-flex">
                                    <div className="w-896">
                                        <Form.Group className="position-relative" controlId=""
                                            onChange={onChangeLiftType}
                                        >
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <Form.Label>Lift Types *</Form.Label>
                                                {/* <Form.Label>Measurable line item types</Form.Label>
                                                <Form.Label>Measurable line items</Form.Label> */}
                                            </div>
                                            <div className="lift_types_checkbox campaign_box_area campaign_box_area_absolute position-relative mb_8">
                                                <label htmlFor="brandLift" className="mb-0">
                                                    <input
                                                        type="checkbox"
                                                        name="gender"
                                                        className="form-check-input"
                                                        id="brandLift"
                                                        value="brandLift"
                                                        checked={liftType === "brandLift"}
                                                        disabled
                                                    />
                                                    <div className="campaign_box  text-center">
                                                        <p className='brand_lift_text'>Brand Lift</p>
                                                        {/* <span className='openrtb_text'>OpenRTB &nbsp;&nbsp; Demand Gen</span>
                                                        <span className='last_number_text'>12</span> */}
                                                    </div>
                                                </label>
                                            </div>
                                            {/* <div className="lift_types_checkbox campaign_box_area campaign_box_area_absolute position-relative">
                                                <label htmlFor="searchLift" className="mb-0">
                                                    <input
                                                        type="checkbox"
                                                        name="gender"
                                                        className="form-check-input"
                                                        id="searchLift"
                                                        value="searchLift"
                                                        checked={liftType === "searchLift"}
                                                    />
                                                    <div className="campaign_box  text-center">
                                                        <p className='brand_lift_text'>Search Lift</p>
                                                        <span className='openrtb_text'>OpenRTB &nbsp;&nbsp; Demand Gen</span>
                                                        <span className='last_number_text'>12</span>
                                                    </div>
                                                </label>
                                            </div> */}
                                            {liftTypeIsRequiredError &&
                                                <>
                                                    <span className="alert_text"> Minimum 1 selection is required </span>

                                                </>
                                            }
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="divider_line"></div>
                        <div className="row_devider d-flex diff_campaign_box">
                            <div className="custom_col_space_label">
                                <div className="step_form_label">
                                    <h4>Brand Lift</h4>
                                </div>
                            </div>
                            <div className="step_input_fields second_step_area">
                                <div className="d-flex">
                                    <div className="w-293 me-8">
                                        <Form.Group className="position-relative" controlId="">
                                            <Form.Label>Select upto 3 metrics to measure *</Form.Label>
                                            <div className="lift_types_checkbox campaign_box_area campaign_box_area_absolute position-relative mb_8">
                                                <label htmlFor="videoAdRecall" className="mb-0">
                                                    <input
                                                        type="checkbox"
                                                        name="gender"
                                                        className="form-check-input"
                                                        id="videoAdRecall"
                                                        value="videoAdRecall"
                                                        onChange={onChangeLocations}
                                                        checked={locations.includes("videoAdRecall")}
                                                        disabled
                                                    />
                                                    <div className="campaign_box  text-center">
                                                        <p className='brand_lift_text'>Video ad recall</p>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="lift_types_checkbox campaign_box_area campaign_box_area_absolute position-relative">
                                                <label htmlFor="awareness" className="mb-0">
                                                    <input
                                                        type="checkbox"
                                                        name="gender"
                                                        className="form-check-input"
                                                        id="awareness"
                                                        value="awareness"
                                                        onChange={onChangeLocations}
                                                        checked={locations.includes("awareness")}
                                                        disabled
                                                    />
                                                    <div className="campaign_box  text-center">
                                                        <p className='brand_lift_text'>Awareness</p>
                                                    </div>
                                                </label>
                                            </div>
                                            {locationsIsRequiredError &&
                                                <>
                                                    <span className="alert_text"> Minimum 1 selection is required </span>
                                                    {locationsMaximumError && <br />}
                                                </>
                                            }
                                            {locationsMaximumError &&
                                                <span className="alert_text"> Maximum 3 selections are allowed </span>
                                            }

                                        </Form.Group>
                                    </div>
                                    <div className="w-293 me-8">
                                        <Form.Group className="position-relative" controlId="">
                                            <Form.Label className='invisible'>Locations *</Form.Label>
                                            <div className="lift_types_checkbox campaign_box_area campaign_box_area_absolute position-relative mb_8">
                                                <label htmlFor="favourability" className="mb-0">
                                                    <input
                                                        type="checkbox"
                                                        name="gender"
                                                        className="form-check-input"
                                                        id="favourability"
                                                        value="favourability"
                                                        onChange={onChangeLocations}
                                                        checked={locations.includes("favourability")}
                                                        disabled

                                                    />
                                                    <div className="campaign_box  text-center">
                                                        <p className='brand_lift_text'>Favorability</p>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="lift_types_checkbox campaign_box_area campaign_box_area_absolute position-relative">
                                                <label htmlFor="purchaseIntent" className="mb-0">
                                                    <input
                                                        type="checkbox"
                                                        name="gender"
                                                        className="form-check-input"
                                                        id="purchaseIntent"
                                                        value="purchaseIntent"
                                                        onChange={onChangeLocations}
                                                        checked={locations.includes("purchaseIntent")}
                                                        disabled
                                                    />
                                                    <div className="campaign_box  text-center">
                                                        <p className='brand_lift_text'>Purchase intent</p>
                                                    </div>
                                                </label>
                                            </div>

                                        </Form.Group>

                                    </div>
                                    <div className="w-293">
                                        <Form.Group className="position-relative" controlId="">
                                            <Form.Label className='invisible'>Locations * </Form.Label>
                                            <div className="lift_types_checkbox campaign_box_area campaign_box_area_absolute position-relative ">
                                                <label htmlFor="consideration" className="mb-0">
                                                    <input
                                                        type="checkbox"
                                                        name="gender"
                                                        className="form-check-input"
                                                        id="consideration"
                                                        value="consideration"
                                                        onChange={onChangeLocations}
                                                        checked={locations.includes("consideration")}
                                                        disabled

                                                    />
                                                    <div className="campaign_box  text-center">
                                                        <p className='brand_lift_text'>Consideration</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="divider_line"></div>
                        <div className="row_devider d-flex diff_campaign_box">
                            <div className="custom_col_space_label">
                                <div className="step_form_label">
                                    <h4>Survey details</h4>
                                </div>
                            </div>
                            <div className="step_input_fields second_step_area">
                                <div className="d-flex">
                                    <div className="w-896">
                                        <Form.Group className="position-relative mb_8" controlId="">
                                            <Form.Label>Enter your brand/product and upto 3 competitors. Your brand/product name should match the name used in your ads. *</Form.Label>
                                            <Form.Control type="text" placeholder=""
                                                value={options[0]}
                                                onChange={(e) => { onChangeOptions(e, 0) }}
                                                onBlur={(e) => { onBlurOptions(e, 0) }}
                                                disabled

                                            />
                                            {/* <span className="alert_text"> This field is required </span> */}
                                        </Form.Group>
                                        <Form.Group className="position-relative mb_8" controlId="">
                                            <Form.Control type="text" placeholder=""
                                                value={options[1]}
                                                onChange={(e) => { onChangeOptions(e, 1) }}
                                                onBlur={(e) => { onBlurOptions(e, 1) }}
                                                disabled
                                            />
                                            {/* <span className="alert_text"> This field is required </span> */}
                                        </Form.Group>
                                        <Form.Group className="position-relative mb_8" controlId="">
                                            <Form.Control type="text" placeholder=""
                                                value={options[2]}
                                                onChange={(e) => { onChangeOptions(e, 2) }}
                                                onBlur={(e) => { onBlurOptions(e, 2) }}
                                                disabled
                                            />
                                            {/* <span className="alert_text"> This field is required </span> */}
                                        </Form.Group>
                                        <Form.Group className="position-relative" controlId="">
                                            <Form.Control type="text" placeholder=""
                                                value={options[3]}
                                                onChange={(e) => { onChangeOptions(e, 3) }}
                                                onBlur={(e) => { onBlurOptions(e, 3) }}
                                                disabled
                                            />
                                            {surveyDetailIsRequiredError &&
                                                <span className="alert_text"> Please fill all input fields </span>
                                            }
                                        </Form.Group>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="divider_line"></div>

                        {
                            liftType !== "" && locations.length > 0 && locations.length < 4 && (options[0].trim() !== "" && options[1].trim() !== "" && options[2].trim() !== "" && options[3].trim() !== "") &&
                            <>
                                <div className="row_devider d-flex diff_campaign_box">
                                    <div className="custom_col_space_label">
                                        <div className="step_form_label">
                                            <h4>Survey settings</h4>
                                        </div>
                                    </div>
                                    <div className="step_input_fields second_step_area">
                                        <div className="d-flex no_drop_cursor">
                                            <div className="w-356 me-16">
                                                <Form.Group className="position-relative" controlId="">
                                                    <Form.Label>Language *</Form.Label>
                                                    <Select
                                                        className="custom_drop_design select-custom p-0"
                                                        placeholder="-Select-"
                                                        isSearchable={false}
                                                        value={language}
                                                        options={languageOptions}
                                                        onChange={onChangeLanguage}
                                                        isDisabled
                                                    >
                                                    </Select>
                                                </Form.Group>
                                            </div>
                                            <div className="w-356 me-16">
                                                <Form.Group className="position-relative" controlId="">
                                                    <Form.Label>Object type *</Form.Label>
                                                    <Select
                                                        className="custom_drop_design select-custom p-0"
                                                        placeholder="-Select-"
                                                        isSearchable={false}
                                                        value={objectType}
                                                        options={objectTypeOptions}
                                                        onChange={onChangeObjectType}
                                                        isDisabled
                                                    >
                                                    </Select>
                                                </Form.Group>
                                            </div>
                                            <div className="w-153">
                                                <Form.Group className="position-relative" controlId="">
                                                    <Form.Label>Intended action *</Form.Label>
                                                    <Select
                                                        className="custom_drop_design select-custom p-0"
                                                        placeholder="-Select-"
                                                        isSearchable={false}
                                                        value={intendedAction}
                                                        options={intendedActionOptions}
                                                        onChange={onChangeIntendedAction}
                                                        isDisabled
                                                    >
                                                    </Select>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className='survey_preview_area'>
                                            {surveyData.map((item, index) => (
                                                <div key={index}>
                                                    {index !== 0 && <hr className="checkbox_devider" />}
                                                    <span className="video_text">
                                                        {item.location === "videoAdRecall" ? "Video Ad Recall" : item.location === "purchaseIntent" ? "Purchase Intent" : locations[index] === "favourability" ? <>Favorability</> : item.location}
                                                    </span>
                                                    <p className="pour_lesquelles_text">{item.question}</p>
                                                    <ul>
                                                        {item.option.map((option, idx) => (
                                                            <li key={idx} style={{ pointerEvents: "none" }}>
                                                                <Form.Check
                                                                    id={`option-${index}-${idx}`}
                                                                    checked={false}
                                                                    label={option}
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                            {/* {locations?.map((ad, index) => (
                                                <div key={index}>
                                                    {index !== 0 &&
                                                        <hr className="checkbox_devider" />
                                                    }
                                                    <span className="video_text">{locations[index] === "videoAdRecall" ? <>Video Ad Recall</> : locations[index] === "purchaseIntent" ? <>Purchase Intent</> : <>{locations[index]}</>}</span>
                                                         <p className="pour_lesquelles_text">{
                                                        locations[index] === "purchaseIntent" || locations[index] === "consideration" ?
                                                            questionSet[liftType][locations[index]][intendedAction.value][language.value] :
                                                            questionSet[liftType][locations[index]][language.value]
                                                    }</p>
                                                    <ul>
                                                        {options.map((option, idx) => (
                                                            <li key={idx} style={{ pointerEvents: "none" }}>
                                                                <Form.Check
                                                                    id={`option-${idx}`}
                                                                    checked={false}
                                                                    label={option}

                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>

                                                </div>
                                            ))} */}

                                        </div>
                                    </div>
                                </div>
                                <div class="divider_line"></div>
                            </>
                        }

                        {/* <div className="row_devider d-flex diff_campaign_box">
                            <div className="custom_col_space_label">
                                <div className="step_form_label">
                                    <h4>Search lift</h4>
                                </div>
                            </div>
                            <div className="step_input_fields second_step_area">
                                {searchLiftRepeater.map((x, i) => {
                                    return (
                                        <div className="">
                                            <div className="w-896">
                                                <Form.Group className="position-relative" controlId="">
                                                    {i === 0 &&
                                                        <Form.Label>Add 1-3 search terms per group. Max 5 groups allowed. </Form.Label>
                                                    }


                                                    <div className='position-relative mb_8'
                                                        style={{ marginBottom: searchLiftRepeater[i].validation_group ? "24px" : "8px" }}

                                                    >
                                                        <Form.Control type="text" placeholder="Words comma seperated"
                                                            value={searchLiftRepeater[i].group}
                                                            onChange={(e) => { handleChangeSearchLiftRepeaterGroup(e, i) }}
                                                            onBlur={(e) => { handleBlurSearchLiftRepeaterGroup(e, i) }}
                                                        />


                                                        <button type='button' className='btn delete_btn'
                                                            onClick={() => { handleRemoveSearchLiftRepeater(i) }}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <path d="M4.66663 14C4.29996 14 3.98618 13.8696 3.72529 13.6087C3.46396 13.3473 3.33329 13.0333 3.33329 12.6667V4C3.1444 4 2.98596 3.93622 2.85796 3.80867C2.7304 3.68067 2.66663 3.52222 2.66663 3.33333C2.66663 3.14444 2.7304 2.986 2.85796 2.858C2.98596 2.73044 3.1444 2.66667 3.33329 2.66667H5.99996C5.99996 2.47778 6.06396 2.31933 6.19196 2.19133C6.31951 2.06378 6.47774 2 6.66663 2H9.33329C9.52218 2 9.68063 2.06378 9.80863 2.19133C9.93618 2.31933 9.99996 2.47778 9.99996 2.66667H12.6666C12.8555 2.66667 13.0137 2.73044 13.1413 2.858C13.2693 2.986 13.3333 3.14444 13.3333 3.33333C13.3333 3.52222 13.2693 3.68067 13.1413 3.80867C13.0137 3.93622 12.8555 4 12.6666 4V12.6667C12.6666 13.0333 12.5362 13.3473 12.2753 13.6087C12.014 13.8696 11.7 14 11.3333 14H4.66663ZM4.66663 4V12.6667H11.3333V4H4.66663ZM5.99996 10.6667C5.99996 10.8556 6.06396 11.0138 6.19196 11.1413C6.31951 11.2693 6.47774 11.3333 6.66663 11.3333C6.85551 11.3333 7.01396 11.2693 7.14196 11.1413C7.26951 11.0138 7.33329 10.8556 7.33329 10.6667V6C7.33329 5.81111 7.26951 5.65267 7.14196 5.52467C7.01396 5.39711 6.85551 5.33333 6.66663 5.33333C6.47774 5.33333 6.31951 5.39711 6.19196 5.52467C6.06396 5.65267 5.99996 5.81111 5.99996 6V10.6667ZM8.66663 10.6667C8.66663 10.8556 8.73063 11.0138 8.85863 11.1413C8.98618 11.2693 9.1444 11.3333 9.33329 11.3333C9.52218 11.3333 9.68063 11.2693 9.80863 11.1413C9.93618 11.0138 9.99996 10.8556 9.99996 10.6667V6C9.99996 5.81111 9.93618 5.65267 9.80863 5.52467C9.68063 5.39711 9.52218 5.33333 9.33329 5.33333C9.1444 5.33333 8.98618 5.39711 8.85863 5.52467C8.73063 5.65267 8.66663 5.81111 8.66663 6V10.6667ZM4.66663 4V12.6667V4Z" fill="#F83A39" />
                                                            </svg>
                                                        </button>

                                                        {searchLiftRepeater[i].validation_group && <span className="alert_text"> This field is required </span>}

                                                    </div>

                                                </Form.Group>

                                            </div>

                                        </div>
                                    )
                                })}

                                {searchLiftRepeater.length < 5 &&
                                    <div className='add_more_input_btn'>
                                        <button type='button' className='btn'
                                            onClick={handleAddSearchLiftRepeater}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path d="M6.99992 11.6666C6.81103 11.6666 6.65281 11.6026 6.52525 11.4746C6.39725 11.347 6.33325 11.1888 6.33325 10.9999V7.66659H2.99992C2.81103 7.66659 2.65259 7.60259 2.52459 7.47459C2.39703 7.34703 2.33325 7.18881 2.33325 6.99992C2.33325 6.81103 2.39703 6.65259 2.52459 6.52459C2.65259 6.39703 2.81103 6.33325 2.99992 6.33325H6.33325V2.99992C6.33325 2.81103 6.39725 2.65259 6.52525 2.52459C6.65281 2.39703 6.81103 2.33325 6.99992 2.33325C7.18881 2.33325 7.34725 2.39703 7.47525 2.52459C7.60281 2.65259 7.66659 2.81103 7.66659 2.99992V6.33325H10.9999C11.1888 6.33325 11.347 6.39703 11.4746 6.52459C11.6026 6.65259 11.6666 6.81103 11.6666 6.99992C11.6666 7.18881 11.6026 7.34703 11.4746 7.47459C11.347 7.60259 11.1888 7.66659 10.9999 7.66659H7.66659V10.9999C7.66659 11.1888 7.60281 11.347 7.47525 11.4746C7.34725 11.6026 7.18881 11.6666 6.99992 11.6666Z" fill="#7375F2" />
                                            </svg>
                                            Add new group
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="divider_line"></div> */}
                        {/* <div className="button_area_bottom d-flex justify-content-end">
                            {
                                buttonProcessing ?
                                    <div className="p-0 text-end">
                                        <button className="d-inline-flex align-items-center justify-content-center default_btn_border_default_btn" disabled> Discard </button>
                                        <button className="d-inline-flex align-items-center justify-content-center default_btn btn ms-12" disabled> Processing..... <LoadingOutlined className="ms-2" /> </button>
                                    </div>
                                    :
                                    <div className="p-0 text-end">
                                        <button className="d-inline-flex align-items-center justify-content-center default_btn_border_default_btn"> Discard </button>
                                        <button className="d-inline-flex align-items-center justify-content-center default_btn btn ms-12" onClick={handleSubmit}> Submit </button>
                                    </div>
                            }

                        </div> */}

                        {initialLoader && (
                            <div className="page_loader_gif">
                                <img src={LoaderImage} alt="" className="img-fluid" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlsFormViewEdit
