import BlsForm from "../components/BlsForm";

function BlsFormPage() {
    return (
        <>
            <BlsForm />
        </>
    );
}

export default BlsFormPage;