import { isExpired } from "react-jwt";
import jwt_decode from "jwt-decode";
import axios from "axios";
import configData from "../config/config.json";
import Cookies from 'js-cookie';


export const TokenVerificationLoggedin = () => {

    let token= Cookies.get("token_moment")
    let status= Cookies.get("token_moment")?true:false;
    if(!status){
        window.location.replace("/login")
    }

    if(status){
    const isMyTokenExpired = isExpired(token);
    if(isMyTokenExpired){
        Cookies.remove("token_moment")
        window.location.replace("/login")
      }
    }
   
  
  }

  export const TokenVerificationLoggedOut = () => {
      let status= Cookies.get("token_moment")?true:false;      
       if (status) {

        let token= Cookies.get("token_moment")
    
        let id = jwt_decode(token)._id;
        let role =jwt_decode(token).role
      
      if(role==='MASTER' ||role === "A"||role === "M"||role === "RM"){
        axios
        .get(configData.SERVER_URL + "user/getStep?id="+id)
        .then((response) => {
       
         if(response.data.data.step===0){
          window.location.replace("/createAdvertiser")
         }
      
         else if(response.data.data.step===1){
          window.location.replace("/addFund")
         }

         else {
          window.location.replace("/dashboard")
         }
      
        })

      }

          if (role === "SA") {
          window.location.replace("/dashboard");
        }

        // if (role === "SA"||role==="A"||role==="M" ||role==="RM") {
        //   window.location.replace("/dashboard");
        // }

      } 
  }


  export const TokenMasterorSuperadmin = () => {
    let status= Cookies.get("token_moment")?true:false;      
    if (status) {
    let token= Cookies.get("token_moment")
    var decoded = jwt_decode(token).role;
    return(decoded)
    }
}

export const ScreenView = () => {
  let status= Cookies.get("token_moment")?true:false;      
  if (status) {
  let token= Cookies.get("token_moment")



  let id = jwt_decode(token)._id;
  let role =jwt_decode(token).role
  

if(role==='MASTER' ||role === "A"||role === "M"||role === "RM"){
  axios
  .get(configData.SERVER_URL + "user/getStep?id="+id)
  .then((response) => {
 
   if(response.data.data.step===0){
    window.location.replace("/createAdvertiser")
   }

   if(response.data.data.step===1){
    window.location.replace("/addFund")
   }

})
.catch((error) => {});
}
  }

}

export const ScreenViewCreate = () => {

  let status= Cookies.get("token_moment")?true:false;

  if(status){
  let token= Cookies.get("token_moment")

  let id = jwt_decode(token)._id;
  let role =jwt_decode(token).role
  

if(role==='MASTER' ||role === "A"||role === "M"||role === "RM"){
  axios
  .get(configData.SERVER_URL + "user/getStep?id="+id)
  .then((response) => {
 
   if(response.data.data.step===1){
    window.location.replace("/addFund")
   }

   if(response.data.data.step===2){
    window.location.replace("/dashboard")
   }

})
.catch((error) => {});
}
  }

}



export const ScreenViewFund = () => {

  let status= Cookies.get("token_moment")?true:false;

  if(status){

  let token= Cookies.get("token_moment")

  let id = jwt_decode(token)._id;
  let role =jwt_decode(token).role

if(role==='MASTER' ||role === "A"||role === "M"||role === "RM"){
  axios
  .get(configData.SERVER_URL + "user/getStep?id="+id)
  .then((response) => {
 
   if(response.data.data.step===0){
    window.location.replace("/createAdvertiser")
   }

   
   if(response.data.data.step===2){
    window.location.replace("/dashboard")
   }

})
.catch((error) => {});
}

  }

}


export const BlockCreateAdvertiser = () => {

  let status= Cookies.get("token_moment")?true:false;

  if(status){
  let token= Cookies.get("token_moment")

  let role =jwt_decode(token).role

if(role==='SA' ){
 
    window.location.replace("/dashboard")

}
  }

}



export const BlockOnlySA = () => {
  let status= Cookies.get("token_moment")?true:false;

  if(status){
  let token= Cookies.get("token_moment")

  let role =jwt_decode(token).role

if(role==='SA'){
 
    window.location.replace("/dashboard")

}
  }

}


export const BlockOnlyRM = () => {
  let status= Cookies.get("token_moment")?true:false;

  if(status){
  let token= Cookies.get("token_moment")

  let role =jwt_decode(token).role

if(role==='RM'){
 
    window.location.replace("/dashboard")

}
  }

}


export const BlockListingAdvertiser = () => {
  let status= Cookies.get("token_moment")?true:false;

  if(status){
  let token= Cookies.get("token_moment")
 
  let role =jwt_decode(token).role

if(role==='MASTER' ||role==="A"||role==="M"||role==="RM"){
    window.location.replace("/dashboard")
}
  }
}



