import React, { useContext, useState } from "react";
import { TokenVerificationLoggedin, ScreenView } from "../utils/tokenVerify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingContext } from "react-router-loading";
import Cookies from "js-cookie";
import Advertisers from "../assets/images/advertisers.svg";
import CricketPlace from "../assets/images/cricket_placeholder.svg";
import WeatherPlace from "../assets/images/weather_placeholder.svg";
import AQIPlace from "../assets/images/aqi_placeholder.svg";
import CryptoPlace from "../assets/images/crypto_placeholder.svg";
import ChartPiePlace from "../assets/images/chart-pie.svg";
import GraphIcon from "../assets/images/graph-icon.svg";
import Campaigns from "../assets/images/campaigns.svg";
import Revenue from "../assets/images/revenue.svg";
import Impressions from "../assets/images/impressions.svg";
import ClicksImg from "../assets/images/clicks.svg";
import DownGraph from "../assets/images/dow-graph.svg";
import CricketBall from "../assets/images/cricket-ball.svg";
import NoDataAvailable from "../assets/images/no_data-available.svg";
import constantData from "../utils/constantData.json";
import Select from "react-select";
import { Select as CustomSelect } from 'antd';
import CTRImg from "../assets/images/ctr.svg";
import { Row, Col, Form, InputGroup, Table } from "react-bootstrap";
import Chart from "react-apexcharts";
import jwt_decode from "jwt-decode";
import axios from "axios";
import configData from "../config/config.json";
import NData from "../assets/images/n.svg";
import CData from "../assets/images/c.svg";
import FluentDust from "../assets/images/fluent_dust.svg";
import CarbonCloud from "../assets/images/carbon_cloud.svg";
import ReportsIcon from "../assets/images/reports_icon.svg";
import TransEyeIcon from "../assets/images/trans_eye_icon.svg";
import { Link } from "react-router-dom";
import advertiserViewData from "../utils/advertiserView.json"

import ContentLoader from "react-content-loader";
import { ToastContainer, toast } from "react-toastify";



function Dashboard() {
  const loadingContext = useContext(LoadingContext);

  const notifysuccess = (msg) => toast.success(msg, { autoClose: 1000 });

  const notifyfailure = (msg) => toast.error(msg, { autoClose: 1000 });

  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 2000 });

  //graph creation starts
  const [graphIsLoading, setGraphIsLoading] = useState(true);
  const [xData, setXData] = useState([]);
  const [plot1, setPlot1] = useState([]);
  const [plot2, setPlot2] = useState([]);
  const [plot3, setPlot3] = useState([]);
  const [plot1Hover, setPlot1Hover] = useState([]);
  const [plot2Hover, setPlot2Hover] = useState([]);
  const [plot3Hover, setPlot3Hover] = useState([]);

  const graphOptions = {
    optionsMixedChart: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false
        }
      },
      grid: {
        position: "front",
        show: false, // you can either change hear to disable all grids
        // padding: {
        //   right: 0,
        //   left: 0,
        //   top: 0,
        //   bottom: 0,
        // },
        xaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: false, //or just here to disable only y axis
          },
        },
      },
      tooltip: {
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 2,
          left: 2,
          blur: 6,
          color: '#999',
          opacity: 0.35
        },
        enabled: true,
        // x: [{
        //   show: true
        // }
        // ],
        style: {
          fontSize: "13px",
          fontWeight: 500,
          CloudFour: "#424242",
          fontFamily: '"Nunito", sans-serif',
        },
        y: [
          {
            title: "Impressions",
            formatter: function (val, context) {
              return plot1Hover[context.dataPointIndex];
            },
          },
          {
            title: "Clicks",
            formatter: function (val, context) {
              return plot2Hover[context.dataPointIndex];
            },
          },
          {
            title: "CTR",
            formatter: function (val, context) {
              return plot3Hover[context.dataPointIndex];
            },
          },
        ],
      },

      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0,
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        marginTop: '10px',
        fontSize: '14px',
        fontFamily: '"Nunito", sans-serif',
        fontWeight: 500,
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      stroke: {
        curve: "smooth",
        colors: ["#ae6d6d", "#fa9091", "#7fc5ac"],
        width: 2,
        opacity: 0.1,
      },

      xaxis: {
        categories: xData,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          stroke: '#fff',
          maxHeight: 120,
          style: {
            colors: [],
            fontSize: "13px",
            fontWeight: 500,
            cssClass: "apexcharts-xaxis-label",
          },
          offsetX: 2.4,
        },
      },

      markers: {
        size: 0,
        width: 8,
        height: 8,
        strokeWidth: 1,
        fillOpacity: 0,
        strokeOpacity: 0,
        hover: {
          size: 8,
        },
      },
      yaxis: {
        show: false,
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
    },
    seriesMixedChart: [
      {
        name: "Impressions",
        color: "#ae6d6d",
        type: "area",
        data: plot1,
      },
      {
        name: "Clicks",
        type: "area",
        color: "#fa9091",
        data: plot2,
      },
      {
        name: "CTR",
        type: "area",
        color: "#7fc5ac",
        data: plot3,
      },
    ],
  };




  const [focusSummary, SetFocusSummary] = useState(false)
  const FocusSummary = () => {
    SetFocusSummary(true)
  }
  const FocusSummaryBlur = () => {
    SetFocusSummary(false)
  }
  //role
  const [role, setRole] = useState();

  //top blocks data

  const [dashboardData1, setDashboardData1] = useState(null);
  const [dashboardData1IsLoading, setDashboardData1IsLoading] = useState(true);

  const [dashboardData2, setDashboardData2] = useState(null);
  const [dashboardData2IsLoading, setDashboardData2IsLoading] = useState(true);

  //campaign /advertiser table
  const [isCPCEnabled, setIsCPCEnabled] = useState(false);
  const [top10CampaignIsLoading, setTop10CampaignIsLoading] = useState(true);
  const [top10Campaign, setTop10Campaign] = useState([]);

  const [top10AdvertiserIsLoading, setTop10AdvertiserIsLoading] =
    useState(true);
  const [top10Advertiser, setTop10Advertiser] = useState([]);

  // widgets data

  const [weatherData, setWeatherData] = useState([]);
  // const [city, setCity] = useState("");
  const [weatherGraphData, setWeatherGraphData] = useState([]);

  const [weatherDataIsLoading, setWeatherIsLoading] = useState(true);

  const [cryptoData, setCryptoData] = useState([]);
  const [cryptoGraphData, setCryptoGraphData] = useState([]);
  const [cryptoDataIsLoading, setCryptoDataIsLoading] = useState(true);

  const [aqiData, setAqiData] = useState([]);
  const [aqiDataIsLoading, setAqiDataIsLoading] = useState(true);

  const [pieData, setPieData] = useState([]);
  const [selectedPieData, setSelectedPieData] = useState({ label: "Impression", value: "Impression" });
  const [selectedPieDataOptions, setSelectedPieDataOptions] = useState([
    { label: "Impressions", value: "Impression" },
    { label: "Clicks", value: "Click" },
    { label: "CTR", value: "Ctr" },
    { label: "Spend", value: "Spend" },
    { label: "eCPM", value: "Ecpm" },

  ]);



  const [pieDataImpression, setPieDataImpression] = useState([]);
  const [pieDataClick, setPieDataClick] = useState([]);
  const [pieDataCtr, setPieDataCtr] = useState([]);
  const [pieDataSpend, setPieDataSpend] = useState([]);
  const [pieDataEcpm, setPieDataEcpm] = useState([]);
  const [pieDataCPC, setPieDataCPC] = useState([]);
  const [pieDataCampaign, setPieDataCampaign] = useState([]);

  const pieChartData = {

    series: pieData,
    options: {
      plotOptions: {
        stroke: {
          width: 1,
          colors: ['#1E252A']
        },
      },
      chart: {
        type: 'donut',
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        // colors: ['#664229', '#987554', '#b99976', '#d2b48c', '#e5d3b3'],
      },
      markers: {
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: '#664229',
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const seriesName = w.config.labels[seriesIndex];
          const value = series[seriesIndex];
          return `<div class="custom-tooltip">
               <span>${seriesName} : <b>${value}</b></span>
             </div>`;
        },
        theme: 'light',
      },
      labels: pieDataCampaign,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            show: false
          }
        }
      }],
      legend: {
        position: 'right',
        offsetY: 0,
        height: 230,
      }
    },
  };

  const [pieDataIsLoading, setPieDataIsLoading] = useState(true);

  const [cricketData, setCricketData] = useState();
  const [cricketDataTab, setCricketDataTab] = useState("live");
  const [cricketDataIsLoading, setCricketDataIsLoading] = useState(true);

  function addCommasToNumber(num) {
    const isDecimal = num % 1 !== 0;
    let numStr = num.toString();
    let [wholePart, decimalPart] = numStr.split(".");
    wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (isDecimal) {
      numStr = `${wholePart}.${decimalPart}`;
    } else {
      numStr = wholePart;
    }

    return numStr;
  }

  const [aidWiseView, setAidWiseView] = useState(false);

  React.useEffect(() => {
    TokenVerificationLoggedin();
    ScreenView();

    let status = Cookies.get("token_moment") ? true : false;
    if (status) {
      let token = Cookies.get("token_moment");
      let checkRole = jwt_decode(token).role;

      setAidWiseView(advertiserViewData?.aid?.includes(jwt_decode(token).aid))

      // setCity(jwt_decode(token).location?.city)
      setRole(checkRole);

      if (checkRole === "SA") {
        axios
          .get(configData.SERVER_URL + `dashboard/getTopTenAdvertisers`, {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          })
          .then((response) => {
            if (response.data.status === 200) {
              setTop10Advertiser(response.data.data);
            }

            setTop10AdvertiserIsLoading(false);
          })
          .catch((error) => {
            setTop10AdvertiserIsLoading(false);
          });
      }

      axios
        .get(
          configData.SERVER_URL + `dashboard/getDashboardData`,

          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setDashboardData1(response.data.data);
          }
          setDashboardData1IsLoading(false);
        })
        .catch((error) => {
          setDashboardData1IsLoading(false);
        });

      axios
        .get(
          configData.SERVER_URL + `dashboard/getSecondRowDashData`,

          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setDashboardData2(response.data.data);
          }
          setDashboardData2IsLoading(false);
        })
        .catch((error) => {
          setDashboardData2IsLoading(false);
        });

      axios
        .get(configData.SERVER_URL + `dashboard/getTopTenCampaigns`, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.data.isCPC) {
            setIsCPCEnabled(true)
          }

          if (response.data.status === 200) {
            setTop10Campaign(response.data.data);

            if (response.data.data.length > 0) {



              let impression = []
              let click = []
              let ctr = []
              let spend = []
              let ecpm = []
              let cpc = []
              let campaignName = []

              let count = 1
              for (let i = 0; i < response.data.data.length; i++) {
                if (count === 6) {
                  break;
                }


                impression.push(response.data.data[i]["IMPRESSIONS"])
                click.push(response.data.data[i]["CLICK"])
                ctr.push(parseFloat(response.data.data[i]["CTR"]))
                ecpm.push(parseFloat(response.data.data[i]["eCPM"].substring(1)))
                campaignName.push(response.data.data[i]["CAMPAIGN NAME"])
                if (response.data.isCPC) {
                  cpc.push(parseFloat(response.data.data[i]["CPC"].substring(1)))
                  spend.push(parseFloat(response.data.data[i]["SPEND CPC"].substring(1)))

                  if (advertiserViewData?.aid?.includes(jwt_decode(token).aid)) {
                    setSelectedPieDataOptions([
                      { label: "Impressions", value: "Impression" },
                      { label: "Clicks", value: "Click" },
                      { label: "CTR", value: "Ctr" },
                      { label: "CPC", value: "CPC" }
                    ])
                  }
                  else {
                    setSelectedPieDataOptions([
                      { label: "Impressions", value: "Impression" },
                      { label: "Clicks", value: "Click" },
                      { label: "CTR", value: "Ctr" },
                      { label: "Spend", value: "Spend" },
                      { label: "CPC", value: "CPC" }
                    ])

                  }

                }
                else {
                  spend.push(parseFloat(response.data.data[i]["SPEND"].substring(1)))

                  if (advertiserViewData?.aid?.includes(jwt_decode(token).aid)) {
                    setSelectedPieDataOptions([
                      { label: "Impressions", value: "Impression" },
                      { label: "Clicks", value: "Click" },
                      { label: "CTR", value: "Ctr" },
                      { label: "eCPM", value: "Ecpm" }
                    ])
                  }

                }


                count = count + 1

              }
              setPieDataImpression(impression)
              setPieDataClick(click)
              setPieDataCtr(ctr)
              setPieDataSpend(spend)
              setPieDataEcpm(ecpm)
              if (response.data.isCPC) {
                setPieDataCPC(cpc)
              }

              setPieDataCampaign(campaignName)
              setPieData(impression)
            }

          }
          setTop10CampaignIsLoading(false);
          setPieDataIsLoading(false)

        })
        .catch((error) => {
          setTop10CampaignIsLoading(false);
          setPieDataIsLoading(false)
        });

      axios
        .get(
          configData.SERVER_URL + `dashboard/getGraphData`,

          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            let tempXaxis = [];
            let tempPlot1 = [];
            let tempPlot2 = [];
            let tempPlot3 = [];
            let tempPlot1Hover = [];
            let tempPlot2Hover = [];
            let tempPlot3Hover = [];

            for (let i = 0; i < response.data.data.length; i++) {
              tempXaxis.push(response.data.data[i].date);
              // tempPlot1.push(
              //   response.data.data[i].normalizedImpressions.toFixed(2)
              // );
              // tempPlot2.push(response.data.data[i].normalizedClicks.toFixed(2));
              // tempPlot3.push(response.data.data[i].normalizedCtr.toFixed(2));
              tempPlot1.push(
                response.data.data[i].impressions
              );
              tempPlot2.push(response.data.data[i].clicks);
              tempPlot3.push(response.data.data[i].ctr);
              tempPlot1Hover.push(
                addCommasToNumber(response.data.data[i].impressions)
              );
              tempPlot2Hover.push(
                addCommasToNumber(response.data.data[i].clicks)
              );
              tempPlot3Hover.push(response.data.data[i].ctr);
            }

            setXData(tempXaxis);
            setPlot1(tempPlot1);
            setPlot2(tempPlot2);
            setPlot3(tempPlot3);
            setPlot1Hover(tempPlot1Hover);
            setPlot2Hover(tempPlot2Hover);
            setPlot3Hover(tempPlot3Hover);
          }
          setGraphIsLoading(false);
        })
        .catch((error) => {
          setGraphIsLoading(false);
        });

      /////////////////////////////cricket api/////////////////////////////////////////

      axios
        .get(
          configData.SERVER_URL + `dashboard/cricketMatch`,

          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setCricketData(response.data.data);

            if (response.data.data.finalLiveData?.length === 0) {
              setCricketDataTab("upcoming");

              let element = document.getElementById("pills-home-tab");
              element.classList.remove("active");
              let element1 = document.getElementById("pills-profile-tab");
              element1.classList.add("active");

              let element3 = document.getElementById("pills-home");
              element3.classList.remove("active");
              element3.classList.remove("show");
              let element4 = document.getElementById("pills-profile");
              element4.classList.add("active");
              element4.classList.add("show");
            }
          }
          setCricketDataIsLoading(false);
        })
        .catch((error) => {
          setCricketDataIsLoading(false);
        });

      /////////////////////////////////////////////////aqi api///////////////////////////
      axios
        .get(configData.SERVER_URL + `dashboard/aqidata`, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            setAqiData(response.data.data);
          }
          setAqiDataIsLoading(false);
        })
        .catch((error) => {
          setAqiDataIsLoading(false);
        });

      /////////////////////////////////////////////////weather api///////////////////////////
      axios
        .get(configData.SERVER_URL + `dashboard/currentLocationWeather`, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            setWeatherData(response.data.data);
            let tempArr = [];
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].MinTemp) {
                const number = parseInt(
                  response.data.data[i].MinTemp.match(/\d+/)[0]
                );

                tempArr.push(number);
              }
            }

            setWeatherGraphData(tempArr);
          }
          setWeatherIsLoading(false);
        })
        .catch((error) => {
          setWeatherIsLoading(false);
        });

      /////////////////////////////////////////////////crypto api///////////////////////////
      axios
        .get(configData.SERVER_URL + `dashboard/getCryptoData`, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            setCryptoData(response.data.data.result);

            let arrMaking = [];
            let graphColor = "red";

            for (let i = 0; i < response.data.data.graphArray.length; i++) {
              let cryptoGraphDataOptions = {
                optionsMixedChart: {
                  chart: {
                    id: "basic-bar",
                    toolbar: {
                      show: true,
                    },
                  },

                  fill: {
                    opacity: 0,
                  },
                  labels: {
                    show: false,
                  },
                  tooltip: {
                    enabled: false, // Hide tooltips
                  },
                  dataLabels: {
                    enabled: false, // Hide data labels
                  },

                  plotOptions: {
                    bar: {
                      columnWidth: "50%",
                    },
                  },
                  stroke: {
                    curve: "smooth",
                    width: 1,
                    colors: ["red"],
                  },
                  yaxis: {
                    show: false,
                  },
                  xaxis: {
                    tickAmount: undefined,
                    labels: {
                      show: false,
                    },

                    axisBorder: {
                      show: false,
                    },
                  },
                },
                seriesMixedChart: [
                  {
                    name: "series-1",
                    color: "#4985e2",
                    type: "area",
                    data: [30, 40, 25, 50, 49, 21, 70, 51],
                  },
                ],
              };

              graphColor = "red";

              cryptoGraphDataOptions.seriesMixedChart[0].data =
                response.data.data.graphArray[i];
              if (
                response.data.data.graphArray[i][5] >
                response.data.data.graphArray[i][4]
              ) {
                graphColor = "green";
              }

              cryptoGraphDataOptions.optionsMixedChart.stroke.colors = [
                graphColor,
              ];

              arrMaking.push(cryptoGraphDataOptions);
            }

            setCryptoGraphData(arrMaking);
          }
          setCryptoDataIsLoading(false);
        })
        .catch((error) => {
          setCryptoDataIsLoading(false);
        });
    }

    loadingContext.done();
  }, []);

  // /////////////////////////////cron api's  start////////////////////////////////////

  React.useEffect(() => {
    const fetchDataCricket = async () => {
      axios
        .get(
          configData.SERVER_URL + `dashboard/cricketMatch`,

          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setCricketData(response.data.data);
            if (response.data.data.finalLiveData?.length === 0) {
              setCricketDataTab("upcoming");

              let element = document.getElementById("pills-home-tab");
              element.classList.remove("active");
              let element1 = document.getElementById("pills-profile-tab");
              element1.classList.add("active");

              let element3 = document.getElementById("pills-home");
              element3.classList.remove("active");
              element3.classList.remove("show");
              let element4 = document.getElementById("pills-profile");
              element4.classList.add("active");
              element4.classList.add("show");
            }
          } else {
            setCricketDataIsLoading(false);
          }
        })
        .catch((error) => {
          setCricketDataIsLoading(false);
        });
    };

    const timeoutId = setTimeout(() => {
      fetchDataCricket();
      const intervalId = setInterval(fetchDataCricket, 300000); // Call API every 5 min  --300000
      return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  React.useEffect(() => {
    const fetchDataCrypto = async () => {
      let status = Cookies.get("token_moment") ? true : false;
      if (status) {
        let token = Cookies.get("token_moment");

        axios
          .get(configData.SERVER_URL + `dashboard/getCryptoData`, {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          })
          .then((response) => {
            if (response.data.status === 200) {
              setCryptoData(response.data.data.result);

            }
          })
          .catch((error) => { });
      }
    };

    const timeoutId = setTimeout(() => {
      fetchDataCrypto();
      const intervalId = setInterval(fetchDataCrypto, 1800000); // Call API every 30 minute
      return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  React.useEffect(() => {
    const fetchDataWeather = async () => {
      let status = Cookies.get("token_moment") ? true : false;
      if (status) {
        let token = Cookies.get("token_moment");
        axios
          .get(configData.SERVER_URL + `dashboard/currentLocationWeather`, {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          })
          .then((response) => {
            if (response.data.status === 200) {
              setWeatherData(response.data.data);
            }
          })
          .catch((error) => { });
      }
    };

    const timeoutId = setTimeout(() => {
      fetchDataWeather();
      const intervalId = setInterval(fetchDataWeather, 1800000); // Call API every 30 minute
      return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  React.useEffect(() => {
    const fetchDataAqi = async () => {
      let status = Cookies.get("token_moment") ? true : false;
      if (status) {
        let token = Cookies.get("token_moment");

        axios
          .get(configData.SERVER_URL + `dashboard/aqidata`, {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          })
          .then((response) => {
            if (response.data.status === 200) {
              setAqiData(response.data.data);
            }
          })
          .catch((error) => { });
      }
    };

    const timeoutId = setTimeout(() => {
      fetchDataAqi();
      const intervalId = setInterval(fetchDataAqi, 1800000); // Call API every 30 minute
      return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  ////////////////////////////// cron api's end /////////////////////////////////////////

  // number format function
  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000)?.toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000)?.toFixed(1) + "K";
    } else {
      return num?.toString();
    }
  }

  ////////////////////////////////////////SUPer ADmin COde Starts////////////////////////////////////////////////////////


  return (
    <div className="main_contain_area bg-transparent w-auto">

      <div className="my_custom_grid_system row">

        <div className="custom_col_grid_9 custom_order_9 pe-0">
          <Row>
            <Col md={8} className="pe-0">
              <div className="d-flex">
                {role === "SA" && (
                  <>
                    {dashboardData1IsLoading ? (
                      <Col md={4} className="px-0">
                        <div className="dash_board_box h-127">
                          <ContentLoader
                            speed={2}
                            width={700}
                            height={100}
                            viewBox="187 -10 452 119"
                            backgroundColor="#e0e0e0"
                            foregroundColor="#ecebeb"
                          >
                            <circle cx="20" cy="20" r="20" />
                            <rect
                              x="48"
                              y="14"
                              rx="3"
                              ry="10"
                              width="88"
                              height="10"
                            />
                            <rect
                              x="0"
                              y="62"
                              rx="3"
                              ry="3"
                              width="80"
                              height="10"
                            />
                            <rect
                              x="0"
                              y="88"
                              rx="3"
                              ry="3"
                              width="100"
                              height="10"
                            />
                          </ContentLoader>
                        </div>
                      </Col>
                    ) : (
                      <Col md={4} className="pe-0">
                        <div className="dash_board_box h-127">
                          <div className="d-flex align-items-center">
                            <img src={Advertisers} className="img-fluid" alt="" />
                            <span>Advertisers</span>
                          </div>
                          {dashboardData1 !== null ? (
                            <h4>{dashboardData1?.advertiserCount}</h4>
                          ) : (
                            <>
                              {" "}
                              <h4 className="d-flex align-items-end">0</h4>
                            </>
                          )}
                          {dashboardData1 !== null ? (
                            <div className="bottom_graph_content d-flex align-items-center">
                              <small className={dashboardData1?.percentageAdvDiff < 0 ? "red_text" : "green_text"}>
                                {dashboardData1?.percentageAdvDiff >= 0 ? (
                                  <img
                                    src={GraphIcon}
                                    className="img-fluid"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={DownGraph}
                                    className="img-fluid"
                                    alt=""
                                  />
                                )}
                                {dashboardData1?.percentageAdvDiff}%
                              </small>
                              <p className="">
                                {dashboardData1?.advertiserDiff} &nbsp; Last Month
                              </p>
                            </div>
                          ) : (
                            <>
                              {" "}
                              <div className="bottom_graph_content d-flex align-items-center">
                                {" "}
                                <p className=""> Last Month</p>{" "}
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                    )}

                    {dashboardData1IsLoading ? (
                      <Col md={4} className="px-0">
                        <div className="dash_board_box h-127">
                          <ContentLoader
                            speed={2}
                            width={700}
                            height={100}
                            viewBox="187 -10 452 119"
                            backgroundColor="#e0e0e0"
                            foregroundColor="#ecebeb"
                          >
                            <circle cx="20" cy="20" r="20" />
                            <rect
                              x="48"
                              y="14"
                              rx="3"
                              ry="10"
                              width="88"
                              height="10"
                            />
                            <rect
                              x="0"
                              y="62"
                              rx="3"
                              ry="3"
                              width="80"
                              height="10"
                            />
                            <rect
                              x="0"
                              y="88"
                              rx="3"
                              ry="3"
                              width="100"
                              height="10"
                            />
                          </ContentLoader>
                        </div>
                      </Col>
                    ) : (
                      <Col md={4} className="px-0">
                        <div className="dash_board_box h-127">
                          <div className="d-flex align-items-center">
                            <img src={Campaigns} className="img-fluid" alt="" />
                            <span>Campaigns</span>
                          </div>
                          <h4 className="d-flex align-items-end">
                            {dashboardData1?.activeCampaignCount}{" "}
                            {dashboardData1 !== null ? <>/</> : <>0/0</>}{" "}
                            {dashboardData1?.totalCampaignCount}{" "}
                            <div className="active_user_text">(Active / Total)</div>
                          </h4>
                          {dashboardData1 !== null ? (
                            <div className="bottom_graph_content d-flex align-items-center">
                              <small className={dashboardData1?.percentDiffCampaign < 0 ? "red_text" : "green_text"}>
                                {dashboardData1?.percentDiffCampaign >= 0 ? (
                                  <img
                                    src={GraphIcon}
                                    className="img-fluid"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={DownGraph}
                                    className="img-fluid"
                                    alt=""
                                  />
                                )}
                                {dashboardData1?.percentDiffCampaign}%
                              </small>
                              <p className="">
                                {dashboardData1?.campaignDiff} &nbsp; Last Month
                              </p>
                            </div>
                          ) : (
                            <>
                              {" "}
                              <div className="bottom_graph_content d-flex align-items-center">
                                {" "}
                                <p className=""> Last Month</p>{" "}
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                    )}

                    {dashboardData1IsLoading ? (
                      <Col md={4} className="px-0">
                        <div className="dash_board_box h-127">
                          <ContentLoader
                            speed={2}
                            width={700}
                            height={100}
                            viewBox="187 -10 452 119"
                            backgroundColor="#e0e0e0"
                            foregroundColor="#ecebeb"
                          >
                            <circle cx="20" cy="20" r="20" />
                            <rect
                              x="48"
                              y="14"
                              rx="3"
                              ry="10"
                              width="88"
                              height="10"
                            />
                            <rect
                              x="0"
                              y="62"
                              rx="3"
                              ry="3"
                              width="80"
                              height="10"
                            />
                            <rect
                              x="0"
                              y="88"
                              rx="3"
                              ry="3"
                              width="100"
                              height="10"
                            />
                          </ContentLoader>
                        </div>
                      </Col>
                    ) : (
                      <Col md={4} className="px-0">
                        <div className="dash_board_box h-127">
                          <div className="d-flex align-items-center">
                            <img src={Revenue} className="img-fluid" alt="" />
                            <span>Revenue</span>
                          </div>
                          <h4>
                            {" "}
                            {dashboardData1 !== null ? (
                              <>
                                <span>{dashboardData1?.revenue.split(" ")[0]}</span>{" "}
                                {formatNumber(dashboardData1?.revenue.split(" ")[1])}
                              </>
                            ) : (
                              <>
                                <span>$</span>&nbsp;0
                              </>
                            )}
                          </h4>
                          {dashboardData1 !== null ? (
                            <div className="bottom_graph_content d-flex align-items-center">
                              <small className={dashboardData1?.spendDiffPercentage < 0 ? "red_text" : "green_text"}>
                                {dashboardData1?.spendDiffPercentage >= 0 ? (
                                  <img
                                    src={GraphIcon}
                                    className="img-fluid"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={DownGraph}
                                    className="img-fluid"
                                    alt=""
                                  />
                                )}
                                {dashboardData1?.spendDiffPercentage}%
                              </small>
                              <p className="">
                                {dashboardData1?.spendPreviousMonth} &nbsp; Last Month
                              </p>
                            </div>
                          ) : (
                            <>
                              {" "}
                              <div className="bottom_graph_content d-flex align-items-center">
                                {" "}
                                <p className="">Last Month</p>{" "}
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                    )}
                  </>
                )}

                {(role === "MASTER" ||
                  role === "A" ||
                  role === "M" ||
                  role === "RM") && (
                    <>
                      {!aidWiseView &&
                        <>
                          {dashboardData1IsLoading ? (
                            <Col md={4} className="px-0">
                              <div className="dash_board_box h-127">
                                <ContentLoader
                                  speed={2}
                                  width={700}
                                  height={100}
                                  viewBox="187 -10 452 119"
                                  backgroundColor="#e0e0e0"
                                  foregroundColor="#ecebeb"
                                >
                                  <circle cx="20" cy="20" r="20" />
                                  <rect
                                    x="48"
                                    y="14"
                                    rx="3"
                                    ry="10"
                                    width="88"
                                    height="10"
                                  />
                                  <rect
                                    x="0"
                                    y="62"
                                    rx="3"
                                    ry="3"
                                    width="80"
                                    height="10"
                                  />
                                  <rect
                                    x="0"
                                    y="88"
                                    rx="3"
                                    ry="3"
                                    width="100"
                                    height="10"
                                  />
                                </ContentLoader>
                              </div>
                            </Col>
                          ) : (
                            <Col md={4} className="pe-0">
                              <div className="dash_board_box h-127">
                                <div className="d-flex align-items-center">
                                  <img src={Advertisers} className="img-fluid" alt="" />
                                  <span> Activations</span>
                                </div>
                                {dashboardData1 !== null ? (
                                  <h4> {dashboardData1?.activationCount}</h4>
                                ) : (
                                  <>
                                    {" "}
                                    <h4 className="d-flex align-items-end">-</h4>
                                  </>
                                )}

                                {/* <div className="bottom_graph_content d-flex align-items-center">
                          <small className="green_text">
                            <img src={GraphIcon} className="img-fluid" alt="" />
                            12%
                          </small>
                          <p className="">+23 Last 7 Days</p>
                        </div> */}
                              </div>
                            </Col>
                          )}
                        </>
                      }

                      {dashboardData1IsLoading ? (
                        <Col md={aidWiseView ? 6 : 4} className="px-0">
                          <div className="dash_board_box h-127">
                            <ContentLoader
                              speed={2}
                              width={700}
                              height={100}
                              viewBox="187 -10 452 119"
                              backgroundColor="#e0e0e0"
                              foregroundColor="#ecebeb"
                            >
                              <circle cx="20" cy="20" r="20" />
                              <rect
                                x="48"
                                y="14"
                                rx="3"
                                ry="10"
                                width="88"
                                height="10"
                              />
                              <rect
                                x="0"
                                y="62"
                                rx="3"
                                ry="3"
                                width="80"
                                height="10"
                              />
                              <rect
                                x="0"
                                y="88"
                                rx="3"
                                ry="3"
                                width="100"
                                height="10"
                              />
                            </ContentLoader>
                          </div>
                        </Col>
                      ) : (
                        <Col md={aidWiseView ? 6 : 4} className="px-0">
                          <div className="dash_board_box h-127">
                            <div className="d-flex align-items-center">
                              <img src={Campaigns} className="img-fluid" alt="" />
                              <span>Campaigns</span>
                            </div>
                            <h4 className="d-flex align-items-end">
                              {" "}
                              {dashboardData1?.activeCampaignCount}{" "}
                              {dashboardData1 !== null ? <>/</> : <>0/0</>}
                              {dashboardData1?.totalCampaignCount}{" "}
                              <div className="active_user_text">(Active/Total)</div>
                            </h4>
                            {dashboardData1 !== null ? (
                              <div className="bottom_graph_content d-flex align-items-center">
                                <small className={dashboardData1?.percentDiffCampaign < 0 ? "red_text" : "green_text"}>
                                  {dashboardData1?.percentDiffCampaign >= 0 ? (
                                    <img
                                      src={GraphIcon}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={DownGraph}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  )}
                                  {dashboardData1?.percentDiffCampaign}%
                                </small>
                                <p className="">
                                  {dashboardData1?.campaignDiff} &nbsp; Last Month
                                </p>
                              </div>
                            ) : (
                              <>
                                {" "}
                                <div className="bottom_graph_content d-flex align-items-center">
                                  {" "}
                                  <p className="">Last Month</p>{" "}
                                </div>
                              </>
                            )}
                          </div>
                        </Col>
                      )}

                      {!aidWiseView &&
                        <>
                          {dashboardData1IsLoading ? (
                            <Col md={4} className="px-0">
                              <div className="dash_board_box h-127">
                                <ContentLoader
                                  speed={2}
                                  width={700}
                                  height={100}
                                  viewBox="187 -10 452 119"
                                  backgroundColor="#e0e0e0"
                                  foregroundColor="#ecebeb"
                                >
                                  <circle cx="20" cy="20" r="20" />
                                  <rect
                                    x="48"
                                    y="14"
                                    rx="3"
                                    ry="10"
                                    width="88"
                                    height="10"
                                  />
                                  <rect
                                    x="0"
                                    y="62"
                                    rx="3"
                                    ry="3"
                                    width="80"
                                    height="10"
                                  />
                                  <rect
                                    x="0"
                                    y="88"
                                    rx="3"
                                    ry="3"
                                    width="100"
                                    height="10"
                                  />
                                </ContentLoader>
                              </div>
                            </Col>
                          ) : (
                            <Col md={4} className="px-0">
                              <div className="dash_board_box h-127">
                                <div className="d-flex align-items-center">
                                  <img src={Revenue} className="img-fluid" alt="" />
                                  <span>Revenue</span>
                                </div>
                                <h4>
                                  {" "}
                                  {dashboardData1 !== null ? (
                                    <>
                                      <span>{dashboardData1?.revenue.split(" ")[0]}</span>{" "}
                                      {formatNumber(dashboardData1?.revenue.split(" ")[1])}

                                    </>
                                  ) : (
                                    <>
                                      <span>$</span>&nbsp;0
                                    </>
                                  )}
                                </h4>

                                {dashboardData1 !== null ? (
                                  <div className="bottom_graph_content d-flex align-items-center">
                                    <small className={dashboardData1?.spendDiffPercentage < 0 ? "red_text" : "green_text"}>
                                      {dashboardData1?.spendDiffPercentage >= 0 ? (
                                        <img
                                          src={GraphIcon}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src={DownGraph}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      )}
                                      {dashboardData1?.spendDiffPercentage}%
                                    </small>
                                    <p className="">
                                      {dashboardData1?.spendPreviousMonth} &nbsp; Last Month
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    <div className="bottom_graph_content d-flex align-items-center">
                                      {" "}
                                      <p className="">Last Month</p>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </Col>
                          )}
                        </>
                      }

                      {aidWiseView &&
                        <>
                          {dashboardData2IsLoading ? (
                            <Col md={6} className="px-0">
                              <div className="dash_board_box h-127">
                                <ContentLoader
                                  speed={2}
                                  width={700}
                                  height={100}
                                  viewBox="187 -10 452 119"
                                  backgroundColor="#e0e0e0"
                                  foregroundColor="#ecebeb"
                                >
                                  <circle cx="20" cy="20" r="20" />
                                  <rect
                                    x="48"
                                    y="14"
                                    rx="3"
                                    ry="10"
                                    width="88"
                                    height="10"
                                  />
                                  <rect x="0" y="62" rx="3" ry="3" width="80" height="10" />
                                  <rect
                                    x="0"
                                    y="88"
                                    rx="3"
                                    ry="3"
                                    width="100"
                                    height="10"
                                  />
                                </ContentLoader>
                              </div>
                            </Col>
                          ) : (
                            <Col md={6} className="pe-0">
                              <div className="dash_board_box h-127 ">
                                <div className="d-flex align-items-center">
                                  <img src={Impressions} className="img-fluid" alt="" />
                                  <span>Impressions</span>
                                </div>

                                {dashboardData2 !== null ? (
                                  <h4>{formatNumber(dashboardData2?.total_impressions)}</h4>
                                ) : (
                                  <>
                                    {" "}
                                    <h4 className="d-flex align-items-end">0</h4>
                                  </>
                                )}
                                {dashboardData2 !== null ? (
                                  <div className="bottom_graph_content d-flex align-items-center">
                                    <small className={dashboardData2?.impressionsDiffPercent < 0 ? "red_text" : "green_text"}>
                                      {dashboardData2?.impressionsDiffPercent >= 0 ? (
                                        <img src={GraphIcon} className="img-fluid" alt="" />
                                      ) : (
                                        <img src={DownGraph} className="img-fluid" alt="" />
                                      )}
                                      {dashboardData2?.impressionsDiffPercent}%
                                    </small>
                                    <p className="">
                                      {formatNumber(dashboardData2?.impressionsPast30Days)} &nbsp;
                                      Last Month
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    <div className="bottom_graph_content d-flex align-items-center">
                                      {" "}
                                      <p className=""> Last Month</p>{" "}
                                    </div>
                                  </>
                                )}
                              </div>
                            </Col>
                          )}
                        </>
                      }

                    </>
                  )}
              </div>
              <div className="d-flex">

                {dashboardData2IsLoading ? (
                  <Col md={4} className={aidWiseView ? "d-none" : "px-0"}>
                    <div className="dash_board_box h-127 mt-16">
                      <ContentLoader
                        speed={2}
                        width={700}
                        height={100}
                        viewBox="187 -10 452 119"
                        backgroundColor="#e0e0e0"
                        foregroundColor="#ecebeb"
                      >
                        <circle cx="20" cy="20" r="20" />
                        <rect
                          x="48"
                          y="14"
                          rx="3"
                          ry="10"
                          width="88"
                          height="10"
                        />
                        <rect x="0" y="62" rx="3" ry="3" width="80" height="10" />
                        <rect
                          x="0"
                          y="88"
                          rx="3"
                          ry="3"
                          width="100"
                          height="10"
                        />
                      </ContentLoader>
                    </div>
                  </Col>
                ) : (
                  <Col md={4} className={aidWiseView ? "d-none" : "pe-0"}>
                    <div className="dash_board_box h-127 mt-16">
                      <div className="d-flex align-items-center">
                        <img src={Impressions} className="img-fluid" alt="" />
                        <span>Impressions</span>
                      </div>

                      {dashboardData2 !== null ? (
                        <h4>{formatNumber(dashboardData2?.total_impressions)}</h4>
                      ) : (
                        <>
                          {" "}
                          <h4 className="d-flex align-items-end">0</h4>
                        </>
                      )}
                      {dashboardData2 !== null ? (
                        <div className="bottom_graph_content d-flex align-items-center">
                          <small className={dashboardData2?.impressionsDiffPercent < 0 ? "red_text" : "green_text"}>
                            {dashboardData2?.impressionsDiffPercent >= 0 ? (
                              <img src={GraphIcon} className="img-fluid" alt="" />
                            ) : (
                              <img src={DownGraph} className="img-fluid" alt="" />
                            )}
                            {dashboardData2?.impressionsDiffPercent}%
                          </small>
                          <p className="">
                            {formatNumber(dashboardData2?.impressionsPast30Days)} &nbsp;
                            Last Month
                          </p>
                        </div>
                      ) : (
                        <>
                          {" "}
                          <div className="bottom_graph_content d-flex align-items-center">
                            {" "}
                            <p className=""> Last Month</p>{" "}
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                )}

                {dashboardData2IsLoading ? (
                  <Col md={aidWiseView ? 6 : 4} className="px-0">
                    <div className="dash_board_box h-127 mt-16">
                      <ContentLoader
                        speed={2}
                        width={700}
                        height={100}
                        viewBox="187 -10 452 119"
                        backgroundColor="#e0e0e0"
                        foregroundColor="#ecebeb"
                      >
                        <circle cx="20" cy="20" r="20" />
                        <rect
                          x="48"
                          y="14"
                          rx="3"
                          ry="10"
                          width="88"
                          height="10"
                        />
                        <rect x="0" y="62" rx="3" ry="3" width="80" height="10" />
                        <rect
                          x="0"
                          y="88"
                          rx="3"
                          ry="3"
                          width="100"
                          height="10"
                        />
                      </ContentLoader>
                    </div>
                  </Col>
                ) : (
                  <Col md={aidWiseView ? 6 : 4} className="px-0">
                    <div className="dash_board_box h-127 mt-16">
                      <div className="d-flex align-items-center">
                        <img src={ClicksImg} className="img-fluid" alt="" />
                        <span>Clicks</span>
                      </div>
                      {dashboardData2 !== null ? (
                        <h4>{formatNumber(dashboardData2?.total_clicks)}</h4>
                      ) : (
                        <>
                          {" "}
                          <h4 className="d-flex align-items-end">0</h4>
                        </>
                      )}
                      {dashboardData2 !== null ? (
                        <div className="bottom_graph_content d-flex align-items-center">
                          <small className={dashboardData2?.clicksDiffPercent < 0 ? "red_text" : "green_text"}>
                            {dashboardData2?.clicksDiffPercent >= 0 ? (
                              <img src={GraphIcon} className="img-fluid" alt="" />
                            ) : (
                              <img src={DownGraph} className="img-fluid" alt="" />
                            )}
                            {dashboardData2?.clicksDiffPercent}%
                          </small>
                          <p className="">
                            {formatNumber(dashboardData2?.clicksPast30Days)} &nbsp;
                            Last Month
                          </p>
                        </div>
                      ) : (
                        <>
                          {" "}
                          <div className="bottom_graph_content d-flex align-items-center">
                            {" "}
                            <p className=""> Last Month</p>{" "}
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                )}

                {dashboardData2IsLoading ? (
                  <Col md={aidWiseView ? 6 : 4} className="px-0">
                    <div className="dash_board_box h-127 mt-16">
                      <ContentLoader
                        speed={2}
                        width={700}
                        height={100}
                        viewBox="187 -10 452 119"
                        backgroundColor="#e0e0e0"
                        foregroundColor="#ecebeb"
                      >
                        <circle cx="20" cy="20" r="20" />
                        <rect
                          x="48"
                          y="14"
                          rx="3"
                          ry="10"
                          width="88"
                          height="10"
                        />
                        <rect x="0" y="62" rx="3" ry="3" width="80" height="10" />
                        <rect
                          x="0"
                          y="88"
                          rx="3"
                          ry="3"
                          width="100"
                          height="10"
                        />
                      </ContentLoader>
                    </div>
                  </Col>
                ) : (
                  <Col md={aidWiseView ? 6 : 4} className="px-0">
                    <div className="dash_board_box h-127 mt-16">
                      <div className="d-flex align-items-center">
                        <img src={CTRImg} className="img-fluid" alt="" />
                        <span>CTR</span>
                      </div>
                      {dashboardData2 !== null ? (
                        <h4>{dashboardData2?.total_ctr}</h4>
                      ) : (
                        <>
                          {" "}
                          <h4 className="d-flex align-items-end">0</h4>
                        </>
                      )}
                      {dashboardData2 !== null ? (
                        <div className="bottom_graph_content d-flex align-items-center">
                          <small className={dashboardData2?.ctrDiffPercent < 0 ? "red_text" : "green_text"}>
                            {dashboardData2?.ctrDiffPercent >= 0 ? (
                              <img src={GraphIcon} className="img-fluid" alt="" />
                            ) : (
                              <img src={DownGraph} className="img-fluid" alt="" />
                            )}
                            {dashboardData2?.ctrDiffPercent}%
                          </small>
                          <p className="">
                            {dashboardData2?.ctrPast30Days} &nbsp; Last Month
                          </p>
                        </div>
                      ) : (
                        <>
                          {" "}
                          <div className="bottom_graph_content d-flex align-items-center">
                            {" "}
                            <p className=""> Last Month</p>{" "}
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                )}
              </div>
            </Col>

            <Col md={4} className="ps-0 pie_chart_design">
              {pieDataIsLoading ? (
                <div className="graph_areas_side h-272 me-16 mt-0 weather_with_four_tabs position-relative p-0">
                  <ContentLoader speed={2} width="500" height="600" viewBox="-16 110 335 200" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
                    <rect x="170" y="30" rx="5" ry="5" width="50" height="10" />
                    <rect x="170" y="70" rx="5" ry="5" width="50" height="10" />
                    <rect x="170" y="110" rx="5" ry="5" width="50" height="10" />
                    <rect x="170" y="150" rx="5" ry="5" width="50" height="10" />
                    <rect x="3" y="25" rx="150" ry="150" width="150" height="150" />
                  </ContentLoader>
                </div>
              ) : (
                <>
                  {pieData?.length > 0 ? (

                    <div className="dash_board_box graph_areas_side h-272 mt-0 p-12 position-relative overflow-visible">
                      <div className="d-flex justify-content-between align-items-center relative_class_for_up">
                        <div className="d-flex align-items-center">
                          <img src={ReportsIcon} className="img-fluid" alt="" />
                          <span>Summary</span>
                        </div>
                        <InputGroup className={focusSummary ? 'full_input_border custom_country_drop_design new_cls_for_reactselect' : 'custom_country_drop_design'}>
                          <InputGroup.Text className="p-0 for_dashbaord_page">
                            <img src={TransEyeIcon} className="img-fluid report_eye_icon" alt="" />
                          </InputGroup.Text>
                          <Select
                            className="custom_drop_design select-custom p-0 new_width_select"
                            options={selectedPieDataOptions}
                            placeholder="-Select-"
                            value={selectedPieData}
                            onFocus={FocusSummary}
                            onBlur={FocusSummaryBlur}
                            onChange={(event) => {
                              setSelectedPieData(event)

                              if (event.value === "Impression") {
                                setPieData(pieDataImpression)

                              }
                              else if (event.value === "Click") {
                                setPieData(pieDataClick)
                              }
                              else if (event.value === "Ctr") {

                                setPieData(pieDataCtr)
                              }
                              else if (event.value === "Ecpm") {

                                setPieData(pieDataEcpm)
                              }
                              else if (event.value === "CPC") {

                                setPieData(pieDataCPC)
                              }
                              else {
                                setPieData(pieDataSpend)
                              }
                            }}
                            isSearchable={false}>
                          </Select>
                        </InputGroup>
                      </div>
                      <Chart
                        options={pieChartData.options}
                        series={pieChartData.series}
                        className="make_custom_chart_position"
                        type="donut"
                        height={200}
                      />
                    </div>
                  ) :
                    <>

                      <div className="dash_board_box h-272 me-16 mt-0 weather_with_four_tabs position-relative p-0">
                        <div className="d-flex justify-content-between p-12 align-items-center relative_class_for_up">
                          <div className="d-flex align-items-center">
                            <img src={ReportsIcon} className="img-fluid" alt="" />
                            <span>Summary</span>
                          </div>
                        </div>
                        <div className="dash_board_box p-12">
                          <div className="d-flex align-items-center red_circle_img pt-5 mt-4 z_index_1 position-relative">
                            <img src={NoDataAvailable} className="img-fluid" alt="" />
                            <p> The chart contains no data.
                              {/* <Link to="#" className="hyper_link_clr text-decoration-underline">Refresh</Link> */}
                            </p>
                          </div>
                        </div>
                        <div className="no_data_available_football">
                          <img src={ChartPiePlace} className="w-auto h-auto m-0" alt="" />
                        </div>
                      </div>

                    </>
                  }
                </>


              )}
            </Col>
          </Row>
        </div>
        <div className="custom_col_grid_9 custom_order_9_mid pe-0">
          {graphIsLoading ? (
            <div className="graph_areas_side h-291 me-16">
              <ContentLoader className="small_view"
                speed={2}
                width="1500"
                height="300"
                viewBox="-16 100 335 -100"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="15" y="30" rx="5" ry="5" width="76" height="10" />
                <rect x="940" y="30" rx="5" ry="5" width="76" height="10" />
                <rect x="1040" y="30" rx="5" ry="5" width="76" height="10" />
                <rect x="1140" y="30" rx="5" ry="5" width="76" height="10" />
                <rect x="15" y="100" rx="5" ry="5" width="1360" height="5" />
                <rect x="15" y="150" rx="5" ry="5" width="1360" height="5" />
                <rect x="15" y="200" rx="5" ry="5" width="1360" height="5" />
                <rect x="15" y="250" rx="5" ry="5" width="1360" height="5" />

              </ContentLoader>
              <ContentLoader className="larg_view"
                speed={2}
                width="1500"
                height="300"
                viewBox="-16 100 335 -100"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="15" y="30" rx="5" ry="5" width="76" height="10" />
                <rect x="1038" y="30" rx="5" ry="5" width="76" height="10" />
                <rect x="1138" y="30" rx="5" ry="5" width="76" height="10" />
                <rect x="1238" y="30" rx="5" ry="5" width="76" height="10" />
                <rect x="15" y="100" rx="5" ry="5" width="1300" height="5" />
                <rect x="15" y="150" rx="5" ry="5" width="1300" height="5" />
                <rect x="15" y="200" rx="5" ry="5" width="1300" height="5" />
                <rect x="15" y="250" rx="5" ry="5" width="1300" height="5" />

                {/* <path d="M15 200 Q120 100 240 200 Q360 300 480 200 Q600 100 720 200 Q840 300 960 200 Q1080 100 1200 200" fill="none" stroke="#000" strokeWidth="3" /> */}
              </ContentLoader>
            </div>
          ) : (
            <>
              {xData.length > 0 ? (
                <div className="graph_areas_side h-291 me-16">
                  <div className="d-flex justify-content-between mb-16">
                    <h4>Performance</h4>
                    {/* <InputGroup className="custom_country_drop_design diff-disabled_cls new_width_select">
                        <InputGroup.Text className="disabled_addon for_dashbaord_page p-0">
                          <img src={Calender} className="img-fluid report_eye_icon m-8" alt="" />
                        </InputGroup.Text>
                        <Select
                          className="custom_drop_design select-custom p-0"
                          options={[{label:"Last 30 Days",value:"Last 30 Days"}]}
                          placeholder="-Select-"
                          value={{label:"Last 30 Days",value:"Last 30 Days"}}
                          onChange={(event)=>{}}
                          isDisabled={true}
                          isSearchable={false}>
                        </Select>
                      </InputGroup> */}
                  </div>
                  {/* <Chart options={optionChart.options} series={optionChart.series} type="area" /> */}
                  <Chart className="for_three_dots"
                    options={graphOptions.optionsMixedChart}
                    series={graphOptions.seriesMixedChart}
                    height={220}
                  />

                </div>
              ) : (
                <div className="graph_areas_side h-291 me-16 no_data_img">
                  <div className="d-flex justify-content-between align-items-center mb-16">
                    <h4>Performance</h4>
                  </div>
                  <div className="red_circle_img d-flex align-items-center bg-transparent mt-5 pt-5">
                    <img src={NoDataAvailable} className="img-fluid" alt="" />
                    <div className="for_table_no_data_text">
                      <p>No Data to show</p>
                      <span>Create your first Advertisers to uncover insights.</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {role === "SA" ?
            <>
              {top10CampaignIsLoading ? (
                <div className="graph_areas_side h-571 me-16">
                  <ContentLoader
                    className=""
                    viewBox="16 16 1180 530"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="16" y="16" rx="5" ry="5" width="153" height="30" />
                    <rect x="27" y="80" rx="4" ry="4" width="20" height="12" />
                    <rect x="67" y="80" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="188"
                      y="80"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="402" y="80" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="523"
                      y="80"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="731" y="80" rx="10" ry="10" width="85" height="12" />
                    <rect x="852" y="80" rx="10" ry="10" width="85" height="12" />
                    <rect x="26" y="120" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="120" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="120"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="120"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="120"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="120"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="120"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect x="26" y="160" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="160" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="160"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="160"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="160"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="160"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="160"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect x="26" y="200" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="200" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="200"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="200"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="200"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="200"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="200"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="978"
                      y="80"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="120"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="160"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="200"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="240" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="240" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="240"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="240"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="240"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="240"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="240"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="240"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="280" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="280" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="280"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="280"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="280"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="280"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="280"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="280"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="320" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="320" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="320"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="320"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="320"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="320"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="320"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="320"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="360" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="360" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="360"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="360"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="360"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="360"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="360"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="360"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="400" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="400" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="400"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="400"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="400"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="400"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="400"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="400"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="440" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="440" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="440"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="440"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="440"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="440"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="440"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="440"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="480" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="480" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="480"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="480"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="480"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="480"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="480"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="480"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="520" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="520" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="520"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="520"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="520"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="520"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="520"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="520"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                  </ContentLoader>
                </div>
              ) : (
                <div className="graph_areas_side h-571 me-16 new_tab_design_for_dashboard">
                  <div className="d-flex justify-content-between align-items-center for_tabs_border">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected="true">
                          Top 10 Campaigns
                        </button>
                      </li>

                      <>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="profile-tab-pane"
                            aria-selected="false">
                            Top 10 Advertisers
                          </button>
                        </li>
                      </>
                    </ul>
                    <p className="table_show_list border-0">
                      Month-to-date
                    </p>
                  </div>
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active list_advertiser_table" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                      {top10Campaign.length > 0 ?
                        <div className="table_border_radius vertical_scroll_table  ">
                          <Table responsive className="display table nowrap table-advertiser-hidden custom_data_table table_first_th on_hover_text_effect">
                            <thead>
                              <tr>
                                <th scope="col">Campaign Name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Advertiser</th>
                                <th scope="col">Impressions</th>
                                <th scope="col">Clicks</th>
                                <th scope="col">CTR (%)</th>
                                <th scope="col">Spend</th>
                                <th className="normal-th eCPM_class" scope="col">eCPM</th>
                              </tr>
                            </thead>
                            <tbody>
                              {top10Campaign.map((element, index) => (
                                <tr>
                                  <td className="nowrap ">
                                    <Link className="" onClick={() =>
                                      window.open(
                                        "/reporting?campaignId=" +
                                        element["CAMPAIGN NAME"],
                                        "_blank"
                                      )
                                    }>
                                      {element["CAMPAIGN NAME"]}
                                      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.222931 0.712949C0.226165 0.347519 0.52332 0.0529825 0.888764 0.0529829L6.97124 0.0529825C7.41307 0.0529825 7.77124 0.411155 7.77124 0.852982L7.77124 6.93546C7.77124 7.30091 7.47671 7.59806 7.11128 7.6013C6.74125 7.60457 6.43953 7.30551 6.43953 6.93546V2.32751L1.17158 7.59545C0.911231 7.8558 0.489121 7.8558 0.228772 7.59545C-0.0315777 7.33511 -0.0315773 6.913 0.228772 6.65265L5.49672 1.3847L0.888765 1.3847C0.518718 1.3847 0.219656 1.08298 0.222931 0.712949Z" fill="#6366F1" />
                                      </svg>
                                    </Link>
                                    {/* {element["CAMPAIGN NAME"]} */}
                                  </td>
                                  <td className="nowrap ">

                                    <CustomSelect
                                      className={element["STATUS"] === "Pending" ? "newclass custom_drop_design" : element["STATUS"] === "Approved" ? "approvedclass custom_drop_design" : "declined custom_drop_design"}
                                      options={constantData.Campaign_Status_Option_SA}
                                      placeholder="-Select-"
                                      value={{ label: element["STATUS"], value: element["STATUS"] }}
                                      onChange={(event) => {

                                        setTop10CampaignIsLoading(true)

                                        let token = Cookies.get("token_moment");
                                        axios
                                          .post(
                                            configData.SERVER_URL +
                                            "campaign/campaignStatusBySA?campaignId=" +
                                            element["CAMPAIGN NAME"].split('-')[0],
                                            {
                                              status: event.toUpperCase(),
                                              campaignId: element["CAMPAIGN NAME"].split('-')[0],
                                            },

                                            {
                                              headers: {
                                                "Content-Type": "application/json",
                                                "x-access-token": token,
                                              },
                                            }
                                          )
                                          .then((response) => {

                                            if (response.data.status === 200) {
                                              // notifysuccess(response.data.message);
                                              let msg = response.data.message


                                              axios
                                                .get(configData.SERVER_URL + `dashboard/getTopTenCampaigns`, {
                                                  headers: {
                                                    "Content-Type": "application/json",
                                                    "x-access-token": token,
                                                  },
                                                })
                                                .then((response) => {
                                                  if (response.data.status === 200) {
                                                    setTop10Campaign(response.data.data);
                                                    notifysuccess(msg);

                                                  }
                                                  setTop10CampaignIsLoading(false);


                                                })
                                                .catch((error) => {
                                                  setTop10CampaignIsLoading(false);

                                                });
                                            }
                                            else {
                                              notifyfailure(response.data.message);
                                            }
                                          })
                                          .catch((error) => {
                                            setTop10CampaignIsLoading(false);
                                            notifyfailure()
                                          });
                                      }}

                                    ></CustomSelect>



                                  </td>
                                  <td className="nowrap ">
                                    {element["ADVERTISER"]}
                                  </td>
                                  <td className="nowrap ">
                                    {addCommasToNumber(element["IMPRESSIONS"])}
                                  </td>

                                  <td className="nowrap ">
                                    {addCommasToNumber(element["CLICK"])}
                                  </td>
                                  <td className="nowrap">{element["CTR"]}</td>

                                  <td className="nowrap">
                                    {element["SPEND"]}
                                  </td>
                                  <td className="nowrap ">{element["eCPM"]}</td>

                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        :
                        <div className="table_border_radius vertical_scroll_table">
                          <Table responsive className="display table nowrap table-advertiser-hidden custom_data_table table_first_th h-480">
                            <thead>
                              <tr>
                                <th scope="col">Campaign Name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Advertiser</th>
                                <th scope="col">Impressions</th>

                                <th scope="col">Clicks</th>
                                <th scope="col">CTR (%)</th>
                                <th scope="col">Spend</th>
                                <th className="normal-th eCPM_class" scope="col">eCPM</th>


                              </tr>
                            </thead>
                            <tbody>
                              <div className="p-12">
                                <div className="no_data_available red_circle_img d-flex align-items-center">
                                  <img src={NoDataAvailable} className="img-fluid" alt="" />
                                  <div className="for_table_no_data_text">
                                    <p> No Data Source for this Report</p>
                                    <span>Create your first campaign to uncover insights.</span>
                                  </div>
                                </div>
                              </div>
                            </tbody>
                          </Table>
                        </div>
                      }
                    </div>

                    {/* ////////////////////////////////////////////Top 10 Campaign code ends///////////////////////////////////////////////////// */}

                    {/* ////////////////////////////////////////////Top 10 Advertiser code starts///////////////////////////////////////////////////// */}
                    <div
                      class="tab-pane fade"
                      id="profile-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabindex="0"
                    >


                      {top10AdvertiserIsLoading ? (
                        <div className="">
                          <ContentLoader
                            className=""
                            viewBox="16 16 1180 220"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                          >
                            <rect
                              x="27"
                              y="80"
                              rx="4"
                              ry="4"
                              width="20"
                              height="12"
                            />
                            <rect
                              x="67"
                              y="80"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="188"
                              y="80"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="402"
                              y="80"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="523"
                              y="80"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="731"
                              y="80"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="852"
                              y="80"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />

                            <rect
                              x="26"
                              y="120"
                              rx="4"
                              ry="4"
                              width="20"
                              height="12"
                            />
                            <rect
                              x="66"
                              y="120"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="187"
                              y="120"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="401"
                              y="120"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="522"
                              y="120"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="730"
                              y="120"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="851"
                              y="120"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />

                            <rect
                              x="26"
                              y="160"
                              rx="4"
                              ry="4"
                              width="20"
                              height="12"
                            />
                            <rect
                              x="66"
                              y="160"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="187"
                              y="160"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="401"
                              y="160"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="522"
                              y="160"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="730"
                              y="160"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="851"
                              y="160"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />

                            <rect
                              x="26"
                              y="200"
                              rx="4"
                              ry="4"
                              width="20"
                              height="12"
                            />
                            <rect
                              x="66"
                              y="200"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="187"
                              y="200"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="401"
                              y="200"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="522"
                              y="200"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="730"
                              y="200"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="851"
                              y="200"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />

                            <rect
                              x="26"
                              y="240"
                              rx="4"
                              ry="4"
                              width="20"
                              height="12"
                            />
                            <rect
                              x="66"
                              y="240"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="187"
                              y="240"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="401"
                              y="240"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="522"
                              y="240"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="730"
                              y="240"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />
                            <rect
                              x="851"
                              y="240"
                              rx="10"
                              ry="10"
                              width="85"
                              height="12"
                            />

                            <rect
                              x="978"
                              y="80"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="977"
                              y="120"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="977"
                              y="160"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="977"
                              y="200"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />
                            <rect
                              x="977"
                              y="378"
                              rx="10"
                              ry="10"
                              width="169"
                              height="12"
                            />

                            <rect
                              x="16"
                              y="16"
                              rx="5"
                              ry="5"
                              width="153"
                              height="30"
                            />
                          </ContentLoader>
                        </div>
                      ) : (
                        <div className="">


                          {top10Advertiser.length > 0 ? (
                            <div className="table_border_radius vertical_scroll_table">
                              <Table responsive className="display table nowrap table-advertiser-hidden custom_data_table table_first_th on_hover_text_effect">
                                <thead>
                                  <tr>
                                    <th scope="col">Advertiser</th>
                                    <th scope="col">Impressions</th>
                                    <th scope="col">Clicks</th>
                                    <th scope="col">CTR (%)</th>
                                    <th scope="col">Spend</th>
                                    <th className="eCPM_class" scope="col">eCPM</th>


                                  </tr>
                                </thead>
                                <tbody>
                                  {top10Advertiser.map((element, index) => (
                                    <tr>
                                      <td className="nowrap ">
                                        <span className="ml-2">
                                          <Link className=""
                                            onClick={() =>
                                              window.open(
                                                "/reporting?advertiserId=" +
                                                element["ADVERTISER"],
                                                "_blank"
                                              )
                                            }>
                                            {element["ADVERTISER"]}
                                            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M0.222931 0.712949C0.226165 0.347519 0.52332 0.0529825 0.888764 0.0529829L6.97124 0.0529825C7.41307 0.0529825 7.77124 0.411155 7.77124 0.852982L7.77124 6.93546C7.77124 7.30091 7.47671 7.59806 7.11128 7.6013C6.74125 7.60457 6.43953 7.30551 6.43953 6.93546V2.32751L1.17158 7.59545C0.911231 7.8558 0.489121 7.8558 0.228772 7.59545C-0.0315777 7.33511 -0.0315773 6.913 0.228772 6.65265L5.49672 1.3847L0.888765 1.3847C0.518718 1.3847 0.219656 1.08298 0.222931 0.712949Z" fill="#6366F1" />
                                            </svg>
                                          </Link>
                                          {/* {element["ADVERTISER"]} */}
                                        </span>
                                      </td>

                                      <td className="nowrap">
                                        {addCommasToNumber(element["IMPRESSIONS"])}
                                      </td>
                                      <td className="nowrap">
                                        {addCommasToNumber(element["CLICKS"])}
                                      </td>
                                      <td className="nowrap">
                                        {element["CTR"]}
                                      </td>
                                      <td className="nowrap">
                                        {element["SPEND"]}
                                      </td>
                                      <td className="nowrap">
                                        {element["eCPM"]}
                                      </td>


                                    </tr>
                                  ))}
                                </tbody>
                              </Table></div>)
                            :
                            <div className="table_border_radius vertical_scroll_table">
                              <Table responsive className="display table nowrap table-advertiser-hidden custom_data_table table_first_th h-480">
                                <thead>
                                  <tr>
                                    <th scope="col">Advertiser</th>
                                    <th scope="col">Impressions</th>
                                    <th scope="col">Clicks</th>
                                    <th scope="col">CTR (%)</th>
                                    <th scope="col">Spend</th>
                                    <th className="eCPM_class" scope="col">eCPM</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  <div className="p-12">
                                    <div className="no_data_available red_circle_img d-flex align-items-center">
                                      <img src={NoDataAvailable} className="img-fluid" alt="" />
                                      <div className="for_table_no_data_text">
                                        <p> No Data Source for this Report</p>
                                        <span>Create your first advertiser to uncover insights.</span>
                                      </div>
                                    </div>
                                  </div>


                                </tbody>
                              </Table>   </div>}

                        </div>
                      )}


                    </div>
                  </div>
                </div>
              )}
            </>
            :
            <>
              {top10CampaignIsLoading ? (
                <div className="graph_areas_side h-571 me-16">
                  <ContentLoader
                    className=""
                    viewBox="16 16 1180 530"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="16" y="16" rx="5" ry="5" width="153" height="30" />
                    <rect x="27" y="80" rx="4" ry="4" width="20" height="12" />
                    <rect x="67" y="80" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="188"
                      y="80"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="402" y="80" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="523"
                      y="80"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="731" y="80" rx="10" ry="10" width="85" height="12" />
                    <rect x="852" y="80" rx="10" ry="10" width="85" height="12" />
                    <rect x="26" y="120" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="120" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="120"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="120"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="120"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="120"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="120"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect x="26" y="160" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="160" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="160"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="160"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="160"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="160"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="160"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect x="26" y="200" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="200" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="200"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="200"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="200"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="200"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="200"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="978"
                      y="80"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="120"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="160"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="200"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="240" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="240" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="240"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="240"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="240"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="240"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="240"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="240"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="280" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="280" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="280"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="280"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="280"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="280"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="280"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="280"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="320" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="320" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="320"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="320"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="320"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="320"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="320"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="320"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="360" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="360" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="360"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="360"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="360"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="360"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="360"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="360"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="400" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="400" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="400"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="400"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="400"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="400"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="400"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="400"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="440" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="440" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="440"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="440"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="440"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="440"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="440"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="440"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="480" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="480" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="480"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="480"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="480"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="480"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="480"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="480"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect x="26" y="520" rx="4" ry="4" width="20" height="12" />
                    <rect x="66" y="520" rx="10" ry="10" width="85" height="12" />
                    <rect
                      x="187"
                      y="520"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="401"
                      y="520"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="522"
                      y="520"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                    <rect
                      x="730"
                      y="520"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="851"
                      y="520"
                      rx="10"
                      ry="10"
                      width="85"
                      height="12"
                    />
                    <rect
                      x="977"
                      y="520"
                      rx="10"
                      ry="10"
                      width="169"
                      height="12"
                    />
                  </ContentLoader>
                </div>
              ) : (
                <div className="graph_areas_side h-571 me-16 new_tab_design_for_dashboard diff_view_dashboard">
                  <div className="d-flex justify-content-between align-items-center for_tabs_border">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected="true">
                          Top 10 Campaigns
                        </button>
                      </li>
                    </ul>

                    <p className="table_show_list border-0">
                      Month-to-date
                    </p>
                  </div>
                  <div class="tab-content list_advertiser_table diff_view_dashboard" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0"
                    >

                      {top10Campaign.length > 0 ?
                        <div className="table_border_radius vertical_scroll_table ">
                          <Table responsive className="display table nowrap table-advertiser-hidden custom_data_table table_first_th on_hover_text_effect">
                            <thead>
                              <tr>

                                <th scope="col">Campaign Name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Impressions</th>
                                <th scope="col">Clicks</th>
                                <th scope="col">CTR (%)</th>
                                {!aidWiseView &&
                                  <th scope="col">Spend</th>
                                }
                                {isCPCEnabled ?
                                  <th className="normal-th eCPM_class" scope="col">CPC</th> :
                                  <th className="normal-th eCPM_class" scope="col">eCPM</th>
                                }



                              </tr>
                            </thead>
                            <tbody>
                              {top10Campaign.map((element, index) => (
                                <tr>
                                  <td className="nowrap ">
                                    <Link className=""
                                      onClick={() =>
                                        window.open(
                                          "/reporting?campaignId=" +
                                          element["CAMPAIGN NAME"],
                                          "_blank"
                                        )
                                      }>
                                      {element["CAMPAIGN NAME"]}
                                      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.222931 0.712949C0.226165 0.347519 0.52332 0.0529825 0.888764 0.0529829L6.97124 0.0529825C7.41307 0.0529825 7.77124 0.411155 7.77124 0.852982L7.77124 6.93546C7.77124 7.30091 7.47671 7.59806 7.11128 7.6013C6.74125 7.60457 6.43953 7.30551 6.43953 6.93546V2.32751L1.17158 7.59545C0.911231 7.8558 0.489121 7.8558 0.228772 7.59545C-0.0315777 7.33511 -0.0315773 6.913 0.228772 6.65265L5.49672 1.3847L0.888765 1.3847C0.518718 1.3847 0.219656 1.08298 0.222931 0.712949Z" fill="#6366F1" />
                                      </svg>
                                    </Link>
                                    {/* {element["CAMPAIGN NAME"]} */}
                                  </td>
                                  <td className="nowrap ">
                                    {/* {element["STATUS"]} */}
                                    {role === "RM" ? (
                                      <span>{element["STATUS"]}</span>
                                    ) : (
                                      <CustomSelect
                                        className={element["STATUS"] === "Pending" ? "newclass custom_drop_design" : element["STATUS"] === "Approved" ? "approvedclass custom_drop_design" : "declined custom_drop_design"}
                                        options={constantData.Campaign_Status_Option_SA}
                                        placeholder="-Select-"
                                        value={{ label: element["STATUS"], value: element["STATUS"] }}
                                        onChange={(event) => {

                                          setTop10CampaignIsLoading(true)

                                          let token = Cookies.get("token_moment");
                                          axios
                                            .post(
                                              configData.SERVER_URL +
                                              "campaign/campaignStatusBySA?campaignId=" +
                                              element["CAMPAIGN NAME"].split('-')[0],
                                              {
                                                status: event.toUpperCase(),
                                                campaignId: element["CAMPAIGN NAME"].split('-')[0],
                                              },

                                              {
                                                headers: {
                                                  "Content-Type": "application/json",
                                                  "x-access-token": token,
                                                },
                                              }
                                            )
                                            .then((response) => {

                                              if (response.data.status === 200) {
                                                // setTimeout(()=>{
                                                //   notifysuccess(response.data.message);
                                                // },2000)

                                                let msg = response.data.message



                                                axios
                                                  .get(configData.SERVER_URL + `dashboard/getTopTenCampaigns`, {
                                                    headers: {
                                                      "Content-Type": "application/json",
                                                      "x-access-token": token,
                                                    },
                                                  })
                                                  .then((response) => {
                                                    if (response.data.status === 200) {
                                                      setTop10Campaign(response.data.data);
                                                      notifysuccess(msg);

                                                    }
                                                    setTop10CampaignIsLoading(false);


                                                  })
                                                  .catch((error) => {
                                                    setTop10CampaignIsLoading(false);

                                                  });
                                              }
                                              else {
                                                notifyfailure(response.data.message);
                                              }
                                            })
                                            .catch((error) => {
                                              setTop10CampaignIsLoading(false);
                                              notifyfailure()
                                            });
                                        }}

                                      ></CustomSelect>
                                    )}
                                  </td>

                                  <td className="nowrap ">
                                    {addCommasToNumber(element["IMPRESSIONS"])}
                                  </td>

                                  <td className="nowrap ">
                                    {addCommasToNumber(element["CLICK"])}
                                  </td>
                                  <td className="nowrap">{element["CTR"]}</td>
                                  {!aidWiseView &&
                                    <>
                                      {isCPCEnabled ?
                                        <td className="nowrap">{element["SPEND CPC"]}  </td> :
                                        <td className="nowrap">{element["SPEND"]}  </td>
                                      }
                                    </>
                                  }
                                  {isCPCEnabled ?
                                    <td className="nowrap ">{element["CPC"]}</td> :
                                    <td className="nowrap ">{element["eCPM"]}</td>
                                  }
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        :
                        <div className="table_border_radius vertical_scroll_table">
                          <Table responsive className="display table nowrap table-advertiser-hidden custom_data_table table_first_th h-480">
                            <thead>
                              <tr>
                                <th scope="col">Campaign Name</th>
                                <th scope="col">Status</th>

                                <th scope="col">Impressions</th>

                                <th scope="col">Clicks</th>
                                <th scope="col">CTR (%)</th>
                                {!aidWiseView &&
                                  <th scope="col">Spend</th>
                                }
                                {isCPCEnabled ?
                                  <th className="normal-th eCPM_class" scope="col">CPC</th> :
                                  <th className="normal-th eCPM_class" scope="col">eCPM</th>
                                }
                              </tr>
                            </thead>
                            <tbody>
                              <div className="p-12">
                                <div className="no_data_available red_circle_img d-flex align-items-center">
                                  <img src={NoDataAvailable} className="img-fluid" alt="" />
                                  <div className="for_table_no_data_text">
                                    <p> No Data Source for this Report</p>
                                    <span>Create your first campaign to uncover insights.</span>
                                  </div>
                                </div>
                              </div>
                            </tbody>
                          </Table>
                        </div>
                      }
                    </div>

                    {/* ////////////////////////////////////////////Top 10 Campaign code ends///////////////////////////////////////////////////// */}


                  </div>
                </div>
              )}
            </>
          }
        </div>

        <div className="custom_col_grid_3 custom_order_3 row pe-1">
          <div className="col-md-12">
            {weatherDataIsLoading ? (
              <div className="graph_areas_side h-272 resp_h_250  mt-0 weather_with_four_tabs position-relative p-0">
                {/* <p>...Loading</p> */}
                <ContentLoader viewBox="0 0 1200 800" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
                  <rect x="60" y="100" rx="5" ry="5" width="400" height="80" />

                  <rect x="950" y="90" rx="100" ry="100" width="200" height="200" />

                  <rect x="66" y="490" rx="10" ry="10" width="100" height="25" />
                  <rect x="240" y="490" rx="10" ry="10" width="100" height="25" />
                  <rect x="440" y="490" rx="10" ry="10" width="100" height="25" />
                  <rect x="640" y="490" rx="10" ry="10" width="100" height="25" />
                  <rect x="840" y="490" rx="10" ry="10" width="100" height="25" />
                  <rect x="1000" y="490" rx="10" ry="10" width="100" height="25" />

                  <rect x="86" y="560" rx="10" ry="10" width="60" height="25" />
                  <rect x="260" y="560" rx="10" ry="10" width="60" height="25" />
                  <rect x="460" y="560" rx="10" ry="10" width="60" height="25" />
                  <rect x="660" y="560" rx="10" ry="10" width="60" height="25" />
                  <rect x="860" y="560" rx="10" ry="10" width="60" height="25" />
                  <rect x="1020" y="560" rx="10" ry="10" width="60" height="25" />

                  <rect x="66" y="630" rx="10" ry="10" width="100" height="25" />
                  <rect x="240" y="630" rx="10" ry="10" width="100" height="25" />
                  <rect x="440" y="630" rx="10" ry="10" width="100" height="25" />
                  <rect x="640" y="630" rx="10" ry="10" width="100" height="25" />
                  <rect x="840" y="630" rx="10" ry="10" width="100" height="25" />
                  <rect x="1000" y="630" rx="10" ry="10" width="100" height="25" />

                  <rect x="850" y="720" rx="10" ry="10" width="250" height="20" />
                </ContentLoader>
              </div>
            ) : (
              <>
                {weatherData?.length > 0 ? (
                  <div className="graph_areas_side h-272 resp_h_250  mt-0 weather_with_four_tabs position-relative p-0">
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane tab_pane_one img_cls_one fade show active position-relative "
                        id="pills-weather"
                        role="tabpanel"
                        aria-labelledby="pills-weather-tab"
                        tabindex="0"
                      >
                        {/* <img src={weatherData[0]?.bg} className="img-fluid weather_change_bg" alt="" /> */}
                        <img src={weatherData[0]?.bg} className="img-fluid weather_change_bg" alt="" />
                        {/* 14<sup>O</sup>C<span>Mostly Clear</span> */}
                        <div className="position-relative">
                          {/* <h4 className="weather_loaction_text">
                            <svg width="16" height="16" viewBox="0 0 16 16" className="transform-y-m-2" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_4803_25921)">
                              <path d="M8.00033 5.33341C6.52699 5.33341 5.33366 6.52675 5.33366 8.00008C5.33366 9.47341 6.52699 10.6667 8.00033 10.6667C9.47366 10.6667 10.667 9.47341 10.667 8.00008C10.667 6.52675 9.47366 5.33341 8.00033 5.33341ZM13.9603 7.33341C13.8093 5.9815 13.2031 4.72111 12.2412 3.75921C11.2793 2.79731 10.0189 2.19109 8.66699 2.04008V0.666748H7.33366V2.04008C5.98174 2.19109 4.72135 2.79731 3.75945 3.75921C2.79756 4.72111 2.19133 5.9815 2.04033 7.33341H0.666992V8.66675H2.04033C2.19133 10.0187 2.79756 11.2791 3.75945 12.241C4.72135 13.2028 5.98174 13.8091 7.33366 13.9601V15.3334H8.66699V13.9601C10.0189 13.8091 11.2793 13.2028 12.2412 12.241C13.2031 11.2791 13.8093 10.0187 13.9603 8.66675H15.3337V7.33341H13.9603ZM8.00033 12.6667C5.42033 12.6667 3.33366 10.5801 3.33366 8.00008C3.33366 5.42008 5.42033 3.33341 8.00033 3.33341C10.5803 3.33341 12.667 5.42008 12.667 8.00008C12.667 10.5801 10.5803 12.6667 8.00033 12.6667Z" fill="#327AC9"/>
                              </g>
                              <defs>
                              <clipPath id="clip0_4803_25921">
                              <rect width="16" height="16" fill="white"/>
                              </clipPath>
                              </defs>
                            </svg>
                              {city}
                          
                            </h4> */}
                          <h2 className="new_clr position-relative">
                            {weatherData[0]?.temperature}<small className="custom_c">C</small>
                            <span> {weatherData[0]?.weatherText}</span>
                          </h2>
                          <img
                            src={weatherData[0]?.weatherIcon}
                            className="img-fluid sun_cloud_img"
                            alt=""
                          />
                          <br />
                          <ul className="weather_cloud_img">

                            <li>
                              <span>{weatherData[1]?.MaxTemp}</span>
                              <img src={weatherData[1]?.Icon} className="img-fluid" alt="" />
                              <small>{weatherData[1]?.Date}</small>
                            </li>
                            <li>
                              <span>{weatherData[2]?.MaxTemp}</span>
                              <img src={weatherData[2]?.Icon} className="img-fluid" alt="" />
                              <small>{weatherData[2]?.Date}</small>
                            </li>
                            <li>
                              <span>{weatherData[3]?.MaxTemp}</span>
                              <img src={weatherData[3]?.Icon} className="img-fluid" alt="" />
                              <small>{weatherData[3]?.Date}</small>
                            </li>
                            <li>
                              <span>{weatherData[4]?.MaxTemp}</span>
                              <img src={weatherData[4]?.Icon} className="img-fluid" alt="" />
                              <small>{weatherData[4]?.Date}</small>
                            </li>
                            <li>
                              <span>{weatherData[5]?.MaxTemp}</span>
                              <img src={weatherData[5]?.Icon} className="img-fluid" alt="" />
                              <small>{weatherData[5]?.Date}</small>
                            </li>
                          </ul>
                          {/* <p className="weather_time_zone_value">*Timezone in UTC</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="graph_areas_side h-272 resp_h_250  mt-0 weather_with_four_tabs position-relative p-0">
                    <div className="dash_board_box p-12">
                      <div className="d-flex align-items-center red_circle_img">
                        <img src={NoDataAvailable} className="img-fluid" alt="" />
                        <p> Failed getting weather data.
                          {/* <Link to="#" className="hyper_link_clr text-decoration-underline">Refresh</Link> */}
                        </p>
                      </div>
                    </div>
                    <div className="no_data_available_football">
                      <img src={WeatherPlace} className="w-auto h-auto m-0" alt="" />
                    </div>
                  </div>
                )}
              </>
            )}

          </div>

          <div className="col-md-12">
            {cryptoDataIsLoading ? (
              <div className="graph_areas_side h-291 resp_h_250  weather_with_four_tabs position-relative p-0 order-3">
                <ContentLoader className="larg_view"
                  speed={2}
                  width="500"
                  height="600"
                  viewBox="-16 100 335 170"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="35" y="10" rx="5" ry="5" width="150" height="10" />
                  <rect x="35" y="45" rx="5" ry="5" width="150" height="10" />
                  <rect x="35" y="80" rx="5" ry="5" width="150" height="10" />
                  <rect x="35" y="115" rx="5" ry="5" width="150" height="10" />
                  <rect x="35" y="150" rx="5" ry="5" width="150" height="10" />
                  <rect x="3" y="5" rx="100" ry="100" width="25" height="25" />
                  <rect x="3" y="40" rx="100" ry="100" width="25" height="25" />
                  <rect x="3" y="75" rx="100" ry="100" width="25" height="25" />
                  <rect
                    x="3"
                    y="110"
                    rx="100"
                    ry="100"
                    width="25"
                    height="25"
                  />
                  <rect
                    x="3"
                    y="145"
                    rx="100"
                    ry="100"
                    width="25"
                    height="25"
                  />
                </ContentLoader>
                <ContentLoader className="small_view"
                  speed={2}
                  width="500"
                  height="600"
                  viewBox="-16 100 335 170"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="35" y="10" rx="5" ry="5" width="150" height="10" />
                  <rect x="35" y="45" rx="5" ry="5" width="150" height="10" />
                  <rect x="35" y="80" rx="5" ry="5" width="150" height="10" />
                  <rect x="35" y="115" rx="5" ry="5" width="150" height="10" />
                  {/* <rect x="35" y="150" rx="5" ry="5" width="150" height="10" /> */}
                  <rect x="3" y="5" rx="100" ry="100" width="25" height="25" />
                  <rect x="3" y="40" rx="100" ry="100" width="25" height="25" />
                  <rect x="3" y="75" rx="100" ry="100" width="25" height="25" />
                  <rect x="3" y="110" rx="100" ry="100" width="25" height="25" />
                  {/* <rect x="3" y="145" rx="100" ry="100" width="25" height="25" /> */}
                </ContentLoader>
              </div>
            ) : (
              <>
                {cryptoData?.length > 0 ? (
                  <div className="graph_areas_side h-291 resp_h_250  weather_with_four_tabs position-relative p-0">
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane tab_pane_one img_cls_three fade show active"
                        id="pills-crypto"
                        role="tabpanel"
                        aria-labelledby="pills-crypto-tab"
                        tabindex="0"
                      >
                        {/* <h4 className="location_area d-flex">
                              <span className="live_indicator"></span> Live
                            </h4> */}
                        <ul>
                          {cryptoData?.map((cryptoItem, index) => (
                            <li key={index}>
                              <div className="img_name">
                                <img
                                  src={cryptoItem?.icon}
                                  className="img-fluid"
                                  alt=""
                                />
                                <div>
                                  <h4>{cryptoItem?.fullName}</h4>
                                  <span>{cryptoItem?.key}</span>
                                </div>
                              </div>

                              {/* <Chart
                                        options={
                                          cryptoGraphData[index].optionsMixedChart
                                        }
                                        series={cryptoGraphData[index].seriesMixedChart}
                                        height={50}
                                        width={120}
                                      /> */}
                              <div className="img_rate text-end">
                                <h4>$ {cryptoItem?.value?.toFixed(2)}</h4>
                                <span className={cryptoItem?.change_percentage?.toString().charAt(0) === "-" ? "red_text" : "green_text"}>
                                  {cryptoItem?.change_percentage}%
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="graph_areas_side h-291 resp_h_250  weather_with_four_tabs position-relative p-0">
                    <div className="dash_board_box p-12">
                      <div className="d-flex align-items-center red_circle_img">
                        <img src={NoDataAvailable} className="img-fluid" alt="" />
                        <p> Failed getting crypto data.
                          {/* <Link to="#" className="hyper_link_clr text-decoration-underline">Refresh</Link> */}
                        </p>
                      </div>
                    </div>
                    <div className="no_data_available_football">
                      <img src={CryptoPlace} className="img-fluid w-auto h-auto m-0" alt="" />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="col-md-12">
            {aqiDataIsLoading ? (
              <div className="graph_areas_side h-280 resp_h_250 weather_with_four_tabs position-relative p-0 order-2">
                <ContentLoader className="larg_view"
                  speed={2}
                  width="500"
                  height="600"
                  viewBox="-16 100 335 200"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="170" y="30" rx="5" ry="5" width="50" height="10" />
                  <rect x="170" y="70" rx="5" ry="5" width="50" height="10" />
                  <rect x="170" y="110" rx="5" ry="5" width="50" height="10" />
                  <rect x="170" y="150" rx="5" ry="5" width="50" height="10" />
                  <rect
                    x="3"
                    y="25"
                    rx="150"
                    ry="150"
                    width="150"
                    height="150"
                  />
                </ContentLoader>
                <ContentLoader className="small_view"
                  speed={2} width="500" height="600" viewBox="-16 100 335 200" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" >
                  {/* <rect x="170" y="30" rx="5" ry="5" width="50" height="10" />
                    <rect x="170" y="70" rx="5" ry="5" width="50" height="10" />
                    <rect x="170" y="110" rx="5" ry="5" width="50" height="10" />
                    <rect x="170" y="150" rx="5" ry="5" width="50" height="10" /> */}
                  <rect x="25" y="10" rx="150" ry="150" width="150" height="150" />
                </ContentLoader>
              </div>
            ) : (
              <>
                {aqiData.length > 0 ? (
                  <div className="graph_areas_side h-280 resp_h_250 weather_with_four_tabs position-relative p-0">
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane tab_pane_one img_cls_two fade show active"
                        id="pills-pollution"
                        role="tabpanel"
                        aria-labelledby="pills-pollution-tab"
                        tabindex="0"
                      >
                        {/* <h4 className="location_area">
                        <img src={LocationIconBlack} className="img-fluid" alt="" />{" "}
                        Noida, Uttar Pradesh
                      </h4> */}
                        <Row className="align-items-center">
                          <Col className="text-center position-relative">
                            <div className="speedometer"></div>
                            <h2 className="meter_value">{aqiData[0]?.aqi}</h2>
                            <div className="meter_niddle_area">
                              <div
                                className="meter_niddle"
                                style={{
                                  transform: `rotate(${aqiData[0]?.needleValue}deg)`,
                                }}
                              ></div>
                            </div>
                            <div className="meter_text">
                              <h4>{aqiData[0]?.text}</h4>
                              <p>AIR QUALITY INDEX</p>
                            </div>
                          </Col>
                          <Col md={4} className="hide_1600">
                            <div className="speedo_meter_data ms-2">
                              <ul>
                                <li>
                                  <img src={NData} alt="" />
                                  <div>
                                    <span>NO2</span>
                                    <p>{aqiData[0]?.no2}</p>
                                  </div>
                                </li>
                                <li>
                                  <img src={CData} alt="" />
                                  <div>
                                    <span>CO</span>
                                    <p className="">{aqiData[0]?.co}</p>
                                  </div>
                                </li>
                                <li>
                                  <img src={FluentDust} alt="" />
                                  <div>
                                    <span>O3</span>
                                    <p>{aqiData[0]?.o3}</p>
                                  </div>
                                </li>
                                <li>
                                  <img src={CarbonCloud} alt="" />
                                  <div>
                                    <span>SO2</span>
                                    <p>{aqiData[0]?.so2}</p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="graph_areas_side h-280 resp_h_250 weather_with_four_tabs position-relative p-0">
                    <div className="dash_board_box p-12">
                      <div className="d-flex align-items-center red_circle_img">
                        <img src={NoDataAvailable} className="img-fluid" alt="" />
                        <p> Failed getting AQI data.
                          {/* <Link to="#" className="hyper_link_clr text-decoration-underline">Refresh</Link> */}
                        </p>
                      </div>
                    </div>
                    <div className="no_data_available_football">
                      <img src={AQIPlace} className="img-fluid w-auto h-auto m-0" alt="" />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {/* ////////////////////////////////////////////// aqi widget ends here///////////////////////////////// */}

          {/* ////////////////////////////////////////////////////////cricket Widget Start here//////////////////////////////////////////////////////////////////////////// */}
          <div className="col-md-12">
            <div className="px-0 pie_chart_design edit_campaign_tabs_design order-1">
              {cricketDataIsLoading ? (
                <div className="dash_board_box graph_areas_side h-275 resp_h_250 p-12 position-relative">
                  <ContentLoader
                    speed={2}
                    width="500"
                    height="600"
                    viewBox="-16 100 335 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="5" y="0" rx="5" ry="5" width="100" height="12" />
                    <rect x="35" y="30" rx="5" ry="5" width="50" height="10" />
                    <rect x="170" y="30" rx="5" ry="5" width="50" height="10" />
                    <rect x="35" y="70" rx="5" ry="5" width="50" height="10" />
                    <rect x="170" y="70" rx="5" ry="5" width="50" height="10" />
                    <rect x="5" y="100" rx="5" ry="5" width="215" height="2" />
                    <rect
                      x="3"
                      y="25"
                      rx="100"
                      ry="100"
                      width="25"
                      height="25"
                    />
                    <rect
                      x="3"
                      y="60"
                      rx="100"
                      ry="100"
                      width="25"
                      height="25"
                    />
                    <rect x="5" y="120" rx="5" ry="5" width="150" height="10" />
                    <rect x="5" y="140" rx="5" ry="5" width="100" height="10" />
                  </ContentLoader>
                </div>
              ) : (
                <>
                  {cricketData ? (
                    <div className="dash_board_box graph_areas_side h-275 resp_h_250 p-12 position-relative">
                      <div className="d-flex justify-content-between align-items-center mb-16 resp-mb-10">
                        <div className="step_area px-0 international_box_design p-0 border-0 m-0">
                          <ul className="nav" id="pills-tab" role="tablist">
                            <li className="" role="presentation">
                              <button
                                className="nav-link btn active px-0"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => {
                                  setCricketDataTab("live");
                                }}
                              >
                                Live
                              </button>
                            </li>
                            <li className="device_custom px-2">|</li>
                            <li className="" role="presentation">
                              <button
                                className="nav-link btn px-0"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                                onClick={() => {
                                  setCricketDataTab("upcoming");
                                }}
                              >
                                Upcoming
                              </button>
                            </li>
                          </ul>
                        </div>
                        <InputGroup className="w-100 custom_country_drop_design diff-disabled_cls new_width_select justify-content-end">
                          <InputGroup.Text className="disabled_addon p-0 for_dashbaord_page">
                            <img src={CricketBall} className="img-fluid report_eye_icon p-0" alt="" />
                          </InputGroup.Text>
                          <Select
                            className="custom_drop_design select-custom p-0"
                            options={[{ label: "Cricket", value: "Cricket" }]}
                            placeholder="-Select-"
                            value={{ label: "Cricket", value: "Cricket" }}
                            onChange={(event) => { }}
                            isDisabled={true}
                            isSearchable={false}>
                          </Select>
                        </InputGroup>
                      </div>

                      <div className="step_form_area px-0">
                        <div className="tab-content" id="pills-tabContent">
                          <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">

                            {cricketData.finalLiveData?.length > 0 ? (
                              <ul class="sports_details_design live_match_details">

                                <li>
                                  <div class="international_box mb-16 resp-mb-10">

                                    {cricketData?.finalLiveData?.length > 0 ? (
                                      <>
                                        <small>
                                          {cricketData?.finalLiveData[0]?.round}-{" "}
                                          {
                                            cricketData?.finalLiveData[0]
                                              ?.leagueName
                                          }
                                        </small>
                                        {cricketData?.finalLiveData[0]?.note && <span>{cricketData?.finalLiveData[0]?.note}</span>}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between first_list_data">
                                    <div class="d-flex align-items-center">

                                      <img src={cricketData?.finalLiveData[0]?.localteam_image} className="img-fluid" alt="" />
                                      <h5>
                                        {
                                          cricketData?.finalLiveData[0]
                                            ?.localteam_name
                                        }
                                      </h5>
                                    </div>

                                    {cricketData?.finalLiveData[0]
                                      .localteam_data?.length > 0 ? (
                                      <>
                                        {cricketData?.finalLiveData[0]
                                          .localteam_data?.length === 2 ? (
                                          <>
                                            <div className="d-flex">
                                              <span>
                                                (
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .localteam_data[0]?.overs
                                                }{" "}
                                                overs)
                                              </span>
                                              <p>
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .localteam_data[0]?.score
                                                }
                                                <small>
                                                  /
                                                  {
                                                    cricketData?.finalLiveData[0]
                                                      .localteam_data[0]?.wickets
                                                  }
                                                </small>{" "}
                                              </p>
                                            </div>
                                            &nbsp; & &nbsp;
                                            <div className="d-flex">
                                              <span>
                                                (
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .localteam_data[1]?.overs
                                                }{" "}
                                                overs)
                                              </span>

                                              <p>
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .localteam_data[1]?.score
                                                }
                                                <small>
                                                  /
                                                  {
                                                    cricketData?.finalLiveData[0]
                                                      .localteam_data[1]?.wickets
                                                  }
                                                </small>{" "}
                                              </p>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="d-flex">
                                              <span>
                                                (
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .localteam_data[0]?.overs
                                                }{" "}
                                                overs)
                                              </span>
                                              <p>
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .localteam_data[0]?.score
                                                }
                                                <small>
                                                  /
                                                  {
                                                    cricketData?.finalLiveData[0]
                                                      .localteam_data[0]?.wickets
                                                  }
                                                </small>{" "}
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between first_list_data">
                                    <div class="d-flex align-items-center">

                                      <img
                                        src={
                                          cricketData?.finalLiveData[0]
                                            ?.visitorteam_image
                                        }
                                        className="img-fluid"
                                        alt=""
                                      />
                                      <h5>
                                        {
                                          cricketData?.finalLiveData[0]
                                            ?.visitorteam_name
                                        }{" "}
                                      </h5>
                                    </div>
                                    {cricketData?.finalLiveData[0]
                                      .visitorteam_data?.length > 0 ? (
                                      <>
                                        {cricketData?.finalLiveData[0]
                                          .visitorteam_data?.length === 2 ? (
                                          <>
                                            <div className="d-flex">
                                              <span>
                                                (
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .visitorteam_data[0]?.overs
                                                }{" "}
                                                overs)
                                              </span>
                                              <p>
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .visitorteam_data[0]?.score
                                                }
                                                <small>
                                                  /
                                                  {
                                                    cricketData?.finalLiveData[0]
                                                      .visitorteam_data[0]
                                                      ?.wickets
                                                  }
                                                </small>{" "}
                                              </p>
                                            </div>
                                            &nbsp; & &nbsp;
                                            <div className="d-flex">
                                              <span>
                                                (
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .visitorteam_data[1]?.overs
                                                }{" "}
                                                overs)
                                              </span>
                                              <p>
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .visitorteam_data[1]?.score
                                                }
                                                <small>
                                                  /
                                                  {
                                                    cricketData?.finalLiveData[0]
                                                      .visitorteam_data[1]
                                                      ?.wickets
                                                  }
                                                </small>{" "}
                                              </p>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="d-flex">
                                              <span>
                                                (
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .visitorteam_data[0]?.overs
                                                }{" "}
                                                overs)
                                              </span>
                                              <p>
                                                {
                                                  cricketData?.finalLiveData[0]
                                                    .visitorteam_data[0]?.score
                                                }
                                                <small>
                                                  /
                                                  {
                                                    cricketData?.finalLiveData[0]
                                                      .visitorteam_data[0]
                                                      ?.wickets
                                                  }
                                                </small>{" "}
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <hr className="hr_line_c" />

                                  <div className="match_overal_detals">

                                    {cricketData.finalLiveData?.length > 0 ? (
                                      <>
                                        <span className="bottom_details_txt">
                                          {cricketData?.finalLiveData[0]?.summary}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                </li>
                              </ul>


                            ) : (
                              <>


                                <div className="py-5 no_data_available red_circle_img d-flex align-items-center">
                                  <img src={NoDataAvailable} className="img-fluid" alt="" />
                                  <p> No live games currently in progress.</p>
                                </div>
                              </>
                            )}



                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabindex="0"
                          >
                            {cricketData.finalUpcomingData?.length > 0 ? (
                              <ul className="sports_details_design sport_widget_scroll_area">
                                {cricketData.finalUpcomingData.map(
                                  (match, index) => (
                                    <li key={index}>
                                      <div className="international_box">
                                        <small>
                                          {match.round}- {match.leagueName}
                                        </small>
                                        <span>{match.startAt}</span>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                          <img
                                            src={match.localteam_image}
                                            className="img-fluid"
                                            alt=""
                                          />
                                          <h5>{match.localteam_name}</h5>
                                        </div>
                                        <div>
                                          <span>VS</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <img
                                            src={match.visitorteam_image}
                                            className="img-fluid"
                                            alt=""
                                          />
                                          <h5>{match.visitorteam_name}</h5>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                )}
                              </ul>
                            ) : (
                              <div className="py-5 no_data_available d-flex align-items-center">
                                <img
                                  src={NoDataAvailable}
                                  className="img-fluid"
                                  alt="" />
                                <p> No Upcoming Match </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="dash_board_box graph_areas_side h-275 resp_h_250 p-12 position-relative">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center red_circle_img">
                          <img src={NoDataAvailable} className="img-fluid" alt="" />
                          <p> Failed getting Cricket data.
                            {/* <Link to="#" className="hyper_link_clr text-decoration-underline">Refresh</Link> */}
                          </p>
                        </div>
                      </div>
                      <div className="no_data_available_football">
                        <img src={CricketPlace} className="img-fluid w-auto h-auto m-0" alt="" />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

    </div>
  );


}

export default Dashboard;
