import ListUserMaster from "../components/ListUserMaster";

function ListUserMasterPage() {
  return (
    <>
      <ListUserMaster />
    </>
  );
}

export default ListUserMasterPage;