import AddFund from "../components/AddFund";

function AddFundPage() {
  return (
    <>
      <AddFund />
    </>
  );
}

export default AddFundPage;
