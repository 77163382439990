import noRecord from "../assets/images/no_data_new.svg";
function NOComponent() {
  return (
    <div className="no_table_data">
      <div className="d-flex justify-content-center min-height-255 pt-4 pb-4 align-items-center">
        <div className="text-center">
          <img src={noRecord} className="img-fluid" alt="" />
          <p className="text-center">No Data Source for this Report</p>
        </div>
      </div>
    </div>
  );
}

export default NOComponent;
