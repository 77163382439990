import React from "react";
import Cookies from "js-cookie";
import { TokenVerificationLoggedin, ScreenView } from "../utils/tokenVerify";
import NotFound from "../assets/images/404.svg";

function NoPageComponent() {
  React.useEffect(() => {
    setLoggedIn(Cookies.get("token_moment") ? true : false);
    TokenVerificationLoggedin();
    ScreenView();
  }, []);

  const [loggedin, setLoggedIn] = React.useState(false);

  if (loggedin) {
    return (
      <>
        <div className="main_contain_area bg-transparent">
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <div className="not_found_page text-center">
              <img src={NotFound} className="img-fluid" alt="" />
              <h2>Page Not Found</h2>
              <p>
                We're sorry, the page you requested could be not found. <br />{" "}
                Please go back to Homepage
              </p>
              <button
                class="d-inline-flex justify-content-center default_btn btn px-4"
                onClick={() => {
                  window.location.replace("/dashboard");
                }}
              >
                Go To Home
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

export default NoPageComponent;
