import ContentLoader from "react-content-loader";
export const OrdLoader = () => {
  return (
    <>
      <ContentLoader
        className=""
        viewBox="16 65 1150 450"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="26" y="80" rx="4" ry="4" width="20" height="6" />
        <rect x="67" y="80" rx="4" ry="4" width="85" height="6" />
        <rect x="188" y="80" rx="4" ry="4" width="169" height="6" />
        <rect x="402" y="80" rx="4" ry="4" width="85" height="6" />
        <rect x="523" y="80" rx="4" ry="4" width="169" height="6" />
        <rect x="731" y="80" rx="4" ry="4" width="85" height="6" />
        <rect x="852" y="80" rx="4" ry="4" width="85" height="6" />

        <rect x="26" y="110" rx="4" ry="4" width="20" height="6" />
        <rect x="66" y="110" rx="4" ry="4" width="85" height="6" />
        <rect x="187" y="110" rx="4" ry="4" width="169" height="6" />
        <rect x="401" y="110" rx="4" ry="4" width="85" height="6" />
        <rect x="522" y="110" rx="4" ry="4" width="169" height="6" />
        <rect x="730" y="110" rx="4" ry="4" width="85" height="6" />
        <rect x="851" y="110" rx="4" ry="4" width="85" height="6" />

        <rect x="26" y="140" rx="4" ry="4" width="20" height="6" />
        <rect x="66" y="140" rx="4" ry="4" width="85" height="6" />
        <rect x="187" y="140" rx="4" ry="4" width="169" height="6" />
        <rect x="401" y="140" rx="4" ry="4" width="85" height="6" />
        <rect x="522" y="140" rx="4" ry="4" width="169" height="6" />
        <rect x="730" y="140" rx="4" ry="4" width="85" height="6" />
        <rect x="851" y="140" rx="4" ry="4" width="85" height="6" />

        <rect x="26" y="170" rx="4" ry="4" width="20" height="6" />
        <rect x="66" y="170" rx="4" ry="4" width="85" height="6" />
        <rect x="187" y="170" rx="4" ry="4" width="169" height="6" />
        <rect x="401" y="170" rx="4" ry="4" width="85" height="6" />
        <rect x="522" y="170" rx="4" ry="4" width="169" height="6" />
        <rect x="730" y="170" rx="4" ry="4" width="85" height="6" />
        <rect x="851" y="170" rx="4" ry="4" width="85" height="6" />

        <rect x="26" y="200" rx="4" ry="4" width="20" height="6" />
        <rect x="66" y="200" rx="4" ry="4" width="85" height="6" />
        <rect x="187" y="200" rx="4" ry="4" width="169" height="6" />
        <rect x="401" y="200" rx="4" ry="4" width="85" height="6" />
        <rect x="522" y="200" rx="4" ry="4" width="169" height="6" />
        <rect x="730" y="200" rx="4" ry="4" width="85" height="6" />
        <rect x="851" y="200" rx="4" ry="4" width="85" height="6" />

        <rect x="26" y="230" rx="4" ry="4" width="20" height="6" />
        <rect x="66" y="230" rx="4" ry="4" width="85" height="6" />
        <rect x="187" y="230" rx="4" ry="4" width="169" height="6" />
        <rect x="401" y="230" rx="4" ry="4" width="85" height="6" />
        <rect x="522" y="230" rx="4" ry="4" width="169" height="6" />
        <rect x="730" y="230" rx="4" ry="4" width="85" height="6" />
        <rect x="851" y="230" rx="4" ry="4" width="85" height="6" />

        <rect x="26" y="260" rx="4" ry="4" width="20" height="6" />
        <rect x="66" y="260" rx="4" ry="4" width="85" height="6" />
        <rect x="187" y="260" rx="4" ry="4" width="169" height="6" />
        <rect x="401" y="260" rx="4" ry="4" width="85" height="6" />
        <rect x="522" y="260" rx="4" ry="4" width="169" height="6" />
        <rect x="730" y="260" rx="4" ry="4" width="85" height="6" />
        <rect x="851" y="260" rx="4" ry="4" width="85" height="6" />

        <rect x="26" y="290" rx="4" ry="4" width="20" height="6" />
        <rect x="66" y="290" rx="4" ry="4" width="85" height="6" />
        <rect x="187" y="290" rx="4" ry="4" width="169" height="6" />
        <rect x="401" y="290" rx="4" ry="4" width="85" height="6" />
        <rect x="522" y="290" rx="4" ry="4" width="169" height="6" />
        <rect x="730" y="290" rx="4" ry="4" width="85" height="6" />
        <rect x="851" y="290" rx="4" ry="4" width="85" height="6" />

        <rect x="978" y="80" rx="4" ry="4" width="169" height="6" />
        <rect x="977" y="110" rx="4" ry="4" width="169" height="6" />
        <rect x="977" y="140" rx="4" ry="4" width="169" height="6" />
        <rect x="977" y="170" rx="4" ry="4" width="169" height="6" />
        <rect x="977" y="200" rx="4" ry="4" width="169" height="6" />
        <rect x="977" y="230" rx="4" ry="4" width="169" height="6" />
        <rect x="977" y="260" rx="4" ry="4" width="169" height="6" />
        <rect x="977" y="290" rx="4" ry="4" width="169" height="6" />
      </ContentLoader>
    </>
  );
};

export default OrdLoader;
