import React, { useState, useContext } from "react";
import { TokenVerificationLoggedin, ScreenView } from "../utils/tokenVerify";
import axios from "axios";
import { LoadingContext } from "react-router-loading";
import configData from "../config/config.json";
import { toast } from "react-toastify";
import TableBlsForm from "./TableBlsForm";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import Tooltip from "react-bootstrap/Tooltip";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


const ListBlsForm = () => {
    const loadingContext = useContext(LoadingContext);

    React.useEffect(() => {
        TokenVerificationLoggedin();
        ScreenView();
        getData();
        loadingContext.done()
    }, []);

    var arr;

    const [checkRole, setCheckRole] = useState("RM");

    //table header and row creation
    const [settingHeader, setSettingHeader] = useState([]);
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);

    // loader
    const [isLoading, setIsLoading] = useState(true);


    const getData = () => {
        let token = Cookies.get("token_moment");
        arr = [];

        axios
            .get(configData.SERVER_URL + `bls/blsList`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                },
            })
            .then((response) => {
                const data = response.data.data[0];
                const dataValue = response.data.data;

                setData(dataValue);
                setInitialData(dataValue[0]);

                let status = Cookies.get("token_moment") ? true : false;
                if (status) {

                    let token = Cookies.get("token_moment");
                    let role = jwt_decode(token).role;
                    setCheckRole(role)

                    arr.push({
                        Header: "Actions",
                        accessor: "Actions",
                        Cell: (cell) => (

                            <>
                                <svg width="16" height="16" className="edit_hover" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                    window.open(
                                        "/editLiftStudy?blsId=" +
                                        cell.row.original["_id"],
                                        "_blank"
                                    );
                                }}>
                                    <path d="M3.33333 12.6667H4.26667L10.0167 6.91671L9.08333 5.98337L3.33333 11.7334V12.6667ZM12.8667 5.95004L10.0333 3.15004L10.9667 2.21671C11.2222 1.96115 11.5362 1.83337 11.9087 1.83337C12.2807 1.83337 12.5944 1.96115 12.85 2.21671L13.7833 3.15004C14.0389 3.4056 14.1722 3.71404 14.1833 4.07537C14.1944 4.43626 14.0722 4.74449 13.8167 5.00004L12.8667 5.95004ZM2.66667 14C2.47778 14 2.31956 13.936 2.192 13.808C2.064 13.6805 2 13.5223 2 13.3334V11.45C2 11.3612 2.01667 11.2752 2.05 11.192C2.08333 11.1085 2.13333 11.0334 2.2 10.9667L9.06667 4.10004L11.9 6.93337L5.03333 13.8C4.96667 13.8667 4.89178 13.9167 4.80867 13.95C4.72511 13.9834 4.63889 14 4.55 14H2.66667ZM9.55 6.45004L9.08333 5.98337L10.0167 6.91671L9.55 6.45004Z" fill="#BDBDBD" />
                                </svg>

                            </>


                        ),
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                    })

                }

                for (const [key] of Object.entries(data)) {
                    if (key === "Status") {
                        arr.push({
                            Header: key,
                            accessor: key,
                            Cell: (cell) => {
                                return (
                                    <>
                                        {cell.value === "active" ? (
                                            <Badge className="success_tag" bg="success">{cell.value
                                                ? cell.value.charAt(0).toUpperCase() + cell.value.slice(1).toLowerCase()
                                                : ''}</Badge>
                                        ) : cell.value === "stopped" ? (
                                            <Badge className="danger_tag" bg="danger">{cell.value
                                                ? cell.value.charAt(0).toUpperCase() + cell.value.slice(1).toLowerCase()
                                                : ''}</Badge>
                                        ) : (
                                            <Badge className="warning_tag" bg="warning">{cell.value
                                                ? cell.value.charAt(0).toUpperCase() + cell.value.slice(1).toLowerCase()
                                                : ''}</Badge>
                                        )}
                                    </>
                                );
                            },
                        });
                    }
                    else if (key === "Campaign" || key === "Lift Name") {
                        arr.push({
                            Header: key,
                            accessor: key,
                            Cell: (cell) => {
                                return (
                                    <div className="d-flex justify-content-between align-items-center limited_text_two">
                                        {cell.value?.length >= 35 ?
                                            (
                                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip2"> {cell.value} </Tooltip>}>
                                                    <span>{cell.value}</span>
                                                </OverlayTrigger>
                                            )
                                            :
                                            (
                                                <span>
                                                    {cell.value}
                                                </span>
                                            )
                                        }
                                    </div>
                                );
                            },
                        });
                    }

                    else {
                        arr.push({
                            Header: key,
                            accessor: key,
                        });
                    }


                }

                setSettingHeader(arr);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };


    return (
        <>
            <div className="main_contain_area">
                <div className="new_badges_design">
                    <div className="table_area_spacing">
                        <TableBlsForm
                            columns={settingHeader}
                            data={data}
                            loading={isLoading}
                            initial={initialData}
                            role={checkRole}
                        />
                    </div>
                </div>

            </div>


        </>
    );
};

export default ListBlsForm;
