import ListFund from "../components/ListFund";

function ListFundPage() {
  return (
    <>
      <ListFund />
    </>
  );
}

export default ListFundPage;
