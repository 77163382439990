import ListAdvertiser from "../components/ListAdvertiser";

function ListAdvertiserPage() {
  return (
    <>
      <ListAdvertiser />
    </>
  );
}

export default ListAdvertiserPage;
