import ListIp from "../components/ListIp";

function ListIpPage() {
  return (
    <>
      <ListIp />
    </>
  );
}

export default ListIpPage;
