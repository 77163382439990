import React, { useState ,useEffect,useRef} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/images/xaprio_logo_new.svg";
import LogoImg from "../assets/images/login_img.svg";
import SlideOne from "../assets/images/slide_one.svg";
import SlideTwo from "../assets/images/slide_two.svg";
import SlideThree from "../assets/images/slide_three.svg";
import SlideFour from "../assets/images/slide_four.svg";
import Form from "react-bootstrap/Form";
import Mail from "../assets/images/mail.svg";
import { Carousel } from "antd";
import { ToastContainer, toast } from "react-toastify";
import MailBlue from "../assets/images/mailblue.svg";
import { LoadingOutlined } from '@ant-design/icons'
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import configData from "../config/config.json";
import { TokenVerificationLoggedOut } from "../utils/tokenVerify";
// import { LoadingContext } from "react-router-loading";
import { Button } from "react-bootstrap";

const contentStyle = {
  margin: 0,
  height: "auto",
  textAlign: "center",
};

const ForgotPassword = () => {
  // const loadingContext = useContext(LoadingContext);
  const buttonRef2 = useRef(null);

  React.useEffect(() => {
    TokenVerificationLoggedOut();
    // loadingContext.done();
  });

  useEffect(() => {
    const handleEnterKeyPress2 = (event) => {
      if (event.key === 'Enter') {
        buttonRef2.current.click();
    
      }
    };

    // Add keydown event listener to the document
    document.addEventListener('keydown', handleEnterKeyPress2);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress2);
    };
  }, []); 

  const notifysuccess = (msg) => toast.success(msg, { autoClose: 1500 });
  const notifyfailure = (msg) => toast.error(msg, { autoClose: 1500 });
  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 1500 });

  const [email, setEmail] = useState(null);
  const [emailIsRequiredError, setEmailIsRequiredError] = useState(false);
  const [emailUrlError, setEmailUrlError] = useState(false);

  const [buttonValue, setButtonValue] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function onChangeEmail(event) {
    setEmailIsRequiredError(false);
    setEmail(event.target.value);

    if (validateEmail(event.target.value) === null) {
      setEmailUrlError(true);
    } else {
      setEmailUrlError(false);
    }
  }

  function onBlurEmail(event) {
    if (event.target.value.length === 0) {
      setEmailUrlError(false);
      setEmailIsRequiredError(true);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email !== null) {
      if (emailIsRequiredError === false && emailUrlError === false) {
        let payload = {
          email: email,
        };

        setButtonValue(true);

        axios
          .post(configData.SERVER_URL + "user/forgotPassword", payload)
          .then((response) => {

            if (response.data.status === 200) {
              notifysuccess(response.data.message);
              setEmail("");
              setButtonValue(false);
              setTimeout(()=>{window.location.replace("/login")},1000)

            } else {
              notifyfailure(response.data.message);
              setButtonValue(false);
            }
          })
          .catch((error) => {
            notifycatch();

            setButtonValue(false);
          });
      }
    } else {
      if (email === null) {
        setEmailIsRequiredError(true);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="login_page">
        <div className="custom_container">
          <Row className="h-100">
            <Col md={6} className="col-md-6 h-100 d-flex align-items-center justify-content-center position-relative">
          <img src={Logo} className="img-fluid fixed_logo" alt="" />
              <div className="form_side_area">
                <div className="for_center_div">
                  <div className="form_fields">
                    <h1>Forgot Password</h1>
                    <p>Welcome back! Please enter your details.</p>
                    {/* <Form> */}
                      <Form.Group className="">
                        <Form.Label>Email Address</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            className=""
                            aria-label="email"
                            aria-describedby="email"
                            value={email}
                            onBlur={onBlurEmail}
                            onChange={onChangeEmail}
                            placeholder="Email Address"
                          />
                          {email?.length > 0? <img src={MailBlue} className="img-fluid abso_element_left" alt="" /> : <img src={Mail} className="img-fluid abso_element_left" alt="" />}
                        </div>
                        {emailIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}
                        {emailUrlError && (
                          <span className="alert_text">
                            Invalid Email Address
                          </span>
                        )}
                      </Form.Group>
                      {!buttonValue && (
                        <Button
                          className="btn btn_button"
                          type="button"
                          onClick={handleSubmit}
                          ref={buttonRef2}
                        >
                          Submit
                        </Button>
                      )}
                      {buttonValue && (
                        <Button className="btn btn_button" type="button">
                          Processing <LoadingOutlined className="ms-2" />
                        </Button>
                      )}
                      <span className="sign_up_text">
                        Don't have an account?{" "}
                        <Link to="/register" className="text-decoration-underline hyper_link_clr">Sign Up</Link>
                      </span>
                    {/* </Form> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="">
              <div className="login_img_area">
                <Carousel
                  //  afterChange={onChange}
                  dots={false}
                  autoplay
                  effect="fade"
                >
                  <div>
                    <div style={contentStyle}>
                      <img src={SlideOne} className="img-fluid" alt="" />
                      <p>
                        Take your campaign performance to the next level with
                        our{" "}
                        <span className="text-decoration-none hyper_text_link_clr"> real-time triggers. </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div style={contentStyle}>
                      <img src={SlideTwo} className="img-fluid" alt="" />
                      <p>
                        Take your campaign performance to the next level with
                        our{" "}
                        <a
                          href="/"
                          className="text-decoration-none hyper_text_link_clr"
                        >
                          real-time triggers.
                        </a>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div style={contentStyle}>
                      <img src={SlideThree} className="img-fluid" alt="" />
                      <p>
                        Take your campaign performance to the next level with
                        our{" "}
                        <a
                          href="/"
                          className="text-decoration-none hyper_text_link_clr"
                        >
                          real-time triggers.
                        </a>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div style={contentStyle}>
                      <img src={SlideFour} className="img-fluid" alt="" />
                      <p>
                        Take your campaign performance to the next level with
                        our{" "}
                        <a
                          href="/"
                          className="text-decoration-none hyper_text_link_clr"
                        >
                          real-time triggers.
                        </a>
                      </p>
                    </div>
                  </div>
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
