import React, { useState, useContext } from "react";
import { Button, InputGroup } from "react-bootstrap";
import LogoHolder from "../assets/images/placeholder-img.svg";
import { Switch } from 'antd';
import { Form } from "react-bootstrap";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'js-cookie';
import axios from "axios";
import configData from "../config/config.json";
import constData from "../utils/constantData.json";
import { TokenVerificationLoggedin, ScreenView } from "../utils/tokenVerify";
import { LoadingContext } from "react-router-loading";
import Select from "react-select";
import constantData from "../utils/constantData.json";
import jwt_decode from "jwt-decode";
// import LoaderImage from "../assets/images/loading-img.gif";
import { Modal } from "react-bootstrap";

const EditAdvertiser = (props) => {
  const loadingContext = useContext(LoadingContext);

  //   const [initialLoader, setInitialLoader] = useState(false);
  const [active, setActive] = useState(true);
  const [checkRole, setCheckRole] = useState("MASTER");

  const [smOptionData, setSmOptionData] = useState([]);
  const [csmOptionData, setCsmOptionData] = useState([]);

  React.useEffect(() => {
    TokenVerificationLoggedin();
    ScreenView();

    // setInitialLoader(true);
    let status = Cookies.get("token_moment") ? true : false;
    if (status) {

      let token = Cookies.get("token_moment");

      let aid = jwt_decode(token).aid;
      let role = jwt_decode(token).role;
      setCheckRole(role);

      if (role === "SA") {
        aid = props.advertiserId;

        axios
          .get(configData.SERVER_URL + "advertiser/smMinifiedList", {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // let makingData = response.data.result.map((val) => {
              //   return {
              //     value: val.advertiserId,
              //     label: val.advertiserName,
              //   };
              // });

              setSmOptionData(response.data.data);
            }
          })
          .catch((error) => { });


        axios
          .get(configData.SERVER_URL + "advertiser/csmMinifiedList", {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // let makingData = response.data.result.map((val) => {
              //   return {
              //     value: val.advertiserId,
              //     label: val.advertiserName,
              //   };
              // });

              setCsmOptionData(response.data.data);
            }
          })
          .catch((error) => { });

      }

      axios
        .get(
          configData.SERVER_URL + "advertiser/getAdvertiserById?aid=" + aid,

          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.result.a_IM_key?.length !== 0) {
              setSelectedIm({
                value: response.data.result.a_IM_key,
                label:
                  response.data.result.a_IM_key.charAt(0).toUpperCase() +
                  response.data.result.a_IM_key.slice(1),
              });
            }
            setSelectedCountry({
              value: response.data.result.country,
              label: response.data.result.countryName,
            });
            setCurrency(response.data.result.currency);
            setRevenueModel({ label: response.data.result.revenue_model, value: response.data.result.revenue_model })
            setIsRevenueModelEnabled(!response.data.result.isCamp)
            setName(response.data.result.fullName);
            setCompanyName(response.data.result.companyName);
            setEmail(response.data.result.email);

            if (role === "SA") {
              setAgencySelected(response.data.result.agencyName[0]);
              setRevShare(response.data.result.agencyCommission);
            }

            setLogo({ preview: response.data.result.logo, data: "" });

            setState(response.data.result.state);

            setAddress(response.data.result.address);
            setPostal(response.data.result.postalCode);
            setIm(response.data.result.IM);

            setSelectedSm(response.data.result.sm);

            setSelectedCSM(response.data.result.csm);
            setEmailAlert(response.data.result.emailAlert)

            //   setInitialLoader(false);
          }
        })
        .catch((error) => { });
    }

    loadingContext.done()
  }, [props.advertiserId]);

  React.useEffect(() => {
    // country list code starts
    axios
      .get(configData.SERVER_URL + "advertiser/countryMinifiedList", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let makingData = [];
          makingData = response.data.data.map((val) => ({
            value: val.ISO,
            label: val.Country,
          }));

          setSelectCountryList(makingData);
        }
      })
      .catch((error) => { });

    // country list code ends
  }, []);

  React.useEffect(() => {

    let status = Cookies.get("token_moment") ? true : false;
    if (status) {
      let token = Cookies.get("token_moment");
      let role = jwt_decode(token).role;
      // agency list code starts

      if (role === "SA") {
        axios
          .get(
            configData.SERVER_URL + "advertiser/agencyMinifiedList",

            {
              headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setAgencyNameOptions(response.data.data);
            }
          })
          .catch((error) => { });
      }
    }

    // agency list code ends
  }, [active]);

  const notifysuccess = (msg) => toast.success(msg, { autoClose: 2000 });
  const notifyfailure = (msg) => toast.error(msg, { autoClose: 2000 });
  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 2000 });

  const [selectCountryList, setSelectCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [agencyNameOptions, setAgencyNameOptions] = useState([]);
  const [agencySelected, setAgencySelected] = useState([]);

  const [logo, setLogo] = useState({ data: "", preview: "" });

  const [logoIsRequiredError, setLogoIsRequiredError] = useState(false);
  const [logoValidError] = useState(false);

  const [name, setName] = useState(null);
  const [nameIsRequiredError, setNameIsRequiredError] = useState(false);
  const [nameValidError, setNameValidError] = useState(false);

  const [revShare, setRevShare] = useState(0);
  const [revShareValidationError, setRevShareValidationError] = useState(false);

  const [companyName, setCompanyName] = useState(null);
  const [companyNameIsRequiredError, setCompanyNameIsRequiredError] =
    useState(false);

  const [currency, setCurrency] = useState([]);
  const [currencyIsRequiredError, setCurrencyIsRequiredError] = useState(false);


  const [revenueModel, setRevenueModel] = useState({ value: 'CPM', label: 'CPM' });
  const [isRevenueModelEnabled, setIsRevenueModelEnabled] = useState(true);

  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [state, setState] = useState(null);
  const [postal, setPostal] = useState(null);
  const [countryIsRequiredError, setCountryIsRequiredError] = useState(false);

  const [im, setIm] = useState("");
  const [imIsRequiredError, setImIsRequiredError] = useState(false);

  const [selectedIm, setSelectedIm] = useState([]);

  const [selectedSm, setSelectedSm] = useState([]);
  const [selectedCSM, setSelectedCSM] = useState([]);

  const [buttonValue, setButtonValue] = useState(false);

  const regexName = /^[a-zA-Z0-9_]{3,30}$/



  //email alert
  const [emailAlert, setEmailAlert] = useState(false);

  function onChangeEmailAlert(event) {
    setEmailAlert(event);
  }



  function onChangeLogo(e) {
    if (e.target.files[0] === undefined) {
      // setLogoValue("");
      // setLogoValidError(false);
      // setLogoIsRequiredError(true);
      // setLogo({
      //   data: "",
      //   preview: "",
      // });
    } else {
      if (e.target.files[0].type.includes("image")) {
        // setLogoIsRequiredError(false);
        // setLogoValidError(false);
        // setLogoValue(e.target.files[0].name);

        if (((e.target.files[0].size) / (1024 * 1024)) < 2) {
          setLogo({
            data: e.target.files[0],
            preview: URL.createObjectURL(e.target.files[0]),
          });
        }
        else {
          toast.error("File Size Should be less than 2 MB")
        }
      } else {
        toast.error("Selected Image Format is Invalid", { autoClose: 2000 });
        // setLogoValue("");
        // setLogoIsRequiredError(false);
        // setLogo({
        //   data: "",
        //   preview: "",
        // });

        // setLogoValidError(true);
      }
    }
  }

  // function onChangeLogoReset(e) {
  //   setLogoIsRequiredError(true);
  //   setLogoValidError(false);
  //   document.getElementById("uploadLogo").value = "";

  //   setLogo({
  //     data: "",
  //     preview: "",
  //   });
  // }

  function onChangeName(event) {
    setNameIsRequiredError(false);

    // if(regexName.test(event.target.value)){
    //   setNameValidError(false)

    // }
    // else{
    //   setNameValidError(true)
    // }

    setName(event.target.value);
  }

  function onChangeRevShare(event) {
    if (event.target.value >= 0 && event.target.value <= 100) {
      setRevShareValidationError(false);
    } else {
      setRevShareValidationError(true);
    }
    setRevShare(event.target.value);
  }

  function onBlurName(event) {
    if (event.target.value.length === 0) {
      // setNameValidError(false)
      setNameIsRequiredError(true);
    }
  }

  function onChangeCompanyName(event) {
    setCompanyNameIsRequiredError(false);
    setCompanyName(event.target.value);
  }

  function onBlurCompanyName(event) {
    if (event.target.value.length === 0) {
      setCompanyNameIsRequiredError(true);
    }
  }

  function onChangeCurrency(event) {
    setCurrencyIsRequiredError(false);
    setCurrency(event);
  }

  function onChangeRevenueModel(event) {
    setRevenueModel(event);
  }


  function onChangeSM(event) {
    setSelectedSm(event);
  }

  function onChangeCSM(event) {
    setSelectedCSM(event);
  }

  function onChangeAddress(event) {
    setAddress(event.target.value);
  }

  function onChangeState(event) {
    setState(event.target.value);
  }

  function onChangePostal(event) {
    setPostal(event.target.value);
  }

  function onChangeCountry(event) {
    setCountryIsRequiredError(false);
    setSelectedCountry(event);
  }

  function onChangeAgency(event) {
    setAgencySelected(event);
  }

  function onChangeIm(event) {
    if (event.target.value.length === 0) {
      setImIsRequiredError(false);
    }
    setIm(event.target.value);
  }

  function onChangeSelectedIm(event) {
    if (event === null && im !== "") {
      setImIsRequiredError(true);
      setSelectedIm([]);
    } else {
      setImIsRequiredError(false);
      setSelectedIm(event);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      logo.preview !== "" &&
      name !== null &&
      companyName !== null &&
      selectedCountry.length !== 0 &&
      currency.length !== 0
    ) {
      if (
        nameIsRequiredError === false &&
        companyNameIsRequiredError === false &&
        countryIsRequiredError === false &&
        currencyIsRequiredError === false &&
        logoIsRequiredError === false &&
        logoValidError === false &&
        revShareValidationError === false
        // nameValidError===false
      ) {
        if (im !== "" && selectedIm.length === 0) {
          setImIsRequiredError(true);
          return;
        }

        let formData = new FormData();

        formData.append("name", name);
        formData.append("company", companyName);

        if (checkRole === "SA") {
          formData.append("agencyName", JSON.stringify(agencySelected));
          formData.append("agencyCommission", revShare);
        }

        formData.append("email", email);
        formData.append("address", address);
        formData.append("state", state);
        formData.append("postalCode", postal);
        formData.append("currency", currency.value);
        formData.append("revenue_model", revenueModel.value);
        formData.append("country", selectedCountry.value);
        formData.append("sm", JSON.stringify(selectedSm));
        formData.append("csm", JSON.stringify(selectedCSM));
        formData.append("selectedIm", selectedIm.value);
        formData.append("im", im);
        formData.append("emailAlert", emailAlert)

        if (logo.data !== "") {
          formData.append("logo", logo.data);
        }

        let token = Cookies.get("token_moment");
        let aid = jwt_decode(token).aid;
        let role = jwt_decode(token).role;

        if (role === "SA") {
          aid = props.advertiserId;
        }

        setButtonValue(true);
        axios
          .post(
            configData.SERVER_URL + "advertiser/updateAdvertiser?aid=" + aid,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "x-access-token": token,
              },
            }
          )
          .then((response) => {
            if (response.data.status === 200) {
              notifysuccess(response.data.message);
              setTimeout(() => {
                setButtonValue(false);
                if (checkRole === "SA") {
                  window.location.replace("/listAdvertiser");
                } else {
                  window.location.reload();
                }
              }, 2000);
            } else {
              notifyfailure(response.data.message);
              setButtonValue(false);
            }
          })
          .catch((error) => {
            notifycatch();
            setButtonValue(false);
          });
      }
    } else {
      if (name === "") {
        setNameIsRequiredError(true);
      }
      if (companyName === "") {
        setCompanyNameIsRequiredError(true);
      }

      if (selectedCountry.length === 0) {
        setCountryIsRequiredError(true);
      }

      if (currency.length === 0) {
        setCurrencyIsRequiredError(true);
      }

      if (logo.preview === "") {
        if (!logoValidError) {
          setLogoIsRequiredError(true);
        }
      }

      if (im !== "" && selectedIm.length === 0) {
        setImIsRequiredError(true);
        return;
      }
    }
  };

  /////////////////////////////////////agency create starts//////////////////////

  const [showColumn, setShowColumn] = useState(false);
  const [agencyNameCreate, setAgencyNameCreate] = useState(null);

  const [agencyNameCreateIsRequiredError, setAgencyNameCreateIsRequiredError] =
    useState(false);

  function onChangeAgencyNameCreate(event) {
    setAgencyNameCreateIsRequiredError(false);
    setAgencyNameCreate(event.target.value);
  }

  function onBlurAgencyNameCreate(event) {
    if (event.target.value.length === 0) {
      setAgencyNameCreateIsRequiredError(true);
    }
  }

  function submitAgencyCreate(event) {
    if (agencyNameCreate !== null) {
      if (agencyNameCreateIsRequiredError === false) {
        setShowColumn(false);

        // setInitialLoader(true);
        let token = Cookies.get("token_moment");
        axios
          .post(
            configData.SERVER_URL + "advertiser/addAgency",
            { name: agencyNameCreate },
            {
              headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
              },
            }
          )
          .then((response) => {
            if (response.data.status === 201) {
              setActive(!active);
              // notifysuccess(response.data.message);
              toast.success(response.data.message, { autoClose: 1000 });
            } else {
              notifyfailure(response.data.message);
              // setInitialLoader(false);
            }
          })
          .catch((error) => {
            notifycatch();
            // setInitialLoader(false);
          });

        setAgencyNameCreate(null);
      }
    } else {
      if (agencyNameCreate === null || agencyNameCreate === "") {
        setAgencyNameCreateIsRequiredError(true);
      }
    }
  }

  // ///////////////////////////////////agency  create ends//////////////////////

  React.useEffect(() => {
    const inputFields = document.querySelectorAll("input[type='number']");

    const disableScroll = (event) => {
      event.preventDefault();
    };

    const enableScroll = (element) => {
      element.removeEventListener("wheel", disableScroll);
    };

    const disableScrollOnFocus = (element) => {
      element.addEventListener("wheel", disableScroll, { passive: false });
    };

    inputFields.forEach((inputField) => {
      inputField.addEventListener("focus", () => {
        disableScrollOnFocus(inputField);
      });

      inputField.addEventListener("blur", () => {
        enableScroll(inputField);
      });
    });

    return () => {
      inputFields.forEach((inputField) => {
        enableScroll(inputField);
      });
    };
  }, []);

  return (
    <>
      <div className="main_contain_area">
        <div className="step_area">
          <h5>Edit your Advertiser Account</h5>
        </div>
        <div className="scroll_area">
          <div className="step_form_area">
            <div className="custom_container_form">
              <div className="row_devider d-flex">
                <div className="custom_col_space_label">
                  <div className="step_form_label">
                    <h4>Basic Information</h4>
                  </div>
                </div>
                <div className="step_input_fields">
                  <div className="w-440">
                    <Form.Label>Company Logo*</Form.Label>
                    <div className="upload_logo_area">
                      <div className="d-flex align-items-center">
                        <img src={logo.preview ? logo.preview : LogoHolder} alt="user-avatar" className="img-fluid" height="100" width="100" id="uploadedAvatar" />
                        <div className="ps-3">
                          <p>Upload an Advertiser logo.</p>
                          <span>Max size 2MB</span>
                        </div>
                      </div>
                      <div className="upload_btn_img">
                        <Form.Control type="file" accept="image/png, image/jpeg,image/jpg" onChange={onChangeLogo} id="uploadLogo" />
                        <span>Browse</span>
                      </div>
                    </div>
                    {logoIsRequiredError && (
                      <span className="alert_text">
                        This field is required
                      </span>
                    )}
                    {logoValidError && (
                      <span className="alert_text">Invalid Image Format</span>
                    )}
                  </div>
                  <div className="d-flex">
                    <div className="w-440 mt-20 me-16">
                      <Form.Group className="" controlId="exampleForm.ControlInput1">
                        <Form.Label>Name*</Form.Label>
                        <Form.Control type="text" value={name} placeholder="Name" onChange={onChangeName} onBlur={onBlurName} />
                        {nameIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}
                        {/* {nameValidError && (
                          <span className="alert_text">
                           Value must be alphanumeric or with underscore ranging between 3-30 characters.
                          </span>
                        )} */}
                      </Form.Group>
                    </div>
                    <div className="w-440 mt-20">
                      <Form.Group className="" controlId="exampleForm.ControlInput1">
                        <Form.Label>Company/Advertiser*</Form.Label>
                        <Form.Control type="text" value={companyName} placeholder="Company Name" onChange={onChangeCompanyName} onBlur={onBlurCompanyName} />
                        {companyNameIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}

                      </Form.Group>
                    </div>
                  </div>
                  {checkRole === "SA" &&
                    <div className="d-flex">
                      <div className="w-440 mt-20 me-16">
                        <Form.Group className="" controlId="exampleForm.ControlInput1">
                          <Form.Label>Default Currency*</Form.Label>
                          <Select
                            className="custom_drop_design select-custom p-0"
                            options={constData.CURRENCY_OPTIONS_ADVERTISER}
                            placeholder="-Select-"
                            value={currency}
                            onChange={onChangeCurrency}
                            // isDisabled={true}
                            isSearchable={true}
                          ></Select>
                          {currencyIsRequiredError && (
                            <span className="alert_text">
                              This field is required
                            </span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="w-440 mt-20">
                        <Form.Group className="" controlId="exampleForm.ControlInput1">
                          <Form.Label>Revenue Model</Form.Label>
                          <Select
                            className="custom_drop_design select-custom p-0"
                            options={constData.REVENUE_MODEL_OPTIONS_ADVERTISER}
                            placeholder="-Select-"
                            value={revenueModel}
                            onChange={onChangeRevenueModel}
                            isDisabled={!isRevenueModelEnabled}
                            isSearchable={false}
                          ></Select>
                        </Form.Group>
                      </div>
                    </div>

                  }
                </div>
              </div>


              <div className="divider_line"></div>
              {checkRole === "SA" && (
                <div className="row_devider d-flex">
                  <div className="custom_col_space_label">
                    <div className="step_form_label">
                      <h4>Agency</h4>
                    </div>
                  </div>
                  <div className="step_input_fields">
                    <div className="d-flex align-items-center">
                      <div className="w-440 me-16">
                        <Form.Group className="" controlId="exampleForm.ControlInput1">
                          <div className='d-flex align-items-center justify-content-between'>
                            <Form.Label>Agency*</Form.Label>
                            <button type="button"
                              className="btn blue_text fs-12 cursor-pointer text-decoration-none text_hover_colour p-0" onClick={() => {
                                setShowColumn(true);
                              }}
                            > <i className="fa-regular fa-plus"></i> Add New Agency </button>
                          </div>
                          <Select
                            className="custom_drop_design select-custom p-0"
                            options={agencyNameOptions}
                            placeholder="-Select-"
                            value={agencySelected}
                            onChange={onChangeAgency}
                            isSearchable={true}
                          ></Select>
                        </Form.Group>
                      </div>
                      <div className="w-440">
                        <Form.Group
                          className=""
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Agency Commission</Form.Label>
                          <InputGroup className="">
                            {/* <InputGroup.Text
                            id="basic-addon1"
                            className="border-end-0 bg-transparent"
                          >
                            <i className="fa-solid fa-dollar-sign"></i>
                          </InputGroup.Text> */}

                            <Form.Control
                              className="border-end-0"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              type="number"
                              value={revShare}
                              placeholder="Agency Commission"
                              onChange={onChangeRevShare}
                            />
                            <InputGroup.Text id="basic-addon2" className="border-start-0 bg-transparent">
                              <span style={{ color: '#bdbdbd' }}> %</span>
                              {/* <i className="fa-solid fa-percent"></i> */}
                            </InputGroup.Text>
                          </InputGroup>
                          {revShareValidationError && (
                            <span className="alert_text">
                              Value Range ( 0-100 )
                            </span>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="divider_line"></div>
              <div className="row_devider d-flex">
                <div className="custom_col_space_label">
                  <div className="step_form_label">
                    <h4>Address</h4>
                  </div>
                </div>
                <div className="step_input_fields">
                  <div className="d-flex">
                    <div className="w-356 me-16">
                      <Form.Group className="" controlId="exampleForm.ControlInput1">
                        <Form.Label>Country*</Form.Label>
                        <Select
                          className="custom_drop_design select-custom p-0"
                          options={selectCountryList}
                          placeholder="-Select-"
                          value={selectedCountry}
                          onChange={onChangeCountry}
                          isSearchable={true}
                        ></Select>
                        {countryIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}
                      </Form.Group>
                    </div>
                    <div className="w-356 me-16">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          value={state}
                          onChange={onChangeState}
                          placeholder="State"
                        />
                      </Form.Group>
                    </div>
                    <div className="w-153">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          type="text"
                          value={postal}
                          onChange={onChangePostal}
                          placeholder="Postal Code"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="w-textarea mt-20 me-16">
                    <Form.Group className="" controlId="exampleForm.ControlInput1">
                      <Form.Label>Address</Form.Label>
                      <textarea name="" id="" className="form-control" cols="30" rows="10" value={address} onChange={onChangeAddress} placeholder="Address"></textarea>
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="divider_line"></div>
              <div className="row_devider d-flex">
                <div className="custom_col_space_label">
                  <div className="step_form_label">
                    <h4>Contact</h4>
                  </div>
                </div>
                <div className="step_input_fields">
                  <div className="d-flex">
                    <div className="w-440 me-16">
                      <Form.Group className="" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="text" value={email} disabled />
                      </Form.Group>
                    </div>
                    <div className="w-440">
                      <Form.Group className="" controlId="exampleForm.ControlInput1">
                        <Form.Label>IM</Form.Label>
                        <InputGroup className="joined_input_fields">
                          <Select
                            className="custom_drop_design select-custom fixed_width_im p-0"
                            options={constantData.IM_OPTIONS}
                            placeholder="-Select-"
                            value={selectedIm}
                            onChange={onChangeSelectedIm}
                          // isClearable={true}
                          />
                          <Form.Control
                            type="text"
                            placeholder="IM"
                            value={im}
                            onChange={onChangeIm}
                          />
                        </InputGroup>
                        {imIsRequiredError && (
                          <span className="alert_text">
                            Please choose IM type.
                          </span>
                        )}

                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>

              {checkRole === "SA" &&
                <>
                  <div className="divider_line"></div>
                  <div className="row_devider d-flex">
                    <div className="custom_col_space_label">
                      <div className="step_form_label">
                        <h4>Assigned To</h4>
                      </div>
                    </div>
                    <div className="step_input_fields">
                      <div className="d-flex">
                        <div className="w-440 me-16">
                          <Form.Group
                            className=""
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Sales Manager</Form.Label>
                            <Select
                              className="custom_drop_design select-custom p-0"
                              options={smOptionData}
                              placeholder="-Select-"
                              value={selectedSm}
                              onChange={onChangeSM}
                              isSearchable={true}
                              isMulti
                            ></Select>
                          </Form.Group>
                        </div>
                        <div className="w-440">
                          <Form.Group className="" controlId="exampleForm.ControlInput1">
                            <Form.Label>Client Success Manager</Form.Label>
                            <Select className="custom_drop_design select-custom p-0" options={csmOptionData} placeholder="-Select-"
                              value={selectedCSM}
                              onChange={onChangeCSM}
                              isSearchable={true}
                              isMulti
                            ></Select>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                </>
              }


              {/* this fields make according to harshil */}
              <div className="divider_line"></div>
              <div className="row_devider d-flex">
                <div className="custom_col_space_label">
                  <div className="step_form_label">
                    <h4>Email alerts</h4>
                  </div>
                </div>
                <div className="step_input_fields">
                  <div className="w-440">
                    <Form.Label> Toggle email alerts for account activity</Form.Label>
                    <Form.Group className="" controlId="">
                      <InputGroup className="">
                        <InputGroup.Text id="basic-addon2" className="form-control bg-transparent d-flex justify-content-between">

                          <span className={emailAlert ? "green_text" : "disabled_text"}>
                            {emailAlert ? "Enabled" : "Disabled"}

                          </span>
                          <Switch
                            checked={emailAlert}
                            onChange={onChangeEmailAlert}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="divider_line"></div>
              <div className="button_area_bottom d-flex justify-content-end">
                <div>
                  {!buttonValue && checkRole !== "RM" && (
                    <button
                      className="d-flex align-items-center justify-content-center default_btn btn"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  )}

                  {buttonValue && (
                    <button
                      className="d-flex align-items-center justify-content-center default_btn btn"
                      type="button"
                      disabled
                    >
                      Processing
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ////////////////////////////////Modal to add new agency////////////////////// */}
      <Modal show={showColumn} className="custom_modal_design" centered>
        <Modal.Header>
          <Modal.Title> Create Agency </Modal.Title>
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            onClick={() => {
              setAgencyNameCreate(null);
              setAgencyNameCreateIsRequiredError(false);
              setShowColumn(false);
            }}>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="" controlId="exampleForm.ControlInput1">
            <Form.Label>Agency Name*</Form.Label>
            <Form.Control type="text" placeholder="Facebook_01" value={agencyNameCreate} onChange={onChangeAgencyNameCreate} onBlur={onBlurAgencyNameCreate} />
            {agencyNameCreateIsRequiredError && (
              <span className="alert_text">

                This field is required
              </span>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="default_btn"
            onClick={() => {
              submitAgencyCreate();
            }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default EditAdvertiser;
