import Reporting from "../components/Reporting";
import { useLocation } from "react-router-dom";

function ReportingPage() {
  const search = useLocation().search;
  const campaignId = new URLSearchParams(search).get("campaignId");
  const advertiserId = new URLSearchParams(search).get("advertiserId");
  return (
    <>
      <Reporting campaignId={campaignId} advertiserId={advertiserId} />
    </>
  );
}

export default ReportingPage;
