import React from "react";
import { useMemo, useState } from "react";

import noRecord from "../assets/images/no_data_new.svg";


import ContentLoader from "react-content-loader";

import {
    useTable,
    useFilters,
    useSortBy,
    usePagination,
    useGlobalFilter,
} from "react-table";

import Loader from "./Loader.jsx";
import NOComponent from "./EmptyTableMsg.jsx";
import { InputGroup, Table } from 'react-bootstrap'
import Select from "react-select";
import ConstantData from "../utils/constantData.json"
import FileSearchIcon from '../assets/images/file_searching_icon.svg'
const TableBlsForm = ({
    columns,
    data,
    initial,
    loading,
    role
}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: currentPage, pageSize: 20 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const [filter, setFilter] = useState("");

    const toggleSortByDirection = useMemo(() => {
        return (columnId, desc) => {
            const column = headerGroups
                .flatMap((headerGroup) => headerGroup.headers)
                .find((header) => header.id === columnId);
            column.toggleSortBy(desc, false);
        };
    }, [headerGroups]);

    function onChangeSizePagination(event) {
        setPageSize(event.value)
    }

    const customStyles = {
        menuPortal: base => ({
            ...base,
            zIndex: 9999, // Set a high z-index to ensure it appears above other content
        }),
        menu: provided => ({
            ...provided,
            position: 'absolute',
            top: 'auto',
            bottom: '100%', // Position the menu above the select input
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Optional: add some shadow
        }),
    };

    const [backupCurrentPage, setBackupCurrentPage] = useState(-1);

    const [minFoc, setMinFoc] = useState(false);
    const onFocusMin = () => {
        setMinFoc(true);
    };
    const onBlurMin = () => {
        setMinFoc(false);
    };

    return (
        <>
            {/* ////////////////////////////////// */}

            <div className="">
                <div className="d-flex align-items-center justify-content-between position-relative ">
                    <span className="icon-search position-absolute ms-6 report-search-icon">
                        <i
                            className="fas fa-solid fa-magnifying-glass  "
                            aria-hidden="true"
                        ></i>
                    </span>
                    <InputGroup className={minFoc ? 'full_input_border search_width custom_search_bar' : 'search_width custom_search_bar'}>
                        <InputGroup.Text id="basic-addon1" className="border-end-0">
                            <i className="fas fa-solid fa-magnifying-glass" aria-hidden="true"></i>
                        </InputGroup.Text>
                        <input
                            id="custom-filter"
                            type="text"
                            onFocus={onFocusMin}
                            onBlur={onBlurMin}
                            data-kt-user-table-filter="search"
                            className="form-control ps-14 report-search border-start-0"
                            placeholder="Search"
                            value={filter || ""}
                            onChange={(e) => {
                                if (e.target.value.length !== 0) {

                                    if (backupCurrentPage === -1) {
                                        setBackupCurrentPage(currentPage)
                                    }

                                    setCurrentPage(0)

                                }
                                else {

                                    setCurrentPage(backupCurrentPage)
                                    setBackupCurrentPage(-1)

                                }
                                setFilter(e.target.value);
                                setGlobalFilter(e.target.value);
                            }}
                        />
                    </InputGroup>
                    {role !== "RM" &&
                        <div className="d-flex search-table-main ">
                            <div className="filter-button">
                                <a className="btn default_btn btn-sm h-40" href="/liftStudy" target="_blank">
                                    <i className="fas fa-plus me-1"></i> Add New </a>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {/* //////////////////////////////////////// */}

            {/* Table Start */}

            {loading ? (
                <ContentLoader className="" viewBox="16 65 1150 450" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
                    <rect x="26" y="80" rx="4" ry="4" width="20" height="6" />
                    <rect x="67" y="80" rx="4" ry="4" width="85" height="6" />
                    <rect x="188" y="80" rx="4" ry="4" width="169" height="6" />
                    <rect x="402" y="80" rx="4" ry="4" width="85" height="6" />
                    <rect x="523" y="80" rx="4" ry="4" width="169" height="6" />
                    <rect x="731" y="80" rx="4" ry="4" width="85" height="6" />
                    <rect x="852" y="80" rx="4" ry="4" width="85" height="6" />

                    <rect x="26" y="110" rx="4" ry="4" width="20" height="6" />
                    <rect x="66" y="110" rx="4" ry="4" width="85" height="6" />
                    <rect x="187" y="110" rx="4" ry="4" width="169" height="6" />
                    <rect x="401" y="110" rx="4" ry="4" width="85" height="6" />
                    <rect x="522" y="110" rx="4" ry="4" width="169" height="6" />
                    <rect x="730" y="110" rx="4" ry="4" width="85" height="6" />
                    <rect x="851" y="110" rx="4" ry="4" width="85" height="6" />

                    <rect x="26" y="140" rx="4" ry="4" width="20" height="6" />
                    <rect x="66" y="140" rx="4" ry="4" width="85" height="6" />
                    <rect x="187" y="140" rx="4" ry="4" width="169" height="6" />
                    <rect x="401" y="140" rx="4" ry="4" width="85" height="6" />
                    <rect x="522" y="140" rx="4" ry="4" width="169" height="6" />
                    <rect x="730" y="140" rx="4" ry="4" width="85" height="6" />
                    <rect x="851" y="140" rx="4" ry="4" width="85" height="6" />

                    <rect x="26" y="170" rx="4" ry="4" width="20" height="6" />
                    <rect x="66" y="170" rx="4" ry="4" width="85" height="6" />
                    <rect x="187" y="170" rx="4" ry="4" width="169" height="6" />
                    <rect x="401" y="170" rx="4" ry="4" width="85" height="6" />
                    <rect x="522" y="170" rx="4" ry="4" width="169" height="6" />
                    <rect x="730" y="170" rx="4" ry="4" width="85" height="6" />
                    <rect x="851" y="170" rx="4" ry="4" width="85" height="6" />

                    <rect x="26" y="200" rx="4" ry="4" width="20" height="6" />
                    <rect x="66" y="200" rx="4" ry="4" width="85" height="6" />
                    <rect x="187" y="200" rx="4" ry="4" width="169" height="6" />
                    <rect x="401" y="200" rx="4" ry="4" width="85" height="6" />
                    <rect x="522" y="200" rx="4" ry="4" width="169" height="6" />
                    <rect x="730" y="200" rx="4" ry="4" width="85" height="6" />
                    <rect x="851" y="200" rx="4" ry="4" width="85" height="6" />

                    <rect x="26" y="230" rx="4" ry="4" width="20" height="6" />
                    <rect x="66" y="230" rx="4" ry="4" width="85" height="6" />
                    <rect x="187" y="230" rx="4" ry="4" width="169" height="6" />
                    <rect x="401" y="230" rx="4" ry="4" width="85" height="6" />
                    <rect x="522" y="230" rx="4" ry="4" width="169" height="6" />
                    <rect x="730" y="230" rx="4" ry="4" width="85" height="6" />
                    <rect x="851" y="230" rx="4" ry="4" width="85" height="6" />

                    <rect x="26" y="260" rx="4" ry="4" width="20" height="6" />
                    <rect x="66" y="260" rx="4" ry="4" width="85" height="6" />
                    <rect x="187" y="260" rx="4" ry="4" width="169" height="6" />
                    <rect x="401" y="260" rx="4" ry="4" width="85" height="6" />
                    <rect x="522" y="260" rx="4" ry="4" width="169" height="6" />
                    <rect x="730" y="260" rx="4" ry="4" width="85" height="6" />
                    <rect x="851" y="260" rx="4" ry="4" width="85" height="6" />

                    <rect x="26" y="290" rx="4" ry="4" width="20" height="6" />
                    <rect x="66" y="290" rx="4" ry="4" width="85" height="6" />
                    <rect x="187" y="290" rx="4" ry="4" width="169" height="6" />
                    <rect x="401" y="290" rx="4" ry="4" width="85" height="6" />
                    <rect x="522" y="290" rx="4" ry="4" width="169" height="6" />
                    <rect x="730" y="290" rx="4" ry="4" width="85" height="6" />
                    <rect x="851" y="290" rx="4" ry="4" width="85" height="6" />

                    <rect x="978" y="80" rx="4" ry="4" width="169" height="6" />
                    <rect x="977" y="110" rx="4" ry="4" width="169" height="6" />
                    <rect x="977" y="140" rx="4" ry="4" width="169" height="6" />
                    <rect x="977" y="170" rx="4" ry="4" width="169" height="6" />
                    <rect x="977" y="200" rx="4" ry="4" width="169" height="6" />
                    <rect x="977" y="230" rx="4" ry="4" width="169" height="6" />
                    <rect x="977" y="260" rx="4" ry="4" width="169" height="6" />
                    <rect x="977" y="290" rx="4" ry="4" width="169" height="6" />
                </ContentLoader>
            ) : (
                <div>
                    {data.length === 0 ? (
                        <div className="no_table_data">
                            <div className="d-flex justify-content-center min-height-255 pt-4 pb-4 align-items-center">
                                <div className="text-center">
                                    <img src={noRecord} className="img-fluid" alt="" />
                                    <h2>Nothing to see here</h2>
                                    <p className="text-center">There are no lift study record to show you <br /> right now. </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="table-responsive table_border_radius tableFixHead">
                                <Table
                                    {...getTableProps()}
                                    className="display table nowrap table-advertiser-hidden custom_data_table"
                                >
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => {
                                                    if (
                                                        column.id !== "_id"

                                                    ) {

                                                        return (
                                                            <th
                                                                {...column.getHeaderProps({
                                                                    onClick: () =>
                                                                        toggleSortByDirection(
                                                                            column.id,
                                                                            !column.isSortedDesc
                                                                        ),
                                                                })}
                                                                className={
                                                                    column.isSorted
                                                                        ? column.isSortedDesc
                                                                            ? "sort-desc"
                                                                            : "sort-asc"
                                                                        : "unsort"
                                                                }
                                                            >
                                                                {column.render("Header")}
                                                            </th>
                                                        );
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                })}
                                            </tr>
                                        ))}
                                    </thead>

                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row);

                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        if (
                                                            cell.column.id !== "_id"
                                                        ) {

                                                            return (
                                                                <td {...cell.getCellProps()}>
                                                                    {cell.render("Cell")}
                                                                </td>
                                                            );
                                                        }
                                                        else {
                                                            return null;
                                                        }
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>

                            {rows.length <= 0 && (
                                <div className="no_search_record d-flex justify-content-center align-items-center">
                                    <div className="text-center">
                                        <img src={FileSearchIcon} className="img-fluid" alt="" />
                                        <h4>Sorry! No result found :(</h4>
                                        <span>Try adjusting your search or filter to find <br /> what you are looking for.</span>
                                    </div>
                                </div>
                            )}

                            {rows.length > 0 &&
                                <div className="card-footer  d-flex align-items-center justify-content-between ">
                                    <div className=" ">
                                        <div className="child-pagination-row">
                                            <Select
                                                className="custom_drop_design select-custom p-0 pagination_select"
                                                value={{ label: pageSize, value: pageSize }}
                                                options={ConstantData.paginationSize}
                                                placeholder="-Select-"
                                                onChange={onChangeSizePagination}
                                                styles={customStyles}
                                                menuPortalTarget={document.body}
                                            >
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="pagination-text-end">
                                        {rows.length > 0 && (
                                            <span>
                                                Showing{" "}
                                                <strong>
                                                    {pageIndex * pageSize + 1} -{" "}
                                                    {pageIndex * pageSize + pageSize > rows.length
                                                        ? rows.length
                                                        : pageIndex * pageSize + pageSize}
                                                </strong>{" "}
                                                of {rows.length} records
                                            </span>
                                        )}
                                        <button
                                            onClick={() => { gotoPage(0); setCurrentPage(0) }}
                                            disabled={!canPreviousPage}
                                        >
                                            <i className="fa-solid fa-angles-left"></i>
                                        </button>{" "}
                                        <button
                                            onClick={() => { previousPage(); setCurrentPage(currentPage - 1) }}
                                            disabled={!canPreviousPage}
                                        >
                                            <i className="fa-solid fa-angle-left"></i>
                                        </button>{" "}
                                        <button onClick={() => { nextPage(); setCurrentPage(currentPage + 1) }} disabled={!canNextPage}>
                                            <i className="fa-solid fa-angle-right"></i>
                                        </button>{" "}
                                        <button
                                            onClick={() => { gotoPage(pageCount - 1); setCurrentPage(pageCount - 1) }}
                                            disabled={!canNextPage}
                                        >
                                            <i className="fa-solid fa-angles-right"></i>
                                        </button>{" "}
                                    </div>
                                </div>
                            }
                        </div>
                    )}
                </div>
            )}

            {/* Table End */}
        </>
    );
};

export default TableBlsForm;
