import CreateAdvertiserAdmin from "../components/CreateAdvertiserAdmin";

function CreateAdvertiserAdminPage() {
  return (
    <>
      <CreateAdvertiserAdmin />{" "}
    </>
  );
}

export default CreateAdvertiserAdminPage;