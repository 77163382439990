import Register from "../components/Registration";

function RegisterPage() {
  return (
    <>
      <Register />
    </>
  );
}

export default RegisterPage;
