import React, { useState ,useContext} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Info from "../assets/images/info.svg";
import { TokenVerificationLoggedin, ScreenView ,BlockListingAdvertiser} from "../utils/tokenVerify";
import axios from "axios";
import { LoadingContext } from "react-router-loading";
import configData from "../config/config.json";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'js-cookie';
import TableUser from "./TableUser";
import { saveAs } from "file-saver";
import jwt_decode from "jwt-decode";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "antd";
import { Switch } from "antd";
import { Link } from "react-router-dom";
import LoaderImage from "../assets/images/loading-img.gif";


const ListUser = () => {
  const loadingContext = useContext(LoadingContext);
  
  const [active, setActive] = useState(true);
  const [initialLoader, setInitialLoader] = useState(false);

  React.useEffect(() => {
    TokenVerificationLoggedin();
    BlockListingAdvertiser();
    getData();
    loadingContext.done()
  }, [active]);


  const notifysuccess = (msg) => toast.success(msg, { autoClose: 1000 });

  const notifyfailure = (msg) => toast.error(msg, { autoClose: 1000 });

  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 2000 });


  var arr;

  const [checkRole, setCheckRole] = useState("RM");

  //table header and row creation
  const [settingHeader, setSettingHeader] = useState([]);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);


  // loader
  const [isLoading, setIsLoading] = useState(true);



  const getData = () => {
    let token = Cookies.get("token_moment");
    arr = [];

    axios
      .get(configData.SERVER_URL + `user/allUserList`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((response) => {
        const data = response.data.data[0];
        const dataValue = response.data.data;

        setData(dataValue);
        setInitialData(dataValue[0]);

        // let status= Cookies.get("token_moment")?true:false;
    //     if(status){
    
    //     let token = Cookies.get("token_moment");
    //     let role = jwt_decode(token).role;
    //     setCheckRole(role)
  
        

    //   }

        for (const [key] of Object.entries(data)) {



            if (key === "status") {
                arr.push({
                  Header: "Status",
                  accessor: "Status",
                  sortType: (a, b) => {
                 
                    if (a.original?.["status"] === b.original?.["status"]) return 0;
                    if (a.original?.["status"] === true) return 1;
                    return -1;
                  },
                  Cell: (cell) => (
                    <>
                      {cell.row.original.status !== true ? (
                        <Link
                          onClick={() => {
                            onSubmitModal(cell.row.original._id);
                          }}
                        >
                              <Switch checked={false} />
                
                        </Link>
                      ) : (
                        <Link
                          onClick={() => {
                            onSubmitModal(cell.row.original._id);
                          }}
                        >
                              <Switch checked={true} />
                   
                        </Link>
                      )}
                    </>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                });
              }

             else if (key === "role") {
                arr.push({
                  Header: key,
                  accessor: key,
                  Cell: (cell) => (
                    <>
                      {cell.row.original.role === "M" ? "Manager":
                      cell.row.original.role === "A" ? "Admin":
                      cell.row.original.role === "RM" ? "Reporting Manager":
                      "Master"
                        
                      }
                    </>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                });
              }
else{
          arr.push({
            Header: key,
            accessor: key,
          });
        }
    }

        setSettingHeader(arr);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };


  function onSubmitModal(id) {
    setInitialLoader(true);
    axios
      .post(configData.SERVER_URL + "user/toggleUser?id=" + id)
      .then((response) => {
        if (response.status === 200) {
          setActive(!active);
          notifysuccess(response.data.message);
        } else {
          notifyfailure(response.data.message);
        
        }
        setInitialLoader(false);
      })
      .catch((error) => {
        notifycatch();
        setInitialLoader(false);
      });
  }


  //////////////////////////////////////////////

  


  return (
    <>
      {/* <ToastContainer/> */}

      <div className="main_contain_area">
        <div className="mb-4">
          <div className="table_area_spacing">
          {initialLoader && (
              <div className="page_loader_gif">
                <img src={LoaderImage} alt="" className="img-fluid" />
              </div>
            )}
            <TableUser
              columns={settingHeader}
              data={data}
              loading={isLoading}
              initial={initialData}
           
              role={checkRole}
            />
          </div>
        </div>

      </div>


    </>
  );
};

export default ListUser;
