import ListBlsForm from "../components/ListBlsForm";

function ListBlsFormPage() {
    return (
        <>
            <ListBlsForm />
        </>
    );
}

export default ListBlsFormPage;