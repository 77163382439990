import MediaPlanner from "../components/MediaPlanner";

function MediaPlannerPage() {
  return (
    <>
      <MediaPlanner />
    </>
  );
}

export default MediaPlannerPage;
