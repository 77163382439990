import Ord from "../components/Ord";

function OrdPage() {
  return (
    <>
      <Ord />
    </>
  );
}

export default OrdPage;