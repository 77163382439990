import React, { useState, useContext } from "react";
import Info from "../assets/images/info.svg";
import { TokenVerificationLoggedin, ScreenView, BlockOnlySA } from "../utils/tokenVerify";
import axios from "axios";
import configData from "../config/config.json";
import { ToastContainer, toast } from "react-toastify";
import TableUserMaster from "./TableUserMaster";
import Cookies from 'js-cookie';
import Tooltip from "react-bootstrap/Tooltip";
import jwt_decode from "jwt-decode";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Dropdown } from "react-bootstrap";
import { LoadingContext } from "react-router-loading";
import Select from "react-select";
import { Row, Col, Form, InputGroup, Table, Button, Modal } from 'react-bootstrap'
import gtalkLogo from "../assets/images/logos_gtalk.svg";
import whatsappLogo from "../assets/images/logos_whatsapp.svg";
import DeleteInfo from "../assets/images/delete_info.svg";
import telegramLogo from "../assets/images/logos_telegram.svg";
import skypeLogo from "../assets/images/logos_skype.svg";

const ListUserMaster = () => {
  const loadingContext = useContext(LoadingContext);

  const notifysuccess = (msg) => toast.success(msg, { autoClose: 1000 });

  const notifyfailure = (msg) => toast.error(msg, { autoClose: 1000 });

  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 2000 });


  const [active, setActive] = useState(true);
  const [checkRole, setCheckRole] = useState("RM");
  const [editId, setEditId] = useState("");


  var arr;

  //table header and row creation
  const [settingHeader, setSettingHeader] = useState([]);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);


  // loader
  const [isLoading, setIsLoading] = useState(true);


  React.useEffect(() => {
    TokenVerificationLoggedin();
    ScreenView();
    BlockOnlySA();



    getData();

    loadingContext.done()

  }, [active]);

  const getData = () => {
    let token = Cookies.get("token_moment");
    arr = [];

    axios
      .get(configData.SERVER_URL + `user/allUserList`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((response) => {
        const data = response.data.data[0];
        const dataValue = response.data.data;

        setData(dataValue);
        setInitialData(dataValue[0]);

        let status = Cookies.get("token_moment") ? true : false;
        if (status) {

          let token = Cookies.get("token_moment");
          let role = jwt_decode(token).role;

          setCheckRole(role)

          if (role !== "RM") {
            arr.push({
              Header: "Actions",
              accessor: "Actions",
              Cell: (cell) => (
                <>
                  {((role === "M" && (cell.row.original.role === "RM" || cell.row.original.role === "M")) ||
                    role === "A" || role === "MASTER") ?
                    <>


                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> Edit </Tooltip>}>
                 
                      <svg width="16" height="16" class="edit_hover me-10" onClick={(e) => {

                        setEditId(cell.row.original._id)
                        setFullName(cell.row.original["Full Name"])
                        setEmail(cell.row.original.email)
                        setPassword(cell.row.original.password)

                          if (cell.row.original.IM.length > 0) {

                            setSelectedIm({
                              value: cell.row.original["IM"]?.split(
                                ":"
                              )[0],
                              label:
                                cell.row.original["IM"]?.split(
                                  ":"
                                )[0].charAt(0).toUpperCase() + cell.row.original["IM"]?.split(
                                  ":"
                                )[0].slice(1)
                              ,
                            });
                            setIm(cell.row.original["IM"]?.split(
                              ":"
                            )[1])
                          }


                          if (cell.row.original["role"]?.split(
                            "-"
                          )[0] === "A") {
                            setSelectedRole({
                              value: "Admin",
                              label: "Admin",
                            });

                          }
                          else if (cell.row.original["role"]?.split(
                            "-"
                          )[0] === "M") {
                            setSelectedRole({
                              value: "Manager",
                              label: "Manager",
                            });

                          }
                          else {
                            setSelectedRole({
                              value: "Reporting Manager",
                              label: "Reporting Manager",
                            });
                          }



                          handleShowModal()

                          }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.33333 12.6667H4.26667L10.0167 6.91671L9.08333 5.98337L3.33333 11.7334V12.6667ZM12.8667 5.95004L10.0333 3.15004L10.9667 2.21671C11.2222 1.96115 11.5362 1.83337 11.9087 1.83337C12.2807 1.83337 12.5944 1.96115 12.85 2.21671L13.7833 3.15004C14.0389 3.4056 14.1722 3.71404 14.1833 4.07537C14.1944 4.43626 14.0722 4.74449 13.8167 5.00004L12.8667 5.95004ZM2.66667 14C2.47778 14 2.31956 13.936 2.192 13.808C2.064 13.6805 2 13.5223 2 13.3334V11.45C2 11.3612 2.01667 11.2752 2.05 11.192C2.08333 11.1085 2.13333 11.0334 2.2 10.9667L9.06667 4.10004L11.9 6.93337L5.03333 13.8C4.96667 13.8667 4.89178 13.9167 4.80867 13.95C4.72511 13.9834 4.63889 14 4.55 14H2.66667ZM9.55 6.45004L9.08333 5.98337L10.0167 6.91671L9.55 6.45004Z" fill="#BDBDBD"></path>
                      </svg>


                      
                </OverlayTrigger>
                      

                <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> Delete </Tooltip>}>
                      <svg class="delete_icon_svg" width="16" height="16" onClick={(e) => {

                        setUserDeleteId(cell.row.original._id)
                        setUserDeleteName(cell.row.original["Full Name"])
                        setShowModalDelete(true)

                      }
                      } viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.66602 14C4.29935 14 3.98557 13.8696 3.72468 13.6087C3.46335 13.3473 3.33268 13.0333 3.33268 12.6667V4C3.14379 4 2.98535 3.93622 2.85735 3.80867C2.72979 3.68067 2.66602 3.52222 2.66602 3.33333C2.66602 3.14444 2.72979 2.986 2.85735 2.858C2.98535 2.73044 3.14379 2.66667 3.33268 2.66667H5.99935C5.99935 2.47778 6.06335 2.31933 6.19135 2.19133C6.3189 2.06378 6.47713 2 6.66602 2H9.33268C9.52157 2 9.68002 2.06378 9.80802 2.19133C9.93557 2.31933 9.99935 2.47778 9.99935 2.66667H12.666C12.8549 2.66667 13.0131 2.73044 13.1407 2.858C13.2687 2.986 13.3327 3.14444 13.3327 3.33333C13.3327 3.52222 13.2687 3.68067 13.1407 3.80867C13.0131 3.93622 12.8549 4 12.666 4V12.6667C12.666 13.0333 12.5356 13.3473 12.2747 13.6087C12.0133 13.8696 11.6993 14 11.3327 14H4.66602ZM4.66602 4V12.6667H11.3327V4H4.66602ZM5.99935 10.6667C5.99935 10.8556 6.06335 11.0138 6.19135 11.1413C6.3189 11.2693 6.47713 11.3333 6.66602 11.3333C6.8549 11.3333 7.01335 11.2693 7.14135 11.1413C7.2689 11.0138 7.33268 10.8556 7.33268 10.6667V6C7.33268 5.81111 7.2689 5.65267 7.14135 5.52467C7.01335 5.39711 6.8549 5.33333 6.66602 5.33333C6.47713 5.33333 6.3189 5.39711 6.19135 5.52467C6.06335 5.65267 5.99935 5.81111 5.99935 6V10.6667ZM8.66602 10.6667C8.66602 10.8556 8.73002 11.0138 8.85802 11.1413C8.98557 11.2693 9.14379 11.3333 9.33268 11.3333C9.52157 11.3333 9.68002 11.2693 9.80802 11.1413C9.93557 11.0138 9.99935 10.8556 9.99935 10.6667V6C9.99935 5.81111 9.93557 5.65267 9.80802 5.52467C9.68002 5.39711 9.52157 5.33333 9.33268 5.33333C9.14379 5.33333 8.98557 5.39711 8.85802 5.52467C8.73002 5.65267 8.66602 5.81111 8.66602 6V10.6667ZM4.66602 4V12.6667V4Z" fill="#BDBDBD"></path></svg>
                    </OverlayTrigger>
                    </> : <></>


                  }
                </>


              ),
              ignoreRowClick: true,
              allowOverflow: true,
              button: true,
            })
          }
        }



        for (const [key] of Object.entries(data)) {

          if (key === "IM") {
            arr.push({
              Header: key,
              accessor: key,

              Cell: (cell) => {
                let input = cell.value.split(":");


                if (input[0].trim() === "whatsapp") {
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        {/* <i className="fas fa-brands fa-whatsapp space-lite mr-2">{" "}</i> */}
                        <img src={whatsappLogo} className="img-fluid im_image" alt="" />
                        <span className=""> {input[1]}</span>
                      </div>
                    </>
                  );
                } else if (input[0].trim() === "skype") {
                  return (
                    <>
                      <div className="d-flex">
                        {/* <i
                          className="fas fa-brands fa-skype mr-2 space-lite"
                          aria-hidden="true"
                        >
                          {" "}
                        </i> */}
                        <img src={skypeLogo} className="img-fluid im_image" alt="" />
                        <span> {input[1]}</span>
                      </div>
                    </>
                  );
                } else if (input[0].trim() === "telegram") {
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        {/* <i className="fas fa-brands fa-telegram mr-2 space-lite">{" "}</i> */}
                        <img src={telegramLogo} className="img-fluid im_image" alt="" />
                        <span className=""> {input[1]}</span>
                      </div>
                    </>
                  );
                } else if (input[0].trim() === "gtalk") {
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        {/* <i className="fas fa-brands fa-google mr-2 space-lite"></i> */}
                        <img src={gtalkLogo} className="img-fluid im_image" alt="" />
                        <span className=""> {input[1]}</span>
                      </div>
                    </>
                  );
                }
              },
            });
          }

          else if (key === "role") {
            arr.push({
              Header: key,
              accessor: key,
              Cell: (cell) => (
                <>
                  {cell.row.original.role === "M" ? "Manager" :
                    cell.row.original.role === "A" ? "Admin" :
                      cell.row.original.role === "RM" ? "Reporting Manager" :
                        "Master"

                  }
                </>
              ),
              ignoreRowClick: true,
              allowOverflow: true,
              button: true,
            });
          }

          else {


            arr.push({
              Header: key,
              accessor: key,
            });
          }
        }

        setSettingHeader(arr);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleDataReceived = (data) => {
    setShowModal(true);
  };

  const handleButtonClick = (id, name) => {
    // handleDownload(id, name);
  };




  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////// modal related code to add user//////////////////////////////

  // modal related code
  const [showModal, setShowModal] = useState(false);

  const [advertiserIdUserCreate, setAdvertiserIdUserCreate] = useState("");
  const handleShowModal = () => setShowModal(!showModal);

  const [fullName, setFullName] = useState("");
  const [fullNameIsRequiredError, setFullNameIsRequiredError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailIsRequiredError, setEmailIsRequiredError] = useState(false);
  const [emailUrlError, setEmailUrlError] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordIsRequiredError, setPasswordIsRequiredError] = useState(false);
  const [passwordStrengthError, setPasswordStrengthError] = useState(false);

  const [selectedRole, setSelectedRole] = useState([]);
  const [roleIsRequiredError, setRoleIsRequiredError] = useState(false);

  const [selectedIm, setSelectedIm] = useState({
    value: "telegram",
    label: "Telegram",
  });

  const [im, setIm] = useState("");
  // const [imIsRequiredError, setImIsRequiredError] = useState(false);

  const [emailToggle, setEmailToggle] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [buttonValue, setButtonValue] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function onChangeFullName(event) {
    setFullNameIsRequiredError(false);
    setFullName(event.target.value);
  }

  function onBlurFullName(event) {
    if (event.target.value.length === 0) {
      setFullNameIsRequiredError(true);
    }
  }

  function onChangeEmail(event) {
    setEmailIsRequiredError(false);
    setEmail(event.target.value);

    if (validateEmail(event.target.value) === null) {
      setEmailUrlError(true);
    } else {
      setEmailUrlError(false);
    }
  }

  function onBlurEmail(event) {
    if (event.target.value.length === 0) {
      setEmailUrlError(false);
      setEmailIsRequiredError(true);
    }
  }

  function onChangePassword(event) {
    setPasswordIsRequiredError(false);
    setPassword(event.target.value);

    if (event.target.value.length >= 8) {
      setPasswordStrengthError(false);
    } else {
      setPasswordStrengthError(true);
    }
  }

  function onBlurPassword(event) {
    if (event.target.value.length === 0) {
      setPasswordStrengthError(false);

      setPasswordIsRequiredError(true);
    }
  }

  function onChangeSelectedRole(event) {
    if (event.length !== 0) {
      setRoleIsRequiredError(false);
    }

    setSelectedRole(event);
  }

  function onChangeSelectedIm(event) {
    setSelectedIm(event);
  }

  function onChangeIm(event) {
    // setImIsRequiredError(false);
    setIm(event.target.value);
  }

  // function onBlurIm(event) {
  //   if (event.target.value.length === 0) {
  //     setImIsRequiredError(true);
  //   }
  // }

  function handleCreateRole() {
    if (
      fullName !== "" &&
      email !== "" &&
      password !== "" &&
      selectedRole.length !== 0
      // &&
      // im !== ""
    ) {
      if (
        fullNameIsRequiredError === false &&
        emailIsRequiredError === false &&
        passwordIsRequiredError === false &&
        emailUrlError === false &&
        roleIsRequiredError === false
        // &&
        // imIsRequiredError === false
      ) {
        let payload = {
          fullName: fullName,
          email: email,
          password: password,
          selectedIm: selectedIm.value,

          im: im,
          role: selectedRole,
          // notes: notes,
          emailNotification: emailToggle,
        };


        let status = Cookies.get("token_moment") ? true : false;
        if (status) {

          let token = Cookies.get("token_moment");
          let aid = jwt_decode(token).aid;

          setButtonValue(true);

          if (editId !== "") {
            axios
              .post(
                configData.SERVER_URL + `user/editUser?_id=` + editId,
                payload,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                  },
                }
              )
              .then((response) => {
                if (response.data.status === 200) {

                  setActive(!active)
                  notifysuccess(response.data.message);



                  setEditId("")
                  setAdvertiserIdUserCreate("");
                  setFullName("");
                  setEmail("");
                  setPassword("");
                  setIm("");
                  setSelectedRole([]);

                  setSelectedIm({
                    value: "telegram",
                    label: "Telegram",
                  });
                  setEmailToggle(false);
                  setEmailToggle(false);
                  setEmailToggle(false);
                  setFullNameIsRequiredError(false);
                  setEmailIsRequiredError(false);
                  setEmailUrlError(false);
                  setPasswordIsRequiredError(false);
                  setPasswordStrengthError(false);
                  setRoleIsRequiredError(false);
                  setButtonValue(false);
                  setShowModal(false)
                  // setImIsRequiredError(false);
                  // setTimeout(() => {
                  //   setButtonValue(false);
                  //   window.location.reload();
                  // }, 1500);
                } else {
                  notifyfailure(response.data.message);
                  setButtonValue(false);
                }
              })
              .catch((error) => {
                notifycatch();
                setButtonValue(false);
              });
          }
          else {
            axios
              .post(
                configData.SERVER_URL +
                "user/createUser?aid=" +
                aid,
                payload,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                  },
                }
              )
              .then((response) => {
                if (response.data.status === 201) {
                  setActive(!active)
                  notifysuccess(response.data.message);
                  setEditId("")
                  setAdvertiserIdUserCreate("");
                  setFullName("");
                  setEmail("");
                  setPassword("");
                  setIm("");
                  setSelectedRole([]);

                  setSelectedIm({
                    value: "telegram",
                    label: "Telegram",
                  });
                  setEmailToggle(false);
                  setEmailToggle(false);
                  setEmailToggle(false);
                  setFullNameIsRequiredError(false);
                  setEmailIsRequiredError(false);
                  setEmailUrlError(false);
                  setPasswordIsRequiredError(false);
                  setPasswordStrengthError(false);
                  setRoleIsRequiredError(false);
                  setButtonValue(false);
                  setShowModal(false)
                  // setTimeout(() => {
                  //   setButtonValue(false);
                  //   window.location.reload();
                  // }, 1500);
                } else {
                  notifyfailure(response.data.message);
                  setButtonValue(false);
                }
              })
              .catch((error) => {
                notifycatch();
                setButtonValue(false);
              });
          }



        }
      }
    } else {
      if (fullName === "") {
        setFullNameIsRequiredError(true);
      }
      if (email === "") {
        setEmailIsRequiredError(true);
      }
      if (password === "") {
        setPasswordIsRequiredError(true);
      }
      if (selectedRole.length === 0) {
        setRoleIsRequiredError(true);
      }
      // if (im === "") {
      //   setImIsRequiredError(true);
      // }
    }
  }

  const optionListIM = [
    { value: "telegram", label: "Telegram" },
    { value: "whatsapp", label: "Whatsapp" },
    { value: "skype", label: "Skype" },
    { value: "gtalk", label: "Gtalk" },
  ];

  const optionListRole = [
    { value: "Admin", label: "Admin" },
    { value: "Manager", label: "Manager" },
    { value: "Reporting Manager", label: "Reporting Manager" },
  ];

  const optionListRoleManager = [
    { value: "Manager", label: "Manager" },
    { value: "Reporting Manager", label: "Reporting Manager" },
  ];

  const optionListRoleReportingManager = [
    { value: "Reporting Manager", label: "Reporting Manager" },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  /////modal confirm to delete code starts //////////////

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [userDeleteId, setUserDeleteId] = useState("");
  const [userDeleteName, setUserDeleteName] = useState("");
  const [userDeleteButton, setUserDeleteButton] = useState(false);
  const handleShowModalDelete = () => setShowModalDelete(!showModalDelete);



  /////modal confirm to delete code ends///////////////




  return (
    <>
      {/* <ToastContainer /> */}
      <div className="main_contain_area">
        <div className="mb-4">
          <div className="table_area_spacing">
            <TableUserMaster
              columns={settingHeader}
              data={data}
              loading={isLoading}
              initial={initialData}
              onDataReceived={handleDataReceived}
              role={checkRole}
            />
          </div>
        </div>

      </div>




      <Modal className="custom_modal_design" show={showModal} size="lg" centered>
        <Modal.Header>
          <Modal.Title> User {" "} </Modal.Title>
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            onClick={() => {
              handleShowModal();
              setEditId("")
              setAdvertiserIdUserCreate("");
              setFullName("");
              setEmail("");
              setPassword("");
              setIm("");
              setSelectedRole([]);

              setSelectedIm({
                value: "telegram",
                label: "Telegram",
              });
              setEmailToggle(false);
              setEmailToggle(false);
              setEmailToggle(false);
              setFullNameIsRequiredError(false);
              setEmailIsRequiredError(false);
              setEmailUrlError(false);
              setPasswordIsRequiredError(false);
              setPasswordStrengthError(false);
              setRoleIsRequiredError(false);
              // setImIsRequiredError(false);
            }}
          >
          </button>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col md={6} className="">
                <label for="validationCustom02">
                  {" "}
                  Full Name <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="role_fullname"
                  name="role_fullname"
                  value={fullName}
                  onBlur={onBlurFullName}
                  onChange={onChangeFullName}
                  placeholder="Full Name"
                ></input>
                {fullNameIsRequiredError && (
                  <span className="alert_text">

                    This field is required
                  </span>
                )}
              </Col>
              <Col md={6} className="">
                <label for="validationCustom02">
                  {" "}
                  Email <span className="text-danger">*</span>
                </label>

                <input
                  type="email"
                  className="form-control"
                  id="role_email"
                  name="role_email"
                  value={email}
                  onBlur={onBlurEmail}
                  onChange={onChangeEmail}
                  placeholder="Email Address"
                  disabled={editId !== "" ? true : false}
                ></input>
                {emailIsRequiredError && (
                  <span className="alert_text">

                    This field is required
                  </span>
                )}

                {emailUrlError && (
                  <span className="alert_text">Invalid Email Address</span>
                )}
              </Col>
              <Col md={6} className="mt-20">
                <label for="validationCustom02">
                  {" "}
                  Password <span className="text-danger">*</span>
                </label>

                <div className="position-relative">
                  <InputGroup className="">
                    <Form.Control
                      type={passwordVisibility ? "text" : "password"}
                      className="form-control"
                      id="role_password"
                      name="role_password"
                      value={password}
                      onBlur={onBlurPassword}
                      onChange={onChangePassword}
                      placeholder="Password"
                      disabled={editId !== "" ? true : false}
                    />
                    {editId === "" ?
                      <InputGroup.Text id="basic-addon1" className="p-0 bg-transparent">
                        <button className="visible-btn btn"
                          onClick={() => {
                            setPasswordVisibility(!passwordVisibility);
                          }}

                        >
                          {!passwordVisibility && <i className="fa-solid fa-eye"></i>}
                          {passwordVisibility && (<i className="fa-solid fa-eye-slash"></i>)}
                        </button>
                      </InputGroup.Text> : <></>}
                  </InputGroup>

                </div>



                {passwordIsRequiredError && (
                  <span className="alert_text">

                    This field is required
                  </span>
                )}
                {passwordStrengthError && (
                  <span className="alert_text">
                    Password should be of minimum 8 characters in length
                  </span>
                )}
              </Col>
              <Col md={6} className="mt-20">
                <label for="validationCustom02">
                  {" "}
                  Role <span className="text-danger">*</span>
                </label>

                <Select
                  className="custom_drop_design select-custom p-0"
                  options={checkRole === "M" ? optionListRoleManager : checkRole === "RM" ? optionListRoleReportingManager : optionListRole}
                  placeholder="-Select-"
                  isSearchable={true}
                  value={selectedRole}
                  onChange={onChangeSelectedRole}
                  isDisabled={editId !== "" ? true : false}
                ></Select>
                {roleIsRequiredError && (
                  <span className="alert_text">

                    This field is required
                  </span>
                )}
              </Col>
              <Col md={12} className="mt-20">
                <Form.Label>IM</Form.Label>
                <div className="">
                  <InputGroup className="joined_input_fields">
                    <Select
                      className="custom_drop_design select-custom p-0"
                      options={optionListIM}
                      placeholder="-Select-" value={selectedIm} onChange={onChangeSelectedIm} />
                    <input className="form-control" id="validationCustom05" type="text" placeholder="IM" value={im} onChange={onChangeIm} />
                  </InputGroup>
                </div>
                {/* {imIsRequiredError && (
                  <span className="alert_text">
                    
                    This field is required
                  </span>
                )} */}
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center justify-content-between w-100">
      

            {editId !== "" ? <div className="form-check">

            </div> :
            <div className="form-check">

            </div>
            
            // <div className="form-check">
            //   <Form.Check type="checkbox" className="" id="exampleCheck1" checked={emailToggle} onClick={() => { setEmailToggle(!emailToggle); }} />
            //   <label className="form-check-label m-0 o-0" for="exampleCheck1">Send Login Credentials to This Email</label>
            // </div>

            }

            <div>
              {!buttonValue && (
                <button variant="secondary" className="default_btn btn"
                  onClick={handleCreateRole}
                >
                  {editId !== "" ? <>Update</> : <>Create</>}

                </button>
              )}
              {buttonValue && (
                <button variant="secondary" className="default_btn btn">
                  Processing
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal className="custom_modal_design delete_modal_popup_design" show={showModalDelete} size="" centered>
        <Modal.Body>
          <div className="text-center">
            <Button
              type="button"
              className="btn user_delete_modal"
              data-dismiss="modal"
              onClick={() => {
                setUserDeleteId("")
                setUserDeleteName("")
                setShowModalDelete(false)
              }}
            >
            </Button>

            <img src={DeleteInfo} className="img-fluid" alt="" />
            <h2>Are you Sure? </h2>
            <p>Are you sure you want to delete the user <strong>"{userDeleteName}"?</strong></p>

            <Button type="button" className="btn border_cancel_btn" onClick={() => {
              setUserDeleteId("")
              setUserDeleteName("")
              setShowModalDelete(false)
            }}> Cancel
            </Button>

            {!userDeleteButton &&
              <Button type="button" className="btn fill_confirm_btn" onClick={() => {
                setUserDeleteButton(true)
                let token = Cookies.get("token_moment");
                axios
                  .post(
                    configData.SERVER_URL + `user/deleteUser?_id=` + userDeleteId,
                    {},

                    {
                      headers: {
                        "Content-Type": "application/json",
                        "x-access-token": token,
                      },
                    }
                  )
                  .then((response) => {
                    if (response.data.status === 200) {
                      notifysuccess(response.data.message);
                      setUserDeleteId("")
                      setUserDeleteName("")
                      setShowModalDelete(false)
                      setActive(!active);
                    }
                    else {
                      notifyfailure(response.data.message);
                    }

                    setUserDeleteButton(false)
                  })
                  .catch((error) => {
                    notifycatch();

                    setUserDeleteButton(false)

                  });
              }}>
                Confirm
              </Button>
            }

            {userDeleteButton &&
              <Button type="button" className="btn fill_confirm_btn" onClick={() => {

              }}>
                Processing
              </Button>
            }


          </div>
        </Modal.Body>

      </Modal>

    </>
  );
};

export default ListUserMaster;
