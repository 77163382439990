function Loader() {
  return (
    <div className="loader-table report_loader text-center table_border_radius">
      <table
        style={{ opacity: "0.5", cellspacing: "0", cellpadding: "0" }}
        className="loading table align-middle table-striped table-theme dataTable dtfc-has-left custom_data_table table" 
      >
        <thead>
          <tr>
            <th style={{ width: "20rem" }} className="sorting_1 dtfc-fixed-left">
              <span></span>
            </th>
            <th style={{ width: "20rem" }}>
              <span></span>
            </th>
            <th style={{ width: "20rem" }}>
              <span></span>
            </th>
            <th style={{ width: "20rem" }}>
              <span></span>
            </th>
            <th style={{ width: "20rem" }}>
              <span></span>
            </th>
            <th style={{ width: "20rem" }}>
              <span></span>
            </th>
            <th style={{ width: "20rem" }}>
              <span></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
          </tr>
          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
          </tr>

          <tr>
            <td className="sorting_1 dtfc-fixed-left">
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
            <td>
              <span style={{ opacity: "0.3" }}></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Loader;
