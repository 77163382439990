import React from "react";
import { Routes, Route } from "react-router-loading";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/login.css";
import "../src/styles/createaccount.css";
import "../src/styles/createnewcampaign.css";
import "../src/styles/headersidebar.css";
import "../src/styles/paymentpage.css";
import "../src/styles/adminiplist.css";
import "../src/styles/campaign.css";
import "../src/styles/tablefund.css";
import "../src/styles/tabledesigns.css";
import "../src/styles/report.css";
import "../src/styles/editcampaign.css";
import "../src/styles/mediaplanner.css";
import "../src/styles/dashboard.css";
import "../src/styles/pagination.css";
import "../src/styles/safari.css";
import "../src/styles/newsidebar.css";
import "../src/styles/customdatepicker.css";
import "../src/styles/newcreative.css";
import "../src/styles/checkboxfilter.css";
import "../src/styles/newreport.css";
import "../src/styles/customcolspace.css";
import "../src/styles/orduploader.css";
import "../src/styles/newmediaplanner.css";
import "../src/styles/responsive.css";


import {
  Header,
  // Test,
  // Ord,
  NoPageComponent
} from "./components";

import {
  // before login pages
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  ResetPasswordPage,

  // master specific
  CreateAdvertiserPage,
  AddFundPage,
  AddFundAdvertiserPage,
  ListUserMasterPage,

  //common for both admin and master
  DashboardPage,
  EditAdvertiserPage,

  ListDomainPage,
  ListIpPage,
  ListFundPage,

  CampaignCreationPage,
  ListCampaignPage,
  EditCampaignPage,
  CampaignClonningPage,

  ReportingPage,
  MediaPlannerPage,
  BlsFormPage,
  BlsFormViewEditPage,
  ListBlsFormPage,


  // super admin specific pages
  ListAdvertiserPage,
  CreateAdvertiserAdminPage,
  ListUserPage,
  AdLabelingPage,
  ReconciliationReportPage,
  OrdPage


} from "./pages";


import NewSideBar from "./components/NewSideBar";
import NewMediaPlanner from "./components/NewMediaPlanner";
// import NewTableDesign from "./components/NewTableDesign";


function App() {

  return (
    <div className="App">

      {/*   
    <Header />  */}
      <NewSideBar />
      <Routes>
        {/* <Route path="/newsidebar" element={<NewSideBar />} /> */}

        {/* Before Login Routes */}

        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={< LoginPage />} />
        <Route path="/register" element={< RegisterPage />} />
        <Route path="/forgotpassword" element={< ForgotPasswordPage />} />
        <Route path="/resetpassword" element={< ResetPasswordPage />} />

        {/* Master Specific Routes */}
        <Route path="/createAdvertiser" element={<CreateAdvertiserPage />} loading />
        <Route path="/addFund" element={<AddFundPage />} loading />
        <Route path="/addFundAdvertiser" element={<AddFundAdvertiserPage />} loading />
        <Route path="/listRoles" element={<ListUserMasterPage />} loading />

        {/* Super Admin Specific Routes */}
        <Route path="/listAdvertiser" element={<ListAdvertiserPage />} loading />
        <Route path="/createAdvertiserAdmin" element={<CreateAdvertiserAdminPage />} loading />
        <Route path="/listUser" element={<ListUserPage />} loading />
        {/* <Route path="/reconciliationReport" element={<ReconciliationReportPage />}  loading /> */}
        {/* <Route path="/adLabeling" element={<AdLabelingPage />}  loading /> */}
        <Route path="/ord" element={<OrdPage />} loading />

        {/* Common for both Master and Admin Routes */}
        <Route path="/editAdvertiser" element={<EditAdvertiserPage />} loading />
        <Route path="/campaignCreation" element={<CampaignCreationPage />} loading />
        <Route path="/editCampaign" element={<EditCampaignPage />} loading />
        <Route path="/campaignClone" element={<CampaignClonningPage />} loading />
        <Route path="/listIp" element={<ListIpPage />} loading />
        <Route path="/listDomain" element={<ListDomainPage />} loading />
        <Route path="/reporting" element={<ReportingPage />} loading />
        <Route path="/mediaPlanner" element={<MediaPlannerPage />} loading />

        <Route path="/listFund" element={<ListFundPage />} loading />
        <Route path="/listCampaign" element={<ListCampaignPage />} loading />
        <Route path="/dashboard" element={<DashboardPage />} loading />

        {/* <Route path="/newtable" element={<NewTableDesign />} /> */}

        {/* <Route path="/mediaPlannerNew" element={<NewMediaPlanner />} /> */}
        <Route path="/liftStudy" element={<BlsFormPage /> } loading />
        <Route path="/editLiftStudy" element={<BlsFormViewEditPage /> } loading />
        <Route path="/listLiftStudy" element={<ListBlsFormPage /> } loading />

        <Route path="*" element={<NoPageComponent />} />



      </Routes>


    </div>
  );
}

export default App;

