import CampaignClonning from "../components/CampaignClonning";
import { useLocation } from "react-router-dom";

function CampaignClonningPage() {
  const search = useLocation().search;
  const campaignId = new URLSearchParams(search).get("campaignId");
  const iscreativeParam = new URLSearchParams(search).get("creative");

  return (
    <>
      <CampaignClonning campaignId={campaignId} isCreativeParam={iscreativeParam} />
    </>
  );
}

export default CampaignClonningPage;