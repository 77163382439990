// import ListCampaign from "../components/ListCampaign"
import ListCampaign from "../components/ListCampaign";

function ListCampaignPage() {
  return (
    <>
      <ListCampaign />
    </>
  );
}

export default ListCampaignPage;
