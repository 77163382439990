import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/images/xaprio_logo_new.svg";
import SlideOne from "../assets/images/slide_one.svg";
import SlideTwo from "../assets/images/slide_two.svg";
import SlideThree from "../assets/images/slide_three.svg";
import SlideFour from "../assets/images/slide_four.svg";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Lock from "../assets/images/Password.svg";
import PassEye from "../assets/images/password_eye.svg";
import PassEyeOff from "../assets/images/password_eye_off.svg";
import Mail from "../assets/images/mail.svg";
import User from "../assets/images/account.svg";
import { LoadingOutlined } from '@ant-design/icons'
import Cname from "../assets/images/work_history.svg";
import CompanyBlue from "../assets/images/companyblue.svg";
import AccountBlue from "../assets/images/accountblue.svg";
import MailBlue from "../assets/images/mailblue.svg";
import LockBlue from "../assets/images/passwordblue.svg";
import { Carousel } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import configData from "../config/config.json";
import { TokenVerificationLoggedOut } from "../utils/tokenVerify";
// import { LoadingContext } from "react-router-loading";

const contentStyle = {
  margin: 0,
  height: "auto",
  textAlign: "center",
};

const Register = () => {
  // const loadingContext = useContext(LoadingContext);

  React.useEffect(() => {
    TokenVerificationLoggedOut();
    // loadingContext.done();
  });

  const notifysuccess = (msg) => toast.success(msg, { autoClose: 1500 });
  const notifyfailure = (msg) => toast.error(msg, { autoClose: 1500 });
  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 1500 });

  const [companyName, setComapanyName] = useState(null);
  const [companyNameIsRequiredError, setCompanyNameIsRequiredError] =
    useState(false);

  const [fullName, setFullName] = useState(null);
  const [fullNameIsRequiredError, setFullNameIsRequiredError] = useState(false);
  const [fullNameValidError, setFullNameValidError] = useState(false);

  const [email, setEmail] = useState(null);
  const [emailIsRequiredError, setEmailIsRequiredError] = useState(false);
  const [emailUrlError, setEmailUrlError] = useState(false);

  const [password, setPassword] = useState(null);
  const [passwordIsRequiredError, setPasswordIsRequiredError] = useState(false);
  const [passwordStrengthError, setPasswordStrengthError] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState(null);
  const [confirmPasswordIsRequiredError, setConfirmPasswordIsRequiredError] =
    useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const [buttonValue, setButtonValue] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const regexName = /^[a-zA-Z0-9_]{3,30}$/

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  function onChangeCompanyName(event) {
    setCompanyNameIsRequiredError(false);
    setComapanyName(event.target.value);
  }

  function onBlurCompanyName(event) {
    if (event.target.value.length === 0) {
      setCompanyNameIsRequiredError(true);
    }
  }

  function onChangeFullName(event) {
    setFullNameIsRequiredError(false);

    // if (regexName.test(event.target.value)) {
    //   setFullNameValidError(false)

    // }
    // else {
    //   setFullNameValidError(true)
    // }

    setFullName(event.target.value);
  }

  function onBlurFullName(event) {
    if (event.target.value.length === 0) {
      // setFullNameValidError(false)
      setFullNameIsRequiredError(true);
    }
  }

  function onChangeEmail(event) {
    setEmailIsRequiredError(false);
    setEmail(event.target.value);

    if (validateEmail(event.target.value) === null) {
      setEmailUrlError(true);
    } else {
      setEmailUrlError(false);
    }
  }

  function onBlurEmail(event) {
    if (event.target.value.length === 0) {
      setEmailUrlError(false);
      setEmailIsRequiredError(true);
    }
  }

  function onChangePassword(event) {
    setPasswordIsRequiredError(false);
    setPassword(event.target.value);
    // const regex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

    // if(regex.test(event.target.value)){
    if (event.target.value.length >= 8) {
      setPasswordStrengthError(false);

      if (confirmPassword !== null) {
        if (event.target.value !== confirmPassword) {
          setPasswordMatchError(true);
        } else {
          setPasswordMatchError(false);
        }
      }
    } else {
      setPasswordStrengthError(true);
    }
  }

  function onBlurPassword(event) {
    if (event.target.value.length === 0) {
      setPasswordStrengthError(false);

      setPasswordIsRequiredError(true);
    }
  }

  function onChangeConfirmPassword(event) {
    setConfirmPasswordIsRequiredError(false);
    setConfirmPassword(event.target.value);

    if (event.target.value !== password) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  }

  function onBlurConfirmpassword(event) {
    if (event.target.value.length === 0) {
      setPasswordMatchError(false);
      setConfirmPasswordIsRequiredError(true);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      companyName !== null &&
      fullName !== null &&
      email !== null &&
      password !== null &&
      confirmPassword !== null
    ) {
      if (
        companyNameIsRequiredError === false &&
        fullNameIsRequiredError === false &&
        emailIsRequiredError === false &&
        passwordIsRequiredError === false &&
        confirmPasswordIsRequiredError === false &&
        emailUrlError === false &&
        passwordStrengthError === false &&
        passwordMatchError === false 
        // fullNameValidError === false
      ) {
        let payload = {
          email: email,
          fullName: fullName,
          companyName: companyName,
          password: password,
        };

        setButtonValue(true);
        axios
          .post(configData.SERVER_URL + "user/register", payload)
          .then((response) => {
            if (response.data.status === 201) {
              notifysuccess(response.data.message);
              setTimeout(() => {
                setButtonValue(false);
                window.location.replace("/login");
              }, 1500);
            } else {
              notifyfailure(response.data.message);
              setButtonValue(false);
            }
          })
          .catch((error) => {
            notifycatch();
            setButtonValue(false);
          });
      }
    } else {
      if (companyName === null) {
        setCompanyNameIsRequiredError(true);
      }

      if (fullName === null) {
        setFullNameIsRequiredError(true);
      }

      if (email === null) {
        setEmailIsRequiredError(true);
      }

      if (password === null) {
        setPasswordIsRequiredError(true);
      }

      if (confirmPassword === null) {
        setConfirmPasswordIsRequiredError(true);
      }
    }
  };

  // const onChange = (currentSlide) => {
  
  // };

  return (
    <>
      <ToastContainer />
      <div className="login_page">
        <div className="custom_container">
          <Row className="h-100">
            <Col md={6} className="col-md-6 h-100 d-flex align-items-center justify-content-center position-relative">
              <img src={Logo} className="img-fluid fixed_logo" alt="" />
              <div className="form_side_area">
                <div className="for_center_div">
                  <div className="form_fields">
                    <h1>Get Started Now</h1>
                    <p>Enter you credentials to see Xaprio in Action!</p>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>Full Name</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            className=""
                            aria-label="name"
                            aria-describedby="name"
                            value={fullName}
                            onBlur={onBlurFullName}
                            onChange={onChangeFullName}
                            placeholder="Enter your full Name"
                          />
                          {fullName?.length > 0 ? <img src={AccountBlue} className="img-fluid abso_element_left" alt="" /> : <img src={User} className="img-fluid abso_element_left" alt="" />}
                        </div>
                        {/* {fullNameValidError && (
                          <span className="alert_text">
                            Value must be alphanumeric or with underscore ranging between 3-30 characters.
                          </span>
                        )} */}
                        {fullNameIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            aria-label="email"
                            className=""
                            aria-describedby="email"
                            value={email}
                            onBlur={onBlurEmail}
                            onChange={onChangeEmail}
                            placeholder="Enter your email"
                          />
                          {email?.length > 0 ? <img src={MailBlue} className="img-fluid abso_element_left" alt="" /> : <img src={Mail} className="img-fluid abso_element_left" alt="" />}
                        </div>
                        {emailIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}

                        {emailUrlError && (
                          <span className="alert_text">
                            Invalid Email Address
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Company's Name</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            aria-label="companyname"
                            className=""
                            aria-describedby="companyname"
                            value={companyName}
                            onBlur={onBlurCompanyName}
                            onChange={onChangeCompanyName}
                            placeholder="Enter your company Name"
                          />

                          {companyName?.length > 0 ? <img src={CompanyBlue} className="img-fluid abso_element_left" alt="" /> : <img src={Cname} className="img-fluid abso_element_left" alt="" />}
                        </div>

                        {companyNameIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type="password"
                            aria-label="password"
                            className=""
                            aria-describedby="password"
                            value={password}
                            onBlur={onBlurPassword}
                            onChange={onChangePassword}
                            placeholder="Enter Password"
                          />
                          {password?.length > 0 ? <img src={LockBlue} className="img-fluid abso_element_left" alt="" /> : <img src={Lock} className="img-fluid abso_element_left" alt="" />}
                        </div>
                        {passwordIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}
                        {passwordStrengthError && (
                          <span className="alert_text">
                            Password should be of minimum 8 characters in length
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="">
                        <Form.Label>Re-type Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type={passwordVisibility ? "text" : "password"}
                            aria-label="cpassword"
                            className=""
                            aria-describedby="cpassword"
                            value={confirmPassword}
                            onBlur={onBlurConfirmpassword}
                            onChange={onChangeConfirmPassword}
                            placeholder="Confirm Password"
                          />
                         {passwordVisibility &&  <img src={PassEye} className="img-fluid eye_abso_element_left abso_element_left" alt=""  onClick={() => {
                          setPasswordVisibility(!passwordVisibility);
                        }}/>}
                        {!passwordVisibility &&  <img src={PassEyeOff} className="img-fluid eye_abso_element_left abso_element_left" alt=""  onClick={() => {
                          setPasswordVisibility(!passwordVisibility);
                        }}/>}
                          {confirmPassword?.length > 0 ? <img src={LockBlue} className="img-fluid abso_element_left" alt="" /> : <img src={Lock} className="img-fluid abso_element_left" alt="" />}

                        </div>

                        {confirmPasswordIsRequiredError && (
                          <span className="alert_text">
                            This field is required
                          </span>
                        )}

                        {passwordMatchError && (
                          <span className="alert_text">
                            Password does not match.
                          </span>
                        )}
                      </Form.Group>

                      {!buttonValue && (
                        <Button
                          type="button"
                          className="btn btn_button"
                          onClick={handleSubmit}
                        >
                          Sign Up
                        </Button>
                      )}
                      {buttonValue && (
                        <Button type="button" className="btn btn_button">
                          Signing Up... <LoadingOutlined className="ms-2" />
                        </Button>
                      )}
                      <span className="sign_up_text">
                        Already have an account?{" "}
                        <Link to="/login" className="text-decoration-underline hyper_link_clr">
                          Sign In
                        </Link>
                      </span>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="">
              <div className="d-flex align-items-center justify-content-center h-100">
                <div className="login_img_area">
                  <Carousel autoplay dots={false} effect="fade">
                    {/* <Carousel autoplay  afterChange={onChange}> */}
                    <div>
                      <div style={contentStyle}>
                        <img src={SlideOne} className="img-fluid" alt="" />
                        <p>
                          Take your campaign performance to the next level with
                          our{" "}
                          <span className="text-decoration-none hyper_text_link_clr"> real-time triggers. </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div style={contentStyle}>
                        <img src={SlideTwo} className="img-fluid" alt="" />
                        <p>
                          Take your campaign performance to the next level with
                          our{" "}
                          <a
                            href="/"
                            className="text-decoration-none hyper_text_link_clr"
                          >
                            real-time triggers.
                          </a>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div style={contentStyle}>
                        <img src={SlideThree} className="img-fluid" alt="" />
                        <p>
                          Take your campaign performance to the next level with
                          our{" "}
                          <a
                            href="/"
                            className="text-decoration-none hyper_text_link_clr"
                          >
                            real-time triggers.
                          </a>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div style={contentStyle}>
                        <img src={SlideFour} className="img-fluid" alt="" />
                        <p>
                          Take your campaign performance to the next level with
                          our{" "}
                          <a
                            href="/"
                            className="text-decoration-none hyper_text_link_clr"
                          >
                            real-time triggers.
                          </a>
                        </p>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Register;
