import EditCampaign from "../components/EditCampaign";
import { useLocation } from "react-router-dom";

function EditCampaignPage() {
  const search = useLocation().search;
  const campaignId = new URLSearchParams(search).get("campaignId");
  const iscreativeParam = new URLSearchParams(search).get("creative");

  return (
    <>
      <EditCampaign campaignId={campaignId} isCreativeParam={iscreativeParam} />
    </>
  );
}

export default EditCampaignPage;
