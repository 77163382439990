import React, { useState, useContext } from "react";
import axios from "axios";
import configData from "../config/config.json";
import { TokenVerificationLoggedin, ScreenView } from "../utils/tokenVerify";
import TableFund from "./TableFund";
import { LoadingContext } from "react-router-loading";
import Badge from "react-bootstrap/Badge";
import Cookies from 'js-cookie';
import { ToastContainer } from "react-toastify";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CommentTooltip from "../assets/images/comment_tooltip.svg"


export default function ListFund() {
  const loadingContext = useContext(LoadingContext);

  var arr;

  //table header and row creation
  const [settingHeader, setSettingHeader] = useState([]);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  // loader
  const [isLoading, setIsLoading] = useState(true);

  function addCommasToNumber(num) {
    const isDecimal = num % 1 !== 0;
    let numStr = num.toString();
    let [wholePart, decimalPart] = numStr.split(".");
    wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (isDecimal) {
      numStr = `${wholePart}.${decimalPart}`;
    } else {
      numStr = wholePart;
    }

    return numStr;
  }

  React.useEffect(() => {
    TokenVerificationLoggedin();
    ScreenView();


    getData();
    loadingContext.done();
  }, []);



  const getData = () => {
    let token = Cookies.get("token_moment");
    arr = [];

    axios
      .get(configData.SERVER_URL + `funds/fundHistory`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((response) => {
        const data = response.data.data.result[0];
        const dataValue = response.data.data.result;

        setData(dataValue);
        setInitialData(dataValue[0]);

        for (const [key] of Object.entries(data)) {
          if (key === "Amount") {
            arr.push({
              Header: key,
              accessor: key,

              Cell: (cell) => {
                return (
                  <>
                    {/* <span>{cell.value}</span> */}
                    {cell.row.original["Currency"] === "Euro" && (
                      <span>€ {addCommasToNumber(cell.value)}</span>
                    )}

                    {cell.row.original["Currency"] === "Dollar" && (
                      <span>$ {addCommasToNumber(cell.value)}</span>
                    )}

                    {cell.row.original["Currency"] === "Turkish Lira" && (
                      <span>₺ {addCommasToNumber(cell.value)}</span>
                    )}

                    {cell.row.original["Currency"] === "Indonesian Rupiah" && (
                      <span>Rp {addCommasToNumber(cell.value)}</span>
                    )}

                    {cell.row.original["Currency"] === "Yen" && (
                      <span>¥ {addCommasToNumber(cell.value)}</span>
                    )}

                    {cell.row.original["Currency"] === "Thai Bhat" && (
                      <span>฿ {addCommasToNumber(cell.value)}</span>
                    )}
                    {cell.row.original["Currency"] === "Ruble" && (
                      <span>₽ {addCommasToNumber(cell.value)}</span>
                    )}
                  </>
                );
              },
            });
          } else if (key === "Status") {
            arr.push({
              Header: key,
              accessor: key,
              Cell: (cell) => {
                return (
                  <>
                    {cell.value === "Success" ? (
                      <Badge bg="success">{cell.value}</Badge>
                    ) : cell.value === "Cancelled" ? (
                      <Badge bg="danger">{cell.value}</Badge>
                    ) : (
                      <Badge bg="warning">{cell.value}</Badge>
                    )}
                  </>
                );
              },
            });
          }

          else if (key === "Comment") {
            arr.push({
              Header: key,
              accessor: key,

              Cell: (cell) => {
                return (
                  <>

                    {cell.row.original["Comment"]?.length !== 0 && cell.row.original["Comment"] !== null ? (

                      <>
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> {cell.value} </Tooltip>}>
                        <svg width="16" height="16" fill="none" className="user_comment_text" xmlns="http://www.w3.org/2000/svg"><path d="M3.088 2.027a1.997 1.997 0 0 0-1.694 1.519c-.044.188-.047.517-.047 5.401v5.2l.057.12c.11.231.364.424.562.426a.666.666 0 0 0 .2-.044c.063-.024.672-.46 1.354-.97.682-.509 1.27-.942 1.307-.962.055-.031.751-.039 4.106-.051l4.04-.014.204-.057a2.027 2.027 0 0 0 1.429-1.474c.044-.187.047-.448.047-3.788 0-3.339-.003-3.6-.047-3.787a2.027 2.027 0 0 0-1.429-1.474l-.204-.058L8.12 2.01c-2.669-.002-4.934.006-5.032.017m9.86 1.356c.134.061.323.272.358.4.037.132.037 6.967.001 7.099-.033.116-.18.293-.314.376l-.1.062-4.04.014c-4.038.014-4.04.014-4.243.071-.329.093-.531.215-1.25.754l-.68.51-.008-4.395c-.005-3.163.001-4.423.022-4.497.038-.13.221-.33.359-.394.102-.046.316-.048 4.947-.048 4.638 0 4.845.002 4.948.048m-8.444 1.31c-.33.057-.484.261-.484.64 0 .323.111.514.351.607.115.044.34.047 3.629.047s3.514-.003 3.629-.047c.241-.093.351-.284.351-.608 0-.316-.101-.496-.34-.604-.101-.045-.298-.048-3.56-.052-1.899-.002-3.508.006-3.576.017m0 2c-.33.057-.484.261-.484.64 0 .323.111.514.351.607.115.044.34.047 3.629.047s3.514-.003 3.629-.047c.241-.093.351-.284.351-.608 0-.316-.101-.496-.34-.604-.101-.045-.298-.048-3.56-.052-1.899-.002-3.508.006-3.576.017m0 2c-.33.058-.484.261-.484.64 0 .323.111.514.351.607.112.043.252.047 1.629.047s1.517-.004 1.629-.047c.24-.093.351-.284.351-.607 0-.315-.095-.488-.327-.597-.117-.055-.154-.056-1.573-.06-.799-.003-1.508.005-1.576.017" fill-rule="evenodd" fill="#000" /></svg>
                        </OverlayTrigger>
{/* 
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 50 }}
                          overlay={
                            <Tooltip id="button-tooltip" className="custom_tooltip_design">

                              {cell.value}
                            </Tooltip>
                          }
                        >

                          <span
                            className="user_bg_text"

                          >
                            <svg width="16" height="16" fill="none" className="user_comment_text" xmlns="http://www.w3.org/2000/svg"><path d="M3.088 2.027a1.997 1.997 0 0 0-1.694 1.519c-.044.188-.047.517-.047 5.401v5.2l.057.12c.11.231.364.424.562.426a.666.666 0 0 0 .2-.044c.063-.024.672-.46 1.354-.97.682-.509 1.27-.942 1.307-.962.055-.031.751-.039 4.106-.051l4.04-.014.204-.057a2.027 2.027 0 0 0 1.429-1.474c.044-.187.047-.448.047-3.788 0-3.339-.003-3.6-.047-3.787a2.027 2.027 0 0 0-1.429-1.474l-.204-.058L8.12 2.01c-2.669-.002-4.934.006-5.032.017m9.86 1.356c.134.061.323.272.358.4.037.132.037 6.967.001 7.099-.033.116-.18.293-.314.376l-.1.062-4.04.014c-4.038.014-4.04.014-4.243.071-.329.093-.531.215-1.25.754l-.68.51-.008-4.395c-.005-3.163.001-4.423.022-4.497.038-.13.221-.33.359-.394.102-.046.316-.048 4.947-.048 4.638 0 4.845.002 4.948.048m-8.444 1.31c-.33.057-.484.261-.484.64 0 .323.111.514.351.607.115.044.34.047 3.629.047s3.514-.003 3.629-.047c.241-.093.351-.284.351-.608 0-.316-.101-.496-.34-.604-.101-.045-.298-.048-3.56-.052-1.899-.002-3.508.006-3.576.017m0 2c-.33.057-.484.261-.484.64 0 .323.111.514.351.607.115.044.34.047 3.629.047s3.514-.003 3.629-.047c.241-.093.351-.284.351-.608 0-.316-.101-.496-.34-.604-.101-.045-.298-.048-3.56-.052-1.899-.002-3.508.006-3.576.017m0 2c-.33.058-.484.261-.484.64 0 .323.111.514.351.607.112.043.252.047 1.629.047s1.517-.004 1.629-.047c.24-.093.351-.284.351-.607 0-.315-.095-.488-.327-.597-.117-.055-.154-.056-1.573-.06-.799-.003-1.508.005-1.576.017" fill-rule="evenodd" fill="#000" /></svg>

                          </span>
                        </OverlayTrigger> */}

                      </>


                    ) :

                      <></>


                    }

                  </>
                );
              },
            });
          }


          else {
            arr.push({
              Header: key,
              accessor: key,
            });
          }
        }

        setSettingHeader(arr);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="main_contain_area">
        {/* <div className="main-content-wrap sidenav-open d-flex flex-column">
        <div className="main-content"> */}
        <div className="table_area_spacing">
          <TableFund columns={settingHeader} data={data} loading={isLoading} initial={initialData} />
        </div>
      </div>
    </>
  );
}
