import EditAdvertiser from "../components/EditAdvertiser";
import { useLocation } from "react-router-dom";

function EditAdvertiserPage() {
  const search = useLocation().search;
  const advertiserId = new URLSearchParams(search).get("advertiserId");
  return (
    <>
      <EditAdvertiser advertiserId={advertiserId} />
    </>
  );
}

export default EditAdvertiserPage;
