import AddFundAdvertiser from "../components/AddFundAdvertiser";

function AddFundAdvertiserPage() {
  return (
    <>
      <AddFundAdvertiser />
    </>
  );
}

export default AddFundAdvertiserPage;
