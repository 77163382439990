import React, { useState, useContext } from "react";
import axios from "axios";
import configData from "../config/config.json";
import { TokenVerificationLoggedin, ScreenView } from "../utils/tokenVerify";
import TableCampaign from "./TableCampaign";
import { LoadingContext } from "react-router-loading";
import Badge from "react-bootstrap/Badge";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import LoaderImage from "../assets/images/loading-img.gif";
import cricketSvg from "../assets/images/four-svg.svg";
import aqiSvg from "../assets/images/two-svg.svg";
import DisabledEditIcon from "../assets/images/disabled_edit_icon.svg";
import cryptoSvg from "../assets/images/three-svg.svg";
import weatherSvg from "../assets/images/one-svg.svg";
import Tooltip from "react-bootstrap/Tooltip";
import { Tooltip as AntTooltip } from "antd";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Switch } from "antd";
import jwt_decode from "jwt-decode";
import { Select } from "antd";
import advertiserViewData from "../utils/advertiserView.json"
import constantData from "../utils/constantData.json";

export default function ListCampaign() {
  const loadingContext = useContext(LoadingContext);

  const [initialLoader, setInitialLoader] = useState(false);

  const [checkRole, setCheckRole] = useState("RM");


  /////////////////////////////////////cpm edit starts//////////////////////

  // const [showColumn, setShowColumn] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [cpm, setCpm] = useState(null);
  // const [campaignId, setCampaignId] = useState(null);
  // const [cpmIsRequiredError, setCpmIsRequiredError] = useState(false);
  // const [cpmValueError, setCpmValueError] = useState(false);

  // function onChangeCpm(event) {
  //   setCpmIsRequiredError(false);
  //   setCpm(event.target.value);
  //   if (event.target.value < 0.1) {
  //     setCpmValueError(true);
  //   } else {
  //     setCpmValueError(false);
  //   }
  // }

  // function onBlurCpm(event) {
  //   if (event.target.value.length === 0) {
  //     setCpmValueError(false);
  //     setCpmIsRequiredError(true);
  //   }
  // }

  // function submitCPM(event) {
  //   if (cpm !== null) {
  //     if (cpmIsRequiredError === false && cpmValueError === false) {
  //       setShowColumn(false);
  //       openDiv(false);
  //       setInitialLoader(true);
  //       let token = Cookies.get("token_moment");
  //       axios
  //         .post(
  //           configData.SERVER_URL + "campaign/editCPM?campaignId=" + campaignId,
  //           { cpm: parseFloat(cpm) },
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               "x-access-token": token,
  //             },
  //           }
  //         )
  //         .then((response) => {
  //           if (response.data.status === 200) {
  //             setActive(!active);
  //             notifysuccess(response.data.message);
  //           } else {
  //             notifyfailure(response.data.message);
  //             setInitialLoader(false);
  //           }
  //         })
  //         .catch((error) => {
  //           notifycatch();
  //           setInitialLoader(false);
  //         });

  //       setCampaignId(null);
  //       setCpm(null);
  //     }
  //   } else {
  //     if (cpm === null || cpm === "") {
  //       setCpmIsRequiredError(true);
  //     }
  //   }
  // }

  // const openDiv = (mode) => {
  //   setOpen(mode);
  // };

  // ///////////////////////////////////cpm edit ends//////////////////////

  function addCommasToNumber(num) {
    const isDecimal = num % 1 !== 0;
    let numStr = num.toString();
    let [wholePart, decimalPart] = numStr.split(".");
    wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (isDecimal) {
      numStr = `${wholePart}.${decimalPart}`;
    } else {
      numStr = wholePart;
    }

    return numStr;
  }


  const notifysuccess = (msg) => toast.success(msg, { autoClose: 1000 });

  const notifyfailure = (msg) => toast.error(msg, { autoClose: 1000 });

  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 2000 });

  const [active, setActive] = useState(true);
  // const [role, setRole] = useState("");

  var arr;

  //table header and row creation
  const [settingHeader, setSettingHeader] = useState([]);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  // loader
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    TokenVerificationLoggedin();
    ScreenView();

    // let status= Cookies.get("token_moment")?true:false;
    // if(status){
    // let token = Cookies.get("token_moment");

    // let role = jwt_decode(token).role;

    // setRole(role);
    // }
    // document.body.style.backgroundColor = "#f3f3f9";
    getData();

    loadingContext.done();
  }, [active]);

  function onSubmitModal(id) {
    setInitialLoader(true);
    let token = Cookies.get("token_moment");
    axios
      .post(
        configData.SERVER_URL + "campaign/editCampaignStatus?campaignId=" + id,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setActive(!active);
          notifysuccess(response.data.message);
        } else {
          notifyfailure(response.data.message);
          setInitialLoader(false);
        }
      })
      .catch((error) => {
        notifycatch();
        setInitialLoader(false);
      });
  }

  function onSubmitTriggerStatus(id) {
    setInitialLoader(true);
    let token = Cookies.get("token_moment");
    axios
      .post(
        configData.SERVER_URL +
        "campaign/campaignTriggerStatus?campaignId=" +
        id,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setActive(!active);
          notifysuccess(response.data.message);
        } else {
          notifyfailure(response.data.message);
          setInitialLoader(false);
        }
      })
      .catch((error) => {
        notifycatch();
        setInitialLoader(false);
      });
  }

  const getData = () => {
    let token = Cookies.get("token_moment");
    arr = [];

    let aidWiseView = false
    let status = Cookies.get("token_moment") ? true : false;
    if (status) {
      let aid = jwt_decode(token).aid;
      aidWiseView = advertiserViewData?.aid?.includes(aid)
    }

    axios
      .get(configData.SERVER_URL + `campaign/campaignList`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((response) => {
        const data = aidWiseView ? Object.fromEntries(Object.entries(response.data.data[0]).filter(([key]) => key !== "TOTAL SPEND")) : response.data.data[0];
        const dataValue = aidWiseView ? response.data.data?.map(({ "TOTAL SPEND": _, ...rest }) => rest) : response.data.data;


        let status = Cookies.get("token_moment") ? true : false;
        if (status) {
          let token = Cookies.get("token_moment");
          let role = jwt_decode(token).role;

          // setCheckRole(role)

          setData(dataValue);
          setInitialData(dataValue[0]);

          arr.push({
            Header: "Actions",
            accessor: "Actions",
            Cell: (cell) => (
              <>
                <>
                  {cell.row.original["isCampaignEditable"] ?
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> Edit </Tooltip>}>
                      <svg width="16" height="16" className="edit_hover" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                        const element = document.getElementById(
                          "popover-positioned-right"
                        );
                        if (element?.classList.contains("show")) {
                          element?.classList.remove("show");
                        }

                        setTimeout(() => {
                          window.open(
                            "/editCampaign?campaignId=" +
                            cell.row.original["campaignId"],
                            "_blank"
                          );
                        }, 100);
                      }}>
                        <path d="M3.33333 12.6667H4.26667L10.0167 6.91671L9.08333 5.98337L3.33333 11.7334V12.6667ZM12.8667 5.95004L10.0333 3.15004L10.9667 2.21671C11.2222 1.96115 11.5362 1.83337 11.9087 1.83337C12.2807 1.83337 12.5944 1.96115 12.85 2.21671L13.7833 3.15004C14.0389 3.4056 14.1722 3.71404 14.1833 4.07537C14.1944 4.43626 14.0722 4.74449 13.8167 5.00004L12.8667 5.95004ZM2.66667 14C2.47778 14 2.31956 13.936 2.192 13.808C2.064 13.6805 2 13.5223 2 13.3334V11.45C2 11.3612 2.01667 11.2752 2.05 11.192C2.08333 11.1085 2.13333 11.0334 2.2 10.9667L9.06667 4.10004L11.9 6.93337L5.03333 13.8C4.96667 13.8667 4.89178 13.9167 4.80867 13.95C4.72511 13.9834 4.63889 14 4.55 14H2.66667ZM9.55 6.45004L9.08333 5.98337L10.0167 6.91671L9.55 6.45004Z" fill="#BDBDBD" />
                      </svg>
                    </OverlayTrigger>
                    :
                    <img src={DisabledEditIcon} className="img-fluid disabled_icon" alt="" />
                  }
                </>

                <>

                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> Clone </Tooltip>}>
                    <svg
                      style={{ marginLeft: "8px", cursor: "pointer" }}
                      onClick={() => {
                        const element = document.getElementById(
                          "popover-positioned-right"
                        );
                        if (element?.classList.contains("show")) {
                          element?.classList.remove("show");
                        }

                        setTimeout(() => {
                          window.open(
                            "/campaignClone?campaignId=" +
                            cell.row.original["campaignId"],
                            "_blank"
                          );
                        }, 100);
                      }}
                      width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 12C5.63333 12 5.31956 11.8696 5.05867 11.6087C4.79733 11.3473 4.66667 11.0333 4.66667 10.6667V2.66668C4.66667 2.30001 4.79733 1.98601 5.05867 1.72468C5.31956 1.46379 5.63333 1.33334 6 1.33334H12C12.3667 1.33334 12.6807 1.46379 12.942 1.72468C13.2029 1.98601 13.3333 2.30001 13.3333 2.66668V10.6667C13.3333 11.0333 13.2029 11.3473 12.942 11.6087C12.6807 11.8696 12.3667 12 12 12H6ZM6 10.6667H12V2.66668H6V10.6667ZM3.33333 14.6667C2.96667 14.6667 2.65267 14.5362 2.39133 14.2753C2.13044 14.014 2 13.7 2 13.3333V4.66668C2 4.47779 2.064 4.31934 2.192 4.19134C2.31956 4.06379 2.47778 4.00001 2.66667 4.00001C2.85556 4.00001 3.014 4.06379 3.142 4.19134C3.26956 4.31934 3.33333 4.47779 3.33333 4.66668V13.3333H10C10.1889 13.3333 10.3473 13.3973 10.4753 13.5253C10.6029 13.6529 10.6667 13.8111 10.6667 14C10.6667 14.1889 10.6029 14.3471 10.4753 14.4747C10.3473 14.6027 10.1889 14.6667 10 14.6667H3.33333ZM6 2.66668V10.6667V2.66668Z" fill="#BDBDBD" />
                    </svg>


                  </OverlayTrigger>

                </>

              </>

            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          });

          for (const [key] of Object.entries(data)) {
            if (key === "CAMPAIGN NAME") {
              arr.push({
                Header: key,
                accessor: key,

                Cell: (cell) => {
                  return (
                    <>
                      <div className="d-flex justify-content-between align-items-center limited_text">

                        {cell.row.original["isCampaignEditable"] ?
                          <>

                            {cell.value.length >= 28 ? <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> {cell.value} </Tooltip>}>
                              <Link className=""
                                onClick={() =>
                                  window.open(
                                    "/editCampaign?campaignId=" +
                                    cell.row.original["campaignId"],
                                    "_blank"
                                  )
                                }>
                                {cell.value}
                              </Link>
                            </OverlayTrigger>
                              :

                              <Link className=""
                                onClick={() =>
                                  window.open(
                                    "/editCampaign?campaignId=" +
                                    cell.row.original["campaignId"],
                                    "_blank"
                                  )
                                }>
                                {cell.value}
                              </Link>

                            }
                          </>
                          :

                          <>

                            {cell.value.length >= 28 ?
                              (
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> {cell.value} </Tooltip>}>

                                  <span>{cell.value}</span>

                                </OverlayTrigger>
                              )
                              :
                              (

                                <span>
                                  {cell.value}
                                </span>
                              )


                            }
                          </>

                        }

                      </div>
                    </>
                  );
                },
              });
            }

            else if (key === "creatives") {
              arr.push({
                Header: key,
                accessor: key,

                Cell: (cell) => {
                  return (
                    <>
                      <div className="">


                        <>


                          {cell.row.original["isCampaignEditable"] ?

                            <Link className="clickable_text"
                              onClick={() =>
                                window.open(
                                  "/editCampaign?creative=yes&campaignId=" +
                                  cell.row.original["campaignId"],
                                  "_blank"
                                )}>
                              {cell.value}
                              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.222931 0.712949C0.226165 0.347519 0.52332 0.0529825 0.888764 0.0529829L6.97124 0.0529825C7.41307 0.0529825 7.77124 0.411155 7.77124 0.852982L7.77124 6.93546C7.77124 7.30091 7.47671 7.59806 7.11128 7.6013C6.74125 7.60457 6.43953 7.30551 6.43953 6.93546V2.32751L1.17158 7.59545C0.911231 7.8558 0.489121 7.8558 0.228772 7.59545C-0.0315777 7.33511 -0.0315773 6.913 0.228772 6.65265L5.49672 1.3847L0.888765 1.3847C0.518718 1.3847 0.219656 1.08298 0.222931 0.712949Z" fill="#6366F1"></path></svg>
                            </Link>
                            :

                            <span>
                              {cell.value}
                            </span>


                          }
                        </>


                      </div>
                    </>
                  );
                },
              });
            }

            else if (key === "camp_type") {
              arr.push({
                Header: "TRIGGER",
                accessor: key,

                Cell: (cell) => {
                  return (
                    <div className="d-flex align-items-center">
                      {cell.value !== "normal" &&
                        <>
                          {!cell.row.original["TRIGGERKEY"] ?
                            <AntTooltip title='Campaign is currently paused due to unmatched trigger conditions.
                    '>
                              <svg width="14" height="14" viewBox="0 0 14 14" className="transform-y-m-1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.75234 4.97505V9.02505M8.45234 4.97505V9.02505M13.1773 7.00005C13.1773 7.79783 13.0202 8.5878 12.7149 9.32485C12.4096 10.0619 11.9621 10.7316 11.398 11.2957C10.8339 11.8598 10.1642 12.3073 9.42715 12.6126C8.69009 12.9179 7.90012 13.075 7.10234 13.075C6.30456 13.075 5.51459 12.9179 4.77754 12.6126C4.04049 12.3073 3.37079 11.8598 2.80667 11.2957C2.24255 10.7316 1.79507 10.0619 1.48978 9.32485C1.18448 8.5878 1.02734 7.79783 1.02734 7.00005C1.02734 5.38886 1.66739 3.84366 2.80667 2.70438C3.94595 1.56509 5.49115 0.925049 7.10234 0.925049C8.71353 0.925049 10.2587 1.56509 11.398 2.70438C12.5373 3.84366 13.1773 5.38886 13.1773 7.00005Z" stroke="#FA8E02" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </AntTooltip>
                            :
                            <AntTooltip title='Campaign is now live as the trigger conditions matched.
                   '>
                              <svg width="14" height="14" viewBox="0 0 14 14" className="transform-y-m-1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8663 7.00031C12.8663 7.78075 12.7126 8.55354 12.414 9.27457C12.1153 9.9956 11.6775 10.6507 11.1257 11.2026C10.5738 11.7544 9.9187 12.1922 9.19767 12.4909C8.47664 12.7895 7.70384 12.9432 6.9234 12.9432C6.14297 12.9432 5.37017 12.7895 4.64914 12.4909C3.92811 12.1922 3.27297 11.7544 2.72111 11.2026C2.16926 10.6507 1.73151 9.9956 1.43285 9.27457C1.13419 8.55354 0.980469 7.78075 0.980469 7.00031C0.980469 5.42414 1.6066 3.91253 2.72111 2.79802C3.83563 1.6835 5.34724 1.05737 6.9234 1.05737C8.49957 1.05737 10.0112 1.6835 11.1257 2.79802C12.2402 3.91253 12.8663 5.42414 12.8663 7.00031Z" stroke="#00A300" stroke-width="1.32065" stroke-linecap="round" stroke-linejoin="round" />
                                <circle cx="6.92255" cy="7.00019" r="2.6413" fill="#00A300" />
                              </svg>
                            </AntTooltip>
                          }

                        </>

                      }
                      <p className="ps-1">
                        {cell.value === "normal" ? "-" : cell.value.charAt(0).toUpperCase() + cell.value.slice(1).toLowerCase()}
                      </p>
                    </div>
                  );
                },
              });
            }


            else if (
              key === "TOTAL SPEND" ||
              key === "TOTAL CPC SPEND" ||
              key === "eCPM" ||
              key === "CPC" ||
              key === "BID (min-max)" ||
              key === "DAILY BUDGET" ||
              key === "TOTAL BUDGET" ||
              key === "CPM"
            ) {
              arr.push({
                Header: key,
                accessor: key,



                Cell: (cell) => {
                  let currencySymbol = ""


                  if (cell.row.original["CURRENCY"] === "Euro") {
                    currencySymbol = "€"
                  }
                  else if (cell.row.original["CURRENCY"] === "Dollar") {
                    currencySymbol = "$"
                  }
                  else if (cell.row.original["CURRENCY"] === "Turkish Lira") {
                    currencySymbol = "₺"
                  }
                  else if (cell.row.original["CURRENCY"] === "Indonesian Rupiah") {
                    currencySymbol = "Rp"
                  }
                  else if (cell.row.original["CURRENCY"] === "Yen") {
                    currencySymbol = "¥"
                  }
                  else if (cell.row.original["CURRENCY"] === "Thai Bhat") {
                    currencySymbol = "฿"
                  }
                  else {
                    //for ruble
                    currencySymbol = "₽"
                  }

                  if (key === "TOTAL SPEND" ||
                    key === "TOTAL BUDGET" ||
                    key === "DAILY BUDGET") {
                    return (
                      <>
                        <span>

                          {currencySymbol}
                          &nbsp;
                          {addCommasToNumber(cell.value)}</span>
                      </>
                    );
                  }
                  else {
                    return (
                      <>
                        <span>

                          {currencySymbol}
                          &nbsp;
                          {cell.value}</span>
                      </>
                    );

                  }
                },
              });
            }

            else if (
              key === "TODAY IMPRESSIONS" ||
              key === "TOTAL IMPRESSIONS" ||
              key === "TOTAL CLICKS"
            ) {
              arr.push({
                Header: key,
                accessor: key,
                Cell: (cell) => {


                  return (
                    <>
                      <span>
                        {addCommasToNumber(cell.value)}</span>
                    </>
                  );
                },
              });
            }




            else if (key === "CSM" || key === "SALES PARTNER") {
              arr.push({
                Header: key,
                accessor: key,

                Cell: (cell) => {
                  return (
                    <>
                      {cell.value.map((value) => (
                        <span key={value}>
                          <Badge className="bg-badge-custom-2">{value}</Badge>
                          &nbsp;
                        </span>
                      ))}
                    </>
                  );
                },
              });
            }
            //  else if (key === "CPM") {
            //   arr.push({
            //     Header: key,
            //     accessor: key,
            //     Cell: (cell) => {
            //       return (
            //         <>
            //           <span>$ {cell.value} &nbsp;</span>

            //           {/* <i
            //           className="fa-solid fa-pencil text-primary"
            //           onClick={() => {
            //             setShowColumn(true);
            //             openDiv(true);
            //             setCpm(cell.value);
            //             setCampaignId(cell.row.original["Adkernel CId"]);
            //           }}
            //         ></i> */}
            //         </>
            //       );
            //     },

            //     //   cell: (row) => row["Sales Partner"].join(", "),
            //   });
            // }


            else if (key === "CTR") {
              arr.push({
                Header: key,
                accessor: key,
                Cell: (cell) => {
                  return (
                    <>
                      <span> {cell.value}</span>
                    </>
                  );
                },
              });
            } else if (key === "STATUS") {
              arr.push({
                Header: key,
                accessor: key,
                Cell: (cell) => {
                  return (
                    <>
                      {role === "SA" ? (
                        <Select
                          className={cell.value === "Pending" ? "newclass custom_drop_design" : cell.value === "Approved" ? "approvedclass custom_drop_design" :
                            cell.value === "Draft" ? "draftclass custom_drop_design" : "declined custom_drop_design"}
                          // className="select-custom p-0 select-width"
                          // menuIsOpen={true}
                          options={constantData.Campaign_Status_Option_SA}
                          placeholder="-Select-"
                          value={{ label: cell.value, value: cell.value }}
                          onChange={(event) => {
                            setInitialLoader(true);


                            let token = Cookies.get("token_moment");
                            axios
                              .post(
                                configData.SERVER_URL +
                                "campaign/campaignStatusBySA?campaignId=" +
                                cell.row.original["campaignId"],
                                {
                                  status: event.toUpperCase(),
                                  campaignId: cell.row.original["CampaignId"],
                                },

                                {
                                  headers: {
                                    "Content-Type": "application/json",
                                    "x-access-token": token,
                                  },
                                }
                              )
                              .then((response) => {
                                if (response.data.status === 200) {
                                  notifysuccess(response.data.message);
                                  setActive(!active);
                                } else {
                                  notifyfailure(response.data.message);
                                  setInitialLoader(false);
                                }
                              })
                              .catch((error) => {
                                setInitialLoader(false);
                              });
                          }}
                        // isSearchable={true}
                        ></Select>
                      ) : role === "RM" ? (
                        <span>{cell.value}</span>
                      ) : (
                        <Select
                          // className="select-custom p-0 select-width"
                          // menuIsOpen={true}
                          className={cell.value === "Pending" ? "newclass custom_drop_design" : cell.value === "Approved" ? "approvedclass custom_drop_design" :
                            cell.value === "Draft" ? "draftclass custom_drop_design" : "declined custom_drop_design"}
                          options={constantData.Campaign_Status_Option_Other}
                          placeholder="-Select-"
                          value={{ label: cell.value, value: cell.value }}
                          onChange={(event) => {
                            setInitialLoader(true);

                            let token = Cookies.get("token_moment");
                            axios
                              .post(
                                configData.SERVER_URL +
                                "campaign/campaignStatusBySA?campaignId=" +
                                cell.row.original["campaignId"],
                                {
                                  status: event.toUpperCase(),
                                  campaignId: cell.row.original["CampaignId"],
                                },

                                {
                                  headers: {
                                    "Content-Type": "application/json",
                                    "x-access-token": token,
                                  },
                                }
                              )
                              .then((response) => {
                                if (response.data.status === 200) {
                                  notifysuccess(response.data.message);
                                  setActive(!active);
                                } else {
                                  notifyfailure(response.data.message);
                                  setInitialLoader(false);
                                }
                              })
                              .catch((error) => {
                                setInitialLoader(false);
                              });
                          }}
                          isSearchable={true}
                        ></Select>
                      )}
                    </>
                  );
                },
              });
            }
            else if (key === "ENABLED") {
              arr.push({
                Header: key,
                accessor: key,
                sortType: (a, b) => {
                  if (a.values?.["ENABLED"] === b.values?.["ENABLED"]) return 0;
                  if (a.values?.["ENABLED"] === true) return 1;
                  return -1;
                },
                Cell: (cell) => {
                  return (
                    <>
                      {cell.value === false ? (
                        <>
                          {cell.row.original["camp_type"] !== "normal" ?
                            <AntTooltip title='The campaign will be automatically enabled when trigger conditions are met.'>
                              <Switch checked={false} />
                            </AntTooltip>
                            :
                            <Link
                              onClick={() => {
                                onSubmitModal(cell.row.original["campaignId"]);
                              }}
                            >
                              <Switch checked={false} />
                            </Link>
                          }
                        </>) : (

                        <Link
                          onClick={() => {
                            onSubmitModal(cell.row.original["campaignId"]);
                          }}
                        >
                          <Switch checked={true} />
                        </Link>

                      )}
                    </>
                  );



                },
              });
            }

            else if (key === "FORCE STOP") {
              arr.push({
                Header: key,
                accessor: key,
                sortType: (a, b) => {
                  if (a.values?.["FORCE STOP"] === b.values?.["FORCE STOP"]) return 0;
                  if (a.values?.["FORCE STOP"] === true) return 1;
                  return -1;
                },
                Cell: (cell) => {
                  return (
                    <>
                      {cell.row.original["camp_type"] !== "normal" && (
                        <div>
                          {cell.value === false ? (
                            <Link
                              onClick={() => {
                                onSubmitTriggerStatus(
                                  cell.row.original["campaignId"]
                                );
                              }}
                            >
                              <Switch checked={false} />
                            </Link>
                          ) : (
                            <Link
                              onClick={() => {
                                onSubmitTriggerStatus(
                                  cell.row.original["campaignId"]
                                );
                              }}
                            >
                              <Switch checked={true} />
                            </Link>
                          )}
                        </div>
                      )}
                    </>
                  );
                },
              });
            } else {
              arr.push({
                Header: key,
                accessor: key,
              });
            }
          }

          setSettingHeader(arr);
          setIsLoading(false);
          setInitialLoader(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setInitialLoader(false);
      });
  };

  // const dropdownRef2 = useRef(null);
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (
  //       dropdownRef2.current &&
  //       !dropdownRef2.current.contains(event.target)
  //     ) {
  //       openDiv(false);
  //       setCpm(null);
  //       setCampaignId(null);
  //     }
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dropdownRef2]);

  return (
    <>
      <ToastContainer />
      <div className="main_contain_area">
        <div className="table_area_spacing">
          {initialLoader && (
            <div className="page_loader_gif">
              <img src={LoaderImage} alt="" className="img-fluid" />
            </div>
          )}
          <div className="list_advertiser_table">
            <TableCampaign columns={settingHeader} data={data} loading={isLoading} initial={initialData} role={checkRole} />
          </div>
        </div>
      </div>

      {/* <Modal show={showColumn} size="sm" centered>
        <Modal.Header>
          <Modal.Title>
            <h6 className="mb-0">Edit CPM</h6>{" "}
          </Modal.Title>
          <button
            type="button"
            data-dismiss="modal"
            onClick={() => {
              openDiv(false);
              setShowColumn(false);
              setCampaignId(null);
              setCpm(null);
              setCpmIsRequiredError(false);
              setCpmValueError(false);
            }}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="d-flex">
              <input
                className="form-control h-34px"
                type="text"
                value={cpm}
                onChange={onChangeCpm}
                onBlur={onBlurCpm}
              />

              <button
                className="btn default_btn btn-sm"
                onClick={() => {
                  submitCPM();
                }}
              >
                Submit
              </button>
            </div>
            {cpmIsRequiredError && (
              <span className="alert_text">
                <i
                  className="fa-solid fa-triangle-exclamation pe-1"
                  data-bs-toggle="tooltip"
                  data-bs-custom-className="custom-tooltip"
                  data-bs-placement="top"
                  aria-label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod "
                  data-bs-original-title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod "
                ></i>
                This field is required
              </span>
            )}
            {cpmValueError && (
              <span className="alert_text">Minimum value 0.10 allowed</span>
            )}
          </>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
