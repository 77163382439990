import React, { useState, useContext } from "react";
import { Accordion, InputGroup, Form } from 'react-bootstrap'
import { UserOutlined } from '@ant-design/icons'
import { TokenVerificationLoggedin, ScreenView } from "../utils/tokenVerify";
import SidebarReport from "./SidebarReport";
import jwt_decode from "jwt-decode";
import ContentLoader from 'react-content-loader'
import { Progress } from 'antd';
import Cookies from 'js-cookie';
import axios from "axios";
import constantData from "../utils/constantData.json";
import configData from "../config/config.json";
import { LoadingContext } from "react-router-loading";
import RedEye from '../assets/images/red_eye.svg'
import Average from '../assets/images/average_icon.svg'
import TrafficTypeImage from '../assets/images/traffic-type.svg'
import TrafficSourceImage from '../assets/images/traffic-source.svg'
import DeviceImage from '../assets/images/device-type.svg'
import SizeImage from '../assets/images/size.svg'
import OSImage from '../assets/images/os.svg'
import CountryImage from '../assets/images/country.svg'
import TableMediaPlanner from "./TableMediaPlanner";
import GraphIcon from '../assets/images/graph-icon.svg'
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Select from "react-select";
import LoaderImage from "../assets/images/loading-img.gif";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import ConstantData from "../utils/constantData.json"
import { toast } from "react-toastify";


const MediaPlanner = () => {
  const [apiCall, setApiCall] = useState(true);

  const loadingContext = useContext(LoadingContext);

  const notifycatch = () =>
  toast.error("Something went wrong", { autoClose: 2000 });

  ////////////////////////////////////////backend pagination code //////////////////////
  const [totalRecords, setTotalRecords] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  ////////////////////////////////////////backend pagination code ////////////////////// 

  React.useEffect(() => {
    TokenVerificationLoggedin();
    ScreenView();

    getData();

    loadingContext.done();
  }, [apiCall, pageNumber, perPage]);

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000)?.toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000)?.toFixed(1) + "K";
    } else {
      return num?.toString();
    }
  }

  // function addCommasToNumber(num) {
  //   // Convert the number to a string
  //   let numStr = num.toString();

  //   // Use a regular expression to insert commas at every three places from the end
  //   numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  //   return numStr;
  // }

  function addCommasToNumber(num) {
    // Check if the number is a decimal number
    const isDecimal = num % 1 !== 0;

    // Convert the number to a string
    let numStr = num.toString();

    // Split the number into whole and decimal parts (if applicable)
    let [wholePart, decimalPart] = numStr.split(".");

    // Use a regular expression to insert commas at every three places in the whole part
    wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the whole and decimal parts back together with the decimal separator
    if (isDecimal) {
      numStr = `${wholePart}.${decimalPart}`;
    } else {
      numStr = wholePart;
    }

    return numStr;
  }

  var arr;
  const [checkRole, setCheckRole] = useState("RM");

  //table header and row creation
  const [settingHeader, setSettingHeader] = useState([]);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [exportHeader, setExportHeader] = useState([]);
  const [exportBody, setExportBody] = useState([]);

  const [estimatedImpressionRequest, setEstimatedImpressionRequest] = useState(0);
  const [estimatedImpressionRequestLoader, setEstimatedImpressionRequestLoader] = useState(false);
  const [averageBidFloor, setAverageBidFloor] = useState(0);
  const [averageBidFloorLoader, setAverageBidFloorLoader] = useState(false);

  const [estimatedImpressionRequestArray, setEstimatedImpressionRequestArray] = useState([]);
  
  const [averageBidFloorArray, setAverageBidFloorArray] = useState([]);

  // loader
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    let token = Cookies.get("token_moment");
    arr = [];
    setIsLoading(true);

    setEstimatedImpressionRequest(0)
    setEstimatedImpressionRequestArray([])
    setAverageBidFloor(0)
    setAverageBidFloorArray([])
    setEstimatedImpressionRequestLoader(true)
    setAverageBidFloorLoader(true)

    axios
      .post(configData.SERVER_URL + `report/media-planner?limit=` +
        perPage +
        `&page=` + pageNumber,
        {
          date: dateRangeApply,
          groupBy: selectedGroupByApply.value,
          deviceFilter: selectedOptionsDeviceType,
          countryFilter: selectedOptionsCountry,
          sizeFilter: selectedOptionsSize,
          osFilter: selectedOptionsOs,
          trafficTypeFilter: selectedOptionsTrafficType,
          trafficSourceFilter: selectedOptionsTrafficSource,


        }, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((response) => {
        const data = response.data.data[0];
        const dataValue = response.data.data;
        setPageCount(Math.ceil(response.data.totalRecords / perPage));
        setTotalRecords(response.data.totalRecords)


        setData(dataValue);
        setInitialData(dataValue[0]);
        setEstimatedImpressionRequest(response.data.total.scaled_impressions_count)
        setAverageBidFloor(response.data.total.avg_bid_floor)
        setEstimatedImpressionRequestArray(response.data.totalImpArray)
        setAverageBidFloorArray(response.data.bidFloorArray)

        for (const [key] of Object.entries(data)) {

          if (key === "Estimated Avails") {
            arr.push({
              Header: key,
              accessor: key,

              Cell: (cell) => {
                return (
                  <>
                    {addCommasToNumber(cell.value)}
                  </>
                );
              },
            });
          }

          else {
            arr.push({
              Header: key,
              accessor: key,
            });
          }

        }

        setSettingHeader(arr);
        setIsLoading(false);
        setEstimatedImpressionRequestLoader(false)
        setAverageBidFloorLoader(false)




        // export data creation starts

        // let arr2 = [];
        // for (const key in response.data.data[0]) {
        //   arr2.push({ label: key, key: key });
        // }
        // setExportHeader(arr2);

        // let arrTemp = response.data.data;
        // setExportBody(arrTemp);
      })
      .catch((error) => {
        // setInitialLoader(false);
        setIsLoading(false);
        setEstimatedImpressionRequestLoader(false)
        setAverageBidFloorLoader(false)
      });
  };



  React.useEffect(() => {



    axios
      .get(configData.SERVER_URL + "report/osysList", {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOptionsOs(response.data.data);
        }
      })
      .catch((error) => { });

    axios
      .get(configData.SERVER_URL + "report/countryMinifiedList", {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOptionsCountry(response.data.data);
        }
      })
      .catch((error) => { });
  }, []);


  // ///////////////////////////////////filter starts /////////////////////////////////////////


  //////////////////////  TrafficType  starts   ////////////////////////////////

  const [optionsTrafficType, setOptionsTrafficType] = useState(constantData.MediaPlanner_TrafficType);
  const [searchValueTrafficType, setSearchValueTrafficType] = useState("");
  const [selectedOptionsTrafficType, setSelectedOptionsTrafficType] = useState(
    []
  );
  const [selectAllTrafficType, setSelectAllTrafficType] = useState(false);

  const handleSearchTrafficType = (event) => {
    setSearchValueTrafficType(event.target.value);
  };

  const handleSelectAllTrafficType = (event) => {
    const isChecked = event.target.checked;


    setSelectAllTrafficType(isChecked);

    if (isChecked) {
      const allOptionIds = optionsTrafficType.map((option) => option.id);
      setSelectedOptionsTrafficType(allOptionIds);
    } else {
      setSelectedOptionsTrafficType([]);
    }
  };

  const handleOptionChangeTrafficType = (event) => {
    const optionId = event.target.id;

    setSelectedOptionsTrafficType((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        setSelectAllTrafficType(false);
        return prevSelectedOptions.filter((id) => id !== optionId);
      } else {
        if (prevSelectedOptions.length + 1 === optionsTrafficType.length) {
          setSelectAllTrafficType(true);
        }
        return [...prevSelectedOptions, optionId];
      }
    });
  };

  const filteredOptionsTrafficType = optionsTrafficType?.filter((option) =>
    option.name.toLowerCase().includes(searchValueTrafficType.toLowerCase())
  );

  ////////////////////////  TrafficType Ends  //////////////////////////////

  //////////////////////  TrafficSource  starts   ////////////////////////////////

  const [optionsTrafficSource, setOptionsTrafficSource] = useState(constantData.MediaPlanner_TrafficSource);
  const [searchValueTrafficSource, setSearchValueTrafficSource] = useState("");
  const [selectedOptionsTrafficSource, setSelectedOptionsTrafficSource] = useState(
    []
  );
  const [selectAllTrafficSource, setSelectAllTrafficSource] = useState(false);

  const handleSearchTrafficSource = (event) => {
    setSearchValueTrafficSource(event.target.value);
  };

  const handleSelectAllTrafficSource = (event) => {
    const isChecked = event.target.checked;


    setSelectAllTrafficSource(isChecked);

    if (isChecked) {
      const allOptionIds = optionsTrafficSource.map((option) => option.id);
      setSelectedOptionsTrafficSource(allOptionIds);
    } else {
      setSelectedOptionsTrafficSource([]);
    }
  };

  const handleOptionChangeTrafficSource = (event) => {
    const optionId = event.target.id;

    setSelectedOptionsTrafficSource((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        setSelectAllTrafficSource(false);
        return prevSelectedOptions.filter((id) => id !== optionId);
      } else {
        if (prevSelectedOptions.length + 1 === optionsTrafficSource.length) {
          setSelectAllTrafficSource(true);
        }
        return [...prevSelectedOptions, optionId];
      }
    });
  };

  const filteredOptionsTrafficSource = optionsTrafficSource?.filter((option) =>
    option.name.toLowerCase().includes(searchValueTrafficSource.toLowerCase())
  );

  ////////////////////////  TrafficSource Ends  //////////////////////////////


  //////////////////////  DeviceType  starts   ////////////////////////////////

  const [optionsDeviceType, setOptionsDeviceType] = useState(constantData.MediaPlanner_DeviceType);
  const [searchValueDeviceType, setSearchValueDeviceType] = useState("");
  const [selectedOptionsDeviceType, setSelectedOptionsDeviceType] = useState(
    []
  );
  const [selectAllDeviceType, setSelectAllDeviceType] = useState(false);

  const handleSearchDeviceType = (event) => {
    setSearchValueDeviceType(event.target.value);
  };

  const handleSelectAllDeviceType = (event) => {
    const isChecked = event.target.checked;


    setSelectAllDeviceType(isChecked);

    if (isChecked) {
      const allOptionIds = optionsDeviceType.map((option) => option.id);
      setSelectedOptionsDeviceType(allOptionIds);
    } else {
      setSelectedOptionsDeviceType([]);
    }
  };

  const handleOptionChangeDeviceType = (event) => {
    const optionId = event.target.id;

    setSelectedOptionsDeviceType((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        setSelectAllDeviceType(false);
        return prevSelectedOptions.filter((id) => id !== optionId);
      } else {
        if (prevSelectedOptions.length + 1 === optionsDeviceType.length) {
          setSelectAllDeviceType(true);
        }
        return [...prevSelectedOptions, optionId];
      }
    });
  };

  const filteredOptionsDeviceType = optionsDeviceType?.filter((option) =>
    option.name.toLowerCase().includes(searchValueDeviceType.toLowerCase())
  );

  ////////////////////////  DeviceType Ends  //////////////////////////////


  //////////////////////  Size  starts   ////////////////////////////////

  const [optionsSize, setOptionsSize] = useState(constantData.MediaPlanner_Size);
  const [searchValueSize, setSearchValueSize] = useState("");
  const [selectedOptionsSize, setSelectedOptionsSize] = useState(
    []
  );
  const [selectAllSize, setSelectAllSize] = useState(false);

  const handleSearchSize = (event) => {
    setSearchValueSize(event.target.value);
  };

  const handleSelectAllSize = (event) => {
    const isChecked = event.target.checked;


    setSelectAllSize(isChecked);

    if (isChecked) {
      const allOptionIds = optionsSize.map((option) => option.id);
      setSelectedOptionsSize(allOptionIds);
    } else {
      setSelectedOptionsSize([]);
    }
  };

  const handleOptionChangeSize = (event) => {
    const optionId = event.target.id;

    setSelectedOptionsSize((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        setSelectAllSize(false);
        return prevSelectedOptions.filter((id) => id !== optionId);
      } else {
        if (prevSelectedOptions.length + 1 === optionsSize.length) {
          setSelectAllSize(true);
        }
        return [...prevSelectedOptions, optionId];
      }
    });
  };

  const filteredOptionsSize = optionsSize?.filter((option) =>
    option.name.toLowerCase().includes(searchValueSize.toLowerCase())
  );

  ////////////////////////  Size Ends  //////////////////////////////

  //////////////////////  Os  starts   ////////////////////////////////

  const [optionsOs, setOptionsOs] = useState(constantData.MediaPlanner_Os);
  const [searchValueOs, setSearchValueOs] = useState("");
  const [selectedOptionsOs, setSelectedOptionsOs] = useState(
    []
  );
  const [selectAllOs, setSelectAllOs] = useState(false);

  const handleSearchOs = (event) => {
    setSearchValueOs(event.target.value);
  };

  const handleSelectAllOs = (event) => {
    const isChecked = event.target.checked;


    setSelectAllOs(isChecked);

    if (isChecked) {
      const allOptionIds = optionsOs.map((option) => option.id);
      setSelectedOptionsOs(allOptionIds);
    } else {
      setSelectedOptionsOs([]);
    }
  };

  const handleOptionChangeOs = (event) => {
    const optionId = event.target.id;

    setSelectedOptionsOs((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        setSelectAllOs(false);
        return prevSelectedOptions.filter((id) => id !== optionId);
      } else {
        if (prevSelectedOptions.length + 1 === optionsOs.length) {
          setSelectAllOs(true);
        }
        return [...prevSelectedOptions, optionId];
      }
    });
  };

  const filteredOptionsOs = optionsOs?.filter((option) =>
    option.name.toLowerCase().includes(searchValueOs.toLowerCase())
  );

  ////////////////////////  Os Ends  //////////////////////////////

  //////////////////////  Country  starts   ////////////////////////////////

  const [optionsCountry, setOptionsCountry] = useState(constantData.MediaPlanner_Country);
  const [searchValueCountry, setSearchValueCountry] = useState("");
  const [selectedOptionsCountry, setSelectedOptionsCountry] = useState(
    []
  );
  const [selectAllCountry, setSelectAllCountry] = useState(false);

  const handleSearchCountry = (event) => {
    setSearchValueCountry(event.target.value);
  };

  const handleSelectAllCountry = (event) => {
    const isChecked = event.target.checked;


    setSelectAllCountry(isChecked);

    if (isChecked) {
      const allOptionIds = optionsCountry.map((option) => option.id);
      setSelectedOptionsCountry(allOptionIds);
    } else {
      setSelectedOptionsCountry([]);
    }
  };

  const handleOptionChangeCountry = (event) => {
    const optionId = event.target.id;

    setSelectedOptionsCountry((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        setSelectAllCountry(false);
        return prevSelectedOptions.filter((id) => id !== optionId);
      } else {
        if (prevSelectedOptions.length + 1 === optionsCountry.length) {
          setSelectAllCountry(true);
        }
        return [...prevSelectedOptions, optionId];
      }
    });
  };

  const filteredOptionsCountry = optionsCountry?.filter((option) =>
    option.name.toLowerCase().includes(searchValueCountry.toLowerCase())
  );

  ////////////////////////  Country Ends  //////////////////////////////




  ///////////////////////////////////////filter ends//////////////////////////////////////////

  //////////////////////////////////////group by handling functions/////////////////////////////////////

  const [selectedGroupByApply, setSelectedGroupByApply] = useState({
    "label": "By Country",
    "value": "country"
  })

  const handleDataReceived = (data) => {
    setSelectedGroupByApply(data);

    if(data.value==="bundle"){
      setSelectedOptionsTrafficSource([]);
      setSelectAllTrafficSource(false);
      if(accordianDefaultKey==="1"){
        setAccordianDefaultKey();
      }

    }

    setApiCall(!apiCall);
    // setPerPage(10)
    setPageNumber(1)

  }



  ///////////////////////date handling functions///////////
  const [dateRangeApply, setDateRangeApply] = useState({});
  const [exportLoader, setExportLoader] = useState(false);

  const onDataReceivedDate = (data) => {
    setDateRangeApply(data);
    setApiCall(!apiCall);
    // setPerPage(20)
    setPageNumber(1)
  };

  
  const onDataReceivedExport = () => {

    let status= Cookies.get("token_moment")?true:false;      
    if (status) {
    let token= Cookies.get("token_moment")

    setExportLoader(true)

    axios
      .post(configData.SERVER_URL + `report/mediaPlannercsv`,
        {
          date: {startDate:moment().subtract(1, 'days').format('YYYY-MM-DD'),endDate:moment().subtract(1, 'days').format('YYYY-MM-DD')},
          groupBy: selectedGroupByApply.value,
          deviceFilter: selectedOptionsDeviceType,
          countryFilter: selectedOptionsCountry,
          sizeFilter: selectedOptionsSize,
          osFilter: selectedOptionsOs,
          trafficTypeFilter: selectedOptionsTrafficType,
          trafficSourceFilter: selectedOptionsTrafficSource,


        }, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Media_planner.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setExportLoader(false)


      })
      .catch((error) => {
        setExportLoader(false)
        notifycatch()
        
      });
    }
 
   
  };


  function onChangeSizePagination(event) {
    setPerPage(event.value)
  }

  const customStyles = {
    menuPortal: base => ({
      ...base,
      zIndex: 9999, // Set a high z-index to ensure it appears above other content
    }),
    menu: provided => ({
      ...provided,
      position: 'absolute',
      top: 'auto',
      bottom: '100%', // Position the menu above the select input
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Optional: add some shadow
    }),
  };

  const [searchRecord, setSearchRecord] = useState(1)

  const [accordianDefaultKey, setAccordianDefaultKey] = useState()

  return (
    <>

      <div className="media_planner_page">

        <div className='d-flex'>
          <div className="filter_area_design position-relative">
            {/* <h4>Filter</h4>
            <hr></hr> */}
            <Accordion className="mt-0 remove_scroll_cls" activeKey={accordianDefaultKey}>
              <Accordion.Item eventKey="0" 
     
              >
                <Accordion.Header          
                onClick={()=>{
                if(accordianDefaultKey==="0"){
                  setAccordianDefaultKey();
                }
                else{
                  setAccordianDefaultKey("0");
                }

              }}
              >
                  <div className="d-flex w-100 align-items-center">
                    <img src={TrafficTypeImage} className="img-fluid" alt="" />
                    <div className="d-flex justify-content-between w-100">
                      {" "}
                      Traffic Type{" "}
                      {selectedOptionsTrafficType.length > 0 &&

                        <small
                          onClick={() => {
                            setSelectedOptionsTrafficType([]);
                            setSelectAllTrafficType(false);
                          }}
                          className="tag_clr"
                        >
                          clear (
                          {selectedOptionsTrafficType.length > 0
                            ? selectedOptionsTrafficType.length
                            : 0}
                          )
                        </small>
                      }
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <InputGroup className="custom_search_bar">
                    <InputGroup.Text id="basic-addon1" className="border-end-0">
                      <i
                        className="fas fa-solid fa-magnifying-glass"
                        aria-hidden="true"
                      ></i>
                    </InputGroup.Text>
                    <input
                      id="custom-filter"
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control ps-14 report-search border-start-0"
                      placeholder="Search"
                      value={searchValueTrafficType}
                      onChange={handleSearchTrafficType}
                    />
                  </InputGroup>

                  <div className="custom_list_boxs">
                    <ul className="">
                      <li>
                        {" "}
                        <input type="checkbox" className="form-check-input"
                          aria-label="option 0"
                          id="0"
                          checked={selectAllTrafficType}
                          onChange={handleSelectAllTrafficType}
                        />{" "}
                        <label htmlFor="0">Select All</label>
                      </li>
                      <li>
                        <hr />
                      </li>
                      {filteredOptionsTrafficType?.map((option) => (
                        <li key={option.id}>
                          <input type="checkbox" className="form-check-input"
                            // type="checkbox"
                            id={option.id}
                            aria-label={`option ${option.id}`}
                            checked={selectedOptionsTrafficType.includes(
                              option.id
                            )}
                            onChange={handleOptionChangeTrafficType}
                            value={option.id}
                          />
                          <label htmlFor={option.id}>{option.name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {selectedGroupByApply.value!=="bundle" &&
              <Accordion.Item eventKey="1"
       
              >
                <Accordion.Header
                       onClick={()=>{
                        if(accordianDefaultKey==="1"){
                          setAccordianDefaultKey();
                        }
                        else{
                          setAccordianDefaultKey("1");
                        }
                      }
                    }
                >
                  <div className="d-flex w-100 align-items-center">
                    <img src={TrafficSourceImage} className="img-fluid" alt="" />
                    <div className="d-flex justify-content-between w-100">
                      {" "}
                      Traffic Source{" "}
                      {selectedOptionsTrafficSource.length > 0 &&

                        <small
                          onClick={() => {
                            setSelectedOptionsTrafficSource([]);
                            setSelectAllTrafficSource(false);
                          }}
                          className="tag_clr"
                        >
                          clear (
                          {selectedOptionsTrafficSource.length > 0
                            ? selectedOptionsTrafficSource.length
                            : 0}
                          )
                        </small>
                      }
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <InputGroup className="custom_search_bar">
                    <InputGroup.Text id="basic-addon1" className="border-end-0">
                      <i
                        className="fas fa-solid fa-magnifying-glass"
                        aria-hidden="true"
                      ></i>
                    </InputGroup.Text>
                    <input
                      id="custom-filter"
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control ps-14 report-search border-start-0"
                      placeholder="Search"
                      value={searchValueTrafficSource}
                      onChange={handleSearchTrafficSource}
                    />
                  </InputGroup>

                  <div className="custom_list_boxs">
                    <ul className="">
                      <li>
                        {" "}
                        <input type="checkbox" className="form-check-input"
                          aria-label="option 1"
                          id="1"
                          checked={selectAllTrafficSource}
                          onChange={handleSelectAllTrafficSource}
                        />{" "}
                        <label htmlFor="1">Select All</label>
                      </li>
                      <li>
                        <hr />
                      </li>
                      {filteredOptionsTrafficSource?.map((option) => (
                        <li key={option.id}>
                          <input type="checkbox" className="form-check-input"
                            // type="checkbox"
                            id={option.id}
                            aria-label={`option ${option.id}`}
                            checked={selectedOptionsTrafficSource.includes(
                              option.id
                            )}
                            onChange={handleOptionChangeTrafficSource}
                            value={option.id}
                          />
                          <label htmlFor={option.id}>{option.name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
}
          
              <Accordion.Item eventKey="2"
              
      
              
              >
                <Accordion.Header
                        onClick={()=>{
                          if(accordianDefaultKey==="2"){
                            setAccordianDefaultKey();
                          }
                          else{
                            setAccordianDefaultKey("2");
                          }
                        }
                      }
                >
                  <div className="d-flex w-100 align-items-center">
                    <img src={DeviceImage} className="img-fluid" alt="" />
                    <div className="d-flex justify-content-between w-100">
                      {" "}
                      Device Type{" "}
                      {selectedOptionsDeviceType.length > 0 &&

                        <small
                          onClick={() => {
                            setSelectedOptionsDeviceType([]);
                            setSelectAllDeviceType(false);
                          }}
                          className="tag_clr"
                        >
                          clear (
                          {selectedOptionsDeviceType.length > 0
                            ? selectedOptionsDeviceType.length
                            : 0}
                          )
                        </small>
                      }
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <InputGroup className="custom_search_bar">
                    <InputGroup.Text id="basic-addon1" className="border-end-0">
                      <i
                        className="fas fa-solid fa-magnifying-glass"
                        aria-hidden="true"
                      ></i>
                    </InputGroup.Text>
                    <input
                      id="custom-filter"
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control ps-14 report-search border-start-0"
                      placeholder="Search"
                      value={searchValueDeviceType}
                      onChange={handleSearchDeviceType}
                    />
                  </InputGroup>

                  <div className="custom_list_boxs">
                    <ul className="">
                      <li>
                        {" "}
                        <input type="checkbox" className="form-check-input"
                          aria-label="option 2"
                          id="2"
                          checked={selectAllDeviceType}
                          onChange={handleSelectAllDeviceType}
                        />{" "}
                        <label htmlFor="2">Select All</label>
                      </li>
                      <li>
                        <hr />
                      </li>
                      {filteredOptionsDeviceType?.map((option) => (
                        <li key={option.id}>
                          <input type="checkbox" className="form-check-input"
                            // type="checkbox"
                            id={option.id}
                            aria-label={`option ${option.id}`}
                            checked={selectedOptionsDeviceType.includes(
                              option.id
                            )}
                            onChange={handleOptionChangeDeviceType}
                            value={option.id}
                          />
                          <label htmlFor={option.id}>{option.name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3"
              
           

              >
                <Accordion.Header
                   onClick={()=>{
                    if(accordianDefaultKey==="3"){
                      setAccordianDefaultKey();
                    }
                    else{
                      setAccordianDefaultKey("3");
                    }
                  }
                }
                >
                  <div className="d-flex w-100 align-items-center">
                    <img src={SizeImage} className="img-fluid" alt="" />
                    <div className="d-flex justify-content-between w-100">
                      {" "}
                      Size{" "}
                      {selectedOptionsSize.length > 0 &&

                        <small
                          onClick={() => {
                            setSelectedOptionsSize([]);
                            setSelectAllSize(false);
                          }}
                          className="tag_clr"
                        >
                          clear (
                          {selectedOptionsSize.length > 0
                            ? selectedOptionsSize.length
                            : 0}
                          )
                        </small>
                      }
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <InputGroup className="custom_search_bar">
                    <InputGroup.Text id="basic-addon1" className="border-end-0">
                      <i
                        className="fas fa-solid fa-magnifying-glass"
                        aria-hidden="true"
                      ></i>
                    </InputGroup.Text>
                    <input
                      id="custom-filter"
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control ps-14 report-search border-start-0"
                      placeholder="Search"
                      value={searchValueSize}
                      onChange={handleSearchSize}
                    />
                  </InputGroup>

                  <div className="custom_list_boxs">
                    <ul className="">
                      <li>
                        {" "}
                        <input type="checkbox" className="form-check-input"
                          aria-label="option 3"
                          id="3"
                          checked={selectAllSize}
                          onChange={handleSelectAllSize}
                        />{" "}
                        <label htmlFor="3">Select All</label>
                      </li>
                      <li>
                        <hr />
                      </li>
                      {filteredOptionsSize?.map((option) => (
                        <li key={option.id}>
                          <input type="checkbox" className="form-check-input"
                            // type="checkbox"
                            id={option.id}
                            aria-label={`option ${option.id}`}
                            checked={selectedOptionsSize.includes(
                              option.id
                            )}
                            onChange={handleOptionChangeSize}
                            value={option.id}
                          />
                          <label htmlFor={option.id}>{option.name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4"
             
              >
                <Accordion.Header
                        onClick={()=>{
                          if(accordianDefaultKey==="4"){
                            setAccordianDefaultKey();
                          }
                          else{
                            setAccordianDefaultKey("4");
                          }
                        }
                      }
                >
                  <div className="d-flex w-100 align-items-center">
                    <img src={OSImage} className="img-fluid" alt="" />
                    <div className="d-flex justify-content-between w-100">
                      {" "}
                      OS{" "}
                      {selectedOptionsOs.length > 0 &&

                        <small
                          onClick={() => {
                            setSelectedOptionsOs([]);
                            setSelectAllOs(false);
                          }}
                          className="tag_clr"
                        >
                          clear (
                          {selectedOptionsOs.length > 0
                            ? selectedOptionsOs.length
                            : 0}
                          )
                        </small>
                      }
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <InputGroup className="custom_search_bar">
                    <InputGroup.Text id="basic-addon1" className="border-end-0">
                      <i
                        className="fas fa-solid fa-magnifying-glass"
                        aria-hidden="true"
                      ></i>
                    </InputGroup.Text>
                    <input
                      id="custom-filter"
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control ps-14 report-search border-start-0"
                      placeholder="Search"
                      value={searchValueOs}
                      onChange={handleSearchOs}
                    />
                  </InputGroup>

                  <div className="custom_list_boxs">
                    <ul className="">
                      <li>
                        {" "}
                        <input type="checkbox" className="form-check-input"
                          aria-label="option 4"
                          id="4"
                          checked={selectAllOs}
                          onChange={handleSelectAllOs}
                        />{" "}
                        <label htmlFor="4">Select All</label>
                      </li>
                      <li>
                        <hr />
                      </li>
                      {filteredOptionsOs?.map((option) => (
                        <li key={option.id}>
                          <input type="checkbox" className="form-check-input"
                            // type="checkbox"
                            id={option.id}
                            aria-label={`option ${option.id}`}
                            checked={selectedOptionsOs.includes(
                              option.id
                            )}
                            onChange={handleOptionChangeOs}
                            value={option.id}
                          />
                          <label htmlFor={option.id}>{option.name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5"
          
              >
                <Accordion.Header
                           onClick={()=>{
                            if(accordianDefaultKey==="5"){
                              setAccordianDefaultKey();
                            }
                            else{
                              setAccordianDefaultKey("5");
                            }
                          }
                        }
                >
                  <div className="d-flex w-100 align-items-center">
                    <img src={CountryImage} className="img-fluid" alt="" />
                    <div className="d-flex justify-content-between w-100">
                      {" "}
                      Country{" "}
                      {selectedOptionsCountry.length > 0 &&

                        <small
                          onClick={() => {
                            setSelectedOptionsCountry([]);
                            setSelectAllCountry(false);
                          }}
                          className="tag_clr"
                        >
                          clear (
                          {selectedOptionsCountry.length > 0
                            ? selectedOptionsCountry.length
                            : 0}
                          )
                        </small>
                      }
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <InputGroup className="custom_search_bar">
                    <InputGroup.Text id="basic-addon1" className="border-end-0">
                      <i
                        className="fas fa-solid fa-magnifying-glass"
                        aria-hidden="true"
                      ></i>
                    </InputGroup.Text>
                    <input
                      id="custom-filter"
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control ps-14 report-search border-start-0"
                      placeholder="Search"
                      value={searchValueCountry}
                      onChange={handleSearchCountry}
                    />
                  </InputGroup>

                  <div className="custom_list_boxs">
                    <ul className="">
                      <li>
                        {" "}
                        <input type="checkbox" className="form-check-input"
                          aria-label="option 5"
                          id="5"
                          checked={selectAllCountry}
                          onChange={handleSelectAllCountry}
                        />{" "}
                        <label htmlFor="5">Select All</label>
                      </li>
                      <li>
                        <hr />
                      </li>
                      {filteredOptionsCountry?.map((option) => (
                        <li key={option.id}>
                          <input type="checkbox" className="form-check-input"
                            // type="checkbox"
                            id={option.id}
                            aria-label={`option ${option.id}`}
                            checked={selectedOptionsCountry.includes(
                              option.id
                            )}
                            onChange={handleOptionChangeCountry}
                            value={option.id}
                          />
                          <label htmlFor={option.id}>{option.name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="clear_apply_btn">
              <div className="px-0 button_area_bottom d-flex justify-content-between pb-0">
                <button className="default_btn border_default_btn btn w-50 d-block me-2" onClick={() => {
                  setSelectedOptionsCountry([]); setSelectedOptionsDeviceType([]); setSelectedOptionsOs([]); setSelectedOptionsSize([]); setSelectedOptionsTrafficSource([]); setSelectedOptionsTrafficType([]); setSelectAllCountry(false);
                  setSelectAllDeviceType(false); setSelectAllOs(false); setSelectAllSize(false); setSelectAllTrafficSource(false); setSelectAllTrafficType(false);
                }} > Clear all </button>
                <button className="default_btn btn w-50 text-center d-block" onClick={() => {
                  setApiCall(!apiCall); 
                  // setPerPage(10)
                  setPageNumber(1)

                 
               


                }}> Apply </button>
              </div>
            </div>
          </div>
          <div className="main_contain_area d-flex">
            <div className="main_contain_area">
              <div className="">
                <div className="table_area_spacing">
                  <TableMediaPlanner
                    exportHeader={exportHeader}
                    exportBody={exportBody}
                    columns={settingHeader}
                    data={data}
                    loading={isLoading}
                    initial={initialData}
                    onDataReceived={handleDataReceived}
                    onDataReceivedDate={onDataReceivedDate}
                    exportLoader={exportLoader}
                    onDataReceivedExport={onDataReceivedExport}
                    onSearchDataReceived={(data) => {
                   
                      if (data.length > 0) {
                        setSearchRecord(1)
                      }
                      else {
                        setSearchRecord(0)
                      }
                    }}
                    role={checkRole}
                  />


                  {!(data.length === 0 || searchRecord === 0) &&
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <div className=" ">
                        <div className="child-pagination-row">
                          {/* <select
                          className="custom_drop_design select-custom p-0"
                          value={perPage}
                          onChange={(e) => {
                            setPerPage(Number(e.target.value));
                          }}
                        >
                          {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              {pageSize}
                            </option>
                          ))}
                        </select> */}
                          <Select
                            className="custom_drop_design select-custom pagination_select"
                            value={{ label: perPage, value: perPage }}
                            options={ConstantData.paginationSize}
                            placeholder="-Select-"
                            onChange={onChangeSizePagination}
                            styles={customStyles}
                            menuPortalTarget={document.body}>
                          </Select>
                        </div>
                      </div>

                      <div className="pagination-text-end">
                        {totalRecords > 0 && (
                          <span>
                            Showing{" "}
                            <strong>
                              {/* {pageIndex * pageSize + 1} -{" "}
                        {pageIndex * pageSize + pageSize > rows.length
                          ? rows.length
                          : pageIndex * pageSize + pageSize} */}

                              {(pageNumber - 1) * perPage + 1}-{pageNumber * perPage > totalRecords ? totalRecords : pageNumber * perPage}
                            </strong>{" "}
                            of {totalRecords} records
                          </span>
                        )}
                        <button
                          onClick={() => setPageNumber(1)}
                          disabled={pageNumber === 1}
                        >
                          <i className="fa-solid fa-angles-left"></i>
                        </button>{" "}
                        <button
                          onClick={() => setPageNumber(pageNumber - 1)}
                          disabled={pageNumber === 1}
                        >
                          <i className="fa-solid fa-angle-left"></i>
                        </button>{" "}
                        <button
                          onClick={() => setPageNumber(pageNumber + 1)}
                          disabled={pageNumber === pageCount}
                        >
                          <i className="fa-solid fa-angle-right"></i>
                        </button>{" "}
                        <button
                          onClick={() => setPageNumber(pageCount)}
                          disabled={pageNumber === pageCount}
                        >
                          <i className="fa-solid fa-angles-right"></i>
                        </button>{" "}
                      </div>


                    </div>
                  }


                </div>
              </div>
            </div>
            <div className="boxs_line_design boxs_line_design_updated_cls">
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="">
                    <div>
                      {estimatedImpressionRequestLoader?
                            <ContentLoader speed={2} width={750} height={100} viewBox="187 0 452 106" backgroundColor="#e0e0e0" foregroundColor="#ecebeb">
                        <rect x="15" y="0" rx="3" ry="10" width="25" height="25" />
                        <rect x="53" y="4" rx="3" ry="10" width="200" height="18" />
                        <rect x="430" y="4" rx="3" ry="3" width="20" height="18" />
                        <rect x="15" y="85" rx="3" ry="3" width="280" height="25" />
                      </ContentLoader>:
                      <>
                           <span><img src={RedEye} className="img-fluid" alt="" /> Estimated Avails</span>
                 
                 <h3>{addCommasToNumber(estimatedImpressionRequest)}</h3>
                      </>
                    
                 
}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="pt-0">
                    {estimatedImpressionRequestArray?.map((item, key) => {
                      const width = Number(item?.impPercentage);
                      const style = { width: `${width}%` };


                      if (selectedGroupByApply.value === item?.dimension) {
                        return (
                          <div key={key} className="custom_modification_bar" style={style}>
                            <div className="d-flex align-items-center justify-content-between">
                              <Form.Label className="m-0 first_bar_label">{item?.dimension === "country" ? "Country" : item?.dimension === "device_type" ? "Device Type" : item?.dimension === "os_type" ? "Os Type" : item?.dimension === "traffic_type" ? "Traffic Type" : item?.dimension === "size" ? "Size" : item?.dimension === "bundle" ? "Bundle" : "Domain"}</Form.Label>
                              {/* <Form.Label className="m-0 first_bar_label">{item?.scaled_impressions_count}</Form.Label> */}
                            </div>
                            <Progress percent={100} size="small" />
                          </div>
                        );
                      }

                      else {
                        return (
                          <div key={key} className="custom_modification_bar gray_bg_bar mt-2" style={style}>
                            <div className="d-flex align-items-center justify-content-between">
                              <Form.Label className="m-0 first_bar_label pe-3">{item?.dimension === "country" ? "Country" : item?.dimension === "device_type" ? "Device Type" : item?.dimension === "os_type" ? "Os Type" : item?.dimension === "traffic_type" ? "Traffic Type" : item?.dimension === "size" ? "Size" : item?.dimension === "bundle" ? "Bundle" : "Domain"}</Form.Label>
                              <Form.Label className="m-0 first_bar_label diff_label">{addCommasToNumber(item?.scaled_impressions_count)}</Form.Label>
                            </div>
                            <Progress percent={100} size="small" />
                          </div>
                        );
                      }
                    })}

                  </Accordion.Body>
                </Accordion.Item >
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="">
                    <div>
{averageBidFloorLoader?
                           <ContentLoader speed={2} width={750} height={100} viewBox="187 0 452 106" backgroundColor="#e0e0e0" foregroundColor="#ecebeb">
                        <rect x="15" y="0" rx="3" ry="10" width="25" height="25" />
                        <rect x="53" y="4" rx="3" ry="10" width="200" height="18" />
                        <rect x="430" y="4" rx="3" ry="3" width="20" height="18" />
                        <rect x="15" y="85" rx="3" ry="3" width="280" height="25" />
                      </ContentLoader>:
                      <>
                      <span> <img src={Average} className="img-fluid" alt="" /> Average Bidfloor</span>
                 
                      <h3>$ {averageBidFloor}</h3>
                      </>}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="pt-0">
                    {averageBidFloorArray?.map((item, key) => {
                      const width = Number(item?.biFloorPercentage);
                      const style = { width: `${width}%` };



                      if (selectedGroupByApply.value === item?.dimension) {
                        return (
                          <div key={key} className="custom_modification_bar" style={style}>
                            <div className="d-flex align-items-center justify-content-between">
                              <Form.Label className="m-0 first_bar_label">{item?.dimension === "country" ? "Country" : item?.dimension === "device_type" ? "Device Type" : item?.dimension === "os_type" ? "Os Type" : item?.dimension === "traffic_type" ? "Traffic Type" : item?.dimension === "size" ? "Size" : item?.dimension === "bundle" ? "Bundle" : "Domain"}</Form.Label>
                              {/* <Form.Label className="m-0 first_bar_label">{item?.avg_bid_floor}</Form.Label> */}
                            </div>
                            <Progress percent={100} size="small" />
                          </div>
                        );
                      }

                      else {
                        return (
                          <div key={key} className="custom_modification_bar gray_bg_bar mt-2" style={style}>
                            <div className="d-flex align-items-center justify-content-between">
                              <Form.Label className="m-0 first_bar_label pe-3">{item?.dimension === "country" ? "Country" : item?.dimension === "device_type" ? "Device Type" : item?.dimension === "os_type" ? "Os Type" : item?.dimension === "traffic_type" ? "Traffic Type" : item?.dimension === "size" ? "Size" : item?.dimension === "bundle" ? "Bundle" : "Domain"}</Form.Label>
                              <Form.Label className="m-0 first_bar_label diff_label">{item?.avg_bid_floor}</Form.Label>
                            </div>
                            <Progress percent={100} size="small" />
                          </div>
                        );
                      }
                    })}

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default MediaPlanner