import React, { useState,useContext } from "react";
import { Container, Row, Col, InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { CheckOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { LoadingContext } from "react-router-loading";
import configData from "../config/config.json";
import Cookies from 'js-cookie';
import {
  TokenVerificationLoggedin,
  ScreenViewFund,
  BlockCreateAdvertiser,
} from "../utils/tokenVerify";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useLocation } from "react-router-dom";
import constantData from "../utils/constantData.json";

const AddFund = () => {
  const loadingContext = useContext(LoadingContext);

  const search = useLocation().search;
  const paymentId = new URLSearchParams(search).get("paymentId");
  const PayerID = new URLSearchParams(search).get("PayerID");
  const tokenFund = new URLSearchParams(search).get("token");

  React.useEffect(() => {
    TokenVerificationLoggedin();
    ScreenViewFund();
    BlockCreateAdvertiser();

    if (tokenFund !== null) {
      if (paymentId !== null) {
        notifyPaysuccess();

        axios
          .get(
            configData.SERVER_URL +
            "funds/success?PayerID=" +
            PayerID +
            "&paymentId=" +
            paymentId,

            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => { })
          .catch((error) => { });

        setTimeout(() => {
          window.location.replace("/dashboard");
        }, 3000);
      } else {
        notifyPaycancel();

        axios
          .get(
            configData.SERVER_URL + "funds/cancel?tokenFund=" + tokenFund,

            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => { })
          .catch((error) => { });
      }
    }

    let status= Cookies.get("token_moment")?true:false;
    if(status){
    let token = Cookies.get("token_moment");

    let aid = jwt_decode(token).aid;
    let checkRole = jwt_decode(token).role;
    setRole(checkRole)

    axios
      .get(
        configData.SERVER_URL + "advertiser/getAdvertiserById?aid=" + aid,

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setAdvertiserName(response.data.result.companyName);

          
if(response.data.result.currency.value==="Euro"){
  setCurrencySymbol("€")
}
else if(response.data.result.currency.value==="Dollar"){
  setCurrencySymbol("$")
}
else if(response.data.result.currency.value==="Turkish Lira"){
  setCurrencySymbol("₺")
}
else if(response.data.result.currency.value==="Indonesian Rupiah"){
  setCurrencySymbol("Rp")
}
else if(response.data.result.currency.value==="Yen"){
  setCurrencySymbol("¥")
}
else if(response.data.result.currency.value==="Thai Bhat"){
  setCurrencySymbol("฿")
}
else{
  //for ruble
  setCurrencySymbol("₽")
  setDefaultValidationCurrencyValue(100000)

  // axios
  // .get(configData.SERVER_URL + "advertiser/rubleValue", {})
  // .then((response) => {
  //   if (response.status === 200) {
  //     setDefaultValidationCurrencyValue(parseFloat(response.data.data));
  //   }
  //   else{
  //     setDefaultValidationCurrencyValue(constantData.RUBLE);
  //   }
  // })
  // .catch((error) => {
  //   setDefaultValidationCurrencyValue(constantData.RUBLE);
  // });
}
    

        }
      })
      .catch((error) => {
        notifycatch();
      });
    }

      loadingContext.done()
  }, [PayerID, paymentId, tokenFund]);

  const notifyPaysuccess = (msg) =>
    toast.success("Payment completed successfully", { autoClose: 2000 });

  const notifyPaycancel = (msg) =>
    toast.error("Payment request cancelled", { autoClose: 1500 });

  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 2000 });

  const [advertiserName, setAdvertiserName] = useState(null);
  const [role, setRole] = useState("MASTER");

  const [date] = useState(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
  // const [mode, setMode] = useState("Paypal");
  const [amount, setAmount] = useState(null);
  const [amountIsRequiredError, setAmountIsRequiredError] = useState(false);
  const [amountMinimumError, setAmountMinimumError] = useState(false);
  const [description, setDescription] = useState(null);
  const [comment, setComment] = useState(null);
  const [buttonValue, setButtonValue] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [defaultValidationCurrencyValue, setDefaultValidationCurrencyValue] = useState(1000);



  function onChangeAmount(event) {
    setAmountIsRequiredError(false);
    setAmount(event.target.value);
    // if (event.target.value < configData.FUND_ADVERTISER_MINIMUM_AMOUNT) {
      if (event.target.value < defaultValidationCurrencyValue) {
      setAmountMinimumError(true);
    } else {
      setAmountMinimumError(false);
    }
  }

  function onBlurAmount(event) {
    if (event.target.value.length === 0) {
      setAmountMinimumError(false);
      setAmountIsRequiredError(true);
    }
  }

  function onChangeDescription(event) {
    setDescription(event.target.value);
  }

  function onChangeComment(event) {
    setComment(event.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (amount !== null) {
      if (amountIsRequiredError === false && amountMinimumError === false) {
        let payload = {
          amount: amount,
          mode: "Paypal",
          type: "credit",
          // description: description,
          comment: comment,
        };

        let token = Cookies.get("token_moment");

        setButtonValue(true);
        axios
          .post(configData.SERVER_URL + "funds/pay", payload, {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          })
          .then((response) => {
            if (response.data.status === 201) {
              window.location.replace(response.data.data);
            } else {
              notifyPaycancel();
              setButtonValue(false);
            }
          })
          .catch((error) => {
            notifycatch();
            setButtonValue(false);
          });
      }
    } else {
      if (amount === null) {
        setAmountIsRequiredError(true);
      }
    }
  };

  return (
    <div className="main_contain_area">
      <div className="step_area">
        {/* <h5><Link to="/" className='text-decoration-none'><img src={LeftArrow} className='img-fluid' alt="" /> </Link> Add Funds</h5> */}
        <h5> Add Funds</h5>
        <div className="steps_buttons">
          <ul className="p-0 m-0">
            <li className="activated_steps">
              <div className="step_count">
                <p>
                  <CheckOutlined />
                </p>
                <span>Create Account</span>
              </div>
            </li>
            <li>
              <hr className="step_line" />
            </li>
            <li className="active_steps">
              <div className="step_count">
                <p>2</p>
                <span>Add Fund</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="scroll_area">
        <div className="step_form_area">
          <div className="custom_container_form">
            <div className="row_devider d-flex">
              <div className="custom_col_space_label">
                <div className="step_form_label">
                  <h4>Payment Details</h4>
                </div>
              </div>
              <div className="step_input_fields">
                <div className="d-flex">
                  <div className="w-440 me-16">
                    <fieldset disabled>
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={advertiserName}
                          disabled
                        />
                      </Form.Group>
                    </fieldset>
                  </div>
                  <div className="w-440">
                    <fieldset disabled>
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={date}
                          required="required"
                          disabled
                        />
                      </Form.Group>
                    </fieldset>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="w-440 me-16">
                    <Form.Group
                      className="mt-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Mode of Payment</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value="PayPal"
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div className="w-440">
                    <Form.Group
                      className="mt-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Amount*</Form.Label>
                      
                      <InputGroup className="">
                        <InputGroup.Text
                          id="basic-addon1"
                          className="border-end-0 bg-transparent"
                        >
                        {currencySymbol}
                        </InputGroup.Text>
                        <Form.Control
                          className=""
                          type="number"
                          placeholder={defaultValidationCurrencyValue}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={amount}
                          onChange={onChangeAmount}
                          onBlur={onBlurAmount}
                        />
                      </InputGroup>
                      {amountIsRequiredError && (
                        <span className="alert_text">
                          This field is required
                        </span>
                      )}
                      {amountMinimumError && (
                        <span className="alert_text">Minimum Amount {currencySymbol}{" "} {defaultValidationCurrencyValue}</span>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="w-896">
                  <Form.Group
                    className="mt-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Comment</Form.Label>
                    <textarea
                      name=""
                      id=""
                      className="form-control"
                      cols="30"
                      rows="10"
                      // value={description}
                      // onChange={onChangeDescription}
                      value={comment}
                      onChange={onChangeComment}
                    ></textarea>
                  </Form.Group>
                </div>
                {/* <div className="w-896">
                  <Form.Group
                    className="mt-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      type="text"
                      value={comment}
                      onChange={onChangeComment}
                    />
                  </Form.Group>
                </div> */}
              </div>
            </div>
            <div className="divider_line"></div>
            <div className="button_area_bottom d-flex justify-content-end">
              <div className="">

                
                {!buttonValue && role!=="RM" && (
                  <button
                    className="default_btn btn"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Proceed to Pay
                  </button>
                )}

                {buttonValue && (
                  <button className="default_btn btn" type="button" disabled>
                    Processing
                  </button>
                )}
                {/* <Link to="/payment" className="default_btn btn">
                  Proceed to Pay
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFund;
