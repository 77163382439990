import CreateAdvertiser from "../components/CreateAdvertiser";

function CreateAdvertiserPage() {
  return (
    <>
      <CreateAdvertiser />{" "}
    </>
  );
}

export default CreateAdvertiserPage;
