import CampaignCreation from "../components/CampaignCreation";

function campaignCreationPage() {
  return (
    <>
      <CampaignCreation />
    </>
  );
}

export default campaignCreationPage;
