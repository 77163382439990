import React, { useState, useContext } from "react";
import axios from "axios";
import configData from "../config/config.json";
import {
  TokenVerificationLoggedin,
  BlockListingAdvertiser,
} from "../utils/tokenVerify";
import TableAdvertiser from "./TableAdvertiser";
import Cookies from 'js-cookie';

import moment from "moment";
import { Row, Col, Form, InputGroup, Table } from 'react-bootstrap'
import { Modal } from "react-bootstrap";
// import Select from "react-select";
import { LoadingContext } from "react-router-loading";
import Badge from "react-bootstrap/Badge";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import PassEye from "../assets/images/password_eye.svg";
import PassEyeOff from "../assets/images/password_eye_off.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import constantData from "../utils/constantData.json";
import LoaderImage from "../assets/images/loading-img.gif";
import TeleGram from "../assets/images/logos_telegram.svg";
import gtalkLogo from "../assets/images/logos_gtalk.svg";
import whatsappLogo from "../assets/images/logos_whatsapp.svg";
import telegramLogo from "../assets/images/logos_telegram.svg";
import skypeLogo from "../assets/images/logos_skype.svg";
import WalletDisabled from "../assets/images/account_balance_wallet_disabled.svg"
import DeleteInfo from "../assets/images/delete_info.svg";

import { Select as CustomSelect } from 'antd';
import Select from "react-select";

export default function ListAdvertiser() {
  const loadingContext = useContext(LoadingContext);
  const [initialLoader, setInitialLoader] = useState(false);

  const notifyPaysuccess = (msg) =>
    toast.success("Payment completed successfully", { autoClose: 2000 });

  const notifyPaycancel = (msg) =>
    toast.error("Payment request cancelled", { autoClose: 2000 });

  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 2000 });

  const notifysuccess = (msg) => toast.success(msg, { autoClose: 2000 });

  const notifyfailure = (msg) => toast.error(msg, { autoClose: 2000 });

  const searched = useLocation().search;
  const paymentId = new URLSearchParams(searched).get("paymentId");
  const PayerID = new URLSearchParams(searched).get("PayerID");
  const tokenFund = new URLSearchParams(searched).get("token");

  var arr;

  //table header and row creation
  const [settingHeader, setSettingHeader] = useState([]);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  // loader
  const [isLoading, setIsLoading] = useState(true);
  const [active, setActive] = useState(false);

  function addCommasToNumber(num) {
    const isDecimal = num % 1 !== 0;
    let numStr = num.toString();
    let [wholePart, decimalPart] = numStr.split(".");
    wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (isDecimal) {
      numStr = `${wholePart}.${decimalPart}`;
    } else {
      numStr = wholePart;
    }
  
    return numStr;
  }


  React.useEffect(() => {
    TokenVerificationLoggedin();
    BlockListingAdvertiser();

    if (tokenFund !== null) {
      if (paymentId !== null) {
        notifyPaysuccess();

        axios
          .get(
            configData.SERVER_URL +
            "funds/success?PayerID=" +
            PayerID +
            "&paymentId=" +
            paymentId,

            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => { })
          .catch((error) => { });

        setTimeout(() => {
          window.location.replace("/listAdvertiser");
        }, 3000);
      } else {
        notifyPaycancel();

        axios
          .get(
            configData.SERVER_URL + "funds/cancel?tokenFund=" + tokenFund,

            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => { })
          .catch((error) => { });
      }
    }

    getData();
    setInitialLoader(false);

    loadingContext.done();
  }, [active]);

  const getData = () => {
    let token = Cookies.get("token_moment");
    arr = [];

    axios
      .get(configData.SERVER_URL + `advertiser/advertiserList`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((response) => {
        const data = response.data.data.result[0];
        const dataValue = response.data.data.result;

        setData(dataValue);
        setInitialData(dataValue[0]);

        arr.push({
          Header: "Actions",
          accessor: "Actions",
          Cell: (cell) => (

            <>
              {cell.row.original["Status"] === "New" ?
                <img src={WalletDisabled} className="img-fluid disabled_icon" /> :

                <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> Fund </Tooltip>}>
                  <svg width="16" height="16" className="wallet_hover" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                    const element = document.getElementById(
                      "popover-positioned-right"
                    );
                    if (element?.classList.contains("show")) {
                      element?.classList.remove("show");
                    }
                    handleButtonClick(
                      cell.row.original._id,
                      cell.row.original["Current Balance"],
                      cell.row.original["AID"],
                      cell.row.original["Advertiser Name"],
                      cell.row.original["Company Name"],
                      cell.row.original["Currency"]
                    );
                  }}>
                    <path d="M10.6667 9C10.9556 9 11.1944 8.90556 11.3833 8.71667C11.5722 8.52778 11.6667 8.28889 11.6667 8C11.6667 7.71111 11.5722 7.47222 11.3833 7.28333C11.1944 7.09444 10.9556 7 10.6667 7C10.3778 7 10.1389 7.09444 9.95 7.28333C9.76111 7.47222 9.66667 7.71111 9.66667 8C9.66667 8.28889 9.76111 8.52778 9.95 8.71667C10.1389 8.90556 10.3778 9 10.6667 9ZM3.33333 14C2.96667 14 2.65267 13.8696 2.39133 13.6087C2.13044 13.3473 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13044 2.65267 2.39133 2.39133C2.65267 2.13044 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3473 2.13044 13.6087 2.39133C13.8696 2.65267 14 2.96667 14 3.33333V5H12.6667V3.33333H3.33333V12.6667H12.6667V11H14V12.6667C14 13.0333 13.8696 13.3473 13.6087 13.6087C13.3473 13.8696 13.0333 14 12.6667 14H3.33333ZM8.66667 11.3333C8.3 11.3333 7.98622 11.2029 7.72533 10.942C7.464 10.6807 7.33333 10.3667 7.33333 10V6C7.33333 5.63333 7.464 5.31933 7.72533 5.058C7.98622 4.79711 8.3 4.66667 8.66667 4.66667H13.3333C13.7 4.66667 14.014 4.79711 14.2753 5.058C14.5362 5.31933 14.6667 5.63333 14.6667 6V10C14.6667 10.3667 14.5362 10.6807 14.2753 10.942C14.014 11.2029 13.7 11.3333 13.3333 11.3333H8.66667Z" fill="#757575" />
                  </svg>
                </OverlayTrigger>
              }
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> Edit </Tooltip>}>
                <svg width="16" height="16" className="edit_hover" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                  const element = document.getElementById(
                    "popover-positioned-right"
                  );
                  if (element?.classList.contains("show")) {
                    element.classList.remove("show");
                  }
                  setTimeout(() => {
                    window.open(
                      "/editAdvertiser?advertiserId=" +
                      cell.row.original["AID"],
                      "_blank"
                    );
                  }, 100);
                }}>
                  <path d="M3.33333 12.6667H4.26667L10.0167 6.91671L9.08333 5.98337L3.33333 11.7334V12.6667ZM12.8667 5.95004L10.0333 3.15004L10.9667 2.21671C11.2222 1.96115 11.5362 1.83337 11.9087 1.83337C12.2807 1.83337 12.5944 1.96115 12.85 2.21671L13.7833 3.15004C14.0389 3.4056 14.1722 3.71404 14.1833 4.07537C14.1944 4.43626 14.0722 4.74449 13.8167 5.00004L12.8667 5.95004ZM2.66667 14C2.47778 14 2.31956 13.936 2.192 13.808C2.064 13.6805 2 13.5223 2 13.3334V11.45C2 11.3612 2.01667 11.2752 2.05 11.192C2.08333 11.1085 2.13333 11.0334 2.2 10.9667L9.06667 4.10004L11.9 6.93337L5.03333 13.8C4.96667 13.8667 4.89178 13.9167 4.80867 13.95C4.72511 13.9834 4.63889 14 4.55 14H2.66667ZM9.55 6.45004L9.08333 5.98337L10.0167 6.91671L9.55 6.45004Z" fill="#BDBDBD" />
                </svg>
              </OverlayTrigger>
            </>

          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        });

        for (const [key] of Object.entries(data)) {
          if (key === "Sales Partner") {
            arr.push({
              Header: key,
              accessor: key,

              Cell: (cell) => {
                return (
                  <>
                    {cell.value.map((value) => (
                      <span key={value}>
                        <Badge className="bg-badge-custom">{value}</Badge>
                        &nbsp;
                      </span>
                    ))}
                  </>
                );
              },
            });
          } else if (key === "Company Name") {
            arr.push({
              Header: key,
              accessor: key,

              Cell: (cell) => {
                return (
                  <>
                    <div className="d-flex align-items-center">
                      <img src={cell.row.original.logo} className="company_name_image" alt="" />
                      <span className="ml-2">{cell.value}</span>
                    </div>
                  </>
                );
              },
            });
          } else if (key === "Current Balance" || key === "Total Spend") {
            arr.push({
              Header: key,
              accessor: key,

              Cell: (cell) => {
                return (
                  <>
                    {cell.row.original["Currency"] === "Euro" && (
                      <span>€ {addCommasToNumber(cell.value)}</span>
                    )}

                    {cell.row.original["Currency"] === "Dollar" && (
                      <span>$ {addCommasToNumber(cell.value)}</span>
                    )}

                    {cell.row.original["Currency"] === "Turkish Lira" && (
                      <span>₺ {addCommasToNumber(cell.value)}</span>
                    )}

                    {cell.row.original["Currency"] === "Indonesian Rupiah" && (
                      <span>Rp {addCommasToNumber(cell.value)}</span>
                    )}

                    {cell.row.original["Currency"] === "Yen" && (
                      <span>¥ {addCommasToNumber(cell.value)}</span>
                    )}

                    {cell.row.original["Currency"] === "Thai Bhat" && (
                      <span>฿ {addCommasToNumber(cell.value)}</span>
                    )}
                    {cell.row.original["Currency"] === "Ruble" && (
                      <span>₽ {addCommasToNumber(cell.value)}</span>
                    )}
                  </>
                );
              },
            });
          }

          // }
          else if (key === "CSM") {
            arr.push({
              Header: key,
              accessor: key,

              Cell: (cell) => {
                return (
                  <>
                    {cell.value.map((value) => (
                      <span key={value}>
                        <Badge className="bg-badge-custom-2">{value}</Badge>
                        &nbsp;
                      </span>
                    ))}
                  </>
                );
              },
            });
          } else if (key === "Users") {
            arr.push({
              Header: key,
              accessor: key,

              Cell: (cell) => {
                return (
                  <>
                    {cell.row.original["Step"] === 2 &&
                      <div className="afteradduser">
                        <ul>

                          {cell.row.original["Users"].length === 0 ? (

                            <li >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 50 }}
                                overlay={
                                  <Tooltip id="button-tooltip">

                                    Create User
                                  </Tooltip>
                                }
                              >
                                <span
                                  className="user_bg_text"
                                  onClick={() => {
                                    setShowModal(true);
                                    setAdvertiserIdUserCreate(
                                      cell.row.original["Company Name"].split(
                                        "-"
                                      )[0]
                                    );
                                  }}
                                >
                                  <svg width="18" height="16" className="table_add_new_user_gray" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.09126 9.86662C5.27081 9.86662 1.63672 10.7533 1.63672 12.5333V13.8666H12.5458V12.5333C12.5458 10.7533 8.91172 9.86662 7.09126 9.86662ZM7.09126 8.53328C7.81458 8.53328 8.50827 8.25233 9.01974 7.75224C9.5312 7.25214 9.81854 6.57386 9.81854 5.86662C9.81854 5.15937 9.5312 4.4811 9.01974 3.981C8.50827 3.4809 7.81458 3.19995 7.09126 3.19995C6.36795 3.19995 5.67425 3.4809 5.16279 3.981C4.65133 4.4811 4.36399 5.15937 4.36399 5.86662C4.36399 6.57386 4.65133 7.25214 5.16279 7.75224C5.67425 8.25233 6.36795 8.53328 7.09126 8.53328Z" fill="#BDBDBD" />
                                    <path d="M15.6825 6.80005V4.80005H14.3189V6.80005H12.2734V8.13338H14.3189V10.1334H15.6825V8.13338H17.728V6.80005" fill="#BDBDBD" />
                                  </svg>
                                </span>
                              </OverlayTrigger>
                            </li>

                          ) :



                            <li className="bgblue">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 50 }}
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Edit User
                                  </Tooltip>
                                }
                              >
                                <span
                                  className="user_bg_text"
                                  onClick={() => {
                                    if (cell.row.original["Users"].length > 0) {
                                      setUsers(cell.row.original["Users"]);
                                      setAdvertiserIdUserCreate(
                                        cell.row.original["Company Name"].split(
                                          "-"
                                        )[0]
                                      );
                                      setShowModalEditTable(true);
                                    }
                                  }}
                                >
                                  <svg width="12" height="12" className="table_add_new_user" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.9974 0.666626C6.70464 0.666626 7.38292 0.947577 7.88301 1.44767C8.38311 1.94777 8.66406 2.62605 8.66406 3.33329C8.66406 4.04054 8.38311 4.71881 7.88301 5.21891C7.38292 5.71901 6.70464 5.99996 5.9974 5.99996C5.29015 5.99996 4.61187 5.71901 4.11178 5.21891C3.61168 4.71881 3.33073 4.04054 3.33073 3.33329C3.33073 2.62605 3.61168 1.94777 4.11178 1.44767C4.61187 0.947577 5.29015 0.666626 5.9974 0.666626ZM5.9974 7.33329C8.94406 7.33329 11.3307 8.52663 11.3307 9.99996V11.3333H0.664062V9.99996C0.664062 8.52663 3.05073 7.33329 5.9974 7.33329Z" fill="#6366F1" />
                                  </svg>
                                  {cell.row.original["Users"].length}
                                </span>
                              </OverlayTrigger>
                            </li>

                          }


                        </ul>
                      </div>
                    }
                  </>
                );
              },
            });
          } else if (key === "IM") {
            arr.push({
              Header: key,
              accessor: key,

              Cell: (cell) => {
                let input = cell.value.split(":");


                if (input[0].trim() === "whatsapp") {
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        {/* <i className="fas fa-brands fa-whatsapp space-lite mr-2">{" "}</i> */}
                        <img src={whatsappLogo} className="img-fluid im_image" alt="" />
                        <span className=""> {input[1]}</span>
                      </div>
                    </>
                  );
                } else if (input[0].trim() === "skype") {
                  return (
                    <>
                      <div className="d-flex">
                        {/* <i
                          className="fas fa-brands fa-skype mr-2 space-lite"
                          aria-hidden="true"
                        >
                          {" "}
                        </i> */}
                        <img src={skypeLogo} className="img-fluid im_image" alt="" />
                        <span> {input[1]}</span>
                      </div>
                    </>
                  );
                } else if (input[0].trim() === "telegram") {
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        {/* <i className="fas fa-brands fa-telegram mr-2 space-lite">{" "}</i> */}
                        <img src={telegramLogo} className="img-fluid im_image" alt="" />
                        <span className=""> {input[1]}</span>
                      </div>
                    </>
                  );
                } else if (input[0].trim() === "gtalk") {
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        {/* <i className="fas fa-brands fa-google mr-2 space-lite"></i> */}
                        <img src={gtalkLogo} className="img-fluid im_image" alt="" />
                        <span className=""> {input[1]}</span>
                      </div>
                    </>
                  );
                }
              },
            });
          } else if (key === "Status") {
            arr.push({
              Header: key,
              accessor: key,
              Cell: (cell) => {
                return (
                  <>
                    <CustomSelect
                      className={cell.value === "New" ? "newclass" : cell.value === "Approved" ? "approvedclass" : cell.value === "Suspended" ? "suspendedclass" : "declined"}
                      // className="form-control select-custom p-0 select-width"
                      options={constantData.statusAdvertiser}
                      placeholder="Select Status"
                      value={{ label: cell.value, value: cell.value }}
                      onChange={(event) => {
                        setInitialLoader(true);

                        let token = Cookies.get("token_moment");
                        axios
                          .post(
                            configData.SERVER_URL + "advertiser/statusChange",
                            {
                              status: event,
                              advertiserId:
                                cell.row.original["Company Name"].split("-")[0],
                            },

                            {
                              headers: {
                                "Content-Type": "application/json",
                                "x-access-token": token,
                              },
                            }
                          )
                          .then((response) => {
                            if (response.data.status === 200) {
                              notifysuccess(response.data.message);
                              setActive(!active);
                            } else {
                              notifyfailure(response.data.message);
                              setInitialLoader(false);
                            }
                          })
                          .catch((error) => {
                            setInitialLoader(false);
                          });
                      }}
                      isSearchable={true}>
                        
                      </CustomSelect>
                    {/* {cell.value === "Active" ? (
                      <Badge bg="success">{cell.value}</Badge>
                    ) : (
                      <Badge bg="danger">{cell.value}</Badge>
                    )} */}
                  </>
                );
              },

              //   cell: (row) => row["Sales Partner"].join(", "),
            });
          } else {
            arr.push({
              Header: key,
              accessor: key,
            });
          }
        }

        setSettingHeader(arr);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //   // modal related states start
  const [showColumn, setShowColumn] = useState(false);
  const [advertiserName, setAdvertiserName] = useState(null);
  const [date, setDate] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );
  // const [mode, setMode] = useState("paypal");
  const [displayType, setDisplayType] = useState(false);
  // const [type, setType] = useState("credit");
  const [amount, setAmount] = useState(null);
  const [exceedAmountError, setExceedAmountError] = useState(false);
  const [negativeAmountError, setNegativeAmountError] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(null);
  const [amountIsRequiredError, setAmountIsRequiredError] = useState(false);
  const [amountvalidationError, setAmountValidationError] = useState(false);
  const [description, setDescription] = useState(null);
  const [comment, setComment] = useState(null);
  const [advertiserAID, setAdvertiserAID] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("$");

  const [addFundButtonValue, setAddFundButtonValue] = useState(false);
  const [currencyValidaionValue, setCurrencyValidationValue] = useState(5);

  //   //modal related states end

  const handleButtonClick = (
    id,
    balance,
    aid,
    a_name,
    companyName,
    currency
  ) => {

  

    if(currency==="Ruble"){
      setCurrencyValidationValue(500)
    }

    handleShowColumn();

    setAdvertiserAID(aid);

    setAdvertiserName(companyName.split("-")[1]);
    setDate(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    setCurrentBalance(balance);
    setCurrencySymbol(currency);
  };

  // modal toggle
  const handleShowColumn = () => setShowColumn(!showColumn);

  //on change function for modal
  // function onChangeDescription(event) {
  //   setDescription(event.target.value);
  // }

  function onChangeComment(event) {
    setComment(event.target.value);
  }

  function onChangeMode(event) {
    setSelectedMode(event);

    if (event.value === "paypal") {





      setDisplayType(false);

      setSelectedType({
        label: "Credit",
        value: "credit",
      });

      setExceedAmountError(false);

      if (amount <currencyValidaionValue && amount>0 ) {
        setAmountValidationError(true);
      }


    }
    if (event.value === "manual") {
      setDisplayType(true);

      setSelectedType({
        label: "Credit",
        value: "credit",
      });
    }
  }

  function onChangeType(event) {
    setSelectedType(event);

    if (event.value === "debit") {
      setAmountValidationError(false)

      if (amount != null) {
        if (amount > currentBalance) {
          setExceedAmountError(true);
        }
      }
    } else {
      setExceedAmountError(false);


      if (amount <currencyValidaionValue && amount>0 ) {
        setAmountValidationError(true);
      }

    }
  }

  function onChangeAmount(event) {
    setAmount(event.target.value);
    setAmountIsRequiredError(false);

    if (event.target.value <= 0) {
      setExceedAmountError(false);
      setAmountValidationError(false)
      setNegativeAmountError(true);
    } 
    
    else {
      setNegativeAmountError(false);



      if (selectedType.value === "debit") {
   
         setAmountValidationError(false)

        if (event.target.value > currentBalance) {
          setExceedAmountError(true);
        } else {
          setExceedAmountError(false);
        }
      }

      if (selectedType.value === "credit") {
        setExceedAmountError(false);

        if (event.target.value <currencyValidaionValue ) {
          setAmountValidationError(true);
        } else {
          setAmountValidationError(false);
        }


      }
    }


  }

  function onBlurAmount(event) {
    if (event.target.value.length === 0) {
      setExceedAmountError(false);
      setNegativeAmountError(false);
      setAmountValidationError(false)
      setAmountIsRequiredError(true);
    }
  }

  // reset Modal states on close

  const resetModalState = () => {
    setAdvertiserName(null);
    setDate(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    setCurrentBalance(null);
    setAmount(null);
    // setType("credit");
    // setMode("paypal");

    setSelectedType({
      label: "Credit",
      value: "credit",
    });

    setSelectedMode({
      label: "Paypal",
      value: "paypal",
    });

    setCurrencyValidationValue(5)
    setCurrencySymbol("$")
    setAmountValidationError(false)

    setComment(null);
    setAdvertiserAID(null);
    setDescription(null);
    setCurrencySymbol(null);
    setAmountIsRequiredError(false);
    setNegativeAmountError(false);
    setExceedAmountError(false);
  };

  const onSubmitModal = async (e) => {
    e.preventDefault();

    if (amount !== null) {
      if (
        amountIsRequiredError === false &&
        exceedAmountError === false &&
        negativeAmountError === false &&
        amountvalidationError===false
      ) {
        let payload = {
          amount: amount,
          mode: selectedMode.value,
          type: selectedType.value,
          description: description,
          comment: comment,
        };

        let token = Cookies.get("token_moment");

        setAddFundButtonValue(true);

        if (selectedMode.value === "paypal") {
          axios
            .post(
              configData.SERVER_URL +
              "funds/master/paypal/pay?aid=" +
              advertiserAID,
              payload,
              {
                headers: {
                  "Content-Type": "application/json",
                  "x-access-token": token,
                },
              }
            )
            .then((response) => {
              if (response.data.status === 201) {
                window.location.replace(response.data.data);
              } else {
                notifyPaycancel();
                setAddFundButtonValue(false);
              }
            })
            .catch((error) => {
              notifycatch();
              setAddFundButtonValue(false);
            });
        } else {
          axios
            .post(
              configData.SERVER_URL +
              "funds/admin/manual/pay?aid=" +
              advertiserAID,
              payload,
              {
                headers: {
                  "Content-Type": "application/json",
                  "x-access-token": token,
                },
              }
            )
            .then((response) => {
              if (response.data.status === 200) {
                notifysuccess(response.data.message);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                notifyfailure(response.data.message);
                setAddFundButtonValue(false);
              }
            })
            .catch((error) => {
              notifycatch();
              setAddFundButtonValue(false);
            });
        }
      }
    } else {
      if (amount === null) {
        setAmountIsRequiredError(true);
      }
    }
  };

  const modeOption = [
    { label: "Paypal", value: "paypal" },
    { label: "Manual", value: "manual" },
  ];

  const [selectedMode, setSelectedMode] = useState({
    label: "Paypal",
    value: "paypal",
  });

  const typeOption = [
    { label: "Credit", value: "credit" },
    { label: "Debit", value: "debit" },
  ];

  const [selectedType, setSelectedType] = useState({
    label: "Credit",
    value: "credit",
  });

  // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////// modal related code to add user//////////////////////////////

  // modal related code
  const [showModal, setShowModal] = useState(false);

  const [advertiserIdUserCreate, setAdvertiserIdUserCreate] = useState("");
  const handleShowModal = () => setShowModal(!showModal);

  const [fullName, setFullName] = useState("");
  const [fullNameIsRequiredError, setFullNameIsRequiredError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailIsRequiredError, setEmailIsRequiredError] = useState(false);
  const [emailUrlError, setEmailUrlError] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordIsRequiredError, setPasswordIsRequiredError] = useState(false);
  const [passwordStrengthError, setPasswordStrengthError] = useState(false);

  const [selectedRole, setSelectedRole] = useState([]);
  const [roleIsRequiredError, setRoleIsRequiredError] = useState(false);

  const [selectedIm, setSelectedIm] = useState({
    value: "telegram",
    label: "Telegram",
  });

  const [im, setIm] = useState("");
  // const [imIsRequiredError, setImIsRequiredError] = useState(false);

  const [emailToggle, setEmailToggle] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [buttonValue, setButtonValue] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function onChangeFullName(event) {
    setFullNameIsRequiredError(false);
    setFullName(event.target.value);
  }

  function onBlurFullName(event) {
    if (event.target.value.length === 0) {
      setFullNameIsRequiredError(true);
    }
  }

  function onChangeEmail(event) {
    setEmailIsRequiredError(false);
    setEmail(event.target.value);

    if (validateEmail(event.target.value) === null) {
      setEmailUrlError(true);
    } else {
      setEmailUrlError(false);
    }
  }

  function onBlurEmail(event) {
    if (event.target.value.length === 0) {
      setEmailUrlError(false);
      setEmailIsRequiredError(true);
    }
  }

  function onChangePassword(event) {
    setPasswordIsRequiredError(false);
    setPassword(event.target.value);

    if (event.target.value.length >= 8) {
      setPasswordStrengthError(false);
    } else {
      setPasswordStrengthError(true);
    }
  }

  function onBlurPassword(event) {
    if (event.target.value.length === 0) {
      setPasswordStrengthError(false);

      setPasswordIsRequiredError(true);
    }
  }

  function onChangeSelectedRole(event) {
    if (event.length !== 0) {
      setRoleIsRequiredError(false);
    }

    setSelectedRole(event);
  }

  function onChangeSelectedIm(event) {
    setSelectedIm(event);
  }

  function onChangeIm(event) {
    // setImIsRequiredError(false);
    setIm(event.target.value);
  }

  // function onBlurIm(event) {
  //   if (event.target.value.length === 0) {
  //     setImIsRequiredError(true);
  //   }
  // }

  function handleCreateRole() {
    if (
      fullName !== "" &&
      email !== "" &&
      password !== "" &&
      selectedRole.length !== 0
      // &&
      // im !== ""
    ) {
      if (
        fullNameIsRequiredError === false &&
        emailIsRequiredError === false &&
        passwordIsRequiredError === false &&
        emailUrlError === false &&
        roleIsRequiredError === false
        // &&
        // imIsRequiredError === false
      ) {
        let payload = {
          fullName: fullName,
          email: email,
          password: password,
          selectedIm: selectedIm.value,

          im: im,
          role: selectedRole,
          // notes: notes,
          emailNotification: emailToggle,
        };

        let token = Cookies.get("token_moment");
        setButtonValue(true);

        axios
          .post(
            configData.SERVER_URL +
            "user/createUser?advertiserId=" +
            advertiserIdUserCreate,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
              },
            }
          )
          .then((response) => {
            if (response.data.status === 201) {
              notifysuccess(response.data.message);
              setTimeout(() => {
                setButtonValue(false);
                window.location.reload();
              }, 1500);
            } else {
              notifyfailure(response.data.message);
              setButtonValue(false);
            }
          })
          .catch((error) => {
            notifycatch();
            setButtonValue(false);
          });
      }
    } else {
      if (fullName === "") {
        setFullNameIsRequiredError(true);
      }
      if (email === "") {
        setEmailIsRequiredError(true);
      }
      if (password === "") {
        setPasswordIsRequiredError(true);
      }
      if (selectedRole.length === 0) {
        setRoleIsRequiredError(true);
      }
      // if (im === "") {
      //   setImIsRequiredError(true);
      // }
    }
  }

  // modal related code for  edit user in  Table
  const [showModalEditTable, setShowModalEditTable] = useState(false);
  const [users, setUsers] = useState(null);

  const handleShowModalEditTable = () =>
    setShowModalEditTable(!showModalEditTable);

  const [editingUser, setEditingUser] = useState(null);
  const [editedUser, setEditedUser] = useState(null);

  const handleEditClick = (user) => {
    let tempData = user;

    let imOptionSelected = user.a_IM?.split(":")[0].trim();
    tempData["im"] = user.a_IM?.split(":")[1].trim();

    if (imOptionSelected === "telegram") {
      tempData["selectedIm"] = { value: "telegram", label: "Telegram" };
    } else if (imOptionSelected === "whatsapp") {
      tempData["selectedIm"] = { value: "whatsapp", label: "Whatsapp" };
    } else if (imOptionSelected === "skype") {
      tempData["selectedIm"] = { value: "skype", label: "Skype" };
    } else {
      tempData["selectedIm"] = { value: "gtalk", label: "Gtalk" };
    }

    setEditingUser(tempData);
    setEditedUser(tempData);
  };

  const handleInputChange = (event, type) => {
    if (type === "role" || type === "selectedIm") {
      setEditedUser({ ...editedUser, [type]: event });
    } else {
      setEditedUser({ ...editedUser, [type]: event.target.value });
      if (type === "fullName") {
        if (event.target.value.length === 0) {
          setFullNameIsRequiredError(true);
        } else {
          setFullNameIsRequiredError(false);
        }
      }

      if (type === "im") {
        // if (event.target.value.length === 0) {
        //   setImIsRequiredError(true);
        // } else {
        //   setImIsRequiredError(false);
        // }
      }
    }
  };

  const handleSaveClick = () => {
    if (editedUser.fullName !== "") {
      if (fullNameIsRequiredError === false) {
        let payload = {
          fullName: editedUser.fullName,
          selectedIm: editedUser.selectedIm.value,
          email: editedUser.email,
          im: editedUser.im,
          role: editedUser.role,
        };

        let token = Cookies.get("token_moment");

        axios
          .post(
            configData.SERVER_URL + `user/editUser?_id=` + editedUser._id,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
              },
            }
          )
          .then((response) => {
            if (response.data.status === 200) {
              notifysuccess(response.data.message);
              setActive(!active);
              setUsers(response.data.data);
            } else {
              notifyfailure(response.data.message);
            }
            setEditingUser(null);
            setEditedUser(null);
          })
          .catch((error) => {
            notifycatch();

            setEditingUser(null);
            setEditedUser(null);
          });
      }
    } else {
      if (editedUser.fullName === "") {
        setFullNameIsRequiredError(true);
      }

      // if (editedUser.im === "") {
      //   setImIsRequiredError(true);
      // }
    }
  };

  const handleDeleteClick = (user) => {
    setUserDeleteButton(true)
    let token = Cookies.get("token_moment");

    axios
      .post(
        configData.SERVER_URL + `user/deleteUser?_id=` + user._id,
        {},

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          notifysuccess(response.data.message);
          setActive(!active);
          setUsers(response.data.data);
          setUserDeleteId("")
          setShowModalDelete(false)
        } else {
          notifyfailure(response.data.message);
        }
        setEditingUser(null);
        setEditedUser(null);
        setUserDeleteButton(false)
      })
      .catch((error) => {
        notifycatch();

        setEditingUser(null);
        setEditedUser(null);
        setUserDeleteButton(false)
      });
  };

  const optionListIM = [
    { value: "telegram", label: "Telegram" },
    { value: "whatsapp", label: "Whatsapp" },
    { value: "skype", label: "Skype" },
    { value: "gtalk", label: "Gtalk" },
  ];

  const optionListRole = [
    { value: "Admin", label: "Admin" },
    { value: "Manager", label: "Manager" },
    { value: "Reporting Manager", label: "Reporting Manager" },
  ];



  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  /////modal confirm to delete code starts //////////////

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [userDeleteId, setUserDeleteId] = useState("");
  const [userDeleteName, setUserDeleteName] = useState("");
  const [userDeleteButton, setUserDeleteButton] = useState(false);
  const handleShowModalDelete = () => setShowModalDelete(!showModalDelete);



  /////modal confirm to delete code ends///////////////




  return (
    <>
      {/* <ToastContainer /> */}
      {/* <div className="main-content-wrap sidenav-open d-flex flex-column">
        {/* <!-- ============ Body content start ============= --> */}
      {/* <div className="main-content">
          <div className="breadcrumb">
            <ul>
              <li>Advertiser</li>
              <li> Advertiser List</li>
            </ul>
          </div> */}
      <div className="main_contain_area position-relative">

        <div className="">
          {initialLoader && (
            <div className="page_loader_gif">
              <img src={LoaderImage} alt="" className="img-fluid" />
            </div>
          )}

          <div className="table_area_spacing list_advertiser_table">
            <TableAdvertiser columns={settingHeader} data={data} loading={isLoading} initial={initialData} />
          </div>
        </div>
      </div>
      {/* </div> */}

      <Modal show={showColumn} size="lg" className="custom_modal_design" centered>
        <Modal.Header>
          <Modal.Title> Add Fund {" "}</Modal.Title>
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            onClick={() => {
              handleShowColumn();
              resetModalState();
            }}>
          </button>
        </Modal.Header>
        <Modal.Body className="">
          <div className="card-body">
            <form className="needs-validation" novalidate="novalidate">
              <Row>
                <Col md={6} className="">
                  <label for="validationCustom02">Company Name</label>
                  <input
                    className="form-control"
                    id="validationCustom02"
                    type="text"
                    placeholder=""
                    value={advertiserName}
                    disabled
                  />
                </Col>
                <Col md={6} className="">
                  <label for="validationCustomUsername">Date</label>
                  <input
                    className="form-control"
                    id="validationCustom02"
                    type="text"
                    placeholder=""
                    value={date}
                    required="required"
                    disabled
                  />
                </Col>
                <Col md={6} className="mt-20">
                  <label for="validationCustom03">
                    Mode of Payment<span className="text-danger">*</span>
                  </label>

                  <Select
                    className="custom_drop_design select-custom p-0"
                    options={modeOption}
                    placeholder="-Select-"
                    onChange={onChangeMode}
                    isSearchable={false}
                    value={selectedMode}
                  />
                </Col>
                <Col md={6} className="mt-20">
                  <label for="validationCustom03">
                    Type<span className="text-danger">*</span>
                  </label>
                  {displayType && (
                    <Select
                      className="custom_drop_design select-custom p-0"
                      options={typeOption}
                      onChange={onChangeType}
                      placeholder="-Select-"
                      isSearchable={false}
                      value={selectedType}
                    />
                  )}
                  {!displayType && (
                    <Select
                      className="custom_drop_design select-custom p-0"
                      options={typeOption}
                      onChange={onChangeType}
                      isSearchable={false}
                      placeholder="-Select-"
                      value={selectedType}
                      isDisabled={true}
                    />
                  )}
                </Col>
                <Col md={6} className="mt-20">
                  <label for="validationCustom04">Current Balance</label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1" className="bg-transparent custom_disabled">
                      {currencySymbol === "Euro" && <span>€ </span>}
                      {currencySymbol === "Dollar" && <span>$ </span>}
                      {currencySymbol === "Turkish Lira" && <span>₺ </span>}
                      {currencySymbol === "Indonesian Rupiah" && (<span>Rp </span>)}
                      {currencySymbol === "Yen" && <span>¥ </span>}
                      {currencySymbol === "Thai Bhat" && <span>฿ </span>}
                      {currencySymbol === "Ruble" && <span>₽ </span>}
                    </InputGroup.Text>
                    <Form.Control
                      className="border-start-0"
                      id="validationCustomUsername"
                      type="number"
                      placeholder="100"
                      aria-describedby="inputGroupPrepend"
                      required="required"
                      value={currentBalance}
                      disabled
                    />
                  </InputGroup>
                </Col>
                <Col md={6} className="mt-20">
                  <label for="validationCustom04">
                    Amount<span className="text-danger">*</span>
                  </label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1" className="bg-transparent">
                      {currencySymbol === "Euro" && <span>€ </span>}
                      {currencySymbol === "Dollar" && <span>$ </span>}
                      {currencySymbol === "Turkish Lira" && <span>₺ </span>}
                      {currencySymbol === "Indonesian Rupiah" && (<span>Rp </span>)}
                      {currencySymbol === "Yen" && <span>¥ </span>}
                      {currencySymbol === "Thai Bhat" && <span>฿ </span>}
                      {currencySymbol === "Ruble" && <span>₽ </span>}
                    </InputGroup.Text>
                    <Form.Control
                      id="validationCustomUsername"
                      className="border-start-0"
                      type="number"
                      aria-describedby="inputGroupPrepend"
                      required="required"
                      value={amount}
                      placeholder={currencyValidaionValue}
                      onChange={onChangeAmount}
                      onBlur={onBlurAmount}
                    />
                  </InputGroup>
                  {amountIsRequiredError && (
                    <span className="alert_text">

                      This field is required
                    </span>
                  )}
                  {exceedAmountError && (
                    <span className="alert_text">
                      Amount Cannot Exceed Current Balance
                    </span>
                  )}
                  {negativeAmountError && (
                    <span className="alert_text">
                      Amount should be greater than zero
                    </span>
                  )}

{amountvalidationError && (
                    <span className="alert_text">
                     Minimum amount {currencySymbol==="Ruble"?"₽":"$"} {" "} {currencyValidaionValue}
                    </span>
                  )}
                </Col>
                <Col md={12} className="mt-20">
                  <label for="validationCustom05">Comment</label>
                  <textarea
                    className="form-control"
                    id="validationCustom05"
                    type="text"
                    placeholder=""
                    required="required"
                    value={comment}
                    onChange={onChangeComment}
                  ></textarea>
                </Col>
              </Row>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          { }
          {!addFundButtonValue && (
            <button
              variant="secondary"
              className="btn default_btn btn-sm"
              onClick={onSubmitModal}
            >
              Submit
            </button>
          )}
          {addFundButtonValue && (
            <button variant="secondary" className="btn default_btn btn-sm">
              Processing
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal className="custom_modal_design" show={showModal} size="lg" centered>
        <Modal.Header>
          <Modal.Title> User </Modal.Title>
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            onClick={() => {
              handleShowModal();
              setAdvertiserIdUserCreate("");
              setFullName("");
              setEmail("");
              setPassword("");
              setIm("");
              setSelectedRole([]);

              setSelectedIm({
                value: "telegram",
                label: "Telegram",
              });
              setEmailToggle(false);
              setEmailToggle(false);
              setEmailToggle(false);
              setFullNameIsRequiredError(false);
              setEmailIsRequiredError(false);
              setEmailUrlError(false);
              setPasswordIsRequiredError(false);
              setPasswordStrengthError(false);
              setRoleIsRequiredError(false);
              // setImIsRequiredError(false);
            }}
          >
          </button>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col md={6} className="">
                <label for="validationCustom02">
                  {" "}
                  Full Name <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="role_fullname"
                  name="role_fullname"
                  value={fullName}
                  onBlur={onBlurFullName}
                  onChange={onChangeFullName}
                  placeholder="Full Name"
                ></input>
                {fullNameIsRequiredError && (
                  <span className="alert_text">

                    This field is required
                  </span>
                )}
              </Col>
              <Col md={6} className="">
                <label for="validationCustom02">
                  {" "}
                  Email <span className="text-danger">*</span>
                </label>

                <input
                  type="email"
                  className="form-control"
                  id="role_email"
                  name="role_email"
                  value={email}
                  onBlur={onBlurEmail}
                  onChange={onChangeEmail}
                  placeholder="Email Address"
                ></input>
                {emailIsRequiredError && (
                  <span className="alert_text">

                    This field is required
                  </span>
                )}

                {emailUrlError && (
                  <span className="alert_text">Invalid Email Address</span>
                )}
              </Col>
              <Col md={6} className="mt-20">
                <label for="validationCustom02">
                  Password <span className="text-danger">*</span>
                </label>

                <div className="position-relative">
                  <InputGroup className="">
                    <Form.Control
                      type={passwordVisibility ? "text" : "password"}
                      className="border-end-0"
                      id="role_password"
                      name="role_password"
                      value={password}
                      onBlur={onBlurPassword}
                      onChange={onChangePassword}
                      placeholder="Password"
                    />
                    <InputGroup.Text id="basic-addon1" className="p-0 bg-transparent border-start-0">
                      <span className="active_border_hide"
                        onClick={() => {
                          setPasswordVisibility(!passwordVisibility);
                        }}>
                        {!passwordVisibility && <img src={PassEye} className="img-fluid eye_abso_element_left abso_element_left" alt="" />}
                        {passwordVisibility && (<img src={PassEyeOff} className="img-fluid eye_abso_element_left abso_element_left" alt="" />)}
                      </span>
                    </InputGroup.Text>
                  </InputGroup>

                </div>



                {passwordIsRequiredError && (
                  <span className="alert_text">

                    This field is required
                  </span>
                )}
                {passwordStrengthError && (
                  <span className="alert_text">
                    Password should be of minimum 8 characters in length
                  </span>
                )}
              </Col>
              <Col md={6} className="mt-20">
                <label for="validationCustom02">
                  {" "}
                  Role <span className="text-danger">*</span>
                </label>

                <Select
                  className="custom_drop_design select-custom p-0"
                  options={optionListRole}
                  placeholder="-Select-"
                  isSearchable={true}
                  value={selectedRole}
                  onChange={onChangeSelectedRole}
                ></Select>
                {roleIsRequiredError && (
                  <span className="alert_text">

                    This field is required
                  </span>
                )}
              </Col>
              <Col md={12} className="mt-20">
                <Form.Label>IM</Form.Label>
                <div className="">
                  <InputGroup className="joined_input_fields">
                    <Select
                      className="custom_drop_design select-custom p-0"
                      options={optionListIM}
                      placeholder="-Select-" value={selectedIm} onChange={onChangeSelectedIm} />
                    <input className="form-control" id="validationCustom05" type="text" placeholder="IM" value={im} onChange={onChangeIm} />
                  </InputGroup>
                </div>
                {/* {imIsRequiredError && (
                  <span className="alert_text">
                    
                    This field is required
                  </span>
                )} */}
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center justify-content-end w-100">
      
            {/* <div className="form-check d-flex align-items-center">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={emailToggle} onClick={() => { setEmailToggle(!emailToggle); }} />
              <label className="form-check-label m-0 o-0 fn-13 ps-2" for="exampleCheck1">Send Login Credentials to This Email</label>
            </div> */}

            <div>
              {!buttonValue && (
                <button variant="secondary" className="default_btn btn"
                  onClick={handleCreateRole}
                >
                  Create
                </button>
              )}
              {buttonValue && (
                <button variant="secondary" className="default_btn btn">
                  Processing
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal className="custom_modal_design custom_modal_pop_over user_padding_modal_popup" show={showModalEditTable} size="xl" centered>
        <Modal.Header>
          <Modal.Title> User {" "} </Modal.Title>
          <div className="d-flex justify-content-end align-items-center">
            <span className="text-decoration-none pe-3 hyper_link_clr fs-13" onClick={() => {
              setShowModalEditTable(false);
              setShowModal(true);
              setEditingUser(null);
              setEditedUser(null);
              setUsers(null);

              setFullNameIsRequiredError(false);
              // setImIsRequiredError(false);
            }} >
              <span className="text_hover_colour d-flex cursor_pointer">
                <svg width="16" height="16" className="hover_icon mtt-1" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.9987 12.6667C7.80981 12.6667 7.65159 12.6027 7.52403 12.4747C7.39603 12.3472 7.33203 12.1889 7.33203 12V8.66671H3.9987C3.80981 8.66671 3.65136 8.60271 3.52336 8.47471C3.39581 8.34715 3.33203 8.18893 3.33203 8.00004C3.33203 7.81115 3.39581 7.65271 3.52336 7.52471C3.65136 7.39715 3.80981 7.33337 3.9987 7.33337H7.33203V4.00004C7.33203 3.81115 7.39603 3.65271 7.52403 3.52471C7.65159 3.39715 7.80981 3.33337 7.9987 3.33337C8.18759 3.33337 8.34603 3.39715 8.47403 3.52471C8.60159 3.65271 8.66536 3.81115 8.66536 4.00004V7.33337H11.9987C12.1876 7.33337 12.3458 7.39715 12.4734 7.52471C12.6014 7.65271 12.6654 7.81115 12.6654 8.00004C12.6654 8.18893 12.6014 8.34715 12.4734 8.47471C12.3458 8.60271 12.1876 8.66671 11.9987 8.66671H8.66536V12C8.66536 12.1889 8.60159 12.3472 8.47403 12.4747C8.34603 12.6027 8.18759 12.6667 7.9987 12.6667Z" fill="#7375F2" />
                </svg> Add new user</span>
            </span>
            <button
              type="button"
              data-dismiss="modal"
              onClick={() => {
                setShowModalEditTable(false);
                setEditingUser(null);
                setEditedUser(null);
                setUsers(null);

                setFullNameIsRequiredError(false);
                // setImIsRequiredError(false);
              }}
            >
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="pb-1 pt-0 pe-0 ps-0">
          <>
            <Table className="table_radius_cls user_modal_table display editable_user_list_modal table nowrap custom_data_table table-advertiser-hidden table-advertiser-visible m-0">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>IM</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users?.length === 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>No Records Found.</td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                {users?.map((user) => (
                  <tr key={user._id}>
                    <td className="firs_td_name_text">
                      {editingUser?._id === user._id ? (
                        <>
                          <input
                            className="form-control"
                            type="text"
                            name="fullName"
                            value={editedUser?.fullName}
                            onChange={(e) => {
                              handleInputChange(e, "fullName");
                            }}
                          />
                          {fullNameIsRequiredError && (
                            <span className="alert_text">

                              This field is required
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="name_text_round">
                            <span>{user.fullName.charAt(0)}</span>
                          </div>
                          {user.fullName}</>
                      )}
                    </td>

                    <td>
                      {editingUser?._id === user._id ? (
                        <>
                          <input
                            className="form-control"
                            type="text"
                            value={user.email}
                            disabled
                          />
                        </>
                      ) : (
                        <>{user.email}</>
                      )}
                    </td>

                    <td>
                      {editingUser?._id === user._id ? (
                        <>
                          <Select
                            className="custom_select_width_design custom_drop_design select-custom p-0"
                            options={optionListRole}
                            placeholder="-Select-"
                            isSearchable={true}
                            value={editedUser?.role}
                            onChange={(e) => {
                              handleInputChange(e, "role");
                            }}
                          ></Select>
                        </>
                      ) : (
                        <>{user.role.value}</>
                      )}
                    </td>

                    <td>

                      {editingUser?._id === user._id ? (
                        <>
                          <div className="">
                            <InputGroup className="joined_input_fields">
                              <Select
                                className="custom_drop_design select-custom p-0"
                                options={optionListIM}
                                placeholder="-Select-"
                                value={editedUser?.selectedIm}
                                onChange={(e) => {
                                  handleInputChange(e, "selectedIm");
                                }}
                              />
                              <Form.Control
                                className="border-left-0 form-control"
                                id="validationCustom05"
                                type="text"
                                placeholder="IM"
                                value={editedUser?.im}
                                onChange={(e) => {
                                  handleInputChange(e, "im");
                                }}
                              />
                            </InputGroup>
                          </div>
                          {/* {imIsRequiredError && (
                            <span className="alert_text">
                              
                              This field is required
                            </span>
                          )} */}
                        </>
                      ) : (
                        <>
                          {user.a_IM?.split(":")[0].trim() === "telegram" ? <img src={telegramLogo} className="img-fluid modal_img_social" alt="" /> :
                            user.a_IM?.split(":")[0].trim() === "whatsapp" ? <img src={whatsappLogo} className="img-fluid modal_img_social" alt="" /> :
                              user.a_IM?.split(":")[0].trim() === "gtalk" ? <img src={gtalkLogo} className="img-fluid modal_img_social" alt="" /> :
                                user.a_IM?.split(":")[0].trim() === "skype" ? <img src={skypeLogo} className="img-fluid modal_img_social" alt="" /> :
                                  <></>}
                          {/* {user.a_IM?.split(":")[1].trim().length>0?(<>:</>):<></>} */}
                          {user.a_IM?.split(":")[1].trim()}
                        </>
                      )}
                    </td>

                    <td>
                      {editingUser?._id === user._id ? (
                        <>
                          <Button
                            variant=""
                            className=" btn-sm p-1 px-2"
                            onClick={handleSaveClick}>
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> Save </Tooltip>}>
                            <svg width="20" height="20" className="edit_hover" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.36667 11.7167C6.27778 11.7167 6.19444 11.7027 6.11667 11.6747C6.03889 11.6471 5.96667 11.6 5.9 11.5333L3.03333 8.66666C2.91111 8.54444 2.85267 8.386 2.858 8.19133C2.86378 7.99711 2.92778 7.83888 3.05 7.71666C3.17222 7.59444 3.32778 7.53333 3.51667 7.53333C3.70556 7.53333 3.86111 7.59444 3.98333 7.71666L6.36667 10.1L12.0167 4.45C12.1389 4.32777 12.2973 4.26666 12.492 4.26666C12.6862 4.26666 12.8444 4.32777 12.9667 4.45C13.0889 4.57222 13.15 4.73044 13.15 4.92466C13.15 5.11933 13.0889 5.27777 12.9667 5.4L6.83333 11.5333C6.76667 11.6 6.69444 11.6471 6.61667 11.6747C6.53889 11.7027 6.45556 11.7167 6.36667 11.7167V11.7167Z" fill="#BDBDBD" />
                            </svg>
                            </OverlayTrigger>
                          </Button>
                          <Button
                            variant=""
                            className="delete_user_table btn-sm p-1 px-2"
                            onClick={() => {
                              setFullNameIsRequiredError(false);
                              // setImIsRequiredError(false);
                              setEditingUser(null);
                              setEditedUser(null);
                            }}>
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> Cancel </Tooltip>}>
                            <svg width="20" height="20" className="delete_icon_svg" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.00003 8.93334L4.73337 12.2C4.61114 12.3222 4.45559 12.3833 4.2667 12.3833C4.07781 12.3833 3.92225 12.3222 3.80003 12.2C3.67781 12.0778 3.6167 11.9222 3.6167 11.7333C3.6167 11.5444 3.67781 11.3889 3.80003 11.2667L7.0667 8L3.80003 4.73334C3.67781 4.61111 3.6167 4.45556 3.6167 4.26667C3.6167 4.07778 3.67781 3.92222 3.80003 3.8C3.92225 3.67778 4.07781 3.61667 4.2667 3.61667C4.45559 3.61667 4.61114 3.67778 4.73337 3.8L8.00003 7.06667L11.2667 3.8C11.3889 3.67778 11.5445 3.61667 11.7334 3.61667C11.9223 3.61667 12.0778 3.67778 12.2 3.8C12.3223 3.92222 12.3834 4.07778 12.3834 4.26667C12.3834 4.45556 12.3223 4.61111 12.2 4.73334L8.93337 8L12.2 11.2667C12.3223 11.3889 12.3834 11.5444 12.3834 11.7333C12.3834 11.9222 12.3223 12.0778 12.2 12.2C12.0778 12.3222 11.9223 12.3833 11.7334 12.3833C11.5445 12.3833 11.3889 12.3222 11.2667 12.2L8.00003 8.93334Z" fill="#BDBDBD" />
                            </svg>                            
                            </OverlayTrigger>
                          </Button>
                        </>
                      ) : (
                        <>
                        
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> Edit </Tooltip>}>
                          <svg width="20" height="20" className="edit_hover me-10" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                            setFullNameIsRequiredError(false);
                            // setImIsRequiredError(false);
                            handleEditClick(user);
                          }}>
                            <path d="M3.33333 12.6667H4.26667L10.0167 6.91671L9.08333 5.98337L3.33333 11.7334V12.6667ZM12.8667 5.95004L10.0333 3.15004L10.9667 2.21671C11.2222 1.96115 11.5362 1.83337 11.9087 1.83337C12.2807 1.83337 12.5944 1.96115 12.85 2.21671L13.7833 3.15004C14.0389 3.4056 14.1722 3.71404 14.1833 4.07537C14.1944 4.43626 14.0722 4.74449 13.8167 5.00004L12.8667 5.95004ZM2.66667 14C2.47778 14 2.31956 13.936 2.192 13.808C2.064 13.6805 2 13.5223 2 13.3334V11.45C2 11.3612 2.01667 11.2752 2.05 11.192C2.08333 11.1085 2.13333 11.0334 2.2 10.9667L9.06667 4.10004L11.9 6.93337L5.03333 13.8C4.96667 13.8667 4.89178 13.9167 4.80867 13.95C4.72511 13.9834 4.63889 14 4.55 14H2.66667ZM9.55 6.45004L9.08333 5.98337L10.0167 6.91671L9.55 6.45004Z" fill="#BDBDBD" />
                          </svg>
                          </OverlayTrigger>

                          <OverlayTrigger placement="top" delay={{ show: 250, hide: 50 }} overlay={<Tooltip id="button-tooltip"> Delete </Tooltip>}>
                          <svg className="delete_icon_svg" width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                            onClick={() => {
                              // handleDeleteClick(user);
                              setUserDeleteId(user)
                              setShowModalDelete(true)
                            }}>
                            <path d="M4.66602 14C4.29935 14 3.98557 13.8696 3.72468 13.6087C3.46335 13.3473 3.33268 13.0333 3.33268 12.6667V4C3.14379 4 2.98535 3.93622 2.85735 3.80867C2.72979 3.68067 2.66602 3.52222 2.66602 3.33333C2.66602 3.14444 2.72979 2.986 2.85735 2.858C2.98535 2.73044 3.14379 2.66667 3.33268 2.66667H5.99935C5.99935 2.47778 6.06335 2.31933 6.19135 2.19133C6.3189 2.06378 6.47713 2 6.66602 2H9.33268C9.52157 2 9.68002 2.06378 9.80802 2.19133C9.93557 2.31933 9.99935 2.47778 9.99935 2.66667H12.666C12.8549 2.66667 13.0131 2.73044 13.1407 2.858C13.2687 2.986 13.3327 3.14444 13.3327 3.33333C13.3327 3.52222 13.2687 3.68067 13.1407 3.80867C13.0131 3.93622 12.8549 4 12.666 4V12.6667C12.666 13.0333 12.5356 13.3473 12.2747 13.6087C12.0133 13.8696 11.6993 14 11.3327 14H4.66602ZM4.66602 4V12.6667H11.3327V4H4.66602ZM5.99935 10.6667C5.99935 10.8556 6.06335 11.0138 6.19135 11.1413C6.3189 11.2693 6.47713 11.3333 6.66602 11.3333C6.8549 11.3333 7.01335 11.2693 7.14135 11.1413C7.2689 11.0138 7.33268 10.8556 7.33268 10.6667V6C7.33268 5.81111 7.2689 5.65267 7.14135 5.52467C7.01335 5.39711 6.8549 5.33333 6.66602 5.33333C6.47713 5.33333 6.3189 5.39711 6.19135 5.52467C6.06335 5.65267 5.99935 5.81111 5.99935 6V10.6667ZM8.66602 10.6667C8.66602 10.8556 8.73002 11.0138 8.85802 11.1413C8.98557 11.2693 9.14379 11.3333 9.33268 11.3333C9.52157 11.3333 9.68002 11.2693 9.80802 11.1413C9.93557 11.0138 9.99935 10.8556 9.99935 10.6667V6C9.99935 5.81111 9.93557 5.65267 9.80802 5.52467C9.68002 5.39711 9.52157 5.33333 9.33268 5.33333C9.14379 5.33333 8.98557 5.39711 8.85802 5.52467C8.73002 5.65267 8.66602 5.81111 8.66602 6V10.6667ZM4.66602 4V12.6667V4Z" fill="#BDBDBD" />
                          </svg>
                          </OverlayTrigger>

                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        </Modal.Body>
      </Modal >



      <Modal className="custom_modal_design delete_modal_popup_design second_modal_popup_design" show={showModalDelete} size="" centered>
        <Modal.Body>
          <div className="text-center">
            <Button
              type="button"
              className="btn user_delete_modal"
              data-dismiss="modal"
              onClick={() => {
                setUserDeleteId("")
                setShowModalDelete(false)
              }}
            >
            </Button>

            <img src={DeleteInfo} className="img-fluid" alt="" />
            <h2>Are you Sure? </h2>
            <p>Are you sure you want to delete the user <strong>"{userDeleteId?.fullName}"?</strong></p>

            <Button type="button" className="btn border_cancel_btn" onClick={() => {
              setUserDeleteId("")
              setShowModalDelete(false)
            }}> Cancel
            </Button>

            {!userDeleteButton &&
              <Button type="button" className="btn fill_confirm_btn" onClick={() => {

                handleDeleteClick(userDeleteId)
                // let token = Cookies.get("token_moment");
                // axios
                //   .post(
                //     configData.SERVER_URL + `user/deleteUser?_id=` + userDeleteId,
                //     {},

                //     {
                //       headers: {
                //         "Content-Type": "application/json",
                //         "x-access-token": token,
                //       },
                //     }
                //   )
                //   .then((response) => {
                //     if (response.data.status === 200) {
                //       notifysuccess(response.data.message);
                //       setUserDeleteId("")

                //       setShowModalDelete(false)
                //       setActive(!active);
                //     }
                //     else {
                //         notifyfailure(response.data.message);
                //       }

                //       setUserDeleteButton(false)
                //   })
                //   .catch((error) => {
                //     notifycatch();

                //     setUserDeleteButton(false)

                //   });
              }}>
                Confirm
              </Button>
            }

            {userDeleteButton &&
              <Button type="button" className="btn fill_confirm_btn" onClick={() => {

              }}>
                Processing
              </Button>
            }


          </div>
        </Modal.Body>

      </Modal>


    </>
  );
}
