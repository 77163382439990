import React, { useState, useContext} from "react";
import { InputGroup } from "react-bootstrap";
import LogoHolder from "../assets/images/placeholder-img.svg";
import { Form } from "react-bootstrap";
import { LoadingContext } from "react-router-loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import configData from "../config/config.json";
import constData from "../utils/constantData.json";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { Switch } from 'antd';
import {
  TokenVerificationLoggedin,
  BlockCreateAdvertiser,
  ScreenViewCreate,
} from "../utils/tokenVerify";

import Select from "react-select";
import constantData from "../utils/constantData.json";

const CreateAdvertiser = () => {
  const loadingContext = useContext(LoadingContext);

  // const [checkRole, setCheckRole] = useState("MASTER");

  React.useEffect(() => {
    TokenVerificationLoggedin();
    ScreenViewCreate();

    BlockCreateAdvertiser();


    let status= Cookies.get("token_moment")?true:false;
    if(status){
    let token = Cookies.get("token_moment");
    // let role = jwt_decode(token).role;
    // setCheckRole(role);

    axios
      .get(
        configData.SERVER_URL + "user/userDetails",

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setName(response.data.data.fullName);
          setCompanyName(response.data.data.companyName);
          setEmail(response.data.data.email);
        }
      })
      .catch((error) => { });


    }


      loadingContext.done()

    

  }, []);

  React.useEffect(() => {
    // country list code starts
    axios
      .get(configData.SERVER_URL + "advertiser/countryMinifiedList", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let makingData = [];

          makingData = response.data.data.map((val) => ({
            value: val.ISO,
            label: val.Country,
          }));

          setSelectCountryList(makingData);
        }
      })
      .catch((error) => { });

    // country list code ends
  }, []);

  const notifysuccess = (msg) => toast.success(msg, { autoClose: 2000 });
  const notifyfailure = (msg) => toast.error(msg, { autoClose: 2000 });
  const notifycatch = () =>
    toast.error("Something went wrong", { autoClose: 2000 });

  const [selectCountryList, setSelectCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [currency, setCurrency] = useState({ "value": "Dollar", "label": "Dollar" });
  const [currencyIsRequiredError, setCurrencyIsRequiredError] = useState(false);

  const [logo, setLogo] = useState({ data: "", preview: "" });

  const [logoIsRequiredError, setLogoIsRequiredError] = useState(false);
  const [logoValidError, setLogoValidError] = useState(false);

  const [name, setName] = useState(null);
  const [nameIsRequiredError, setNameIsRequiredError] = useState(false);
  const [nameValidError, setNameValidError] = useState(false);

  const [companyName, setCompanyName] = useState(null);
  const [companyNameIsRequiredError, setCompanyNameIsRequiredError] =
    useState(false);

  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [state, setState] = useState(null);
  const [postal, setPostal] = useState(null);
  const [countryIsRequiredError, setCountryIsRequiredError] = useState(false);

  const [im, setIm] = useState("");
  const [imIsRequiredError, setImIsRequiredError] = useState(false);

  const [selectedIm, setSelectedIm] = useState([]);

  const [selectedSm, setSelectedSm] = useState([]);
  const [selectedCSM, setSelectedCSM] = useState([]);

  const [buttonValue, setButtonValue] = useState(false);

  const regexName= /^[a-zA-Z0-9_]{3,30}$/

    //email alert
    const [emailAlert, setEmailAlert] = useState(false);

    function onChangeEmailAlert(event) {
      setEmailAlert(event);
    }
  

  function onChangeLogo(e) {
    if (e.target.files[0] === undefined) {
      // setLogoValue("");
      setLogoValidError(false);
      setLogoIsRequiredError(true);
      setLogo({
        data: "",
        preview: "",
      });
    } else {
      if (e.target.files[0].type.includes("image")) {

      
       if(((e.target.files[0].size)/(1024*1024))<2){

       

        setLogoIsRequiredError(false);
        setLogoValidError(false);
        // setLogoValue(e.target.files[0].name);
        setLogo({
          data: e.target.files[0],
          preview: URL.createObjectURL(e.target.files[0]),
        });
      }
      else{
        toast.error("File Size Should be less than 2 MB")
      }
      } else {
        // setLogoValue("");
        setLogoIsRequiredError(false);
        setLogo({
          data: "",
          preview: "",
        });

        setLogoValidError(true);
      }
    }
  }

  // function onChangeLogoReset(e) {
  //   setLogoIsRequiredError(true);
  //   setLogoValidError(false);
  //   document.getElementById("uploadLogo").value = "";

  //   setLogo({
  //     data: "",
  //     preview: "",
  //   });
  // }

  function onChangeName(event) {
    setNameIsRequiredError(false);

    // if(regexName.test(event.target.value)){
    //   setNameValidError(false)

    // }
    // else{
    //   setNameValidError(true)
    // }

    setName(event.target.value);
  }

  function onBlurName(event) {
    if (event.target.value.length === 0) {
      // setNameValidError(false)
      setNameIsRequiredError(true);
    }
  }

  function onChangeCompanyName(event) {
    setCompanyNameIsRequiredError(false);
    setCompanyName(event.target.value);
  }

  function onBlurCompanyName(event) {
    if (event.target.value.length === 0) {
      setCompanyNameIsRequiredError(true);
    }
  }

  function onChangeSM(event) {
    setSelectedSm(event);
  }

  function onChangeCSM(event) {
    setSelectedCSM(event);
  }

  function onChangeAddress(event) {
    setAddress(event.target.value);
  }

  function onChangeState(event) {
    setState(event.target.value);
  }

  function onChangePostal(event) {
    setPostal(event.target.value);
  }

  function onChangeCountry(event) {
    setCountryIsRequiredError(false);
    setSelectedCountry(event);
  }

  function onChangeCurrency(event) {
    setCurrencyIsRequiredError(false);
    setCurrency(event);
  }

  function onChangeIm(event) {
    if (event.target.value.length === 0) {
      setImIsRequiredError(false);
    }
    setIm(event.target.value);
  }

  function onChangeSelectedIm(event) {
    if (event === null && im !== "") {
      setImIsRequiredError(true);
      setSelectedIm([]);
    } else {
      setImIsRequiredError(false);
      setSelectedIm(event);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      logo.preview !== "" &&
      name !== null &&
      companyName !== null &&
      selectedCountry.length !== 0 &&
      currency.length !== 0
    ) {
      if (
        nameIsRequiredError === false &&
        companyNameIsRequiredError === false &&
        countryIsRequiredError === false &&
        currencyIsRequiredError === false &&
        logoIsRequiredError === false &&
        logoValidError === false 
        // nameValidError===false
      ) {
        if (im !== "" && selectedIm.length === 0) {
          setImIsRequiredError(true);
          return;
        }

        let formData = new FormData();

        formData.append("name", name);
        formData.append("company", companyName);
        formData.append("email", email);
        formData.append("address", address);
        formData.append("state", state);
        formData.append("postalCode", postal);

        formData.append("country", selectedCountry.value);
        formData.append("currency", currency.value);
        // formData.append("sm", JSON.stringify(selectedSm));
        // formData.append("csm", JSON.stringify(selectedCSM));
        formData.append(selectedIm.value, im);

        formData.append("logo", logo.data);
        formData.append("emailAlert",emailAlert)

        let token = Cookies.get("token_moment");

        setButtonValue(true);
        axios
          .post(
            configData.SERVER_URL + "advertiser/createAdvertiser",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "x-access-token": token,
              },
            }
          )
          .then((response) => {
            if (response.data.status === 201) {
              notifysuccess(response.data.message);
              setTimeout(() => {
                setButtonValue(false);
                window.location.replace("/addFund");
              }, 2000);
            } else {
              notifyfailure(response.data.message);
              setButtonValue(false);
            }
          })
          .catch((error) => {
            notifycatch();
            setButtonValue(false);
          });
      }
    } else {
      if (name === "") {
        setNameIsRequiredError(true);
      }
      if (companyName === "") {
        setCompanyNameIsRequiredError(true);
      }

      if (selectedCountry.length === 0) {
        setCountryIsRequiredError(true);
      }

      if (currency.length === 0) {
        setCurrencyIsRequiredError(true);
      }

      if (logo.preview === "") {
        if (!logoValidError) {
          setLogoIsRequiredError(true);
        }
      }

      if (im !== "" && selectedIm.length === 0) {
        setImIsRequiredError(true);
        return;
      }
    }
  };

  return (
    <div className="main_contain_area">
      <div className="step_area">
        <h5>Setup your Advertiser Account</h5>
        <div className="steps_buttons">
          <ul className="p-0 m-0">
            <li className="active_steps">
              <div className="step_count">
                <p>1</p>
                <span>Create Account</span>
              </div>
            </li>
            <li>
              <hr className="step_line" />
            </li>
            <li>
              <div className="step_count">
                <p>2</p>
                <span>Add Fund</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="scroll_area">
        <div className="step_form_area">
          <div className="custom_container_form">
            <div className="row_devider d-flex">
              <div className="custom_col_space_label">
                <div className="step_form_label">
                  <h4>Basic Information</h4>
                </div>
              </div>
              <div className="step_input_fields">
                <div className="w-440">
                  <Form.Label>Company Logo*</Form.Label>
                  <div className="upload_logo_area">
                    <div className="d-flex align-items-center">
                      <img src={logo.preview ? logo.preview : LogoHolder} alt="user-avatar" className="img-fluid" id="uploadedAvatar" />
                      <div className="ps-3">
                        <p>Upload an Advertiser logo.</p>
                        <span>Max size 2MB</span>
                      </div>
                    </div>
                    <div className="upload_btn_img">
                      <Form.Control type="file" accept="image/png, image/jpeg,image/jpg" onChange={onChangeLogo} id="uploadLogo" />
                      <span>Browse</span>
                    </div>
                  </div>
                  {logoIsRequiredError && (
                    <span className="alert_text">This field is required</span>
                  )}
                  {logoValidError && (
                    <span className="alert_text">Invalid Image Format</span>
                  )}
                </div>
                <div className="d-flex">
                  <div className="w-440 me-16">
                    <Form.Group className="mt-20" controlId="exampleForm.ControlInput1">
                      <Form.Label>Name*</Form.Label>
                      <Form.Control type="text" value={name} placeholder="Name" onChange={onChangeName} onBlur={onBlurName} />
                      {nameIsRequiredError && (
                        <span className="alert_text">
                          This field is required
                        </span>
                      )}
                        {/* {nameValidError && (
                          <span className="alert_text">
                           Value must be alphanumeric or with underscore ranging between 3-30 characters.
                          </span>
                        )} */}
                    </Form.Group>
                  </div>
                  <div className="w-440">
                    <Form.Group
                      className="mt-20"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Company/Advertiser*</Form.Label>
                      <Form.Control
                        type="text"
                        value={companyName}
                        placeholder="Company Name"
                        onChange={onChangeCompanyName}
                        onBlur={onBlurCompanyName}
                      />
                      {companyNameIsRequiredError && (
                        <span className="alert_text">
                          This field is required
                        </span>
                      )}
                    </Form.Group>
                  </div>
                </div>
                {/* <div className="w-440">
                  <Form.Group
                    className="mt-20"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Default Currency*</Form.Label>
                    <Select
                      className="custom_drop_design select-custom p-0"
                      options={constData.CURRENCY_OPTIONS}
                      placeholder="-Select-"
                      value={currency}
                      onChange={onChangeCurrency}
                      isSearchable={true}
                    ></Select>
                    {currencyIsRequiredError && (
                      <span className="alert_text">This field is required</span>
                    )}
                  </Form.Group>
                </div> */}
              </div>
            </div>
            <div className="divider_line"></div>
            <div className="row_devider d-flex">
              <div className="custom_col_space_label">
                <div className="step_form_label">
                  <h4>Address</h4>
                </div>
              </div>
              <div className="step_input_fields">
                <div className="d-flex">
                  <div className="w-356 me-16">
                    <Form.Group
                      className=""
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Country*</Form.Label>
                      <Select
                        className="custom_drop_design select-custom p-0"
                        options={selectCountryList}
                        placeholder="-Select-"
                        value={selectedCountry}
                        onChange={onChangeCountry}
                        isSearchable={true}
                      ></Select>
                      {countryIsRequiredError && (
                        <span className="alert_text">
                          This field is required
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="w-356 me-16">
                    <Form.Group
                      className=""
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        value={state}
                        onChange={onChangeState}
                        placeholder="State"
                      />
                    </Form.Group>
                  </div>
                  <div className="w-153">
                    <Form.Group
                      className=""
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        type="text"
                        value={postal}
                        onChange={onChangePostal}
                        placeholder="Postal Code"
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="w-textarea me-16">
                  <Form.Group className="mt-20" controlId="exampleForm.ControlInput1">
                    <Form.Label>Address</Form.Label>
                    <textarea name="" id="" className="form-control" cols="30" rows="10" value={address} onChange={onChangeAddress} placeholder="Address"></textarea>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="divider_line"></div>
            <div className="row_devider d-flex">
              <div className="custom_col_space_label">
                <div className="step_form_label">
                  <h4>Contact</h4>
                </div>
              </div>
              <div className="step_input_fields">
                <div className="d-flex">
                  <div className="w-440 me-16">
                    <Form.Group className="" controlId="exampleForm.ControlInput1">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control type="text" value={email} disabled />
                    </Form.Group>
                  </div>
                  <div className="w-440">
                    <Form.Group className="" controlId="exampleForm.ControlInput1">
                      <Form.Label>IM</Form.Label>
                      <InputGroup className="joined_input_fields">
                        <Select
                          className="custom_drop_design select-custom p-0"
                          options={constantData.IM_OPTIONS}
                          placeholder="-Select-"
                          value={selectedIm}
                          onChange={onChangeSelectedIm}
                          // isClearable={true}
                        />
                        <Form.Control
                          type="text"
                          placeholder="IM"
                          className=""
                          value={im}
                          onChange={onChangeIm}
                        />

</InputGroup>
                        {imIsRequiredError && (
                          <span className="alert_text">
                            Please choose IM type.
                          </span>
                        )}
                  
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

          {/* {checkRole!=="SA" &&
          <>
            <div className="divider_line"></div>
            <div className="row_devider d-flex">
              <div className="custom_col_space_label">
                <div className="step_form_label">
                  <h4>Assigned To</h4>
                </div>
              </div>
              <div className="step_input_fields">
                <div className="d-flex">
                  <div className="w-440 me-16">
                    <Form.Group
                      className=""
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Sales Manager</Form.Label>
                      <Select
                        className="custom_drop_design select-custom p-0"
                        options={constData.SM}
                        placeholder="-Select-"
                        value={selectedSm}
                        onChange={onChangeSM}
                        isSearchable={true}
                        isMulti
                      ></Select>
                    </Form.Group>
                  </div>
                  <div className="w-440">
                    <Form.Group className="" controlId="exampleForm.ControlInput1">
                      <Form.Label>Client Success Manager</Form.Label>
                      <Select
                        className="custom_drop_design select-custom p-0"
                        options={constData.CSM}
                        placeholder="-Select-"
                        value={selectedCSM}
                        onChange={onChangeCSM}
                        isSearchable={true}
                        isMulti
                      ></Select>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            </>
} */}



<div className="divider_line"></div>
              <div className="row_devider d-flex">
                <div className="custom_col_space_label">
                  <div className="step_form_label">
                    <h4>Email alerts</h4>
                  </div>
                </div>
                <div className="step_input_fields">
                <div className="w-440">
                  <Form.Label> Toggle email alerts for account activity</Form.Label>
                  <Form.Group className="" controlId="">
                    <InputGroup className="">
                      <InputGroup.Text id="basic-addon2" className="form-control bg-transparent d-flex justify-content-between">
                  
                        <span className={ emailAlert ? "green_text" : "disabled_text"}>
                          {emailAlert ? "Enabled" : "Disabled"}
               
                        </span>
                        <Switch
                            checked={emailAlert}
                            onChange={onChangeEmailAlert}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </div>
                </div>
              </div>


            <div className="divider_line"></div>
            <div className="button_area_bottom d-flex justify-content-end">
              <div className="">
                {!buttonValue && (
                  <button
                    className="default_btn btn"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Next                       
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.52888 3.12924C7.78956 2.87313 8.20795 2.87498 8.46636 3.13339L12.7673 7.43435C13.0797 7.74677 13.0797 8.25331 12.7673 8.56573L8.46636 12.8667C8.20795 13.1251 7.78956 13.1269 7.52888 12.8708C7.26491 12.6115 7.26303 12.1867 7.52469 11.925L10.783 8.66671L3.33301 8.66671C2.96482 8.66671 2.66634 8.36823 2.66634 8.00004C2.66634 7.63185 2.96482 7.33337 3.33301 7.33337L10.783 7.33337L7.52469 4.07506C7.26303 3.8134 7.26491 3.38858 7.52888 3.12924Z" fill="white" />
</svg>
                  </button>
                )}

                {buttonValue && (
                  <button className="default_btn btn" type="button" disabled>
                    Processing
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdvertiser;
